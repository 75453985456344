// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc.
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business
// eslint-disable-next-line import/no-unresolved

import { AlwaysOnFeatures, Features } from 'types/Features';
import { RouteComponent } from 'types/Routes';

import lazy from 'utils/lazy';

export default function createRoutes(): RouteComponent[] {
  return [
    {
      path: '/home',
      name: Features.Home,
      element: lazy(() => import('./HomePage')),
    },
    {
      path: '/frontPage',
      name: Features.FrontPage,
      element: lazy(() => import('./FrontPage')),
    },
    {
      path: '/',
      exact: true,
      name: Features.Root,
      element: lazy(() => import('sharedComponents/Redirect/')),
    },
    {
      path: '/login',
      exact: true,
      public: true,
      name: Features.Login,
      element: lazy(() => import('Login/LoginRegistered/loadable')),
    },
    {
      path: '/createUser',
      public: true,
      name: Features.CreateUser,
      element: lazy(() => import('./Login/CreateUser/loadable')),
    },
    {
      path: '/activation/:emailToken',
      public: true,
      name: Features.Activation,
      element: lazy(() => import('./Login/Activation/loadable')),
    },
    {
      path: '/register',
      public: true,
      name: Features.LoginRegister,
      element: lazy(() => import('./Login/Register/loadable')),
    },
    {
      path: '/dvvCardLogin',
      public: true,
      name: Features.DVVSmartCardLogin,
      element: lazy(() => import('Login/DVVCardLogin/DVVCardLogin')),
    },
    {
      path: '/calendar',
      name: Features.Calendar,
      element: lazy(() => import('./Calendar')),
    },
    {
      path: '/servicePacket/:customerId',
      name: Features.ServicePackets,
      element: lazy(() => import('./Customers/components/ServicePackets')),
    },
    {
      path: '/careplan/:customerId',
      name: Features.CarePlan,
      element: lazy(() => import('./nonRouteFeatures/Careplan/CarePlan')),
    },
    {
      path: '/customers/:id?/:feature?/:childParam?/:secondChildParam?/:thirdChildParam?',
      name: Features.Customers,
      element: lazy(() => import('Customers/')),
      childRoutes: [
        {
          path: '/customers/:id/attachments',
          exact: true,
          name: Features.CustomerAttachments,
          element: lazy(() => import('./Customers/containers/CustomerAttachmentsContainer')),
        },
        {
          path: '/customers/:id/:feature?',
          exact: true,
          name: Features.CustomerDetail,
          element: lazy(() => import('./Customers/containers/CustomerDetailContainer/CustomerDetailContainer')),
          childRoutes: [
            {
              path: `/customers/:id/${Features.RestrictionEvents}`,
              name: Features.RestrictionEvents,
              element: lazy(() => import('./Customers/shared/RestrictionEvents')),
            },
            {
              path: `/customers/:id/${Features.CustomerMedicines}`,
              name: Features.CustomerMedicines,
              element: lazy(() => import('./sharedComponents/MedicineList/MedicineList')),
            },
            {
              path: `/customers/:id/${Features.Diagnoses}`,
              name: Features.Diagnoses,
              element: lazy(() => import('./sharedComponents/Diagnoses')),
            },
            {
              path: `/customers/:id/${Features.Wallet}`,
              name: Features.Wallet,
              element: lazy(() => import('./Customers/components/Wallet')),
            },
            {
              path: `/customers/:id/${Features.Reports}`,
              name: Features.Reports,
              element: lazy(() => import('./sharedComponents/ReportDisplay')),
            },
            {
              path: `/customers/:id/${Features.PreferredEmployees}`,
              name: Features.PreferredEmployees,
              element: lazy(() => import('./Customers/components/PreferredEmployees/PreferredEmployees')),
            },
            {
              path: `/customers/:id/${Features.Rai}`,
              exact: true,
              name: Features.Rai,
              element: lazy(() => import('./nonRouteFeatures/RAIv2/container/RaiAssessments/loadable')),
            },
            {
              path: `/customers/:id/${Features.CustomerAttachments}`,
              name: Features.CustomerAttachments,
              element: lazy(() => import('./Customers/containers/CustomerAttachmentsContainer')),
            },
            {
              path: `/customers/:id/${Features.Billing}`,
              name: Features.CustomerBilling,
              element: lazy(() => import('Customers/shared/Billing/Billing')),
            },
            {
              path: `/customers/:id/${Features.CustomerDashboard}`,
              name: Features.CustomerDashboard,
              element: lazy(() => import('Customers/components/CustomerDashboard')),
            },
            {
              path: `/customers/:id/${Features.SocialArchiveCustomerShips}`,
              name: Features.SocialArchiveCustomerShips,
              element: lazy(() => import('Customers/shared/SocialArchive/CustomerShips')),
            },
            {
              path: `/customers/:id/${Features.SocialArchiveTransfers}`,
              name: Features.SocialArchiveTransfers,
              element: lazy(() => import('Customers/shared/SocialArchive/Transfers')),
            },
            {
              path: `/customers/:id/${Features.SocialArchiveSearch}`,
              name: Features.SocialArchiveSearch,
              element: lazy(() => import('Customers/shared/SocialArchive/RegistrySearch')),
            },
            {
              path: `/customers/:id/${Features.CustomerCalendar}`,
              name: Features.CustomerCalendar,
              element: lazy(() => import('./Calendar')),
            },
            {
              path: `/customers/:id/${Features.CustomerMeasurements}`,
              name: Features.CustomerMeasurements,
              element: lazy(() => import('Customers/shared/Measurements')),
            },
            {
              path: `/customers/:id/${Features.CustomerGoals}`,
              name: Features.CustomerGoals,
              element: lazy(() => import('Customers/shared/CustomerGoals')),
            },
            {
              path: `/customers/:id/${Features.SocialArchiveInteractionLog}`,
              name: Features.SocialArchiveInteractionLog,
              element: lazy(() => import('Customers/shared/SocialArchive/InteractionLog')),
            },
          ],
        },
        {
          path: '/customers/:id/documents/:childParam',
          name: Features.Documents,
          element: lazy(() => import('./nonRouteFeatures/Documents/Documents')),
        },
        {
          path: '/customers/:id/rai/:childParam/:secondChildParam',
          exact: true,
          name: Features.Rai,
          element: lazy(() => import('./nonRouteFeatures/RAI/loadable')),
        },
        {
          path: '/customers/:id/rai/:childParam/:secondChildParam/:thirdChildParam',
          exact: true,
          name: Features.Rai,
          element: lazy(() => import('./nonRouteFeatures/RAI/container/InquiryMode/loadable')),
        },
        {
          path: `/customers/:id/${Features.Rai_v2}/:childParam/:secondChildParam`,
          exact: true,
          name: Features.Rai_v2,
          element: lazy(() => import('./nonRouteFeatures/RAIv2/loadable')),
        },
        {
          path: `/customers/:id/${Features.Rai_v2}/:childParam/:secondChildParam/:thirdChildParam`,
          exact: true,
          name: Features.Rai_v2,
          element: lazy(() => import('./nonRouteFeatures/RAIv2/container/InquiryMode/loadable')),
        },
        {
          path: `/customers/:id/${Features.SosMetaForms}/:documentId/:versionId/:mode?/:oid?/:reasonCode?`,
          name: Features.SosMetaForms,
          element: lazy(() => import('Customers/shared/SocialArchive/SosMetaForms/SosMetaForms')),
        },
        {
          path: `/customers/:id/${Features.SocialArchiveDocumentHistory}/:documentId/:versionId/:mode?/:oid?`,
          name: Features.SocialArchiveDocumentHistory,
          element: lazy(() => import('Customers/shared/SocialArchive/DocumentHistory')),
        },
      ],
    },
    {
      path: '/tasks/:duration/:date/:id?',
      name: Features.Tasks,
      element: lazy(() => import('Tasks')),
    },
    {
      path: '/messagecenter/:id?',
      name: Features.MessageCenter,
      element: lazy(() => import('./MessageCenter/loadable')),
    },
    {
      path: '/extranetmessages/:id?',
      name: Features.Extranet,
      element: lazy(() => import('Extranet/loadable')),
    },
    {
      path: '/duties',
      name: Features.Duties,
      element: lazy(() => import('Duties/loadable')),
    },
    {
      path: '/settings',
      name: Features.Settings,
      element: lazy(() => import('Settings/container/UserSettings')),
    },
    {
      path: '/booking/auction/:timeTabId?',
      name: Features.TaskAuction,
      exact: true,
      element: lazy(() => import('TaskAuction/shared/BookingTaskAuction')),
    },
    {
      path: '/booking/:feature?/:timeTabId?',
      exact: true,
      name: Features.Booking,
      element: lazy(() => import('Booking/loadable')),
    },
    {
      path: '/booking/:feature/:timeTabId/management',
      name: Features.Booking,
      exact: true,
      element: lazy(() => import('Booking/shared/WorkShiftsManagement')),
    },
    {
      path: `/${Features.Users}`,
      name: Features.Users,
      element: lazy(() => import('Users')),
    },
    {
      path: '/logout/:reason?',
      public: true,
      name: AlwaysOnFeatures.Logout,
      element: lazy(() => import('Login/Logout/')),
    },
    {
      path: '/work-planning/availability',
      name: Features.WorkPlanning,
      element: lazy(() => import('WorkPlanning')),
    },
    {
      path: '/work-planning/auction',
      name: Features.TaskAuction,
      element: lazy(() => import('TaskAuction/EmployeeView')),
    },
    {
      path: '/billing/contracts/:type?/:id?',
      name: Features.BillingContracts,
      element: lazy(() => import('./Billing/Contracts')),
    },
    {
      path: '/webview/:feature?/:id?/:childParam?/:secondChildParam?/:thirdChildParam?',
      name: Features.WebView,
      element: lazy(() => import('WebView')),
      childRoutes: [
        {
          path: '/webview/calendar/:id?',
          name: Features.Calendar,
          element: lazy(() => import('Calendar')),
        },
        {
          path: '/webview/reports/:id',
          name: Features.Reports,
          element: lazy(() => import('./sharedComponents/ReportDisplay/loadable')),
        },
        {
          path: '/webview/finalreports/:id',
          name: Features.Reports,
          element: lazy(() => import('./WebView/FinalReportsWebView')),
        },
        {
          path: `/webview/${Features.Rai}/:id/:childParam?/:secondChildParam?/:thirdChildParam?`,
          name: Features.Rai,
          element: lazy(() => import('nonRouteFeatures/RAI/webview')),
        },
        {
          path: `/webview/${Features.Rai_v2}/:id/:childParam?/:secondChildParam?/:thirdChildParam?`,
          name: Features.Rai_v2,
          element: lazy(() => import('nonRouteFeatures/RAIv2/webview')),
        },
        {
          path: `/webview/${Features.Documents}/:id/:childParam`,
          name: Features.Documents,
          element: lazy(() => import('nonRouteFeatures/Documents/webview')),
        },
        {
          path: '/webview/customerbilling/:id',
          name: Features.CustomerBilling,
          element: lazy(() => import('Customers/shared/Billing/loadable')),
        },
        {
          path: '/webview/messagecenter/:id?',
          name: Features.MessageCenter,
          element: lazy(() => import('MessageCenter/webview')),
        },
        {
          path: '/webview/restrictionEvents/:id',
          name: Features.RestrictionEvents,
          element: lazy(() => import('Customers/shared/RestrictionEvents/RestrictionEventsWebview')),
        },
      ],
    },
    {
      path: '/raiStats/:id?/:feature?',
      name: Features.RaiStats,
      element: lazy(() => import('RaiStats/loadable')),
      childRoutes: [
        {
          path: '/raiStats/:id',
          exact: true,
          name: Features.Analyses,
          element: lazy(() => import('RaiStats/containers/Analysis/Analysis')),
        },
        {
          path: '/raiStats/:id/resultsTable',
          name: Features.AnalysisTableTab,
          element: lazy(() => import('RaiStats/containers/AnalysisTableTab/AnalysisTableTab')),
        },
      ],
    },
    {
      path: '/payroll',
      name: Features.Payroll,
      element: lazy(() => import('Payroll')),
    },
    {
      path: '/exportArchive',
      name: Features.ExportArchive,
      element: lazy(() => import('Payroll/ExportArchive')),
    },
    {
      path: '/eResepti',
      name: Features.Eresepti,
      element: lazy(() => import('EResepti')),
    },
    {
      path: '/erecAuth/:hash', // do not change. This is used by eRec signer, an external program
      name: Features.Eresepti,
      element: lazy(() => import('EResepti/Auth')),
      public: true,
    },
    {
      path: '/cardLogout/:action?',
      name: Features.Eresepti,
      element: lazy(() => import('EResepti/CardLogout')),
      public: true,
    },
    {
      path: '/corrections',
      name: Features.Corrections,
      element: lazy(() => import('Payroll/Corrections')),
    },
    {
      path: '/sso',
      name: Features.SSO,
      element: lazy(() => import('SSO')),
      public: true,
    },
    {
      // Never put any route AFTER this route. It will not register if you do
      path: '*',
      name: AlwaysOnFeatures.NotFound,
      element: lazy(() => import('sharedComponents/Redirect/')),
    },
  ];
}
