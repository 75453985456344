import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';

import messages from './messages';

import { messageDismiss } from './actions';
import selectErrorNotifications from './selectors';
import { NetworkResponseState } from './reducer';

type Props = {
  location: string;
} & ConnectedProps<typeof connector>;

/**
 * FIXME: refactor or REMOVE this garbage...
 * @deprecated don't use it :)
 */
export class NetworkResponseHandler extends React.PureComponent<Props> {
  componentDidUpdate() {
    const { errorNotifications, location } = this.props;
    // iF the personalizedErrorMessage field is true,
    if (location !== '/login') {
      if (errorNotifications.personalizedErrorMessage) {
        this.showPersonalizedMessage(errorNotifications);
        // Only show error message when the message field is not empty
      } else if (errorNotifications.message !== '') {
        this.showAutomatedErrorMessages(errorNotifications);
      }
    }
    if (errorNotifications.message || errorNotifications.personalizedErrorMessage) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.messageDismiss();
    }
  }

  // Function to show error toast.
  notifyError = (message: ReactNode) => {
    toast.error(message);
  };

  // function to show success toast
  notifySuccess = (message: ReactNode) => {
    toast.success(message);
  };

  // When the message is personalized, it can be either success or error
  showPersonalizedMessage = (notifications: NetworkResponseState) => {
    // Check if the message type is success, if it is show success message,
    // otherwise show error.
    if (notifications.notificationType === 'success') {
      this.notifySuccess(notifications.message);
    } else {
      this.notifyError(notifications.message);
    }
  };

  // This functions is for showing network message received from backend
  showAutomatedErrorMessages = (notifications: NetworkResponseState) => {
    // FIXME: FORCE BACKEND TO RETURN TRANSLATED MESSAGES!!!!!!!!!!
    if (notifications.message === 'Given task already has a report connected to it.') {
      this.notifyError(<FormattedMessage {...messages.REPORT_ALREADY_EXIST} />);
      // @ts-expect-error just because it sux
    } else if (messages[notifications.internalErrorCode] !== undefined) {
      // @ts-expect-error just because it sux
      this.notifyError(<FormattedMessage {...messages[notifications.internalErrorCode]} />);
    } else {
      this.notifyError(<FormattedMessage {...messages.DEFAULT} />);
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = createStructuredSelector({
  errorNotifications: selectErrorNotifications(),
});

const mapDispatchToProps = {
  messageDismiss,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NetworkResponseHandler);
