import {
  NETWORK_REQUEST_ERROR_NOTIFY,
  NETWORK_REQUEST_MESSAGE_DISMISS,
  NETWORK_REQUEST_SUCCESS_NOTIFY,
} from './constants';

export function notifyError(
  message: string,
  personalizedErrorMessage: boolean,
  statusCode?: string,
  internalErrorCode?: string,
  explanation?: string,
  developerMessage?: string,
) {
  return {
    type: NETWORK_REQUEST_ERROR_NOTIFY,
    message,
    personalizedErrorMessage,
    statusCode,
    internalErrorCode,
    explanation,
    developerMessage,
  };
}

export function notifySuccess(message: string, personalizedErrorMessage: boolean) {
  return {
    type: NETWORK_REQUEST_SUCCESS_NOTIFY,
    message,
    personalizedErrorMessage,
  };
}

export function messageDismiss() {
  return {
    type: NETWORK_REQUEST_MESSAGE_DISMISS,
  };
}
