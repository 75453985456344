import { defineMessages } from 'react-intl';

export default defineMessages({
  TASK_ILLEGAL_STATE_TRANSITION: {
    id: 'app.containers.networkResponse.TASK_ILLEGAL_STATE_TRANSITION',
    defaultMessage: 'Task is already finished',
  },
  PARAMETER_INVALID_FOR_RESOURCE: {
    id: 'app.containers.networkResponse.PARAMETER_INVALID_FOR_RESOURCE',
    defaultMessage: 'Invalid query parameters',
  },
  REPORT_ALREADY_EXIST: {
    id: 'app.containers.networkResponse.REPORT_ALREADY_EXIST',
    defaultMessage: 'Report for this task has already been submitted',
  },
  NON_SPECIFIED: {
    id: 'app.containers.networkResponse.NON_SPECIFIED',
    defaultMessage: 'An error was encountered',
  },
  DEFAULT: {
    id: 'app.containers.networkResponse.DEFAULT',
    defaultMessage: 'An error was encountered',
  },
});
