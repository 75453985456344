import { defineMessages } from 'react-intl';

export default defineMessages({
  default: {
    id: 'error.default',
    defaultMessage: 'An error was encountered',
  },
  defaultErrorCode: {
    id: 'error.defaultErrorCode',
    defaultMessage: 'An error was encountered ({errorCode})',
  },
  generalIO: {
    id: 'error.generalIoException',
    defaultMessage: 'General IO Exception',
  },
});
