import commonTranslations from 'translations/CommonTranslations';

import messages from './messages';

export type ErrorCode = Record<string, { id: string; defaultMessage: string }>;

export const usersErrorCodes: ErrorCode = {
  EMPLOYEE_AGREEMENT_HISTORY_NEW_AGREEMENT_OVERLAPPING_WITH_EARLIER_AGREEMENT: commonTranslations.overlappingDate,
  EMPLOYEE_AGREEMENT_HISTORY_GAPS_BETWEEN_AGREEMENTS_NOT_ALLOWED: commonTranslations.gapBetweenAgreements,
  EMPLOYEE_AGREEMENT_HISTORY_START_DATE_AFTER_END_DATE: messages.startDateAfterEndDate,
  EMPLOYEE_AGREEMENT_HISTORY_START_DATE_MISSING: messages.startDateMissing,
  EMPLOYEE_AGREEMENT_HISTORY_ID_MISSING: messages.idMissing,
  EMPLOYEE_AGREEMENT_HISTORY_ID_ZERO_OR_NEGATIVE: messages.idZeroOrNegative,
  EMPLOYEE_AGREEMENT_HISTORY_PAYLOAD_MISSING: messages.payloadMissing,
  EMPLOYEE_AGREEMENT_HISTORY_USER_ID_MISSING_FROM_PAYLOAD: messages.userIdMissing,
  EMPLOYEE_AGREEMENT_HISTORY_AGREEMENT_ID_MISSING_FROM_PAYLOAD: messages.agreementIdMissing,
  EMPLOYEE_AGREEMENT_HISTORY_ACTIVE_USER_DOES_NOT_EXIST: messages.activeUserDoesNotExist,
  EMPLOYEE_AGREEMENT_HISTORY_AGREEMENT_DOES_NOT_EXIST: messages.agreementDoesNotExist,
  EMPLOYEE_AGREEMENT_HISTORY_DOES_NOT_BELONG_TO_USER: messages.agreementDoesNotBelongToUser,
  EMPLOYEE_AGREEMENT_HISTORY_DOES_NOT_EXIST: messages.agreementHistoryDoesNotExist,
  EMPLOYEE_AGREEMENT_HISTORY_AGREEMENT_IS_NOT_LATEST: messages.agreementIsNotLatest,
  EMPLOYEE_AGREEMENT_HISTORY_PAYLOAD_MISSING_USER_IDS: messages.userIdsMissing,
  EMPLOYEE_AGREEMENT_HISTORY_PAYLOAD_CONTAINED_MULTIPLE_UNIQUE_USER_IDS: messages.multipleUniqueUserIds,
  EMPLOYEE_AGREEMENT_HISTORY_PAYLOAD_CONTAINED_AGREEMENTS_FOR_OTHER_USER: messages.agreementsForOtherUser,
  EMPLOYEE_AGREEMENT_HISTORY_PAYLOAD_CONTAINED_INVALID_OPEN_ENDED_AGREEMENT: messages.invalidOpenEndedAgreement,
  EMPLOYEE_AGREEMENT_HISTORY_PAYLOAD_CONTAINED_GAPS_BETWEEN_AGREEMENTS: messages.gapsBetweenAgreements,
  EMPLOYEE_AGREEMENT_HISTORY_PAYLOAD_CONTAINED_OVERLAPPING_AGREEMENTS: messages.overlappingAgreements,
  EMPLOYEE_AGREEMENT_HISTORY_CONTAINED_OVERLAPPING_AGREEMENTS: messages.overlappingAgreements,
};
