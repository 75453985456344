import { createSelector } from 'reselect';
import { RootState } from 'store/index';
import { LANGUAGE_REDUCER_NAME, LanguageState } from 'store/language/slice';

export const selectLanguage = (state: RootState): LanguageState => state[LANGUAGE_REDUCER_NAME];

/**
 * Select the language locale
 */

export const makeSelectLocale = () => createSelector(selectLanguage, (languageState) => languageState.locale);
