import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import getFeatures from 'services/features';
import { State } from 'types/State';
import { Privileges } from 'types/privileges';

export const PRIVILEGES_REDUCER_NAME = 'privileges';

export const getPrivileges = createAsyncThunk(
  `${PRIVILEGES_REDUCER_NAME}/getPrivileges`,
  async () => (await getFeatures()).data,
);

export type PrivilegesState = {
  features: Privileges;
  state: State;
  error: null | SerializedError;
};

const initialState = {
  features: {},
  state: State.NOT_STARTED,
} as PrivilegesState;

const slice = createSlice({
  name: PRIVILEGES_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPrivileges.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(getPrivileges.fulfilled, (draft, action) => {
      draft.features = action.payload;
      draft.state = State.SUCCESS;
    });
    builder.addCase(getPrivileges.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
    });
  },
});

export default slice.reducer;
