import { createSlice } from '@reduxjs/toolkit';

import { getCurrentDateInDbFormat, getFirstDateOfCurrentMonth } from 'utils/dateUtil';

import { FilterProps, Status } from './types';

export type Filters = {
  filters: FilterProps;
};

//     dateRange: { startDate:  getFirstDateOfCurrentMonth(), endDate: getCurrentDateInDbFormat() },
export const initialState: Filters = {
  filters: {
    dateRange: { startDate: getFirstDateOfCurrentMonth(), endDate: getCurrentDateInDbFormat() },
    status: Status.UNAPPROVED,
    timetabs: [],
    units: [],
    agreements: [],
  },
};

export const payrollSlice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    addFilter: (draft, action) => {
      draft.filters = action.payload;
    },
    resetFilters: (draft) => {
      draft.filters = {
        dateRange: { startDate: getFirstDateOfCurrentMonth(), endDate: getCurrentDateInDbFormat() },
        status: Status.UNAPPROVED,
        timetabs: [],
        units: [],
        agreements: [],
      };
    },
  },
});

export const { addFilter, resetFilters } = payrollSlice.actions;

export default payrollSlice.reducer;
