import React from 'react';
import cn from 'classnames';
import styles from './HeaderBar.scss';

type Props = {
  className?: string;
};

const HeaderBar: React.FC<Props> = ({ children, className }) => (
  <div className={cn(styles.root, className)} data-testid="header-bar">
    {children}
  </div>
);

export default HeaderBar;
