import {
  AtostekLoginResponse,
  EArkistoDataView,
  EArkistoLoginStatus,
  EarkistoTransferOrder,
  EarkistoTransferOrderResponse,
} from 'types/Kanta';

import api from 'utils/api';

export const getDataViews = () => api.get<EArkistoDataView[]>('earkisto/dataviews');

export const getTransferrableReports = () => api.get('customers/v2/reports/earkisto_transferrable');

export const getTransferredReports = () => api.get('customers/v2/reports/earkisto_transferred');

export const postReportTransfer = (data: EarkistoTransferOrder) =>
  api.post<EarkistoTransferOrderResponse>('earkisto/transfer', data);

export const getReportTransferStatus = (id: number) => api.get(`earkisto/transfer/${id}`);

export const postEarkistoLogin = () => api.post<AtostekLoginResponse>('earkisto/login/1');

export const logoutEArkisto = () => api.post('earkisto/logout');

export const getEarkistoLoginStatus = () => api.get<EArkistoLoginStatus>('earkisto/login/status');

export default getDataViews;
