import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { getUnitsAPI, patchUnitAPI } from 'services/units';
import { Unit } from 'types/Unit';
import { State } from 'types/State';
import { RootState } from 'store';

export const UNITS_REDUCER_NAME = 'units';

export const loadUnits = createAsyncThunk(
  `${UNITS_REDUCER_NAME}/loadUnits`,
  async (_force?: boolean) => (await getUnitsAPI({ hasVisibleCustomers: true })).data,
  {
    condition: (force, { getState }) => {
      const { units } = getState() as RootState;
      const fetchStatus = units.state;
      if (force) return true;
      return !(fetchStatus === State.SUCCESS || fetchStatus === State.PENDING);
    },
  },
);

export const patchUnit = createAsyncThunk(
  `${UNITS_REDUCER_NAME}/patchUnit`,
  async ({ unitId, payload }: { unitId: number; payload: Partial<Unit> }) => (await patchUnitAPI(unitId, payload)).data,
);

export type UnitsState = {
  unitsList: Unit[];
  state: State;
  error: null | SerializedError;
};

const initialState = {
  unitsList: [],
  state: State.NOT_STARTED,
  error: null,
} as UnitsState;

const slice = createSlice({
  name: UNITS_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadUnits.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(loadUnits.fulfilled, (draft, action) => {
      draft.unitsList = action.payload;
      draft.state = State.SUCCESS;
    });
    builder.addCase(loadUnits.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
    });
    builder.addCase(patchUnit.pending, (draft) => {
      draft.error = null;
    });
    builder.addCase(patchUnit.fulfilled, (draft, action) => {
      draft.unitsList = draft.unitsList.map((unit) => {
        if (unit.id === action.payload.id) {
          return { ...unit, ...action.payload };
        }
        return unit;
      });
    });
    builder.addCase(patchUnit.rejected, (draft, action) => {
      draft.error = action.error;
    });
  },
});

export default slice.reducer;
