import { defineMessages } from 'react-intl';

export default defineMessages({
  addNoRepetitionAlert: {
    id: 'Booking.AddEditTask.addNoRepetitionAlert',
    defaultMessage: 'You are now adding repetitions. If you do not want to add repetition click {hereElem}',
  },
  addRepetitionAlert: {
    id: 'Booking.AddEditTask.addRepetitionAlert',
    defaultMessage: 'If you want to add repetitions click {hereElem}',
  },
  editSingleTaskAlert: {
    id: 'Booking.AddEditTask.editSingleTaskAlert',
    defaultMessage: 'You are now editing repeated tasks. If you want to edit only single task click {hereElem}',
  },
  editRepeatedTaskAlert: {
    id: 'Booking.AddEditTask.editRepeatedTaskAlert',
    defaultMessage: 'You are now editing single task. If you want to edit repeated task click {hereElem}',
  },
  customerChangesNotification: {
    id: 'Booking.AddTaskDetails.customerChangesNotification',
    defaultMessage: 'Changes to customer are stored automatically',
  },
  here: {
    id: 'Booking.AddEditTask.here',
    defaultMessage: 'here',
  },
  editTaskWithId: {
    id: 'Booking.AddEditTask.editTaskWithId',
    defaultMessage: 'Edit Task: {taskId}',
  },
  error: {
    id: 'Booking.AddEditTask.error',
    defaultMessage: 'Error during loading task details. { details }. Cannot continue',
  },
  errorDetailsTaskIsEmpty: {
    id: 'Booking.AddEditTask.errorDetailsTaskIsEmpty',
    defaultMessage: 'Task details are empty',
  },
  header: {
    id: 'Booking.AddEditTask.EditTaskConfirmModal.header',
    defaultMessage: 'Update single task or the repetition',
  },
  alert: {
    id: 'Booking.AddEditTask.EditTaskConfirmModal.alert',
    defaultMessage:
      'Preview your changes below and select if you want make update to the single task or the task repetition.',
  },
  updateSingleTask: {
    id: 'Booking.AddEditTask.EditTaskConfirmModal.updateSingleTask',
    defaultMessage: 'Update the single task',
  },
  updateRepeatedTask: {
    id: 'Booking.AddEditTask.EditTaskConfirmModal.updateRepeatedTask',
    defaultMessage: 'Update all tasks in repetition',
  },
  deleteRepeatedTask: {
    id: 'Booking.AddEditTask.EditTaskConfirmModal.deleteRepeatedTask',
    defaultMessage: 'Delete all tasks in repetition',
  },
  deleteSingleTask: {
    id: 'Booking.AddEditTask.EditTaskConfirmModal.deleteSingleTask',
    defaultMessage: 'Delete the single task',
  },
  selectCustomer: {
    id: 'Booking.AddEditTask.selectCustomer',
    defaultMessage: 'Select customer',
  },
  selectTaskType: {
    id: 'Booking.AddEditTask.selectTaskType',
    defaultMessage: 'Select task type',
  },
  selectRealizedStartTime: {
    id: 'Booking.AddEditTask.selectRealizedStartTime',
    defaultMessage: 'Select realized start time',
  },
  selectRealizedEndTime: {
    id: 'Booking.AddEditTask.selectRealizedEndTime',
    defaultMessage: 'Select realized end time',
  },
  removeFromAuction: {
    id: 'Booking.AddEditTaskForm.footer.Removetaskfromauction',
    defaultMessage: 'Remove task from auction',
  },
  addTaskToAuction: {
    id: 'Booking.AddEditTaskForm.footer.Addtasktoauction',
    defaultMessage: 'Add task to auction',
  },
  addRepetition: {
    id: 'Booking.AddEditTaskForm.footer.Addrepetition',
    defaultMessage: 'Add repetition',
  },
  disableRepetition: {
    id: 'Booking.AddEditTaskForm.footer.disablerepetition',
    defaultMessage: 'Disable repetition',
  },
  invoiceLegend: {
    id: 'Booking.AddEditTask.Products.invoiceLegend',
    defaultMessage: 'Invoice Legend',
  },
  total: {
    id: 'Booking.AddEditTask.Products.total',
    defaultMessage: 'Total(€)',
  },
  quantity: {
    id: 'Booking.AddEditTask.Products.quantity',
    defaultMessage: 'Quantity',
  },
  price: {
    id: 'Booking.AddEditTask.Products.price',
    defaultMessage: 'Price(€)',
  },
  name: {
    id: 'Booking.AddEditTask.Products.name',
    defaultMessage: 'Name',
  },
  taskTypeNote: {
    id: 'Booking.AddEditTask.Products.taskTypeNote',
    defaultMessage: 'NOTE: the services coming from type cannot be deselected.',
  },
  type: {
    id: 'Booking.AddEditTask.Products.type',
    defaultMessage: 'type',
  },
  addTask: {
    id: 'Booking.AddEditTask.addTask',
    defaultMessage: 'Add task',
  },
  taskId: {
    id: 'Booking.AddEditTask.taskId',
    defaultMessage: 'Task id',
  },
  repetitionId: {
    id: 'Booking.AddEditTask.repetitionId',
    defaultMessage: 'Repetition id',
  },
  sameTime: {
    id: 'Booking.AddEditTask.sameTime',
    defaultMessage: 'Picked time is the same',
  },
  earliestStartTimeAfterLatestStart: {
    id: 'Booking.AddEditTask.earliestStartTimeAfterLatestStart',
    defaultMessage: 'Earliest start time is after latest start time',
  },
  plannedStartTimeNotBetweenEarliestAndLatest: {
    id: 'Booking.AddEditTask.plannedStartTimeNotBetweenEarliestAndLatest',
    defaultMessage: 'Planned start time is not between earliest and latest start time',
  },
  plannedStartTimeIsAfterPlannedEndTime: {
    id: 'Booking.AddEditTask.plannedStartTimeIsAfterPlannedEndTime',
    defaultMessage: 'Start time is after end time',
  },
  taskIsCancelled: {
    id: 'Booking.AddEditTask.taskIsCancelled',
    defaultMessage: 'Task is cancelled',
  },
  taskBelongToRepetition: {
    id: 'Booking.AddEditTask.taskBelongToRepetition',
    defaultMessage: 'Task belongs to repetition, would you like to only update this task?',
  },
  editTaskLoading: {
    id: 'Booking.AddEditTask.editTaskLoading',
    defaultMessage: 'Updating tasks',
  },
  taskIsInPayroll: {
    id: 'Booking.AddEditTask.taskIsInPayroll',
    defaultMessage: 'Task is in payroll',
  },
  unlinkedTasksExplanation: {
    id: 'Booking.AddEditTask.unlinkedTasksExplanation',
    defaultMessage:
      'You can update the tasks that were originally part of the same repetition which have been unlinked from it by being modified individually',
  },
  updateRepetitionAlso: {
    id: 'Booking.AddEditTask.updateRepetitionAlso',
    defaultMessage: 'Update repetition also',
  },
  deleteRepetitionAlso: {
    id: 'Booking.AddEditTask.deleteRepetitionAlso',
    defaultMessage: 'Delete repetition also',
  },
  updatedFields: {
    id: 'Booking.AddEditTask.updatedFields',
    defaultMessage: 'Updated fields',
  },
  unlinkedTasks: {
    id: 'Booking.AddEditTask.unlinkedTasks',
    defaultMessage: 'Unlinked tasks',
  },
  noShowOptions: {
    id: 'Booking.AddEditTask.noShowOptions',
    defaultMessage: 'No, show options',
  },
  yesOnlyOneTask: {
    id: 'Booking.AddEditTask.yesOnlyOneTask',
    defaultMessage: 'Yes, only update this task',
  },
  cancelRepetitionAlso: {
    id: 'Booking.AddEditTask.cancelRepetitionAlso',
    defaultMessage: 'Cancel repetition also',
  },
  noOtherTasks: {
    id: 'Booking.AddEditTask.noOtherTasks',
    defaultMessage: 'No other tasks',
  },
  noWorkShifts: {
    id: 'Booking.AddEditTask.noWorkShifts',
    defaultMessage: 'No work shifts',
  },
  overlappingTasks: {
    id: 'Booking.AddEditTask.overlappingTasks',
    defaultMessage: 'Overlapping tasks',
  },
  overlappingWorkShifts: {
    id: 'Booking.AddEditTask.overlappingWorkShifts',
    defaultMessage: 'Overlapping work shifts',
  },
  noOverlapping: {
    id: 'Booking.AddEditTask.noOverlapping',
    defaultMessage: 'No overlapping',
  },
  partlyOverlapping: {
    id: 'Booking.AddEditTask.partlyOverlapping',
    defaultMessage: 'Partly overlapping',
  },
  fullyOverlapping: {
    id: 'Booking.AddEditTask.fullyOverlapping',
    defaultMessage: 'Fully overlapping',
  },
  partialAvailability: {
    id: 'Booking.AddEditTask.partialAvailability',
    defaultMessage: 'Partial availability',
  },
  commentInfo: {
    id: 'Booking.AddEditTask.commentInfo',
    defaultMessage:
      'This is task auction comment field. It is only shown to admin users and it is visible in task auction section. Normal employees do not see this field.',
  },
  taskInPayrollCreateMessage: {
    id: 'Booking.AddEditTask.taskInPayrollCreateMessage',
    defaultMessage:
      'You are adding a task to a date already in payroll. This might affect payroll and needs to be processed separately. Do you wish to continue?',
  },
  taskInPayrollEditMessage: {
    id: 'Booking.AddEditTask.taskInPayrollEditMessage',
    defaultMessage:
      'You are editing a task already in payroll. This might affect payroll and needs to be processed separately. Do you wish to continue?',
  },
  taskInPayrollDeleteMessage: {
    id: 'Booking.AddEditTask.taskInPayrollDeleteMessage',
    defaultMessage:
      'You are deleting a task already in payroll. This might affect payroll and needs to be processed separately. Do you wish to continue?',
  },
  taskInPayrollCancelMessage: {
    id: 'Booking.AddEditTask.taskInPayrollCancelMessage',
    defaultMessage:
      'You are canceling a task already in payroll. This might affect payroll and needs to be processed separately. Do you wish to continue?',
  },
});
