import { DomaDesktopChannel } from 'types/WebView';

/**
 * @description get DomaDesktopChannel object from window
 */
export const getWebViewDomaChannel = (): DomaDesktopChannel => window.domadesktopchannel;

/**
 * @description check if sidenav is Enabled from webview
 * */
export const sidenavEnabled = (win: Window, path: string): boolean => {
  if (!win.domadesktopchannel && !path.includes('webview')) {
    return true;
  }
  return win.domadesktopchannel?.disableSideNav === false;
};

/**
 * @description check if top header is Enabled from webview
 * */
export const topHeaderEnabled = (win: Window, path: string): boolean => {
  if (!win.domadesktopchannel && !path.includes('webview')) {
    return true;
  }
  return win.domadesktopchannel?.disableTopHeader === false;
};
