import moment, { MomentInput } from 'moment';
import _omitBy from 'lodash/omitBy';
import differenceWith from 'lodash/differenceWith';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import _compose from 'lodash/fp/compose';
import _range from 'lodash/range';
import _padStart from 'lodash/padStart';
import _omit from 'lodash/fp/omit';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';

import { editTask } from 'services/booking/tasks';
import { updateSingleTimeShiftAssignment } from 'services/booking/workShift';

import { TravelModeString } from 'types/TravelMode';

import {
  addTo,
  createMoment,
  formatDateTimeToISO,
  formatDateToDbDateTimeFormat,
  getCurrentDateTimeInDbFormat,
  getDurationBetween,
  isAfter,
  isBefore,
} from 'utils/dateUtil';
import { pickNonUndefinedValues } from 'utils/helperFunctions';

import commonTranslations from 'translations/common';

import {
  BookingCalendarDraggingItem,
  BookingCalendarItem,
  BookingCalendarTaskItem,
  BookingCalendarWorkShift,
  BookingCalendarWorkShiftAvailability,
} from 'Booking/types/BookingCalendarItem';
import { Repetition } from 'Booking/types/Repetition/Repetition';
import { Employee } from 'Booking/types/Employee';
import { SuitableEmployees } from 'Booking/types/SuitableEmployees/SuitableEmployees';
import { BookingType } from 'Booking/types/BookingType';
import { NeedAndOffer } from 'Booking/types/NeedsAndOffers/NeedAndOffer';
import { TaskTimeShiftAssignment } from 'Booking/types/TaskTimeShiftAssignment/TaskTimeShiftAssignment';
import { WorkShiftAvailability } from 'Booking/types/TaskTimeShiftAssignment/WorkShiftAvailability';
import TaskStatuses from 'Booking/enums/TaskStatuses';
import RepetitionSpecialHolidayOptionType from 'Booking/enums/RepetitionSpecialHolidayOptionType';
import { getCurrentWeekDayForSelectedWeekdays } from 'Booking/shared/AddEditTask/utils';
import { TaskTimeShiftAssignmentType } from 'Booking/types/TaskTimeShiftAssignment/TaskTimeShiftAssignmentType';
import styles from 'Booking/components/Box/Box.scss';
import TimelineViews from 'Booking/enums/TimelineViews';
import { BookingItem } from 'Booking/types/BookingItem';
import { RepetitionType } from 'constants/repetitionType';
import { AvailabilityTypes } from 'WorkPlanning/types';
import UpdateMode from 'Booking/enums/UpdateMode';
import bookingMessages from 'Booking/messages';
import colors from 'assets/styles/variablesExported.scss';
import TaskTimeStatusEnums from 'Booking/enums/TaskTimeStatusEnums';

import {
  ITEM_MOVE_INTERVAL_FOR_LESS_THAN_2_DAYS_IN_MINS,
  ITEM_MOVE_INTERVAL_FOR_MORE_THAN_2_DAYS_IN_MINS,
  itemStateStyles,
  OTHER_TASKS_ID,
  zoomValues,
} from './constants';
import { EmployeeGroup } from './types/Timeline/Groups/EmployeeGroup';
import { TaskBookingResource } from './types/TaskBookingResource';
import { GroupGroup } from './types/Timeline/Groups/GroupGroup';
import { CustomerGroup } from './types/CustomerGroup';
import { MoveTaskType } from './types/PropsAndStates/MoveTaskType';
import { TemplateRepetition } from './types/Repetition/TemplateRepetition';

export const fireResizeEvent = () => {
  window.dispatchEvent(new Event('resize'));
};

export const handleItemColor = (status: string, type: string) => {
  if (status === TaskStatuses.WORK_SHIFT) {
    if (type === TaskTimeShiftAssignmentType.REAL_SHIFT) {
      return styles.box_real_workShift;
    }
    if (type === TaskTimeShiftAssignmentType.DRAFT) {
      return styles.box_draft_workShift;
    }
  }
  return itemStateStyles[status];
};

export const handleBorderColor = (
  status: string,
  defaultItemColor: string,
  color: string | undefined,
  bottomColor: string,
) => {
  if (status === TaskStatuses.WORK_SHIFT) {
    if (!color) {
      return defaultItemColor;
    }
    if (color === '#FFFFFF') {
      return '#FCD0DA';
    }
    return color;
  }
  return bottomColor;
};

export const handleTaskBorderColorOnAbsentCustomer = (
  absent: boolean | undefined,
  defaultColor: string,
  status: string,
) => {
  if (
    status === TaskStatuses.WORK_SHIFT ||
    status === TaskStatuses.WORK_SHIFT_AVAILABLE ||
    status === TaskStatuses.WORK_SHIFT_NOT_AVAILABLE
  ) {
    return defaultColor;
  }
  if (absent) {
    return '#2a2727';
  }
  return defaultColor;
};

/**
 calculate the changedTime when you move the task within the vertical lines,
 if the changeTime is equal or less than the dragging item duration,
 then we should consider that the user does not want to change the time :D
 this is a requested feature from med group.
 */
export const hasMovedWithoutChangingTime = (changedTime: number, item: BookingCalendarDraggingItem) => {
  const comparedTime = getDurationBetween(item.plannedStartTime, item.plannedEndTime, 'milliseconds');
  const abs = Math.abs(changedTime);
  return abs >= 0 && abs <= comparedTime;
};

export const digitCheck = (time: number) => `${time < 10 ? '0' : ''}${time}`;

export const checkToFetch = (value: string, list: string[]) => list.some((item) => item === value);

export const generateJointId = (calendar: string, id: number | string) => `${calendar}_${id}`;

/**
 * Check if source lies inside target
 * @param {number} targetX
 * @param {number} targetWidth
 * @param {number} sourceX
 * @param {number} sourceWidth
 * @returns {boolean}
 */
export function checkIfInsideDropTarget(targetX: number, targetWidth: number, sourceX: number, sourceWidth: number) {
  return sourceX >= targetX && sourceX + sourceWidth <= targetX + targetWidth;
}

export const sortAccordingToStartTime = (list: BookingCalendarItem[]) =>
  [...list].sort((previous: BookingCalendarItem, current: BookingCalendarItem) => {
    const previousItemWorkShiftValidation =
      previous.status === TaskStatuses.WORK_SHIFT && !!previous.taskTimeShiftTemplate?.absence;

    const currentItemWorkShiftValidation =
      current.status === TaskStatuses.WORK_SHIFT && !!current.taskTimeShiftTemplate?.absence;

    const previousIsAvailability =
      previous.status === TaskStatuses.WORK_SHIFT_AVAILABLE ||
      previous.status === TaskStatuses.WORK_SHIFT_NOT_AVAILABLE;

    const currentIsAvailability =
      current.status === TaskStatuses.WORK_SHIFT_AVAILABLE || current.status === TaskStatuses.WORK_SHIFT_NOT_AVAILABLE;

    if (previousIsAvailability) {
      return 1;
    }
    if (currentIsAvailability) {
      return -1;
    }

    if (previousItemWorkShiftValidation) {
      return 1;
    }
    if (currentItemWorkShiftValidation) {
      return -1;
    }

    if (isBefore(previous.plannedStartTime, current.plannedStartTime)) {
      return -1;
    }
    if (isAfter(previous.plannedStartTime, current.plannedStartTime)) {
      return 1;
    }

    return 0;
  });

export const sortTopTimelineGroups = (sortOrder: number[], groups: any) => {
  // get employees that are not in the sort order list
  const filteredEmployees = groups.filter((group: any) => !sortOrder.includes(group.id));

  // sort employees without work shifts and tasks alphabetically
  const sortedFilteredEmployees = sortBy(filteredEmployees.slice(), 'name');

  // find corresponding employee object from employee array, filter out undefined because find produces them
  const sortedEmployees: (BookingCalendarItem | CustomerGroup)[] = [];
  sortOrder.forEach((id) => {
    const existedGroup = groups.find((group: any) => group.id === id);
    if (existedGroup) {
      sortedEmployees.push(existedGroup);
    }
  });
  // we combine employees that were found and sorted and those who were unsorted
  return [...sortedEmployees, ...sortedFilteredEmployees];
};

export const workShiftStatusValidation = (status: string) =>
  status !== TaskStatuses.WORK_SHIFT &&
  status !== TaskStatuses.WORK_SHIFT_AVAILABLE &&
  status !== TaskStatuses.WORK_SHIFT_NOT_AVAILABLE;

export const switchValidation = (checkPoint: string, pickedSwitch: string | null) => {
  if (!pickedSwitch) {
    return null;
  }
  return pickedSwitch === checkPoint;
};

export const taskTimeStatusValidation = (
  taskTimeStatus: TaskTimeStatusEnums,
  plannedTime: number,
  realizedTime: number | undefined,
) => {
  if (taskTimeStatus === TaskTimeStatusEnums.REALIZED) {
    if (!realizedTime) {
      return plannedTime;
    }
    return realizedTime;
  }
  return plannedTime;
};

export const timeShiftsAssignmentSeparator = (lists: TaskTimeShiftAssignment[], taskTimeStatus: TaskTimeStatusEnums) =>
  lists.reduce<{
    workShiftsInEmployeeCal: BookingCalendarWorkShift[];
    workShiftsInGroupCal: BookingCalendarWorkShift[];
  }>(
    (acc, item) => {
      const newItem = convertWorkShift(item, taskTimeStatus);
      if (item.employeeId) {
        acc.workShiftsInEmployeeCal.push(newItem);
      } else {
        acc.workShiftsInGroupCal.push(newItem);
      }
      return acc;
    },
    { workShiftsInEmployeeCal: [], workShiftsInGroupCal: [] },
  );

export const getOptionsLabel = (option: any) => {
  if (option.label) {
    return `${option.label}`;
  }
  if (option.firstName) {
    return `${option.lastName} ${option.firstName}`;
  }
  if (option.name) {
    return `${option.name}`;
  }
  return `${option.categoryName}`;
};

export const getAddTaskTimeValues = (selectedTime: number | string) => {
  const plannedStartTime = formatDateToDbDateTimeFormat(selectedTime);
  const plannedEndTime = formatDateToDbDateTimeFormat(addTo(selectedTime, 60, 'm'));
  return {
    plannedStartTime,
    plannedEndTime,
  };
};

export const createTaskInitialValues = (task: BookingItem, templateRepetitions: TemplateRepetition | null) => ({
  ..._pick(task, [
    'info',
    'isCanceled',
    'plannedStartTime',
    'repetitionId',
    'reservedRepetitionId',
    'plannedEndTime',
    'realizedEndTime',
    'realizedStartTime',
    'taskTemplateId',
    'auctioned',
    'bookingItemAuction',
    'billed',
    'startLocation',
    'confirmLocation',
  ]),
  taskId: task.id,
  status: task.itemState,
  taskType: task.itemTypeId || null,
  customer: task.customerId,
  repetitions: templateRepetitions || {},
  employee: task.employeeId || null,
  group: task.taskGroupId || null,
  requirements: task.needIds || [],
  services: task.services
    ? task.services.map((service) => ({
        serviceId: service.serviceId,
        count: service.count,
        legend: service.legend || '',
        selected: true,
      }))
    : [],
  earliestStartTime: task.earliestStartTime || null,
  latestStartTime: task.latestStartTime || null,
  timeOfModification: task.timeOfModification || null,
  createdTime: task.createdTime,
  createdUser: task.createdUser,
  modifyingUser: task.modifyingUser,
  mobileKey: task.mobileKey || null,
});

// Interval shown during dragging the task.
// If the zoom level is less than 2 days, interval is in 5-min step.
// If zoom level is more than 2 days, interval is in 1-hour step.
export const itemMoveTimeIntervalInMinsFromVisibleTime = (
  visibleTimeStart: string | number,
  visibleTimeEnd: string | number,
) =>
  getDurationBetween(visibleTimeStart, visibleTimeEnd, 'day') >= 2
    ? ITEM_MOVE_INTERVAL_FOR_MORE_THAN_2_DAYS_IN_MINS
    : ITEM_MOVE_INTERVAL_FOR_LESS_THAN_2_DAYS_IN_MINS;

export const tooSmallForTimeline = (width: number) => width < 750;
export const tooSmallForCalendarMapView = (width: number) => width < 1500;

export const adjustTimeForInterval = (intervalInMinutes: number) => (time: number) => {
  const msForInterval = intervalInMinutes * 60 * 1000;
  return Math.ceil(time / msForInterval) * msForInterval;
};

export const getAdjustedTimeByInterval = ({
  time,
  interval,
}: {
  time: { start: number; end: number };
  interval: number;
}) => {
  const start = adjustTimeForInterval(interval)(time.start);
  const end = time.end + (start - time.start);

  return { start, end };
};

const acceptableSourcesReducer = (acc: any, as: any) =>
  acc.concat(as.items.map((it: string) => generateJointId(as.calendar, it)));
export const flattenAcceptableSource = (acceptableSources: any) =>
  acceptableSources.reduce(acceptableSourcesReducer, []);

export const objectDiff = (object: any, base: any) => _omitBy(object, (v, k) => base[k] === v);

export const hasObjectChanged = (prevObj: any, newObj: any, comparisonKey: any) =>
  !(!newObj && !prevObj) &&
  ((newObj && !prevObj) || (!newObj && prevObj) || prevObj[comparisonKey] !== newObj[comparisonKey]);

export const getInitialVisibleTimes = (date?: MomentInput) => {
  const visibleTimeStart = createMoment(date || new Date())
    .startOf('day')
    .valueOf();
  const visibleTimeEnd = createMoment(date || new Date())
    .add(1, 'day')
    .startOf('day')
    .valueOf();
  return { visibleTimeStart, visibleTimeEnd };
};
// this is for fetching tasks and work shifts more than what is shown
export const getDataRange = (date?: any) => {
  const visibleTimeStart = createMoment(date || new Date())
    .startOf('day')
    .valueOf();
  const visibleTimeEnd = createMoment(date || new Date())
    .add(1, 'day')
    .startOf('day')
    .valueOf();
  return { visibleTimeStart, visibleTimeEnd };
};

export const findEmployeeById = (employeeList: EmployeeGroup[], empID: number) =>
  employeeList.find((item) => item.id === empID);

export const getStatisticsData = (tasksList: any[]) => {
  // total number of tasks
  const totalNumberOfTasks = tasksList.length;

  // combine task duration
  const totalDuration: number = tasksList.reduce((accumulator: string, task: any) => {
    const getFormattedPlannedStartTime = moment(task.plannedStartTime, 'YYYY-MM-DD HH:mm:ss');
    const getFormattedPlannedEndTime = moment(task.plannedEndTime, 'YYYY-MM-DD HH:mm:ss');
    const durationInMS = moment.duration(getFormattedPlannedEndTime.diff(getFormattedPlannedStartTime));
    return accumulator + durationInMS;
  }, 0);
  return { totalNumberOfTasks, totalDuration };
};

// makes the long name shorter
export const truncateHandler = (str: string) => {
  if (!str) {
    return null;
  }
  if (str.length > 17) {
    return `${str.substring(0, 17)}...`;
  }
  return str;
};

export const getNeedsAndOfferFulfilledEmployees = (employees: any, needs: number[], antiNeeds: number[]): Employee[] =>
  employees.filter(({ offerIds }: { offerIds: number[] }) => {
    const unfulfilledNeeds = differenceWith(needs, offerIds, isEqual);
    const offeredAntiNeeds = intersectionWith(antiNeeds, offerIds, isEqual);
    return unfulfilledNeeds.length + offeredAntiNeeds.length <= 0;
  });

export const determineSuitableEmployee = (
  suitableEmployees: SuitableEmployees,
  taskDetail: BookingItem,
  employee: Employee | EmployeeGroup | undefined | null,
): boolean => {
  const extractedSuitableEmployees = suitableEmployees[taskDetail.id];

  const currentEmployeeSuitability = extractedSuitableEmployees[employee?.id as number];

  return isEmployeeSuitable(currentEmployeeSuitability, taskDetail);
};

export const isEmployeeSuitable = (employee: Employee, taskDetail: BookingItem) => {
  let employeeIsSuitable = true;

  for (let i = 0; i < taskDetail.needIds.length; i += 1) {
    const needId = taskDetail.needIds[i];
    if (
      (employee?.suitability.requirementPremise.matching &&
        !employee?.suitability.requirementPremise.matching.includes(needId)) ||
      (employee?.suitability.requirementPremise.conflicting &&
        employee?.suitability.requirementPremise.conflicting.includes(needId))
    ) {
      employeeIsSuitable = false;
      break;
    }
  }

  return employeeIsSuitable;
};

// TODO: remove this
const convertFieldsForItem = (item: BookingItem) => ({
  status: item.itemState,
  customer: item.customer,
  isCanceled: item.isCanceled,
  info: item.info,
  plannedStartTime: item.plannedStartTime,
  plannedEndTime: item.plannedEndTime,
  realizedStartTime: item.realizedStartTime,
  realizedEndTime: item.realizedEndTime,
  customerId: item.customerId,
  repetitionId: item.repetitionId,
  reservedRepetitionId: item.reservedRepetitionId,
  canMove: item.itemState === TaskStatuses.UNCONFIRMED && !item.isCanceled,
  taskId: item.id,
  needIds: item.needIds || [],
  antiNeedIds: item.antiNeedIds || [],
  taskTypeId: item.itemTypeId,
  taskTemplateId: item.taskTemplate?.id,
  services: item.services,
  taskType: item.itemType,
  latestStartTime: item.latestStartTime,
  earliestStartTime: item.earliestStartTime,
  mustShowInTaskGroup: item.mustShowInTaskGroup,
  auctioned: item.auctioned,
  employeeId: item.employeeId,
  hasServices: item.hasServices,
  taskGroupId: item.taskGroupId,
  bookingItemAuction: item.bookingItemAuction,
  timeOfModification: item.timeOfModification,
  createdTime: item.createdTime,
  modifyingUser: item.modifyingUser,
  createdUser: item.createdUser,
  mobileKey: item.mobileKey,
  billed: item.billed,
  startLocation: item.startLocation,
  confirmLocation: item.confirmLocation,
});

export const handleGroupForTimelineViews = (item: BookingItem, timelineView: TimelineViews) => {
  switch (timelineView) {
    case TimelineViews.CUSTOMER_VIEW:
      return item.customerId;
    case TimelineViews.TASKS_VIEW:
    default:
      return item.employeeId;
  }
};

export const convertFieldsForEmployeeItem = (item: BookingItem, timelineView: TimelineViews) => ({
  id: `employee_item_${item.id}`,
  group: handleGroupForTimelineViews(item, timelineView),
  travelMode: item.employee?.travelMode,
  ...convertFieldsForItem(item),
});

const isEmployeeItem = (val: BookingItem) => !!val.employeeId;

export const convertFieldsForGroupItem = (item: BookingItem) => ({
  id: `group_item_${item.id}`,
  group: item.taskGroupId || OTHER_TASKS_ID,
  travelMode: item.taskGroup?.travelMode,
  ...convertFieldsForItem(item),
});

export const convertTaskFromAPIValues = (val: BookingItem, timelineView: TimelineViews) => {
  if (timelineView === TimelineViews.CUSTOMER_VIEW || isEmployeeItem(val)) {
    return convertFieldsForEmployeeItem(val, timelineView);
  }
  return convertFieldsForGroupItem(val);
};

/**
 * @description calculates the duration of a task, and adds the duration to the latestTimeStart
 * to reach a theoretical latest ending time for a task
 * @param {number} startTime optimal start time for task
 * @param {number} endTime optimal end time for task
 * @param {number} latestTimeStart theoretical upper boundary for starting the task
 * @returns {number} theoretical upper boundary for ending the task
 */
const addDurationToTimeEnd = (startTime: number, endTime: number, latestTimeStart: number) => {
  const duration = endTime - startTime;
  return duration + latestTimeStart;
};

const createCalendarItemReducer =
  (taskTimeStatus: TaskTimeStatusEnums, timelineView: TimelineViews) => (acc: any, val: any) => {
    const subIndex =
      timelineView === TimelineViews.CUSTOMER_VIEW || isEmployeeItem(val) ? 'employeeItems' : 'groupItems';
    const plannedStartTime = new Date(val.plannedStartTime).getTime();
    const plannedEndTime = new Date(val.plannedEndTime).getTime();
    const latestTime = new Date(val.latestStartTime).getTime();
    const realizedStartTime = val.realizedStartTime && new Date(val.realizedStartTime).getTime();
    const realizedEndTime = val.realizedEndTime && new Date(val.realizedEndTime).getTime();

    return {
      ...acc,
      [subIndex]: [
        ...acc[subIndex],
        {
          ...convertTaskFromAPIValues(val, timelineView),
          start: taskTimeStatusValidation(taskTimeStatus, plannedStartTime, realizedStartTime),
          end: taskTimeStatusValidation(taskTimeStatus, plannedEndTime, realizedEndTime),
          droppableTimeFrameEnd:
            val.latestStartTime && addDurationToTimeEnd(plannedStartTime, plannedEndTime, latestTime),
          droppableTimeFrameStart: val.earliestStartTime && new Date(val.earliestStartTime).getTime(),
        },
      ],
    };
  };

/**
 * @description converts apiData (tasks) to employee items and group items
 * so that they can be displayed in two different calendars
 */
export const convertCalendarItems = (
  apiData: Partial<BookingType>[],
  taskTimeStatus: TaskTimeStatusEnums,
  timelineView: TimelineViews,
) =>
  apiData.reduce(createCalendarItemReducer(taskTimeStatus, timelineView), {
    employeeItems: [],
    groupItems: [],
  });

export const convertRepetitionFromAPIValues = (repetition: Repetition): TemplateRepetition => ({
  id: repetition.id,
  nextTaskIdInRepetition: repetition.nextTaskIdInRepetition,
  previousTaskIdInRepetition: repetition.previousTaskIdInRepetition,
  option: repetition.option || RepetitionSpecialHolidayOptionType.ALLOW_SPECIAL_HOLIDAY,
  weekOfMonth1: repetition.weekOfMonth1,
  weekOfMonth2: repetition.weekOfMonth2,
  weekOfMonth3: repetition.weekOfMonth3,
  weekOfMonth4: repetition.weekOfMonth4,
  weekOfMonth5: repetition.weekOfMonth5,
  weekOfMonth6: repetition.weekOfMonth6,
  customStartTimeOfWeek1: repetition.customStartTimeOfWeek1,
  customStartTimeOfWeek2: repetition.customStartTimeOfWeek2,
  customStartTimeOfWeek3: repetition.customStartTimeOfWeek3,
  customStartTimeOfWeek4: repetition.customStartTimeOfWeek4,
  customStartTimeOfWeek5: repetition.customStartTimeOfWeek5,
  customStartTimeOfWeek6: repetition.customStartTimeOfWeek6,
  customStartTimeOfWeek7: repetition.customStartTimeOfWeek7,
  dayOfWeek1: repetition.dayOfWeek1,
  dayOfWeek2: repetition.dayOfWeek2,
  dayOfWeek3: repetition.dayOfWeek3,
  dayOfWeek4: repetition.dayOfWeek4,
  dayOfWeek5: repetition.dayOfWeek5,
  dayOfWeek6: repetition.dayOfWeek6,
  dayOfWeek7: repetition.dayOfWeek7,
  startDate: repetition.startDate,
  endDate: repetition.endDate,
  endDateSelected: !!repetition.endDate,
  nDays: repetition.nDayNumber,
  repetitionType: repetition.repetitionType,
  monthDays: convertTaskDayOfMonthForCalendar(repetition),
  foreverOngoing: repetition.foreverOngoing,
});

const apiMonthDaysFromMonthDays = (monthDays: number[]) =>
  _range(1, 32)
    .map((day: any) => ({
      key: `dayOfMonth${_padStart(day, 2, '0')}`,
      val: !!monthDays.find((md: any) => md === day),
    }))
    .reduce((acc, val) => ({ ...acc, [val.key]: val.val }), {});

// TODO: BE is going to change this to an array of numbers like workShift repetition
export const convertTaskDayOfMonthForCalendar = (repetition: Repetition) => {
  const monthDays = [];
  if (repetition.dayOfMonth01) {
    monthDays.push(1);
  }
  if (repetition.dayOfMonth02) {
    monthDays.push(2);
  }
  if (repetition.dayOfMonth03) {
    monthDays.push(3);
  }
  if (repetition.dayOfMonth04) {
    monthDays.push(4);
  }
  if (repetition.dayOfMonth05) {
    monthDays.push(5);
  }
  if (repetition.dayOfMonth06) {
    monthDays.push(6);
  }
  if (repetition.dayOfMonth07) {
    monthDays.push(7);
  }
  if (repetition.dayOfMonth08) {
    monthDays.push(8);
  }
  if (repetition.dayOfMonth09) {
    monthDays.push(9);
  }
  if (repetition.dayOfMonth10) {
    monthDays.push(10);
  }
  if (repetition.dayOfMonth11) {
    monthDays.push(11);
  }
  if (repetition.dayOfMonth12) {
    monthDays.push(12);
  }
  if (repetition.dayOfMonth13) {
    monthDays.push(13);
  }
  if (repetition.dayOfMonth14) {
    monthDays.push(14);
  }
  if (repetition.dayOfMonth15) {
    monthDays.push(15);
  }
  if (repetition.dayOfMonth16) {
    monthDays.push(16);
  }
  if (repetition.dayOfMonth17) {
    monthDays.push(17);
  }
  if (repetition.dayOfMonth18) {
    monthDays.push(18);
  }
  if (repetition.dayOfMonth19) {
    monthDays.push(19);
  }
  if (repetition.dayOfMonth20) {
    monthDays.push(20);
  }
  if (repetition.dayOfMonth21) {
    monthDays.push(21);
  }
  if (repetition.dayOfMonth22) {
    monthDays.push(22);
  }
  if (repetition.dayOfMonth23) {
    monthDays.push(23);
  }
  if (repetition.dayOfMonth24) {
    monthDays.push(24);
  }
  if (repetition.dayOfMonth25) {
    monthDays.push(25);
  }
  if (repetition.dayOfMonth26) {
    monthDays.push(26);
  }
  if (repetition.dayOfMonth27) {
    monthDays.push(27);
  }
  if (repetition.dayOfMonth28) {
    monthDays.push(28);
  }
  if (repetition.dayOfMonth29) {
    monthDays.push(29);
  }
  if (repetition.dayOfMonth30) {
    monthDays.push(30);
  }
  if (repetition.dayOfMonth31) {
    monthDays.push(31);
  }
  return monthDays;
};
/**
 * @description This handles transforming repetition values to API compatible format
 * The problem with this is that we shouldn't need this. The values should match straight away,
 * without conversion boilerplate, or WITH MINIMAL boilerplate
 * @param repetitionData
 * @param {number} taskTemplateId
 */
export const convertRepetitionToApiValues = (repetitionData: Partial<TemplateRepetition>, taskTemplateId?: number) => {
  const {
    endDate,
    startDate,
    option,
    dayOfWeek1,
    dayOfWeek2,
    dayOfWeek3,
    dayOfWeek4,
    dayOfWeek5,
    dayOfWeek6,
    dayOfWeek7,
    monthDays,
    weekOfMonth1,
    weekOfMonth2,
    weekOfMonth3,
    weekOfMonth4,
    weekOfMonth5,
    customStartTimeOfWeek1,
    customStartTimeOfWeek2,
    customStartTimeOfWeek3,
    customStartTimeOfWeek4,
    customStartTimeOfWeek5,
    customStartTimeOfWeek6,
    customStartTimeOfWeek7,
  } = repetitionData;
  const commonParams: Partial<Repetition> = { endDate, startDate, taskTemplateId, option };
  switch (repetitionData.repetitionType) {
    case RepetitionType.EVERY_NDAY:
      return {
        ...commonParams,
        repetitionType: RepetitionType.EVERY_NDAY,
        nDayNumber: repetitionData.nDays,
      };
    case RepetitionType.EVERY_DAY:
      return { ...commonParams, repetitionType: RepetitionType.EVERY_DAY };
    case RepetitionType.EVERY_MONTH:
      return {
        ...commonParams,
        repetitionType: RepetitionType.EVERY_MONTH,
        ...apiMonthDaysFromMonthDays(monthDays as number[]),
      };
    case RepetitionType.EVERY_WEEK: {
      if (customStartTimeOfWeek1) {
        commonParams.customStartTimeOfWeek1 = customStartTimeOfWeek1;
      }
      if (customStartTimeOfWeek2) {
        commonParams.customStartTimeOfWeek2 = customStartTimeOfWeek2;
      }
      if (customStartTimeOfWeek3) {
        commonParams.customStartTimeOfWeek3 = customStartTimeOfWeek3;
      }
      if (customStartTimeOfWeek4) {
        commonParams.customStartTimeOfWeek4 = customStartTimeOfWeek4;
      }
      if (customStartTimeOfWeek5) {
        commonParams.customStartTimeOfWeek5 = customStartTimeOfWeek5;
      }
      if (customStartTimeOfWeek6) {
        commonParams.customStartTimeOfWeek6 = customStartTimeOfWeek6;
      }
      if (customStartTimeOfWeek7) {
        commonParams.customStartTimeOfWeek7 = customStartTimeOfWeek7;
      }
      return {
        ...commonParams,
        repetitionType: RepetitionType.EVERY_WEEK,
        dayOfWeek1: !!dayOfWeek1,
        dayOfWeek2: !!dayOfWeek2,
        dayOfWeek3: !!dayOfWeek3,
        dayOfWeek4: !!dayOfWeek4,
        dayOfWeek5: !!dayOfWeek5,
        dayOfWeek6: !!dayOfWeek6,
        dayOfWeek7: !!dayOfWeek7,
        weekOfMonth1,
        weekOfMonth2,
        weekOfMonth3,
        weekOfMonth4,
        weekOfMonth5,
      };
    }
    default:
      return {
        startDate,
        endDate,
        taskTemplateId,
      };
  }
};

export const convertTaskToApiValues = ({ employee, status, taskType, customer, group, ...rest }: any) => ({
  employeeId: employee,
  itemState: status,
  itemTypeId: taskType,
  customerId: customer,
  taskGroupId: group === OTHER_TASKS_ID ? null : group,
  ...rest,
});

export const pickOnlyAddableValuesInTask = _omit([
  'requirements',
  'repetitions',
  'billed',
  'createdTime',
  'mobileKey',
  'timeOfModification',
  'modifyingUser',
  'reservedRepetitionId',
  'taskId',
  'createdUser',
]);

export const pickOnlyEditableValuesInTask = _compose(
  pickNonUndefinedValues,
  _omit(['repetitions', 'modifyingUser', 'mobileKey', 'createdTime', 'timeOfModification']),
);

export const needsOffersFromAPIValues = (needsOffers: NeedAndOffer[]): Record<number, NeedAndOffer> =>
  keyBy(needsOffers, 'id');

export const bookingTypesFromApiValues = (bookingTypes: BookingType[]): Record<number, BookingType> =>
  keyBy(bookingTypes, 'id');

export const suitableEmployeesFromAPIValues = (suitableEmployees: Employee[]): Record<number, Employee> =>
  keyBy(suitableEmployees, 'id');

export const busTravelDataFromAPIValues = (busTravelData: any[]) =>
  busTravelData.reduce((acc, val) => {
    const { planName } = val;
    const newBuses = [
      ...(acc[planName] ? acc[planName] : []),
      ...val.legs.map(({ arrivalTime, departureTime }: { arrivalTime: any; departureTime: any }) => ({
        arrivalTime,
        departureTime,
      })),
    ];
    return { ...acc, [planName]: newBuses };
  }, {});

export const convertEmployeeFromAPIValues = (employee: Employee) =>
  ({
    offerIds: employee.offerIds,
    notes: employee.notes,
    color: employee.color,
    email: employee.email,
    phoneNumber: employee.phoneNumber,
    streetAddress: employee.streetAddress,
    isAcceptSms: employee.isAcceptSms,
    id: employee.id,
    workTimeBegin: employee.workTimeBegin,
    workTimeEnd: employee.workTimeEnd,
    name: `${employee.lastName} ${employee.firstName}`,
    travelMode: employee.travelMode || TravelModeString.PUBLIC,
    droppable: true,
    firstName: employee.firstName,
    lastName: employee.lastName,
    partTimePercentage: employee.partTimePercentage,
    employmentAgreementId: employee.employmentAgreementId,
    removed: employee.removed,
    employeesPayrollStatus: employee.employeesPayrollStatus,
  } as EmployeeGroup);

export const groupFieldsFromAPIValues = (group: TaskBookingResource): GroupGroup => ({
  id: group.id,
  name: group.name,
  color: group.color,
  travelMode: group.travelMode || TravelModeString.PUBLIC,
  droppable: true,
});

export const convertWorkShift = (
  item: TaskTimeShiftAssignment,
  taskTimeStatus: TaskTimeStatusEnums,
): BookingCalendarWorkShift => {
  const plannedStartTime = new Date(item.plannedStartTime).getTime();
  const plannedEndTime = new Date(item.plannedEndTime).getTime();
  const realizedStartTime = item.realizedStartTime ? new Date(item.realizedStartTime).getTime() : undefined;
  const realizedEndTime = item.realizedEndTime ? new Date(item.realizedEndTime).getTime() : undefined;
  return {
    ...item,
    start: taskTimeStatusValidation(taskTimeStatus, plannedStartTime, realizedStartTime),
    end: taskTimeStatusValidation(taskTimeStatus, plannedEndTime, realizedEndTime),
    id: `work_shift_item_${item.id}`,
    group: (item.employeeId || item.taskGroupId) as number,
    canMove: true,
    status: TaskStatuses.WORK_SHIFT,
    taskId: item.id,
  };
};

export const workShiftAvailabilityCheck = (type: AvailabilityTypes) =>
  type === AvailabilityTypes.available ? TaskStatuses.WORK_SHIFT_AVAILABLE : TaskStatuses.WORK_SHIFT_NOT_AVAILABLE;

export const convertWorkShiftAvailabilities = (item: WorkShiftAvailability): BookingCalendarWorkShiftAvailability => {
  const startDateTime = formatDateTimeToISO(item.date, item.startTime);
  const endDateTime = formatDateTimeToISO(item.date, item.endTime);
  const formattedEndDateTime = isBefore(createMoment(endDateTime), createMoment(startDateTime))
    ? formatDateToDbDateTimeFormat(addTo(createMoment(endDateTime), 1, 'day'))
    : endDateTime;
  return {
    ...item,
    start: new Date(startDateTime).getTime(),
    end: new Date(formattedEndDateTime).getTime(),
    plannedStartTime: startDateTime,
    plannedEndTime: formattedEndDateTime,
    id: `work_shift_availability_item_${item.id}`,
    employeeId: item.userId,
    group: item.userId,
    canMove: false,
    status: workShiftAvailabilityCheck(item.type),
    taskId: item.id,
  };
};

export const repetitionDefaultValCommon = (startDate?: string, endDate?: string) => ({
  startDate: startDate || getCurrentDateTimeInDbFormat(),
  endDate,
  option: RepetitionSpecialHolidayOptionType.ALLOW_SPECIAL_HOLIDAY,
});

export const repetitionDefaultValMap = (repetitionType: RepetitionType, startDate?: string, endDate?: string) => {
  switch (repetitionType) {
    case RepetitionType.EVERY_MONTH:
      return {
        ...repetitionDefaultValCommon(startDate, endDate),
        repetitionType: RepetitionType.EVERY_MONTH,
        monthDays: [new Date().getDate()],
      };
    case RepetitionType.EVERY_NDAY:
      return {
        ...repetitionDefaultValCommon(startDate, endDate),
        repetitionType: RepetitionType.EVERY_NDAY,
        nDays: 2,
      };
    case RepetitionType.EVERY_WEEK:
      return {
        ...repetitionDefaultValCommon(startDate, endDate),
        repetitionType: RepetitionType.EVERY_WEEK,
        weekOfMonth1: true,
        weekOfMonth2: true,
        weekOfMonth3: true,
        weekOfMonth4: true,
        weekOfMonth5: true,
        [getCurrentWeekDayForSelectedWeekdays()]: true,
      };
    case RepetitionType.EVERY_DAY:
    default:
      return {
        ...repetitionDefaultValCommon(startDate, endDate),
        repetitionType: RepetitionType.EVERY_DAY,
      };
  }
};

export const isBookingCalendarTaskItem = (item: BookingCalendarItem): item is BookingCalendarTaskItem =>
  item.status === TaskStatuses.CONFIRMED ||
  item.status === TaskStatuses.UNCONFIRMED ||
  item.status === TaskStatuses.FINISHED;

export const moveItemInCalendar = (
  tasks: MoveTaskType[],
  changedTime: number,
  data: Partial<BookingItem>,
  updateMode: UpdateMode,
  timeTabId?: number,
) =>
  Promise.all(
    tasks.map(({ taskId, start, end, status, type, repetitionId }) => {
      const payload = { ...data };
      payload.plannedStartTime = formatDateToDbDateTimeFormat(start + (changedTime || 0));
      payload.plannedEndTime = formatDateToDbDateTimeFormat(end + (changedTime || 0));
      if (status === TaskStatuses.WORK_SHIFT) {
        payload.type = type as TaskTimeShiftAssignmentType;
        return updateSingleTimeShiftAssignment(taskId, payload, {
          type: type as TaskTimeShiftAssignmentType,
          timeTabId,
          applyToWorkShiftsInRepetition: !!(repetitionId && updateMode === UpdateMode.REPEATED),
        });
      }
      return editTask(taskId, payload, {
        applyToTasksInRepetition: !!(repetitionId && updateMode === UpdateMode.REPEATED),
      });
    }),
  );

// gets earliest plannedStartTime and latest plannedEndTime when adjusting timeline
export const calculatedPlannedTimes = (calendarItems: BookingCalendarItem[], adjustTimeline?: boolean) => {
  let plannedStartTime = '';
  let plannedEndTime = '';

  if (adjustTimeline && calendarItems.length > 0) {
    calendarItems.forEach((item) => {
      if (!plannedStartTime || !plannedEndTime) {
        plannedStartTime = item.plannedStartTime;
        plannedEndTime = item.plannedEndTime;
      } else {
        if (isBefore(item.plannedStartTime, plannedStartTime)) {
          plannedStartTime = item.plannedStartTime;
        }
        if (isAfter(item.plannedEndTime, plannedEndTime)) {
          plannedEndTime = item.plannedEndTime;
        }
      }
    });
  }
  return { plannedStartTime, plannedEndTime };
};

export const findClosestZoomLevel = (plannedStartTime: string, plannedEndTime: string) => {
  // we need the duration to be as precise as possible to cover all the visible items
  const duration = getDurationBetween(plannedStartTime, plannedEndTime, 'minutes') / 60;

  return zoomValues.reduce((prev, curr) => {
    const prevDiff = Math.abs(prev.value - duration);
    const curDiff = Math.abs(curr.value - duration);
    if (prevDiff === curDiff) {
      return prev.value > curr.value ? prev : curr;
    }
    return curDiff < prevDiff ? curr : prev;
  }).value;
};

// this method optimistically updates the tasks depending on the updateMode and task repetitionId
export const updateTasksRepetitionId = (tasks: BookingCalendarItem[], updateMode: UpdateMode) =>
  tasks.map((task) => {
    const { repetitionId } = task as BookingCalendarTaskItem;
    return {
      ...task,
      repetitionId: repetitionId && updateMode === UpdateMode.REPEATED ? repetitionId : undefined,
    };
  });

export const getStatusInfo = (status: TaskStatuses) => {
  let taskStateColor = colors.unconfirmedTask;
  let taskStateText = bookingMessages.notStarted;

  if (status === TaskStatuses.CONFIRMED) {
    taskStateColor = colors.confirmedTask;
    taskStateText = bookingMessages.started;
  } else if (status === TaskStatuses.FINISHED) {
    taskStateColor = colors.finishedTask;
    taskStateText = bookingMessages.finished;
  } else if (status === TaskStatuses.DELETED) {
    taskStateColor = colors.deletedTask;
    taskStateText = commonTranslations.deleted;
  }
  return { taskStateColor, taskStateText };
};
