import { AnyAction } from '@reduxjs/toolkit';
import {
  CarePlanCategory,
  CarePlanTreeCategory,
  CarePlanActiveVersion,
  CarePlanEvaluation,
  CarePlanEvaluationScale,
  CarePlanBatchEvaluation,
  CarePlanRaiNotes,
} from 'nonRouteFeatures/Careplan/types';
import {
  GET_CURRENT_CARE_PLAN_SCHEMA_SUCCESS,
  GET_PREVIOUS_CARE_PLAN_SCHEMA_SUCCESS,
  GET_CURRENT_CARE_PLAN,
  GET_CURRENT_CARE_PLAN_SUCCESS,
  GET_CARE_PLAN_VERSIONS_SUCCESS,
  GET_PREVIOUS_CARE_PLAN_VERSION,
  GET_PREVIOUS_CARE_PLAN_VERSION_SUCCESS,
  POST_NEW_CARE_PLAN,
  GET_CATEGORY_EVALUATIONS_SUCCESS,
  POST_CATEGORY_EVALUATIONS,
  GET_CATEGORY_EVALUATIONS_QUESTIONS,
  GET_CATEGORY_EVALUATIONS_QUESTIONS_SUCCESS,
  GET_SIMPLE_EVALUATIONS_QUESTIONS,
  GET_SIMPLE_EVALUATIONS_QUESTIONS_SUCCESS,
  GET_GOAL_EVALUATIONS,
  GET_GOAL_EVALUATIONS_SUCCESS,
  POST_GOAL_EVALUATIONS,
  GET_GOAL_EVALUATIONS_QUESTIONS,
  GET_GOAL_EVALUATIONS_QUESTIONS_SUCCESS,
  GET_PENDING_EVALUATIONS,
  GET_PENDING_EVALUATIONS_SUCCESS,
  GET_RAI_NOTES,
  GET_RAI_NOTES_SUCCESS,
  RESET_CARE_PLAN_STORE,
} from './constants';

export type CarePlanState = {
  currentCarePlanSchema: { categories: CarePlanTreeCategory[] } | null;
  currentCarePlan: { categories: CarePlanCategory[] } | null;
  carePlanVersions: CarePlanActiveVersion[];
  carePlanVersionDate: string;
  categoryEvaluations: CarePlanEvaluation[];
  categoryEvaluationsQuestions: CarePlanEvaluationScale[];
  simpleEvaluationsQuestions: CarePlanEvaluationScale[];
  goalEvaluations: CarePlanEvaluation[];
  goalEvaluationsQuestions: CarePlanEvaluationScale[];
  pendingEvaluations: CarePlanBatchEvaluation[];
  raiNotes: CarePlanRaiNotes | null;
};

const initialState: CarePlanState = {
  currentCarePlanSchema: null,
  currentCarePlan: null,
  carePlanVersions: [],
  carePlanVersionDate: 'current',
  categoryEvaluations: [],
  categoryEvaluationsQuestions: [],
  simpleEvaluationsQuestions: [],
  goalEvaluations: [],
  goalEvaluationsQuestions: [],
  pendingEvaluations: [],
  raiNotes: null,
};

// Tasks reducer

function carePlanReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_CURRENT_CARE_PLAN_SCHEMA_SUCCESS:
      return { ...state, currentCarePlanSchema: action.currentCarePlanSchema };

    case GET_PREVIOUS_CARE_PLAN_SCHEMA_SUCCESS:
      return { ...state, currentCarePlanSchema: action.previousCarePlanSchema };

    case GET_CURRENT_CARE_PLAN:
      return { ...state, carePlanVersionDate: 'current', currentCarePlan: null };

    case GET_CURRENT_CARE_PLAN_SUCCESS:
      return { ...state, currentCarePlan: action.currentCarePlan };

    case GET_CARE_PLAN_VERSIONS_SUCCESS:
      return { ...state, carePlanVersions: action.carePlanVersions };

    case GET_PREVIOUS_CARE_PLAN_VERSION:
      return { ...state, currentCarePlan: null };
    case GET_PREVIOUS_CARE_PLAN_VERSION_SUCCESS:
      return { ...state, currentCarePlan: action.previousCarePlan, carePlanVersionDate: action.date };

    case POST_NEW_CARE_PLAN:
      return { ...state, currentCarePlan: null };

    case GET_CATEGORY_EVALUATIONS_SUCCESS:
      return { ...state, categoryEvaluations: action.categoryEvaluations };

    case POST_CATEGORY_EVALUATIONS:
      return { ...state, categoryEvaluations: null };

    case GET_CATEGORY_EVALUATIONS_QUESTIONS:
      return { ...state, categoryEvaluationsQuestions: [] };
    case GET_CATEGORY_EVALUATIONS_QUESTIONS_SUCCESS:
      return { ...state, categoryEvaluationsQuestions: action.categoryEvaluationsQuestions };

    case GET_GOAL_EVALUATIONS:
      return { ...state, goalEvaluations: [] };
    case GET_GOAL_EVALUATIONS_SUCCESS:
      return { ...state, goalEvaluations: action.goalEvaluations };

    case POST_GOAL_EVALUATIONS:
      return { ...state, goalEvaluations: null };

    case GET_GOAL_EVALUATIONS_QUESTIONS:
      return { ...state, goalEvaluationsQuestions: [] };
    case GET_GOAL_EVALUATIONS_QUESTIONS_SUCCESS:
      return { ...state, goalEvaluationsQuestions: action.goalEvaluationsQuestions };

    case GET_SIMPLE_EVALUATIONS_QUESTIONS:
      return { ...state, simpleEvaluationsQuestions: [] };
    case GET_SIMPLE_EVALUATIONS_QUESTIONS_SUCCESS:
      return { ...state, simpleEvaluationsQuestions: action.simpleEvaluationsQuestions };

    case GET_PENDING_EVALUATIONS:
      return state;
    case GET_PENDING_EVALUATIONS_SUCCESS:
      return { ...state, pendingEvaluations: action.pendingEvaluations };

    case GET_RAI_NOTES:
      return state;
    case GET_RAI_NOTES_SUCCESS:
      return { ...state, raiNotes: action.raiNotes };

    case RESET_CARE_PLAN_STORE:
      return { ...state, currentCarePlanSchema: null, currentCarePlan: null, pendingEvaluations: [], raiNotes: null };

    default:
      return state;
  }
}

export default carePlanReducer;
