import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import cn from 'classnames';
import HeaderBar from 'common/HeaderBar';
import styles from './HeaderActions.scss';

type ActionItemBase = {
  label: React.ReactText;
  active?: boolean;
};

type ActionItemClick = { onClick: () => void };
type ActionItemLink = Pick<LinkProps, 'to'>;

export type ActionItem = ActionItemBase & (ActionItemClick | ActionItemLink);

type Props = {
  className?: string;
  items: ActionItem[];
};

const TEST_ID = 'header-action';

const HeaderActionItem: React.FC<{ item: ActionItem }> = ({ item }) => {
  const classes = cn(styles.link, { [styles.active]: item.active });
  const content = <span>{item.label}</span>;
  if ('to' in item) {
    return (
      <Link to={item.to} className={classes} data-testid={TEST_ID} data-active={item.active}>
        {content}
      </Link>
    );
  }

  const handleClick = () => {
    item.onClick();
  };
  return (
    <button type="button" className={classes} onClick={handleClick} data-testid={TEST_ID} data-active={item.active}>
      {content}
    </button>
  );
};

const HeaderActions = ({ className, items }: Props) => (
  <HeaderBar className={cn(styles.root, className)}>
    {items.map((item) => (
      <HeaderActionItem key={item.label} item={item} />
    ))}
  </HeaderBar>
);

export default HeaderActions;
