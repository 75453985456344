import { defineMessages } from 'react-intl';

export default defineMessages({
  moreFilters: {
    id: 'app.payroll.moreFilters',
    defaultMessage: 'More filters',
  },
  salaryData: {
    id: 'app.payroll.salaryData',
    defaultMessage: 'Salary data',
  },
  workingHours: {
    id: 'app.payroll.workingHours',
    defaultMessage: 'Working hours',
  },
  workshiftDeviation: {
    id: 'app.payroll.workshiftDeviation',
    defaultMessage: 'Workshift deviation',
  },
  deviation: {
    id: 'app.payroll.deviation',
    defaultMessage: 'Deviation',
  },
  deviationDetails: {
    id: 'app.payroll.deviationDetails',
    defaultMessage: 'Deviation details',
  },
  salaryDetails: {
    id: 'app.payroll.salaryDetails',
    defaultMessage: 'Salary details',
  },
  formOfWages: {
    id: 'app.payroll.formOfWages',
    defaultMessage: 'form of wages',
  },
  confirmAcceptRows: {
    id: 'app.payroll.confirmAcceptRows',
    defaultMessage: 'Are you sure you want to accept the checked rows?',
  },
  exportPayrollData: {
    id: 'app.payroll.exportPayrollData',
    defaultMessage: 'Export payroll data',
  },
  salaryType: {
    id: 'app.payroll.salaryType',
    defaultMessage: 'Salary type',
  },
  cpu: {
    id: 'app.payroll.cpu',
    defaultMessage: 'CPU',
  },
  mandatoryFields: {
    id: 'app.payroll.mandatoryFields',
    defaultMessage: 'Select either unit(s), booking calendar(s), or employee agreement(s)',
  },
  filename: {
    id: 'app.payroll.filename',
    defaultMessage: 'Filename',
  },
  exporter: {
    id: 'app.payroll.exporter',
    defaultMessage: 'Exporter',
  },
  exportedTime: {
    id: 'app.payroll.exportedTime',
    defaultMessage: 'Exported Time',
  },
  numberOfRows: {
    id: 'app.payroll.numberOfRows',
    defaultMessage: 'Number of rows',
  },
  exportFileDetails: {
    id: 'app.payroll.exportFileDetails',
    defaultMessage: 'Export File Details',
  },
  allSalaries: {
    id: 'app.payroll.allSalaries',
    defaultMessage: 'All salaries',
  },
  rowsAccepted: {
    id: 'app.payroll.rowsAccepted',
    defaultMessage: '{rows, plural, one {# row} other {# rows}} accepted',
  },
  rowsExported: {
    id: 'app.payroll.rowsExported',
    defaultMessage: '{rows, plural, one {# row} other {# rows}} exported',
  },
  noAdditionalDetailsApprovedRow: {
    id: 'app.payroll.noAdditionalDetailsApprovedRow',
    defaultMessage: 'No additional details are available for "approved" rows',
  },
  confirmRevert: {
    id: 'app.payroll.confirmRevertExport',
    defaultMessage: 'Confirm reverting rows',
  },
  revertSuccessful: {
    id: 'app.payroll.revertSuccessful',
    defaultMessage: 'Revert successful',
  },
  payroll: {
    id: 'app.payroll.payroll',
    defaultMessage: 'Payroll',
  },
  exportArchive: {
    id: 'app.payroll.exportArchive',
    defaultMessage: 'Export Archive',
  },
  planningPeriod1: {
    id: 'app.payroll.planningPeriod1',
    defaultMessage: '1st planning period',
  },
  planningPeriod2: {
    id: 'app.payroll.planningPeriod2',
    defaultMessage: '2nd planning period',
  },
  equalizationPeriod: {
    id: 'app.payroll.equalizationPeriod',
    defaultMessage: 'Equalization period',
  },
  overtimeLimit: {
    id: 'app.payroll.overtimeLimit',
    defaultMessage: 'Overtime limit',
  },
  payrollWarning: {
    id: 'app.payroll.payrollWarning',
    defaultMessage: 'This table contains some warnings.',
  },
  payrollError: {
    id: 'app.payroll.payrollError',
    defaultMessage: 'This table contains some errors.',
  },
  MISSING_TES_DATA: {
    id: 'app.payroll.MISSING_TES_DATA',
    defaultMessage: 'The user has no agreement',
  },
  MISSING_SHIFT_TEMPLATE: {
    id: 'app.payroll.MISSING_SHIFT_TEMPLATE',
    defaultMessage: "The shift doesn't have any template",
  },
  MISSING_ABSENCE_TYPE: {
    id: 'app.payroll.MISSING_ABSENCE_TYPE',
    defaultMessage: 'Salary type is missing in absence shift',
  },
  MISSING_STAND_BY_TYPE: {
    id: 'app.payroll.MISSING_STAND_BY_TYPE',
    defaultMessage: 'Salary type is missing in stand by shift',
  },
  MISSING_CUSTOM_TYPE_CONFIG: {
    id: 'app.payroll.MISSING_CUSTOM_TYPE_CONFIG',
    defaultMessage: 'Salary type is defined but missing in salary agreement',
  },
  MISSING_BASE_RATE: {
    id: 'app.payroll.MISSING_BASE_RATE',
    defaultMessage: "User don't have base rate configured",
  },
  MISSING_OVERTIME_CONFIG: {
    id: 'app.payroll.MISSING_OVERTIME_CONFIG',
    defaultMessage: "Agreement doesn't contain any overtime configuration",
  },
  MISSING_SSN: {
    id: 'app.payroll.MISSING_SSN',
    defaultMessage: "User don't have SNN defined",
  },
  MISSING_EXTERNAL_ID: {
    id: 'app.payroll.MISSING_EXTERNAL_ID',
    defaultMessage: "User doesn't have external ID defined",
  },
  MISSING_MONTHLY_SALARY: {
    id: 'app.payroll.MISSING_MONTHLY_SALARY',
    defaultMessage: "User doesn't have a monthly salary defined",
  },
  MISSING_MONTHLY_RATE: {
    id: 'app.payroll.MISSING_MONTHLY_RATE',
    defaultMessage: "Employee's agreement is missing the salary type for monthly salary",
  },
  TASK_NOT_LINKED_TO_AGREEMENT: {
    id: 'app.payroll.TASK_NOT_LINKED_TO_AGREEMENT',
    defaultMessage: 'Task does not have a countable salary linked to the employee agreement',
  },
  viewChanges: {
    id: 'app.payroll.viewChanges',
    defaultMessage: 'View changes',
  },
  duplicateFileName: {
    id: 'app.payroll.duplicateFileName',
    defaultMessage: 'The file name already exists. Please change it and try again',
  },
  dateSelectionError: {
    id: 'app.payroll.dateSelectionError',
    defaultMessage: 'The date selection should be up to 62 days or less',
  },
  endDateAfterStartError: {
    id: 'app.payroll.endDateAfterStartError',
    defaultMessage: "The end date shouldn't be after the start date",
  },
  noWorkShiftTask: {
    id: 'app.payroll.noWorkShiftTask',
    defaultMessage: 'No tasks during work shift',
  },
  changeInEuro: {
    id: 'app.payroll.changeInEuro',
    defaultMessage: 'Change in euro',
  },
  change: {
    id: 'app.payroll.change',
    defaultMessage: 'Change',
  },
  timeSpan: {
    id: 'app.payroll.timeSpan',
    defaultMessage: 'Applied timespan {start} - {end}',
  },
  noRealizedTime: {
    id: 'app.payroll.noRealizedTime',
    defaultMessage: 'No realized time',
  },
  nameOrExternalId: {
    id: 'app.payroll.nameOrExternalId',
    defaultMessage: 'Employee name or external ID',
  },
  noAdditionalDetail: {
    id: 'app.payroll.noAdditionalDetail',
    defaultMessage: 'No additional details',
  },
  employeeFilterInfo: {
    id: 'app.payroll.employeeFilterInfo',
    defaultMessage: 'Select any other filter to select employees',
  },
  NOT_WITHIN_ONE_AGREEMENT: {
    id: 'app.payroll.NOT_WITHIN_ONE_AGREEMENT',
    defaultMessage: 'Shift/task is not done within one history period',
  },
  AGREEMENT_HISTORY_OVERLAPPING_ERROR: {
    id: 'app.payroll.AGREEMENT_HISTORY_OVERLAPPING_ERROR',
    defaultMessage: 'Agreement history periods are overlapping.',
  },
});
