import { OptionsType } from 'react-select';
import { MessageDescriptor } from '@formatjs/intl';

export type OptionTypeBase = Record<string, any>;

export type SelectOption<T extends SelectOptionValue = SelectOptionValue> = {
  label: string;
  value: T;
};
export type SelectOptionTranslate<T extends SelectOptionValue = SelectOptionValue> = {
  label: MessageDescriptor;
  value: T;
};
export type SelectOptionValue = string | number;
export type SelectCreatableOption<T extends SelectOptionValue = SelectOptionValue> = SelectOption<T> & {
  __isNew__?: true;
};
export type SelectOptions<Option extends OptionTypeBase = any> = OptionsType<Option>;
export type SelectSelectedOptions<isMulti extends boolean, Option extends OptionTypeBase = any> = isMulti extends true
  ? Option[]
  : Option;

export type SelectOnlyValue<isMulti extends boolean> = isMulti extends false ? SelectOptionValue : SelectOptionValue[];

export type SelectSelectedValue<
  isMulti extends boolean | undefined = false,
  onlyValue extends boolean | undefined = false,
  Option extends OptionTypeBase = any,
> = onlyValue extends true
  ? isMulti extends true
    ? SelectOptionValue[]
    : SelectOptionValue
  : isMulti extends true
  ? Option[]
  : Option;

export type GetSelectOptionLabel<OptionType extends OptionTypeBase = any> = (option: OptionType) => string;

export type GetSelectOptionValue<OptionType extends OptionTypeBase = any> = (option: OptionType) => SelectOptionValue;

export type isOptionDisabled<OptionType extends OptionTypeBase = any> = (option: OptionType) => boolean;

export enum reactSelectActions {
  REMOVE_VALUE = 'remove-value',
  POP_VALUE = 'pop-value',
  CLEAR = 'clear',
  SELECT_OPTION = 'select-option',
  DESELECT_OPTION = 'deselect-option',
}
