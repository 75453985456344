export enum AvailabilityTypes {
  nothing,
  available = 'AVAILABLE',
  notAvailable = 'UNAVAILABLE',
  workShift = 'workShift',
}

export enum DayOfWeek {
  monday = 'MONDAY',
  tuesday = 'TUESDAY',
  wednesday = 'WEDNESDAY',
  thursday = 'THURSDAY',
  friday = 'FRIDAY',
  saturday = 'SATURDAY',
  sunday = 'SUNDAY',
}

// ---- SHIFTS ----

export type AvailabilityShift = {
  id: number;
  userId: number;
  date: string;
  type: AvailabilityTypes;
  comment?: string;
  startTime: string;
  endTime: string;
};

export type PartialAvailabilityShift = Omit<AvailabilityShift, 'id'> & { id?: number };

// used for mapping the "calendar"
export type Period = {
  weekNum: number;
  dayNums: string[]; // array of dates
  id: number;
};

export type WorkPlanningContextType = {
  getShifts: () => void;
  showAvailability: boolean;
};
