/* medicine constants */
export const GET_MEDICINE_LIST = 'app/MedicineList/GET_MEDICINE_LIST';
export const GET_MEDICINE_LIST_SUCCESS = 'app/MedicineList/GET_MEDICINE_LIST_SUCCESS';
export const GET_MEDICINE_LIST_FAILED = 'app/MedicineList/GET_MEDICINE_LIST_FAILED';

export const GET_DISTRIBUTION_DESCRIPTOR = 'app/MedicineList/GET_DISTRIBUTION_DESCRIPTOR';
export const GET_DISTRIBUTION_DESCRIPTOR_SUCCESS = 'app/MedicineList/GET_DISTRIBUTION_DESCRIPTOR_SUCCESS';
export const GET_DISTRIBUTION_DESCRIPTOR_FAILED = 'app/MedicineList/GET_DISTRIBUTION_DESCRIPTOR_FAILED';

export const ADD_NEW_MEDICINE = 'app/MedicineList/ADD_NEW_MEDICINE';
export const ADD_NEW_MEDICINE_SUCCESS = 'app/MedicineList/ADD_NEW_MEDICINE_SUCCESS';
export const ADD_NEW_MEDICINE_FAILED = 'app/MedicineList/ADD_NEW_MEDICINE_FAILED';

export const EDIT_MEDICINE = 'app/MedicineList/EDIT_MEDICINE';
export const EDIT_MEDICINE_SUCCESS = 'app/MedicineList/EDIT_MEDICINE_SUCCESS';
export const EDIT_MEDICINE_FAILED = 'app/MedicineList/EDIT_MEDICINE_FAILED';

export const DELETE_MEDICINE = 'app/MedicineList/DELETE_MEDICINE';
export const DELETE_MEDICINE_SUCCESS = 'app/MedicineList/DELETE_MEDICINE_SUCCESS';
export const DELETE_MEDICINE_FAILED = 'app/MedicineList/DELETE_MEDICINE_FAILED';

export const GET_EXTRA_MEDICINE_INFO = 'app/MedicineList/GET_EXTRA_MEDICINE_INFO';
export const GET_EXTRA_MEDICINE_INFO_SUCCESS = 'app/MedicineList/GET_EXTRA_MEDICINE_INFO_SUCCESS';
export const GET_EXTRA_MEDICINE_INFO_FAILED = 'app/MedicineList/GET_EXTRA_MEDICINE_INFO_FAILED';

/* medicine log constants */
export const GET_MEDICINE_LOG = 'app/MedicineList/GET_MEDICINE_LOG';
export const GET_MEDICINE_LOG_SUCCESS = 'app/MedicineList/GET_MEDICINE_LOG_SUCCESS';
export const GET_MEDICINE_LOG_FAILED = 'app/MedicineList/GET_MEDICINE_LOG_FAILED';

export const ADD_MEDICINE_LOG = 'app/MedicineList/ADD_MEDICINE_LOG';
export const ADD_MEDICINE_LOG_SUCCESS = 'app/MedicineList/ADD_MEDICINE_LOG_SUCCESS';
export const ADD_MEDICINE_LOG_FAILED = 'app/MedicineList/ADD_MEDICINE_LOG_FAILED';

export const DELETE_MEDICINE_LOG = 'app/MedicineList/DELETE_MEDICINE_LOG';
export const DELETE_MEDICINE_LOG_SUCCESS = 'app/MedicineList/DELETE_MEDICINE_LOG_SUCCESS';
export const DELETE_MEDICINE_LOG_FAILED = 'app/MedicineList/DELETE_MEDICINE_LOG_FAILED';

/* Allergies constants */
export const GET_ALLERGIES = 'app/MedicineList/GET_ALLERGIES';
export const GET_ALLERGIES_SUCCESS = 'app/MedicineList/GET_ALLERGIES_SUCCESS';
export const GET_ALLERGIES_FAILED = 'app/MedicineList/GET_ALLERGIES_FAILED';

export const SUBMIT_NEW_ALLERGIES = 'app/MedicineList/SUBMIT_NEW_ALLERGIES';
export const SUBMIT_NEW_ALLERGIES_SUCCESS = 'app/MedicineList/SUBMIT_NEW_ALLERGIES_SUCCESS';
export const SUBMIT_NEW_ALLERGIES_FAILED = 'app/MedicineList/SUBMIT_NEW_ALLERGIES_FAILED';

export const UPDATE_ALLERGIES = 'app/MedicineList/UPDATE_ALLERGIES';
export const UPDATE_ALLERGIES_SUCCESS = 'app/MedicineList/UPDATE_ALLERGIES_SUCCESS';
export const UPDATE_ALLERGIES_FAILED = 'app/MedicineList/UPDATE_ALLERGIES_FAILED';

/* medicine checklog constants */
export const GET_MEDICINE_CHECKLOG = 'app/MedicineList/GET_MEDICINE_CHECKLOG';
export const GET_MEDICINE_CHECKLOG_SUCCESS = 'app/MedicineList/GET_MEDICINE_CHECKLOG_SUCCESS';
export const GET_MEDICINE_CHECKLOG_FAILED = 'app/MedicineList/GET_MEDICINE_CHECKLOG_FAILED';

export const ADD_MEDICINE_CHECKLOG = 'app/MedicineList/ADD_MEDICINE_CHECKLOG';
export const ADD_MEDICINE_CHECKLOG_SUCCESS = 'app/MedicineList/ADD_MEDICINE_CHECKLOG_SUCCESS';
export const ADD_MEDICINE_CHECKLOG_FAILED = 'app/MedicineList/ADD_MEDICINE_CHECKLOG_FAILED';

/* medicine constants */
export const GET_MEDICINE_HISTORY = 'app/MedicineList/GET_MEDICINE_HISTORY';
export const GET_MEDICINE_HISTORY_SUCCESS = 'app/MedicineList/GET_MEDICINE_HISTORY_SUCCESS';
export const GET_MEDICINE_HISTORY_FAILED = 'app/MedicineList/GET_MEDICINE_HISTORY_FAILED';

/* reset medicine tore */
export const RESET_MEDICINE_STORE = 'app/Careplan/RESET_MEDICINE_STORE';
export const RESET_MEDICINE_STORE_SUCCESS = 'app/Careplan/RESET_MEDICINE_STORE_SUCCESS';
