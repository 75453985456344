import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import useModal from 'hooks/useModal';

import { selectAuthRequiredDomain } from 'store/auth/selectors';
import LoginModal from 'Login/components/Login/LoginModal';

const useLoginSessionChannel = () => {
  const [showLoginModalAction, hideLoginModalAction] = useModal(LoginModal);
  const { showLoginModal } = useSelector(selectAuthRequiredDomain());

  useEffect(() => {
    if (showLoginModal) {
      showLoginModalAction();
    } else {
      hideLoginModalAction();
    }
  }, [hideLoginModalAction, showLoginModal, showLoginModalAction]);
};

export default useLoginSessionChannel;
