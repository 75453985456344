/* eslint-disable react/prop-types */
import React from 'react';
import cn from 'classnames';
import styles from 'common/form/Label.scss';

type Props = {
  required?: boolean;
  htmlFor?: string;
};

const Label: React.FC<Props> = ({ children, htmlFor, required }) => (
  <label className={cn(styles.root, { [styles.required]: required })} htmlFor={htmlFor}>
    {children}
  </label>
);

export default Label;
