/* eslint-disable react/jsx-props-no-spreading,react/button-has-type */
import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import styles from './Button.scss';

export type ButtonProps = {
  secondary?: boolean;
  success?: boolean;
  danger?: boolean;
  large?: boolean; // TODO: make it bigger!
  loading?: boolean;
  testId?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = React.forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  ({ className, type = 'button', onClick, children, secondary, success, danger, loading, testId, ...rest }, ref) => (
    <button
      type={type}
      className={cn(
        styles.root,
        { [styles.secondary]: secondary, [styles.success]: success, [styles.danger]: danger },
        className,
      )}
      onClick={onClick}
      ref={ref}
      data-testid={testId}
      {...rest}
    >
      {children}
      {loading && <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />}
    </button>
  ),
);

Button.displayName = 'Button';

export default Button;
