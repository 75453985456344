import { createSlice, current } from '@reduxjs/toolkit';

import { Customer } from 'types/Customer';
import { Features } from 'types/Features';

import {
  AnswerProgression,
  Answers,
  Assessment,
  AssessmentMeasurements,
  CapsAndRapsType,
  InstrumentQuestions,
  Instruments,
  InstrumentsList,
  Note,
  QuestionIdsMap,
  ScoreType,
} from 'nonRouteFeatures/RAIv2/types';
import {
  getAssessmentAnswersSuccess,
  getCustomerAllAssessmentsSuccess,
  getCustomerDetailsSuccess,
  getInstrumentQuestionsSuccess,
  getInstrumentsListSuccess,
  getModifiedCategoriesSuccess,
  getSingleAssessmentSuccess,
  postAnswer,
  postAnswerFailed,
  postAnswerSuccess,
  resetAssessments,
  resetRai,
  setRaiIsLoading,
  startNewAssessmentSuccess,
  getModifiedCategories,
  resetInquiryMode,
  getAssessmentMeasurementsSuccess,
  getAssessmentMeasurements,
  setFocused,
  getRaiNotesSuccess,
} from 'nonRouteFeatures/RAIv2/actions';
import { handleArrayToMap, handleCategoryChange } from 'nonRouteFeatures/RAIv2/utils';

export type RaiState = {
  instrumentsList: InstrumentsList;
  instrumentQuestions: null | InstrumentQuestions;
  raiScores: ScoreType[];
  newAssessment: null | Assessment<Instruments>;
  customerAllAssessments: Assessment<Instruments>[];
  ongoingAssessments: Assessment<Instruments>[];
  assessmentArchived: null | Assessment<Instruments>;
  archivedAssessments: Assessment<Instruments>[];
  assessmentAnswers: Answers;
  uniqueAssessment: null | Assessment<Instruments>;
  capsAndRaps: CapsAndRapsType[];
  raiNotes: Note[];
  answerProgression: null | AnswerProgression;
  modifiedInstrumentQuestions: null | InstrumentQuestions;
  unlockedAssessment: null | Assessment<Instruments>;
  customerDetails: null | Customer;
  affectingQuestionsLoading: boolean;
  raiNotesLoading: boolean;
  postedAnswerLoading: boolean;
  inquiryModeView: boolean;
  questionIdsMap: QuestionIdsMap | null;
  raiIsLoading: boolean;
  postedAnswer: null | Answers;
  assessmentMeasurements: null | AssessmentMeasurements;
  focused: boolean;
};

const raiInitialState: RaiState = {
  instrumentsList: [],
  instrumentQuestions: null,
  raiScores: [],
  newAssessment: null,
  customerAllAssessments: [],
  ongoingAssessments: [],
  assessmentArchived: null,
  archivedAssessments: [],
  assessmentAnswers: {},
  uniqueAssessment: null,
  capsAndRaps: [],
  raiNotes: [],
  postedAnswer: null,
  answerProgression: null,
  modifiedInstrumentQuestions: null,
  unlockedAssessment: null,
  customerDetails: null,
  affectingQuestionsLoading: false,
  raiNotesLoading: false,
  postedAnswerLoading: false,
  inquiryModeView: false,
  questionIdsMap: null,
  raiIsLoading: true,
  assessmentMeasurements: null,
  focused: false,
};

const raiSlice = createSlice({
  reducers: {},
  name: Features.Rai_v2,
  initialState: raiInitialState,
  extraReducers(builder) {
    builder
      .addCase(getAssessmentMeasurements, (draft) => {
        draft.assessmentMeasurements = null;
      })
      .addCase(getAssessmentMeasurementsSuccess, (draft, action) => {
        draft.assessmentMeasurements = action.payload.measurements;
      })
      .addCase(getCustomerDetailsSuccess, (draft, action) => {
        draft.customerDetails = action.payload.customerDetails;
      })
      .addCase(getInstrumentsListSuccess, (draft, action) => {
        draft.instrumentsList = action.payload.instrumentsList;
      })
      .addCase(resetAssessments, (draft) => {
        draft.archivedAssessments = [];
        draft.ongoingAssessments = [];
        draft.customerAllAssessments = [];
        draft.assessmentArchived = null;
      })
      .addCase(startNewAssessmentSuccess, (draft, action) => {
        draft.newAssessment = action.payload.newAssessment;
      })
      .addCase(getCustomerAllAssessmentsSuccess, (draft, action) => {
        const { customerAllAssessments } = action.payload;

        draft.customerAllAssessments = customerAllAssessments;

        draft.ongoingAssessments = customerAllAssessments.filter((item) => !item.archived && !item.locked);

        draft.archivedAssessments = customerAllAssessments
          .filter((item) => item.archived)
          .sort((a, b) => (a.created > b.created ? -1 : 1));

        draft.unlockedAssessment = customerAllAssessments.find((item) => !item.locked && item.archived) || null;
      })
      .addCase(getSingleAssessmentSuccess, (draft, action) => {
        draft.uniqueAssessment = action.payload.assessment;
      })
      .addCase(resetRai, (draft) => {
        draft.inquiryModeView = true;
        draft.assessmentAnswers = {};
        draft.instrumentQuestions = null;
        draft.answerProgression = null;
        draft.modifiedInstrumentQuestions = null;
        draft.postedAnswer = null;
      })
      .addCase(resetInquiryMode, (draft) => {
        draft.modifiedInstrumentQuestions = null;
        draft.inquiryModeView = false;
        draft.assessmentAnswers = {};
        draft.uniqueAssessment = null;
        draft.answerProgression = null;
        draft.instrumentQuestions = null;
        draft.postedAnswer = null;
      })
      .addCase(getInstrumentQuestionsSuccess, (draft, action) => {
        draft.instrumentQuestions = action.payload.instrumentQuestions;
      })
      .addCase(setFocused, (draft, action) => {
        draft.focused = action.payload;
      })
      .addCase(setRaiIsLoading, (draft, action) => {
        draft.raiIsLoading = action.payload;
      })
      .addCase(postAnswer, (draft) => {
        draft.postedAnswerLoading = true;
      })
      .addCase(postAnswerFailed, (draft) => {
        draft.postedAnswerLoading = false;
      })
      .addCase(postAnswerSuccess, (draft, action) => {
        if (!action.payload) {
          draft.postedAnswerLoading = false; // no response
        }
        draft.postedAnswer = action.payload.answers;
        // const assessmentAnswers = handleAssessmentAnswersChange(draft.assessmentAnswers, action.payload);
        // const fullProgress = handleAssessmentProgress(
        //   draft.answerProgression?.fullProgression,
        //   action.payload.answerProgression.fullProgression,
        // );
        // const categoryProgresses = handleCategoryProgress(
        //   draft.answerProgression?.categoryProgression,
        //   action.payload.answerProgression.categoryProgression,
        // );
        const newCategories = handleCategoryChange(
          current(draft.instrumentQuestions?.categories),
          action.payload.answers,
        );

        if (newCategories) {
          draft.modifiedInstrumentQuestions = {
            ...(current(draft.instrumentQuestions) as InstrumentQuestions),
            categories: newCategories,
          };
        }

        draft.questionIdsMap = handleArrayToMap(newCategories);

        draft.assessmentAnswers = action.payload.answers;
        draft.answerProgression = action.payload.answerProgression;

        draft.postedAnswerLoading = false;
      })
      .addCase(getRaiNotesSuccess, (draft, action) => {
        draft.raiNotes = action.payload.raiNotes;
      })
      .addCase(getAssessmentAnswersSuccess, (draft, action) => {
        draft.assessmentAnswers = action.payload.answers;
        draft.answerProgression = action.payload.answerProgression;
      })
      .addCase(getModifiedCategories, (draft) => {
        draft.inquiryModeView = true;
      })
      .addCase(getModifiedCategoriesSuccess, (draft, action) => {
        const { answers, answerProgression } = action.payload.answers;

        const newCategories = handleCategoryChange(action.payload.questions.categories, answers);

        draft.questionIdsMap = handleArrayToMap(newCategories);

        draft.assessmentAnswers = answers;

        draft.answerProgression = answerProgression;

        draft.instrumentQuestions = action.payload.questions;

        if (newCategories) {
          draft.modifiedInstrumentQuestions = {
            ...action.payload.questions,
            categories: newCategories,
          };
        }
      });
  },
});

const { reducer } = raiSlice;

export default reducer;
