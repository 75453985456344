import commonMessages from 'translations/common';

import headerMessages from 'Booking/components/Header/messages';
import bookingMessages from 'Booking/messages';
import TaskStatuses from 'Booking/enums/TaskStatuses';
import { DANGER_COLOR, SUCCESS_COLOR, WARNING_COLOR } from 'constants/colors';
import styles from 'Booking/components/Box/Box.scss';
import TimelineViews from 'Booking/enums/TimelineViews';
import SortTimelineBy from 'Booking/enums/SortTimelineBy';
import TaskTimeStatusEnums from 'Booking/enums/TaskTimeStatusEnums';

import ZoomLevels from './enums/ZoomLevels';

export const TRAVEL_BOX_SPINNER_SIZE = 40;
export const UNDO_TIME = 5000;
export const DRAG_SNAP = 1000 * 60 * 15; // 15min
export const LINE_HEIGHT = 28;
export const HEADER_LABEL_GROUP_HEIGHT = 20;
export const HEADER_LABEL_HEIGHT = 23;
export const ITEM_HEIGHT_RATIO = 0.95;
export const CLICK_TOLERANCE = 1;
export const VERTICAL_RENDER_OFFSET = 30;
export const DRAG_DEBOUNCE_DURATION = 150;
export const SIDEBAR_WIDTH_EXPANDED = 250;
export const MIN_IN_MS = 60 * 1000;
export const ITEM_MOVE_INTERVAL_FOR_LESS_THAN_2_DAYS_IN_MINS = 5;
export const ITEM_MOVE_INTERVAL_FOR_MORE_THAN_2_DAYS_IN_MINS = 60;
export const POLLING_DURATION_IN_MINUTES = 0.5;
export const BOOKING_TYPE_INDICATOR_HEIGHT = 6;
export const BOOKING_ABSENT_CUSTOMER_HEIGHT = 4;
export const EMPLOYEE_CALENDAR = 'employee_calendar';
export const GROUP_CALENDAR = 'group_calendar';
export const VIEW_TYPE = {
  MAP_ONLY: 'mapOnly',
  CALENDAR_ONLY: 'calendarOnly',
  MAP_CALENDAR: 'mapCalendar',
};

export const TRAVEL_MODE_CAR = 'CAR';
export const TRAVEL_MODE_BUS = 'PUBLIC';
export const TRAVEL_MODE_BIKE = 'BICYCLE';

export const OTHER_TASKS_ID = -1;

export const TIMELINE_SORTING_OPTIONS = [
  { label: commonMessages.startTime, value: SortTimelineBy.START_TIME },
  { label: commonMessages.alphabeticalOrder, value: SortTimelineBy.ALPHABETICAL },
];

export const TIMELINE_VIEWS_OPTIONS = [
  { label: bookingMessages.tasksView, value: TimelineViews.TASKS_VIEW },
  { label: bookingMessages.customerView, value: TimelineViews.CUSTOMER_VIEW },
];

export const FILTER_OPTIONS = [
  { label: bookingMessages.workShifts, value: TaskStatuses.WORK_SHIFT },
  { label: bookingMessages.tasks, value: TaskStatuses.TASKS },
  { label: bookingMessages.availabilities, value: TaskStatuses.WORK_SHIFT_AVAILABLE },
];

export const SELECTED_FILTER_OPTIONS = [TaskStatuses.WORK_SHIFT, TaskStatuses.TASKS, TaskStatuses.WORK_SHIFT_AVAILABLE];

export const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'name',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'name',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start',
  itemTimeEndKey: 'end',
};

export const defaultTimeSteps = {
  second: 1,
  minute: 1,
  hour: 1,
  day: 1,
  month: 1,
  year: 1,
};

export const defaultHeaderLabelFormats = {
  yearShort: 'YY',
  yearLong: 'YYYY',
  monthShort: 'MM/YY',
  monthMedium: 'MM/YYYY',
  monthMediumLong: 'MMM YYYY',
  monthLong: 'MMMM YYYY',
  dayShort: 'L',
  dayLong: 'dddd, LL',
  hourShort: 'HH',
  hourMedium: 'HH:00',
  hourMediumLong: 'L, HH:00',
  hourLong: 'dddd, LL, HH:00',
  time: 'LLL',
};

export const defaultSubHeaderLabelFormats = {
  yearShort: 'YY',
  yearLong: 'YYYY',
  monthShort: 'MM',
  monthMedium: 'MMM',
  monthLong: 'MMMM',
  dayShort: 'D',
  dayMedium: 'dd D',
  dayMediumLong: 'ddd, Do',
  dayLong: 'dddd, Do',
  hourShort: 'HH',
  hourLong: 'HH:00',
  minuteShort: 'mm',
  minuteLong: 'HH:mm',
};

export const zoomValues = [
  { label: bookingMessages.fourHours, value: ZoomLevels.FOUR_HOURS },
  { label: bookingMessages.eightHours, value: ZoomLevels.EIGHT_HOURS },
  { label: bookingMessages.tenHours, value: ZoomLevels.TEN_HOURS },
  { label: bookingMessages.twelveHours, value: ZoomLevels.TWELVE_HOURS },
  { label: bookingMessages.fourteenHours, value: ZoomLevels.FOURTEEN_HOURS },
  { label: bookingMessages.sixteenHours, value: ZoomLevels.SIXTEEN_HOURS },
  { label: bookingMessages.eighteenHours, value: ZoomLevels.EIGHTEEN_HOURS },
  { label: bookingMessages.oneDay, value: ZoomLevels.ONE_DAY },
  { label: bookingMessages.twoDays, value: ZoomLevels.TWO_DAYS },
  { label: bookingMessages.threeDays, value: ZoomLevels.THREE_DAYS },
  { label: bookingMessages.fourDays, value: ZoomLevels.FOUR_DAYS },
  { label: bookingMessages.fiveDays, value: ZoomLevels.FIVE_DAYS },
  { label: bookingMessages.sixDays, value: ZoomLevels.SIX_DAYS },
  { label: bookingMessages.oneWeek, value: ZoomLevels.ONE_WEEK },
];

export const TASK_STATUS: Record<
  string,
  { label: { defaultMessage: string; id: string }; value: string; color: string }
> = {
  UNCONFIRMED: {
    label: bookingMessages.notStarted,
    value: TaskStatuses.UNCONFIRMED,
    color: DANGER_COLOR,
  },
  CONFIRMED: {
    label: bookingMessages.started,
    value: TaskStatuses.CONFIRMED,
    color: WARNING_COLOR,
  },
  FINISHED: {
    label: bookingMessages.finished,
    value: TaskStatuses.FINISHED,
    color: SUCCESS_COLOR,
  },
};

export const switchOptions = [
  { value: TaskStatuses.WORK_SHIFT, label: headerMessages.moveOnlyWorkShifts },
  { value: TaskStatuses.TASKS, label: headerMessages.moveOnlyTasks },
  { label: headerMessages.switchOff },
];

export const plannedRealizedOptions = [
  { value: TaskTimeStatusEnums.PLANNED, label: bookingMessages.planned },
  { value: TaskTimeStatusEnums.REALIZED, label: bookingMessages.realised },
  { value: TaskTimeStatusEnums.LIST, label: bookingMessages.listView },
];

export const itemStateStyles: Record<string, string> = {
  UNCONFIRMED: styles.box_unconfirmed,
  CONFIRMED: styles.box_confirmed,
  FINISHED: styles.box_finished,
  AUCTIONED: styles.box_auctioned,
  WORK_SHIFT_AVAILABLE: styles.box_workShiftAvailable,
  WORK_SHIFT_NOT_AVAILABLE: styles.box_workShiftUnavailable,
};

export const SELECT_QUERY = [
  'id',
  'employeeId',
  'taskGroupId',
  'plannedStartTime',
  'plannedEndTime',
  'realizedStartTime',
  'realizedEndTime',
  'customerId',
  'itemTypeId',
  'itemType.id',
  'itemType.color',
  'itemType.name',
  'info',
  'itemState',
  'customerId',
  'auctioned',
  'taskGroup.travelMode',
  'hasServices',
  'earliestStartTime',
  'latestStartTime',
  'needIds',
  'antiNeedIds',
  'isCanceled',
];

export const EMPLOYEE_DETAILS_QUERY = ['employee.id', 'employee.lastName', 'employee.firstName'];

export const CUSTOMER_DETAILS_QUERY = [
  'customer.firstName',
  'customer.lastName',
  'customer.streetAddress',
  'customer.postalCode',
  'customer.postOffice',
  'customer.additionalInfo',
  'customer.otherConsideration',
  'customer.present',
];

/** Redux constants. Can stay here */
export const LOAD_TIME_TABS = 'app/booking/LOAD_TIME_TABS';
export const LOAD_TIME_TABS_SUCCESS = 'app/booking/LOAD_TIME_TABS_SUCCESS';
export const LOAD_TIME_TABS_FAILED = 'app/booking/LOAD_TIME_TABS_FAILED';
export const LOAD_CALENDAR_DATA = 'app/booking/LOAD_CALENDAR_DATA';
export const LOAD_CUSTOMERS = 'app/booking/LOAD_CUSTOMERS';

export const LOAD_TIME_TAB_CUSTOMERS_SUCCESS = 'app/booking/LOAD_TIME_TAB_RESOURCES_SUCCESS';
export const LOAD_TIME_TAB_EMPLOYEE_SUCCESS = 'app/booking/LOAD_TIME_TAB_EMPLOYEE_SUCCESS';

export const LOAD_GROUPS = 'app/booking/LOAD_GROUPS';
export const LOAD_GROUP_GROUPS_SUCCESS = 'app/booking/LOAD_GROUP_GROUPS_SUCCESS';
export const LOAD_GROUP_ITEMS_SUCCESS = 'app/booking/LOAD_GROUP_ITEMS_SUCCESS';
export const LOAD_EMPLOYEE_GROUPS_SUCCESS = 'app/booking/LOAD_EMPLOYEE_GROUPS_SUCCESS';
export const LOAD_EMPLOYEES = 'app/booking/LOAD_EMPLOYEES';
export const LOAD_EMPLOYEE_ITEMS_SUCCESS = 'app/booking/LOAD_EMPLOYEE_ITEMS_SUCCESS';
export const LOAD_SUITABLE_EMPLOYEES_STARTED = 'app/booking/LOAD_SUITABLE_EMPLOYEES_STARTED';
export const LOAD_SUITABLE_EMPLOYEES_SUCCESS = 'app/booking/LOAD_SUITABLE_EMPLOYEES_SUCCESS';
export const LOAD_CALENDAR_DATA_SUCCESS = 'app/booking/LOAD_CALENDAR_DATA_SUCCESS';
export const LOAD_CALENDAR_DATA_FAILED = 'app/booking/LOAD_CALENDAR_DATA_SUCCESS';
export const LOAD_BOOKING_TYPES = 'app/booking/LOAD_BOOKING_TYPES';
export const LOAD_BOOKING_TYPES_SUCCESS = 'app/booking/LOAD_BOOKING_TYPES_SUCCESS';
export const LOAD_BOOKING_TYPES_FAILED = 'app/booking/LOAD_BOOKING_TYPES_FAILED';
export const LOAD_CUSTOMER_NEEDS_SUCCESS = 'app/booking/LOAD_CUSTOMER_NEEDS_SUCCESS';

// task detail loading constants
export const LOAD_TASK_DETAIL = 'app/booking/LOAD_TASK_DETAIL';
export const CLEAR_TASK_DETAIL = 'app/booking/CLEAR_TASK_DETAIL';
export const LOAD_TASK_DETAIL_SUCCESS = 'app/booking/LOAD_TASK_DETAIL_SUCCESS';
export const LOAD_TASK_DETAIL_ERROR = 'app/booking/LOAD_TASK_DETAIL_ERROR';

export const CLEAR_CALENDAR_DATA = 'app/booking/CLEAR_CALENDAR_DATA';
export const SEARCH = 'app/booking/SEARCH';
export const UPDATE_SINGLE_TASK_EMPLOYEE_CALENDAR = 'app/booking/UPDATE_SINGLE_TASK_EMPLOYEE_CALENDAR';
export const UPDATE_SINGLE_TASK_GROUP_CALENDAR = 'app/booking/UPDATE_SINGLE_TASK_GROUP_CALENDAR';
export const UPDATE_SINGLE_TASK_EMPLOYEE_TO_GROUP_CALENDAR =
  'app/booking/UPDATE_SINGLE_TASK_EMPLOYEE_TO_GROUP_CALENDAR';
export const UPDATE_SINGLE_TASK_GROUP_TO_EMPLOYEE_CALENDAR =
  'app/booking/UPDATE_SINGLE_TASK_GROUP_TO_EMPLOYEE_CALENDAR';
export const UPDATE_ALL_TASKS_EMPLOYEE_TO_GROUP_CALENDAR = 'app/booking/UPDATE_ALL_TASK_EMPLOYEE_TO_GROUP_CALENDAR';
export const UPDATE_ALL_TASKS_GROUP_TO_EMPLOYEE_CALENDAR = 'app/booking/UPDATE_ALL_TASK_GROUP_TO_EMPLOYEE_CALENDAR';
export const UPDATE_REPEATED_TASK_EMPLOYEE_CALENDAR = 'app/booking/UPDATE_REPEATED_TASK_EMPLOYEE_CALENDAR';
export const UPDATE_REPEATED_TASK_GROUP_CALENDAR = 'app/booking/UPDATE_REPEATED_TASK_GROUP_CALENDAR';
export const MOVE_TASK_EMPLOYEE_TO_GROUP_CALENDAR = 'app/booking/MOVE_TASK_EMPLOYEE_TO_GROUP_CALENDAR';
export const MOVE_TASK_GROUP_TO_EMPLOYEE_CALENDAR = 'app/booking/MOVE_TASK_GROUP_TO_EMPLOYEE_CALENDAR';
export const MOVE_TASK_GROUP_CALENDAR = 'app/booking/MOVE_TASK_GROUP_CALENDAR';
export const MOVE_TASK_EMPLOYEE_CALENDAR = 'app/booking/MOVE_TASK_EMPLOYEE_CALENDAR';
export const LOAD_TEMPLATE_REPETITIONS_SUCCESS = 'app/booking/LOAD_TEMPLATE_REPETITIONS_SUCCESS';
export const LOAD_AVAILABLE_SERVICES_SUCCESS = 'app/booking/LOAD_AVAILABLE_SERVICES_SUCCESS';
export const LOAD_SPECIAL_HOLIDAYS_SUCCESS = 'app/booking/LOAD_SPECIAL_HOLIDAYS_SUCCESS';
export const LOAD_AVAILABLE_SERVICES = 'app/booking/LOAD_AVAILABLE_SERVICES';
export const EDIT_TASK = 'app/booking/EDIT_TASK';
export const ADD_TASK = 'app/booking/ADD_TASK';
export const TASK_ADD_SUCCESS = 'app/booking/TASK_ADD_SUCCESS';
export const TASK_ADD_ERROR = 'app/booking/TASK_ADD_ERROR';
export const TASK_EDIT_SUCCESS = 'app/booking/TASK_EDIT_SUCCESS';
export const TASK_EDIT_ERROR = 'app/booking/TASK_EDIT_ERROR';
export const REPETITION_CHANGED = 'app/booking/REPETITION_CHANGED';
export const REPETITION_VIEW_FETCH_SUCCESS = 'app/booking/REPETITION_VIEW_FETCH_SUCCESS';
export const LOAD_SPECIAL_HOLIDAYS = 'app/booking/LOAD_SPECIAL_HOLIDAYS';
export const LOAD_NEEDS_OFFERS = 'app/booking/LOAD_NEEDS_OFFERS';
export const LOAD_NEEDS_OFFERS_SUCCESS = 'app/booking/LOAD_NEEDS_OFFERS_SUCCESS';
export const LOAD_NEEDS_OFFERS_FAILED = 'app/booking/LOAD_NEEDS_OFFERS_FAILED';
export const LOAD_TRAVEL_DATA_BUS = 'app/booking/LOAD_TRAVEL_DATA_BUS';
export const LOAD_TRAVEL_DATA_BUS_STARTED = 'app/booking/LOAD_TRAVEL_DATA_BUS_STARTED';
export const LOAD_TRAVEL_DATA_BUS_SUCCESS = 'app/booking/LOAD_TRAVEL_DATA_BUS_SUCCESS';
export const LOAD_TRAVEL_DATA_BUS_FAILED = 'app/booking/LOAD_TRAVEL_DATA_BUS_FAILED';
export const LOAD_TRAVEL_DATA_CAR_STARTED = 'app/booking/LOAD_TRAVEL_DATA_CAR_STARTED';
export const LOAD_TRAVEL_DATA_CAR_SUCCESS = 'app/booking/LOAD_TRAVEL_DATA_CAR_SUCCESS';
export const LOAD_TRAVEL_DATA_CAR_FAILED = 'app/booking/LOAD_TRAVEL_DATA_CAR_FAILED';
export const LOAD_TRAVEL_MODE_STARTED = 'app/booking/LOAD_TRAVEL_MODE_STARTED';
export const LOAD_TRAVEL_MODE_SUCCESS = 'app/booking/LOAD_TRAVEL_MODE_SUCCESS';
export const UPDATE_EMPLOYEE_SUCCESS = 'app/booking/UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_TRAVEL_MODE_FOR_GROUP_SUCCESS = 'app/booking/UPDATE_TRAVEL_MODE_FOR_GROUP_SUCCESS';
export const LOAD_TIME_SHIFT_SUCCESS = 'app/booking/LOAD_TIME_SHIFT_SUCCESS';
export const LOAD_EMPLOYEE_REQUIREMENTS = 'app/booking/LOAD_EMPLOYEE_REQUIREMENTS';
export const LOAD_EMPLOYEE_REQUIREMENTS_SUCCESS = 'app/booking/LOAD_EMPLOYEE_REQUIREMENTS_SUCCESS';
export const LOAD_EMPLOYEE_REQUIREMENTS_FAILED = 'app/booking/LOAD_EMPLOYEE_REQUIREMENTS_FAILED';
export const ADD_TASK_MODAL_GET_EMPLOYEE_REQUIREMENTS = 'app/booking/ADD_TASK_MODAL_GET_EMPLOYEE_REQUIREMENTS';
export const ADD_TASK_GET_EMPLOYEE_REQUIREMENTS_SUCCESS = 'app/booking/ADD_TASK_GET_EMPLOYEE_REQUIREMENTS_SUCCESS';
export const ADD_TASK_GET_EMPLOYEE_REQUIREMENTS_FAILED = 'app/booking/ADD_TASK_GET_EMPLOYEE_REQUIREMENTS_FAILED';

export const SET_THE_SWITCH = 'app/booking/SET_THE_SWITCH';
export const SET_TASK_TIME_STATUS = 'app/booking/SET_TASK_TIME_STATUS';
export const POLLING_CALENDAR_DATA = 'app/booking/POLLING_CALENDAR_DATA';
export const SET_VISIBLE_TIMES = 'app/booking/SET_VISIBLE_TIMES';
export const SET_HOVERED_ITEM = 'app/booking/SET_HOVERED_ITEM';
export const SET_MAP_ITEM = 'app/booking/MAP_ITEM';
export const SET_FILTER_OPTIONS = 'app/booking/SET_FILTER_OPTIONS';
export const SET_TIME_TAB_ID = 'app/booking/SET_TIME_TAB_ID';
export const START_VALIDATION = 'app/booking/START_VALIDATION';
export const VALIDATION_SUCCESS = 'app/booking/VALIDATION_SUCCESS';

export const UPDATE_CUSTOMERS_SUCCESS = 'app/booking/UPDATE_CUSTOMERS_SUCCESS';
export const SET_ZOOM_LEVEL = 'app/booking/SET_ZOOM_LEVEL';

export const LOAD_CUSTOMER_GROUPS = 'app/booking/LOAD_CUSTOMER_GROUPS';
export const SET_TIMELINE_VIEW = 'app/booking/SET_TIMELINE_VIEW';
export const ITEMS_SHOULD_OVERLAP = 'app/booking/ITEMS_SHOULD_OVERLAP';

export const LOAD_WORK_HOUR_FORECAST_SUCCESS = 'app/booking/LOAD_WORK_HOUR_FORECAST_SUCCESS';
export const LOAD_EMPLOYEE_AGREEMENTS_SUCCESS = 'app/booking/LOAD_EMPLOYEE_AGREEMENTS_SUCCESS';
export const SET_SORT_TIMELINE_BY = 'app/booking/SET_SORT_TIMELINE_BY';

export const LOAD_WORK_SHIFT_AVAILABILITIES_SUCCESS = 'app/booking/LOAD_WORK_SHIFT_AVAILABILITIES_SUCCESS';
