import { ReportFilters, ReportsParams, ReportsPrintParams } from 'services/customers/reports';

import { AlternativeSymbolNode, ReportArchiveType, SymbolTreeMap } from 'types/CustomerReport';

import { DATABASE_DATE_TIME_FORMAT, formatDateTimeToISO, isValidDateFormat } from 'utils/dateUtil';

import { EditPayloadNode, EditSymbolSelection, Parent, ReportContent, SymbolEntries } from './types';

/* Return map of symbols with their icons */
export const getSymbolsMap = (symbols: AlternativeSymbolNode[]) =>
  new Map<string, string>(symbols.map((symbol) => [symbol.displayName, symbol.icon ? symbol.icon : 'info']));

export const getPrintParams = (reportFilters: ReportFilters) => {
  const params: ReportsPrintParams = {};

  if (reportFilters.startDate) {
    params.from = reportFilters.startDate;
  }

  if (reportFilters.endDate) {
    params.to = reportFilters.endDate;
  }

  if (reportFilters.searchKeys) {
    params.search = reportFilters.searchKeys;
  }

  if (reportFilters.symbolsId.length) {
    params.symbol_ids = reportFilters.symbolsId.toString();
  }

  return params;
};

export const getParams = (reportFilters: ReportFilters, lastReportId?: number) => {
  const params: ReportsParams = {
    limit: 10,
    ...getPrintParams(reportFilters),
  };

  if (lastReportId) {
    params.after = lastReportId;
  }

  if (reportFilters.sources) {
    params.reportArchiveTypes = Array.from(reportFilters.sources || []) as unknown as ReportArchiveType[];
  }

  return params;
};

/*
/*
 * Recursive function to get selected symbols
 * */
const getSymbolSelection = (report: ReportContent, parent: Parent, category: string): SymbolEntries[] => {
  const selectedSymbols: SymbolEntries[] = [];

  // children is there
  report.children?.forEach((children) => {
    if (children.dataClass === 'LEAF') {
      const symbol: EditPayloadNode = {
        displayName: children.name,
        reportName: children.name,
        id: children.id,
        type: children.type,
        parentId: report.id,
        category,
        parent,
      };

      if (children.type === 'text') {
        symbol.text = children.text;
      }

      selectedSymbols.push({ selectedLeafNode: symbol });
    } else {
      selectedSymbols.push(
        ...getSymbolSelection(
          children as ReportContent,
          {
            id: children.id,
            displayName: children.name,
            reportName: children.name,
            type: children.type,
            parentId: parent.id,
            parent,
          },
          category,
        ),
      );
    }
  });
  return selectedSymbols;
};

/*
 * This is to convert selected symbols JSON to old format because AddEditReportModal works on the old JSON format
 * */
export const revertSymbolSelectionToOldJSON = (reports: ReportContent[], symbolsMap: Map<string, string>) => {
  const convertedJSON: EditSymbolSelection[] = [];
  let selection: EditSymbolSelection;

  reports.forEach((report) => {
    selection = { entries: [], shownSymbol: symbolsMap.get(report.name) as string };

    // For the Free text
    if (report.dataClass === 'LEAF') {
      const freeText = {
        id: report.id,
        type: report.type,
        shownSymbol: 'NONE',
        text: report.text,
        entries: [],
      };
      convertedJSON.push(freeText);
    } else if (report.children) {
      const parent = {
        id: report.id,
        displayName: report.name,
        reportName: report.name,
        type: report.type,
      };
      selection.entries = getSymbolSelection(report, parent, report.name);
      convertedJSON.push(selection);
    }
  });

  return convertedJSON;
};

// Show and hide symbols based on "show hidden symbols" checkbox
export const filterSymbols = (symbolsTree: AlternativeSymbolNode[], hiddenSymbols: boolean) =>
  symbolsTree.filter((symbol) => {
    if (hiddenSymbols) {
      return symbol;
    }
    return symbol.status !== 'HIDDEN';
  });

// Create a map of symbol tree to easily manipulate the tree
export const createSymbolTreeMap = (symbolTree: AlternativeSymbolNode[], selectedSymbol: number[]) => {
  const symbolMap = new Map();

  symbolTree.forEach((symbol) =>
    symbolMap.set(symbol.id, { selected: selectedSymbol.includes(symbol.id), parentId: [], data: symbol }),
  );

  return symbolMap;
};

// This is a helper function for addChildSymbolsToSymbolsTreeMap
const updateHelper = (
  symbol: AlternativeSymbolNode,
  symbolMap: Map<number, SymbolTreeMap>,
  parentIdArray: number[],
  rootSelected: boolean,
  selectedSymbol: number[],
) => {
  symbol.children.forEach((childSymbol) => {
    symbolMap.set(childSymbol.id, {
      selected: rootSelected || selectedSymbol.includes(symbol.id),
      parentId: [...parentIdArray, childSymbol.parentId],
      data: childSymbol,
    });

    if (childSymbol.children.length) {
      updateHelper(
        childSymbol,
        symbolMap,
        symbolMap.get(childSymbol.id)?.parentId as number[],
        rootSelected,
        selectedSymbol,
      );
    }
  });
};

// This is used to add child symbols to the symbol map when use opens any symbol, and we fetch the child symbols
export const addChildSymbolsToSymbolsTreeMap = (
  symbol: AlternativeSymbolNode,
  symbolsMap: Map<number, SymbolTreeMap>,
  selectedSymbol: number[],
) => {
  const updatedMap = new Map(symbolsMap);
  const rootSymbol = updatedMap.get(symbol.id);
  const rootSelected = !!rootSymbol?.selected;

  if (rootSymbol) {
    updatedMap.set(symbol.id, {
      selected: rootSelected || selectedSymbol.includes(symbol.id),
      parentId: [],
      data: symbol,
    });

    symbol.children.forEach((childSymbol) => {
      // Add the first child to the map
      updatedMap.set(childSymbol.id, {
        selected: rootSelected || selectedSymbol.includes(childSymbol.id),
        parentId: [childSymbol.parentId],
        data: childSymbol,
      });

      if (childSymbol.children.length) {
        updateHelper(childSymbol, updatedMap, [childSymbol.parentId], rootSelected, selectedSymbol);
      }
    });

    return updatedMap;
  }

  return updatedMap;
};

export const toggleSelectSymbol = (selectedSymbolId: number, symbolsMap: Map<number, SymbolTreeMap>) => {
  const symbolsMapClone = new Map(symbolsMap);
  const selectedSymbolMap = symbolsMapClone.get(selectedSymbolId) as SymbolTreeMap; // We know it won't be empty
  const symbolTree = selectedSymbolMap?.data;

  if (selectedSymbolMap.selected) {
    return deselectSymbol(symbolTree, symbolsMapClone);
  }

  return selectSymbol(symbolTree, symbolsMapClone);
};

const selectChildHelper = (symbol: AlternativeSymbolNode[], symbolMap: Map<number, SymbolTreeMap>) => {
  symbol.forEach((childSymbol) => {
    const childSymbolMap = symbolMap.get(childSymbol.id);
    if (childSymbolMap) {
      symbolMap.set(childSymbol.id, {
        ...childSymbolMap,
        selected: true,
      });
    }

    if (childSymbol.children.length) {
      selectChildHelper(childSymbol.children, symbolMap);
    }
  });
};

const selectSymbol = (symbol: AlternativeSymbolNode, symbolsMap: Map<number, SymbolTreeMap>) => {
  const updatedMap = new Map(symbolsMap);
  const selectedSymbolMap = updatedMap.get(symbol.id);

  if (symbol && selectedSymbolMap) {
    updatedMap.set(symbol.id, { ...selectedSymbolMap, selected: true });

    if (symbol.children.length) {
      selectChildHelper(symbol.children, updatedMap);
    }
  }

  return updatedMap;
};

const deselectChildHelper = (symbol: AlternativeSymbolNode[], symbolMap: Map<number, SymbolTreeMap>) => {
  symbol.forEach((childSymbol) => {
    const childSymbolMap = symbolMap.get(childSymbol.id);

    if (childSymbolMap) {
      symbolMap.set(childSymbol.id, {
        ...childSymbolMap,
        selected: false,
      });
    }

    if (childSymbol.children.length) {
      deselectChildHelper(childSymbol.children, symbolMap);
    }
  });
};

const deselectSymbol = (symbol: AlternativeSymbolNode, symbolsMap: Map<number, SymbolTreeMap>) => {
  const updatedMap = new Map(symbolsMap);
  const selectedSymbolMap = updatedMap.get(symbol.id);

  // When the user is unchecking a checkbox, we don't show parent as selected
  // Parent is only shown as selected when they are intentionally selected by user
  if (selectedSymbolMap && selectedSymbolMap.parentId.length) {
    selectedSymbolMap.parentId.forEach((parentId) => {
      const parent = updatedMap.get(parentId) as SymbolTreeMap;
      updatedMap.set(parentId, { ...parent, selected: false });
    });
  }

  if (symbol && selectedSymbolMap) {
    updatedMap.set(symbol.id, { ...selectedSymbolMap, selected: false });

    if (symbol.children.length) {
      deselectChildHelper(symbol.children, updatedMap);
    }
  }
  return updatedMap;
};

export const extractSymbolIdsToFilter = (symbolsMap: Map<number, SymbolTreeMap>) => {
  const ids: number[] = [];
  symbolsMap.forEach((symbol, key) => {
    if (symbol.selected) {
      ids.push(key);
    }
  });
  return ids;
};

export const getReportPrintDate = (date: string | null, time: string) => {
  if (date) {
    if (isValidDateFormat(date, DATABASE_DATE_TIME_FORMAT)) {
      return date;
    }

    return formatDateTimeToISO(date, time);
  }
  return null;
};
