import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getCurrentUser } from 'services/eResepti';
import { CurrentUser, EReseptiState } from 'EResepti/types';

// initial state that has currentUser of type CurrentUser
const initialState: EReseptiState = {
  currentUser: {} as CurrentUser,
};

// createAsyncThunk for getting current user
export const getCurrentUserThunk = createAsyncThunk('eResepti/getCurrentUser', async () => {
  const response = await getCurrentUser();
  return response.data;
});

// the slice for eResepti
export const eReseptiSlice = createSlice({
  name: 'eResepti',
  initialState,
  reducers: {
    // action to set the current user
    setCurrentUser: (draft, action) => {
      draft.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    // if getCurrentUserThunk is fulfilled, set the current user
    builder.addCase(getCurrentUserThunk.fulfilled, (draft, action) => {
      draft.currentUser = action.payload;
    });
    builder.addCase(getCurrentUserThunk.rejected, (draft) => {
      draft.currentUser = {} as CurrentUser;
    });
    builder.addCase(getCurrentUserThunk.pending, (draft) => {
      draft.currentUser = {} as CurrentUser;
    });
  },
});

// export the actions
export const { setCurrentUser } = eReseptiSlice.actions;

// export the reducer
export default eReseptiSlice.reducer;
