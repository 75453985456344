import React, { createContext, FC, useCallback, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import { Features } from 'types/Features';
import appFeatures from 'App/appFeatures';
import useWindowThreshold from 'hooks/useWindowThreshold';
import { fireResizeEvent } from 'Booking/utils';

export enum SIDE_NAV_STATE {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
}

const SIDE_NAV_VISIBILITY = 'SIDE_NAV_VISIBILITY';

type AppContextType = {
  featureTitle: null | MessageDescriptor;
  setFeature: (feature: Features) => void;
  printComponent?: (params: any) => void;
  openSideNav: SIDE_NAV_STATE;
  isSmallScreen: boolean;
  onOpenSideNav: (open?: SIDE_NAV_STATE) => void;
};

export const AppContext = createContext({
  featureTitle: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFeature() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  printComponent() {},
  openSideNav: SIDE_NAV_STATE.OPENED,
  isSmallScreen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOpenSideNav() {},
  stash: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleStash() {},
} as AppContextType);

AppContext.displayName = 'AppContext';

type Props = {
  children: (
    openSideNav: SIDE_NAV_STATE,
    isSmallScreen: boolean,
    handleOpenSideNav: (open: SIDE_NAV_STATE) => void,
  ) => React.ReactElement;
};

const AppProvider: FC<Props> = ({ children }) => {
  const [featureTitle, setFeatureTitle] = useState<MessageDescriptor | null>(null);
  const isSmallScreen = useWindowThreshold(960);
  const [openSideNav, setOpenSideNav] = useState<SIDE_NAV_STATE>(() => {
    try {
      const savedState = (localStorage.getItem(SIDE_NAV_VISIBILITY) as SIDE_NAV_STATE) || SIDE_NAV_STATE.OPENED;
      return isSmallScreen ? SIDE_NAV_STATE.CLOSED : savedState;
    } catch {
      return isSmallScreen ? SIDE_NAV_STATE.CLOSED : SIDE_NAV_STATE.OPENED;
    }
  });
  // can be used for anything, but mostly meant for useModal data workaround

  const onOpenSideNav = useCallback(
    (open?: SIDE_NAV_STATE) => {
      const sideNavState =
        open || openSideNav === SIDE_NAV_STATE.OPENED ? SIDE_NAV_STATE.CLOSED : SIDE_NAV_STATE.OPENED;
      fireResizeEvent();
      setOpenSideNav(sideNavState);
      localStorage.setItem(SIDE_NAV_VISIBILITY, String(sideNavState));
    },
    [openSideNav],
  );

  const handleSetFeature = (feature: Features) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { featureTitle } = appFeatures(feature);
    setFeatureTitle(featureTitle);
  };

  const ctx = useMemo(
    () => ({
      featureTitle,
      setFeature: handleSetFeature,
      openSideNav,
      isSmallScreen,
      onOpenSideNav,
    }),
    [featureTitle, openSideNav, isSmallScreen, onOpenSideNav],
  );

  // used to store data for modal

  return <AppContext.Provider value={ctx}>{children(openSideNav, isSmallScreen, onOpenSideNav)}</AppContext.Provider>;
};

export default AppProvider;
