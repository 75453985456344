import React from 'react';
import cn from 'classnames';

import InfoIcon from 'common/icons/InfoIcon';
import ErrorIcon from 'common/icons/ErrorIcon';
import WarningIcon from 'common/icons/WarningIcon';
import Tooltip from 'common/Tooltip';

import styles from './Info.scss';

type Props = {
  warning?: boolean;
  error?: boolean;
  textClassName?: string;
  className?: string;
  tooltipContent?: React.ReactNode;
};

const Info: React.FC<Props> = ({ children, className, textClassName, warning, error, tooltipContent }) => {
  const selectIcon = () => {
    if (warning) {
      return <WarningIcon />;
    }

    if (error) {
      return <ErrorIcon />;
    }

    return <InfoIcon />;
  };

  return (
    <div className={cn(styles.root, className)}>
      <div
        className={cn(styles.icon, {
          [styles.attentionIcon]: error,
          [styles.warningIcon]: warning,
        })}
      >
        <Tooltip visible={tooltipContent ? undefined : false} portal tooltip={tooltipContent}>
          <div>{selectIcon()}</div>
        </Tooltip>
      </div>
      <div className={cn(styles.infoTextWrapper, textClassName)}>
        <p className={styles.infoText}>{children}</p>
      </div>
    </div>
  );
};

export default Info;
