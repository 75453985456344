import React from 'react';

import { DateRangeType } from 'common/form/pickers/DateRangePicker';

import { formatDateToDisplayFormat } from 'utils/dateUtil';

import styles from './CustomInput.scss';

export type Props = {
  inputProps: { value: DateRangeType };
  Icon: React.ReactElement;
  onIconClick: () => void;
};

const CustomInput = ({ onIconClick, Icon, inputProps }: Props) => {
  const { value } = inputProps;

  const startDate = (value?.startDate && formatDateToDisplayFormat(value?.startDate)) || '';
  const endDate = (value?.endDate && formatDateToDisplayFormat(value?.endDate)) || '';

  return (
    <div className={styles.rangeInput}>
      <span className="mr-2">{`${startDate} - ${endDate}`}</span>
      <span role="button" onClick={onIconClick} className={styles.icon}>
        {Icon}
      </span>
    </div>
  );
};

export default CustomInput;
