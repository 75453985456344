import { FC, useEffect } from 'react';
import { toast, ToastContainer, TypeOptions } from 'react-toastify';
import { MessageDescriptor, useIntl } from 'react-intl';
import { ToastEmitter } from 'utils/toast';

const ToastProvider: FC = ({ children }) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    const cb = ({
      type,
      message,
      values,
    }: {
      type: TypeOptions;
      message: MessageDescriptor;
      values?: Record<string, unknown>;
    }) => {
      toast(formatMessage(message, values as Record<string, string>), {
        type,
      });
    };
    ToastEmitter.on('message', cb);
    return () => ToastEmitter.off('message', cb);
  }, [formatMessage]);

  return (
    <>
      <ToastContainer autoClose={2000} theme="colored" />
      {children}
    </>
  );
};

export default ToastProvider;
