// Needed for redux-saga es6 generator support
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
// @ts-expect-error no types...
import PiwikReactRouter from 'piwik-react-router';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import ErrorBoundary from 'sharedComponents/ErrorBoundary';

import * as NetworkErrorsActions from './nonRouteFeatures/NetworkResponseHandler/actions';

import './assets/styles/app.global.scss';
// Import root app
import App from './App';

// Import selector for `syncHistoryWithStore`
// Import Language Provider
import LanguageProvider from './nonRouteFeatures/LanguageProvider';

// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/no-webpack-loader-syntax, import/extensions */
import '!file-loader?name=[name].[ext]!./assets/images/favicon.ico';
import '!file-loader?name=[name].[ext]!./assets/images/brand/domacare_favicon_48x48.png';
import '!file-loader?name=[name].[ext]!./assets/images/brand/domacare_favicon_96x96.png';
import '!file-loader?name=[name].[ext]!./assets/images/brand/domacare_favicon_144x144.png';
import '!file-loader?name=[name].[ext]!./assets/images/brand/domacare_favicon_192x192.png';

// import '!file-loader?name=[name].[ext]!./manifest.json';
// import 'file-loader?name=[name].[ext]!./.htaccess';
import store from './store';

// Import root routes
import './assets/styles/variables.scss';
import { polyfillIntlLocale } from './i18n';
import history from './utils/history';
import ToastProvider from './providers/ToastProvider';

Sentry.init({
  dsn: 'https://aba7e1a3138649ffb23bbd814fd21ba4@sentry2.domacare.fi/7',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  ignoreErrors: ['ChunkLoadError', 'Request failed with status code 403'],
  release: process.env.RELEASE_NAME,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.API_ENV === 'development' ? 1 : 0.2,
  environment: process.env.API_ENV,
  enabled: process.env.NODE_ENV === 'production',
});

window.status = 'domadesktopinit';

(window as any).NetworkActions = bindActionCreators(NetworkErrorsActions, store.dispatch);

/* Piwik code */
const piwik = new PiwikReactRouter({
  url: 'analytics.domacare.fi/piwik',
  siteId: 2,
  trackErrors: true,
});

piwik.connectToHistory(history);

const start = async () => {
  await polyfillIntlLocale();

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <LanguageProvider>
          <ToastProvider>
            <ErrorBoundary>
              <Router history={piwik.connectToHistory(history)}>
                <App />
              </Router>
            </ErrorBoundary>
          </ToastProvider>
        </LanguageProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('domaApp'),
    () => {
      const el = document.getElementById('appLoader') as HTMLElement;
      el.parentNode?.removeChild(el);
    },
  );
};

start();
