import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import Modal, { ModalSize } from 'common/Modal';

import lazy from 'utils/lazy';

import commonMessage from 'translations/CommonTranslations/messages';

import { resetStates } from 'Duties/actions';

const LazyDuties = lazy(() => import('Duties/loadable'));

type Props = {
  show: boolean;
  onHide: () => void;
};

const DutiesModal = ({ show, onHide }: Props) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const handleHide = () => {
    dispatch(resetStates());
    onHide();
  };
  return (
    <Modal
      show={show}
      onHide={handleHide}
      size={ModalSize.MD}
      title={formatMessage(commonMessage.toDo)}
      fullHeight
      data-cy="duty__modal"
    >
      <Modal.Body className="p-0">
        <LazyDuties isDutyModalView />
      </Modal.Body>
    </Modal>
  );
};

export default DutiesModal;
