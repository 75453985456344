import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.common.errors.applicationError.title',
    defaultMessage: 'Uh Oh!',
  },
  text: {
    id: 'app.common.errors.applicationError.text',
    defaultMessage: "Something went wrong on our end. Don't worry, it's not you - it's us. Sorry about that.",
  },
});
