import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import languageReducer, { LANGUAGE_REDUCER_NAME } from 'store/language/slice';
import unitsReducer, { UNITS_REDUCER_NAME } from 'store/Units/slice';
import authReducer, { AUTH_REDUCER_NAME } from 'store/auth/slice';
import { RESET_STORE } from 'store/constants';
import { RootState } from 'store/RootState';
import networkErrorsNotificationsReducer from 'nonRouteFeatures/NetworkResponseHandler/reducer';
import carePlanReducer from 'nonRouteFeatures/Careplan/reducer';
import medicineListReducer from 'sharedComponents/MedicineList/reducer';
import payrollReducer from 'Payroll/slice';
import eReseptiReducer from 'EResepti/slice';
import preferencesReducer, { PREFERENCES_REDUCER_NAME } from 'store/preferences/slice';
import raiSlice from 'nonRouteFeatures/RAIv2/slice';
import eArkistoReducer from 'nonRouteFeatures/EArkisto/slice';

import customersReducer, { CUSTOMERS_REDUCER_NAME } from './Customers/slice';
import privilegesReducer, { PRIVILEGES_REDUCER_NAME } from './privileges/slice';
import employeesReducer, { EMPLOYEES_REDUCER_NAME } from './Employees/slice';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
const createReducer = (asyncReducers?: Record<string, unknown>) => (state: any, action: AnyAction) => {
  const combinedReducers = combineReducers({
    [AUTH_REDUCER_NAME]: authReducer,
    [LANGUAGE_REDUCER_NAME]: languageReducer,
    medicineList: medicineListReducer,
    networkErrors: networkErrorsNotificationsReducer,
    [PRIVILEGES_REDUCER_NAME]: privilegesReducer,
    carePlan: carePlanReducer,
    payroll: payrollReducer,
    [CUSTOMERS_REDUCER_NAME]: customersReducer,
    [EMPLOYEES_REDUCER_NAME]: employeesReducer,
    [UNITS_REDUCER_NAME]: unitsReducer,
    [PREFERENCES_REDUCER_NAME]: preferencesReducer,
    rai_v2: raiSlice,
    eResepti: eReseptiReducer,
    eArkisto: eArkistoReducer,
    ...asyncReducers,
  });

  // reset store is called from login container
  // e.g. after logout to clear the store
  if (action.type === RESET_STORE) {
    return combinedReducers(undefined, action) as unknown as RootState;
  }

  return combinedReducers(state, action) as unknown as RootState;
};

export default createReducer;
