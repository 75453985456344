import { createSelector } from 'reselect';
import { RootState } from 'store/index';
import { PRIVILEGES_REDUCER_NAME, PrivilegesState } from 'store/privileges/slice';

/**
 * Direct selector to the privileges state domain
 */
const selectPrivilegesDomain = () => (state: RootState): PrivilegesState => state[PRIVILEGES_REDUCER_NAME];

const makeSelectPrivileges = () => createSelector(selectPrivilegesDomain(), (substate) => substate);

export default makeSelectPrivileges;
export { selectPrivilegesDomain };
