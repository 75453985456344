import { defineMessages } from 'react-intl';

const headerMessages = defineMessages({
  search: {
    id: 'Booking.header.Search',
    defaultMessage: 'Search',
  },
  date: {
    id: 'Booking.header.Date',
    defaultMessage: 'Date',
  },
  zoomInDays: {
    id: 'Booking.header.zoomInDays',
    defaultMessage: 'Zoom',
  },
  view: {
    id: 'Booking.header.View',
    defaultMessage: 'View',
  },
  showOnlySuitableEmployees: {
    id: 'Booking.header.showOnlySuitableEmployees',
    defaultMessage: 'Show only suitable employees',
  },
  calendarDataChanged: {
    id: 'Booking.header.calendarDataChanged',
    defaultMessage: 'Calendar data changed',
  },
  switch: {
    id: 'Booking.header.switch',
    defaultMessage: 'Move switch',
  },
  moveOnlyWorkShifts: {
    id: 'Booking.header.moveOnlyWorkShifts',
    defaultMessage: 'Move only workShifts',
  },
  moveOnlyTasks: {
    id: 'Booking.header.moveOnlyTasks',
    defaultMessage: 'Move only tasks',
  },
  switchOff: {
    id: 'Booking.header.switchIsOff',
    defaultMessage: 'Switch off',
  },
  tasksOrWorkShiftsShouldOverlap: {
    id: 'Booking.header.tasksOrWorkShiftsShouldOverlap',
    defaultMessage: 'Tasks and work shifts should overlap on timeline',
  },
  adjustView: {
    id: 'Booking.header.adjustView',
    defaultMessage: 'Adjust view',
  },
  adjustViewInfo: {
    id: 'Booking.header.adjustViewInfo',
    defaultMessage: 'Sets optimal zoom level and start time for timeline',
  },
});
export default headerMessages;
