import { useEffect, useMemo } from 'react';

import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import { privilegePathCheck } from 'utils/privileges';
import EventEmitter from 'utils/EventEmitter';

import AuthRequired from 'sharedComponents/AuthRequired';
import DutiesModal from 'Duties/shared/DutiesModal/DutiesModal';
import { getCategoriesAPI } from 'services/duties/categories';

import styles from './styles.scss';

export const DutiesEmitter = new EventEmitter<{
  refresh: undefined;
}>();

const DutyCategoryMetaInfo = () => {
  const [showDutiesModal] = useModal(DutiesModal);
  const { data, fetch } = useApi(getCategoriesAPI, null, { keepData: true });

  useEffect(() => {
    DutiesEmitter.on('refresh', () => {
      fetch();
    });
    fetch();
  }, [fetch]);

  const unfinishedDutiesCount = useMemo(() => {
    if (!data) return 0;
    return data.reduce((acc, duty) => acc + (!duty.metaInfo ? 0 : duty.metaInfo.unfinishedDuties), 0);
  }, [data]);
  return (
    <div role="button" className={styles.root} onClick={() => showDutiesModal()} data-cy="duties__content">
      <div className={styles.count}>{unfinishedDutiesCount}</div>
    </div>
  );
};

export default AuthRequired(DutyCategoryMetaInfo, privilegePathCheck(['DUTY']));
