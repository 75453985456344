import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { NavItem } from 'sharedComponents/Privileges/types';
import SideNavSubMenu from 'sharedComponents/Sidenav/components/SideNavSubMenu/SideNavSubMenu';
import ArrowRightIcon from 'common/icons/ArrowRightIcon';
import ArrowDownIcon from 'common/icons/ArrowDownIcon';
import useAppContext from 'hooks/useAppContext';
import { SIDE_NAV_STATE } from 'providers/AppProvider';

import { contentVisible } from 'sharedComponents/Sidenav/utils';
import usePrivileges from 'hooks/usePrivileges';
import styles from './SideNavItem.scss';

type Props = {
  detail: NavItem;
  currentLocation: string;
};

const SideNavItem: React.FC<Props> = ({ detail, currentLocation }) => {
  const { openSideNav, isSmallScreen, onOpenSideNav } = useAppContext();
  const privileges = usePrivileges();
  const itemRef = useRef<HTMLDivElement | null>(null);
  const { formatMessage } = useIntl();
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const { children } = detail;

  const haveVisibleChildren = useMemo(
    () => (children ? children.some((child) => contentVisible(child, privileges)) : false),
    [children, privileges],
  );

  const isSelected = useMemo(
    () =>
      (detail.url && currentLocation.startsWith(detail.url)) ||
      (haveVisibleChildren && children && children.some((child) => currentLocation.startsWith(child.url))),
    [children, currentLocation, detail.url, haveVisibleChildren],
  );

  const classes = cn(styles.item, {
    [styles.selected]: isSelected,
    [styles.itemSmallScreen]: isSmallScreen && openSideNav === SIDE_NAV_STATE.OPENED,
  });
  const formattedLabel = formatMessage(detail.state);

  const toggleSubmenu = () => setOpenSubMenu((pv) => !pv);

  const handleToggleSubmenu = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    toggleSubmenu();
  };

  const handleClickOnLink = () => {
    if (haveVisibleChildren && !detail.url) {
      toggleSubmenu();
      return;
    }
    if (isSmallScreen && detail.url) {
      onOpenSideNav(SIDE_NAV_STATE.CLOSED);
    }
  };

  const openFloatingSubMenu = () => {
    if (openSideNav === SIDE_NAV_STATE.CLOSED && !isSmallScreen) setOpenSubMenu(true);
  };

  const closeFloatingSubMenu = () => {
    if (openSideNav === SIDE_NAV_STATE.CLOSED && !isSmallScreen) setOpenSubMenu(false);
  };

  const handleClickOnSubItem = () => {
    if (isSmallScreen) {
      onOpenSideNav(SIDE_NAV_STATE.CLOSED);
    }
  };

  useEffect(() => {
    if (openSideNav) setOpenSubMenu(false);
  }, [openSideNav]);

  return (
    <div ref={itemRef} onMouseEnter={openFloatingSubMenu} onMouseLeave={closeFloatingSubMenu}>
      <Link
        to={detail.url}
        className={classes}
        onClick={handleClickOnLink}
        data-testid={detail.key}
        title={formattedLabel}
      >
        <div className="mr-1 d-flex">{detail.icon}</div>
        {openSideNav === SIDE_NAV_STATE.OPENED && <div className="flex-grow-1">{formattedLabel}</div>}
        {haveVisibleChildren && openSideNav === SIDE_NAV_STATE.OPENED && (
          <button type="button" onClick={handleToggleSubmenu} className={styles.subMenuIcon}>
            {openSubMenu ? <ArrowDownIcon /> : <ArrowRightIcon />}
          </button>
        )}
      </Link>
      {haveVisibleChildren && children && openSubMenu && (
        <SideNavSubMenu
          sideNavChildren={children}
          itemRef={itemRef}
          floating={openSideNav === SIDE_NAV_STATE.CLOSED && !isSmallScreen}
          currentLocation={currentLocation}
          handleClick={handleClickOnSubItem}
        />
      )}
    </div>
  );
};

export default SideNavItem;
