import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { set } from 'lodash';

import PreferencesStore from 'types/Preferences';

import { updatePreferencesThunk } from 'store/preferences/slice';
import selectPreferencesStateDomain from 'store/preferences/selectors';
import { AppDispatch } from 'store';

// abstraction for interacting with the preferences slice
// Note: Do not fire update on mounts. Only during interactions
const usePreferences = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { data } = useSelector(selectPreferencesStateDomain());

  // fetch will be called during the initial load of the app (app.tsx)
  // so interaction with the backend is not necessary here
  const getPreference = useCallback((key: keyof PreferencesStore) => data[key], [data]);

  // note: The API replaces the entire store on update, so we need to merge the new data with the old
  const updatePreference = useCallback(
    async (path: keyof PreferencesStore | (string | number)[], value: unknown) => {
      if (path !== undefined) {
        const newData = { ...data };
        set(newData, path, value);
        return dispatch(updatePreferencesThunk(newData)).unwrap();
      }
      return undefined;
    },
    [data, dispatch],
  );

  return { getPreference, updatePreference, preferences: data };
};

export default usePreferences;
