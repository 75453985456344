import axios from 'axios';

import {
  AtostekCardLoginPayload,
  AtostekCardLoginResponse,
  AtostekPingSuccess,
  CardReaderSystemInfoLevel,
} from 'types/Atostek';

import api from 'utils/api';

import { CARD_READER_MIN_VERSION } from 'Login/config';

// external service (that connects to a local card reader through https proxy), so it needs its own axios instance and baseURL
const atostekAPI = axios.create({
  ...api.defaults,
  withCredentials: false,
  headers: {},
  baseURL: 'https://erasmartcard.ehoito.fi:44304',
});

export const atostekCardLogin = (payload: AtostekCardLoginPayload) =>
  atostekAPI.post<AtostekCardLoginResponse>('/login', payload);

export const pingAtostekCardLogin = () =>
  atostekAPI.post<AtostekPingSuccess>('/pingcard', {
    GetSystemInfo: CardReaderSystemInfoLevel.GET_SYSTEM_INFO,
    MinVersion: CARD_READER_MIN_VERSION,
  });
