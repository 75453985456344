import { useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import styles from './DatePickerYears.scss';

type Props = {
  onChange: (year: number) => void;
  year: number;
  minDate?: string;
  maxDate?: string;
};

const MIN_YEAR = 1900;
const MAX_YEAR = 2100;

const DatePickerYears = ({ onChange, year, minDate, maxDate }: Props) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const listOfYears = useMemo(() => {
    const years = [];
    const minYear = minDate ? new Date(minDate).getFullYear() : MIN_YEAR;
    const maxYear = maxDate ? new Date(maxDate).getFullYear() : MAX_YEAR;
    for (let i = minYear; i <= maxYear; i += 1) {
      years.push(i);
    }
    return years;
  }, [maxDate, minDate]);

  useEffect(() => {
    if (rootRef.current) {
      rootRef.current.querySelector(`#datepicker-year-${year}`)?.scrollIntoView({ block: 'center' });
    }
  }, [year]);

  return (
    <div className={styles.root} ref={rootRef}>
      {listOfYears.map((y) => (
        <div
          key={y}
          id={`datepicker-year-${y}`}
          className={cn(styles.year, { [styles.selected]: y === year })}
          onClick={() => onChange(y)}
        >
          {y}
        </div>
      ))}
    </div>
  );
};

export default DatePickerYears;
