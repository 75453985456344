import utilsMessages from 'utils/messages';

import { bookingErrorCodes } from 'Booking/bookingErrorCodes';
import { payrollErrorCodes } from 'Payroll/payrollErrorCodes';
import { customersErrorCodes } from 'Customers/CustomersErrorCodes';
import { calendarErrorCodes } from 'Calendar/calendarErrorCodes';
import { usersErrorCodes } from 'Users/usersErrorCodes';
import { raiErrorCodes } from 'nonRouteFeatures/RAIv2/raiErrorCodes';

import messages from './messages';

export default {
  DEFAULT: messages.default,
  ERROR_CODE: messages.defaultErrorCode,
  GENERAL_IO_EXCEPTION: messages.generalIO,
  FORBIDDEN: utilsMessages.FORBIDDEN,
  ...bookingErrorCodes,
  ...payrollErrorCodes,
  ...customersErrorCodes,
  ...calendarErrorCodes,
  ...usersErrorCodes,
  ...raiErrorCodes,
};
