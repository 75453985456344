import { getHandbook } from 'services/customers/rai_v2/handbooks';

import {
  CategoryAnswer,
  Progression,
  Category,
  QuestionIdsMap,
  Question,
  Subcategory,
  AnswerColumn,
  RowAnswersStatus,
  QuestionAnswer,
  RowAnswer,
} from './types';

// export const handleAssessmentAnswersChange = (answers: CategoryAnswer[], responses: AnswerResponse) => {
//   const deepCopyOfAnswers = cloneDeep(answers);
//   responses.answers.forEach((categoryCur) => {
//     const existed = deepCopyOfAnswers.find((item) => item.label === categoryCur.label);
//     if (existed) {
//       if (existed.hidden !== undefined || categoryCur.hidden !== undefined) {
//         const existedIndex = deepCopyOfAnswers.indexOf(existed);
//         deepCopyOfAnswers.splice(existedIndex, 1, categoryCur);
//       } else {
//         categoryCur.subcategories.forEach((subCategoryCur) => {
//           const subExisted = existed.subcategories.find((item) => item.label === subCategoryCur.label);
//           if (subExisted) {
//             if (subExisted.hidden !== undefined || subCategoryCur.hidden !== undefined) {
//               const subExistedIndex = existed.subcategories.indexOf(subExisted);
//               existed.subcategories.splice(subExistedIndex, 1, subCategoryCur);
//             } else {
//               subCategoryCur.answers.forEach((answerValueCur) => {
//                 const answerValueExisted = subExisted.answers.find(
//                   (item) => item.questionId === answerValueCur.questionId,
//                 );
//                 if (answerValueExisted) {
//                   const answerValueExistedIndex = subExisted.answers.indexOf(answerValueExisted);
//                   subExisted.answers.splice(answerValueExistedIndex, 1, answerValueCur);
//                 } else {
//                   subExisted.answers.push(answerValueCur);
//                 }
//               });
//             }
//           } else {
//             existed.subcategories.push(subCategoryCur);
//           }
//         });
//       }
//     } else {
//       deepCopyOfAnswers.push(categoryCur);
//     }
//   });
//   return deepCopyOfAnswers;
// };

export const handleCategoryChange = (
  categories: Category[] | undefined,
  assessmentAnswers: Record<string, CategoryAnswer>,
) => {
  if (!categories) {
    return null;
  }

  return categories.reduce((categorySum: Category[], category) => {
    const existedCategoryInAssessmentAnswers = assessmentAnswers[category.label];

    if (existedCategoryInAssessmentAnswers && !existedCategoryInAssessmentAnswers.hidden) {
      const newCat = {
        ...category,

        subcategories: category.subcategories.reduce((subCategorySum: Subcategory[], subCategory) => {
          const subCategoryExistedInAssessmentAnswers =
            existedCategoryInAssessmentAnswers.subcategories[subCategory.label];

          if (subCategoryExistedInAssessmentAnswers && !subCategoryExistedInAssessmentAnswers.hidden) {
            const newSub = {
              ...subCategory,

              questions: subCategory.questions.reduce((questionSum: Question[], question) => {
                const existedQuestionInAssessmentAnswers = subCategoryExistedInAssessmentAnswers.answers[question.id];

                if (existedQuestionInAssessmentAnswers && !existedQuestionInAssessmentAnswers.hidden) {
                  questionSum.push(question);
                }

                return questionSum;
              }, []),
            };
            subCategorySum.push(newSub);
          }
          return subCategorySum;
        }, []),
      };
      categorySum.push(newCat);
    }
    return categorySum;
  }, []);
};

export const handleCategoryProgress = (
  answerCategoryProgresses: Record<string, Progression> | undefined,
  responseCategoryProgresses: Record<string, Progression> | undefined,
) => {
  // sometimes backend doesn't return this :|
  if (!responseCategoryProgresses) {
    return answerCategoryProgresses;
  }

  if (!answerCategoryProgresses) {
    return null;
  }

  const newCategoryProgresses: Record<string, Progression> = { ...answerCategoryProgresses };

  return Object.keys(responseCategoryProgresses).reduce((acc, key) => {
    const responseCategoryProgress = responseCategoryProgresses[key];

    const answerCategoryProgress = answerCategoryProgresses[key];

    if (answerCategoryProgress) {
      const totalQuestionCount =
        answerCategoryProgresses[key].totalQuestionCount + responseCategoryProgress.totalQuestionCount;

      const validAnswerCount =
        answerCategoryProgresses[key].validAnswerCount + responseCategoryProgress.validAnswerCount;

      const warningAnswerCount =
        answerCategoryProgresses[key].warningAnswerCount + responseCategoryProgress.warningAnswerCount;

      const errorAnswerCount =
        answerCategoryProgresses[key].errorAnswerCount + responseCategoryProgress.errorAnswerCount;

      newCategoryProgresses[key] = {
        totalQuestionCount,
        validAnswerCount,
        warningAnswerCount,
        errorAnswerCount,
      };
    } else {
      newCategoryProgresses[key] = responseCategoryProgress;
    }
    return acc;
  }, newCategoryProgresses);
};

export const percentageCalculation = (num: number) => {
  let string = num.toString();
  const slicedString = string.split('.');

  if (slicedString[1] && slicedString[1].length > 2) {
    string = string.slice(0, string.indexOf('.') + 3);

    return Number(string);
  }
  return num;
};

export const handleProgressBar = (progress: Progression) => {
  const total = progress.totalQuestionCount;

  const green = progress.validAnswerCount;

  const red = progress.errorAnswerCount;

  const orange = progress.warningAnswerCount;

  const answeredPercentage = percentageCalculation((green / total) * 100);

  const mandatoryPercentage = percentageCalculation((red / total) * 100);

  const optionalPercentage = percentageCalculation((orange / total) * 100);

  return {
    completed: answeredPercentage,
    mandatoryNotAnswered: mandatoryPercentage,
    optionalNotAnswered: optionalPercentage,
  };
};

export const handleAssessmentProgress = (
  answerAssessmentProgress: Progression | undefined,
  responseAssessmentProgress: Progression | undefined,
) => {
  if (!answerAssessmentProgress || !responseAssessmentProgress) {
    return null;
  }

  const totalQuestions = answerAssessmentProgress.totalQuestionCount + responseAssessmentProgress.totalQuestionCount;

  const validAnswerCount = answerAssessmentProgress.validAnswerCount + responseAssessmentProgress.validAnswerCount;

  const warningAnswerCount =
    answerAssessmentProgress.warningAnswerCount + responseAssessmentProgress.warningAnswerCount;

  const errorAnswerCount = answerAssessmentProgress.errorAnswerCount + responseAssessmentProgress.errorAnswerCount;

  return {
    totalQuestions,
    validAnswerCount,
    warningAnswerCount,
    errorAnswerCount,
  };
};

export const separateLabel = (label: string | null) => {
  const results: { [key: string]: string | null } = {
    labelLetters: null,
    mainLabel: null,
  };
  if (!label) {
    return results;
  }
  const splitLabel = label.split('.');
  results.labelLetters = splitLabel[0]?.trim();
  results.mainLabel = splitLabel[1]?.trim();
  return results;
};

export const startQuestion = (id: string, questionIdsMap: QuestionIdsMap) => {
  const result: { index: { [key: string]: number | null }; label: { [key: string]: string | null } } = {
    index: {
      currentCategoryIndex: null,
      currentSubCategoryIndex: null,
      currentQuestionIndex: null,
    },
    label: {
      currentSubCategoryLabel: null,
      currentCategoryLabel: null,
      questionType: null,
    },
  };
  const chosenQuestionIdMap = questionIdsMap[id];
  if (chosenQuestionIdMap) {
    const { categoryIndex, questionType, subCategoryIndex, questionIndex, subCategoryLabel, categoryLabel } =
      chosenQuestionIdMap;
    result.index.currentCategoryIndex = categoryIndex;

    result.index.currentSubCategoryIndex = subCategoryIndex;

    result.index.currentQuestionIndex = questionIndex;

    result.label.currentCategoryLabel = categoryLabel;

    result.label.currentSubCategoryLabel = subCategoryLabel;

    result.label.questionType = questionType;
  }
  return result;
};

export const handleDownloadFileFromURL = (downloadLink: string, filename: string) => {
  const tag = document.createElement('a');
  tag.download = filename;
  tag.href = downloadLink;
  tag.click();
};

export const pdfURLGenerator = (instrument: string) => `assets/pdf/rai/rai_${instrument.toLowerCase()}.pdf`;

export const handleArrayToMap = (categories: Category[] | null) => {
  if (!categories) {
    return null;
  }

  const questionIdsMap: QuestionIdsMap = {};

  categories.forEach((category, categoryIndex) => {
    category.subcategories.forEach((subCategory, subCategoryIndex) => {
      subCategory.questions.forEach((question, questionIndex) => {
        questionIdsMap[question.id] = {
          categoryLabel: category.label,
          categoryIndex,
          subCategoryIndex,
          questionIndex,
          questionType: question.type,
          subcategoryDisplayTitle: '',
          subCategoryLabel: subCategory.label,
        };
      });
    });
  });
  return questionIdsMap;
};

export const handleDownloadPdf = (raiInstrument: string, definitionVersionId: number | null, filename: string) => {
  if (definitionVersionId === null) {
    return;
  }
  getHandbook(raiInstrument, definitionVersionId, { filename }).then((response) =>
    handleDownloadFileFromURL(window.URL.createObjectURL(response.data), filename),
  );
};

export const handleFilteringOutButtons = (answerColumns: AnswerColumn[]) =>
  answerColumns.filter((col) => col.value !== RowAnswersStatus.DISABLED && col.value !== RowAnswersStatus.ENABLED);

export const rowsWithFilteredColumns = (rows: RowAnswer[]) =>
  rows.map((item) => {
    // the DELETE and CHOSEN, if ENABLED or DISABLED,should not be sent to the backend

    const filteredColumns = handleFilteringOutButtons(item.columns);
    return { rowId: item.rowId, columns: filteredColumns };
  });
export const handleDeleteRow = (id: number, answers: QuestionAnswer) => {
  const newRows = answers.rows?.filter((item) => item.rowId !== id) || [];

  return rowsWithFilteredColumns(newRows);
};
