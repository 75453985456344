import { ReactNode } from 'react';
import cn from 'classnames';

import styles from './Modal.scss';

type Props = {
  children: ReactNode;
  className?: string;
};

const ModalContent = ({ children, className }: Props) => (
  <div className={cn(styles.content, className)}>{children}</div>
);

export default ModalContent;
