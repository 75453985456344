/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useMemo, useState } from 'react';

type ModalContextType = {
  showModal: (modalKey: string, component: any, modalProps?: Record<string, any>, onHide?: () => void) => void;
  hideModal: (modalKey: string, onHide?: () => void) => void;
  removeModal: (modalKey: string) => void;
};

export const ModalContext = React.createContext({
  showModal: () => null,
  hideModal: () => null,
  removeModal: () => null,
} as ModalContextType);

const ModalProvider: FC = ({ children }) => {
  const [modalsConfig, setConfig] = useState<Record<string, any>>({});

  const removeModal = useCallback((modalKey: string) => {
    setConfig((prevConfig) => {
      const configs = { ...prevConfig };
      delete configs[modalKey];
      return configs;
    });
  }, []);

  const hideModal = useCallback(
    (modalKey: string, onHide?: () => void) => {
      setConfig((prevConfig) => {
        // edge case condition, when somebody is calling hideModal when modal is already hidden
        if (!prevConfig[modalKey]) return prevConfig;
        return { ...prevConfig, [modalKey]: { ...prevConfig[modalKey], show: false } };
      });

      // modal fadeOut animation duration, after that it's safe to remove it
      setTimeout(() => {
        removeModal(modalKey);
      }, 250);

      if (onHide) {
        onHide();
      }
    },
    [removeModal],
  );
  const showModal = useCallback(
    (modalKey, component, modalData, onHide) => {
      setConfig((prevConfig) => ({
        ...prevConfig,
        [modalKey]: {
          show: true,
          component,
          data: modalData,
          onHide: () => {
            hideModal(modalKey);
            if (onHide) {
              onHide();
            }
          },
        },
      }));
    },
    [hideModal],
  );

  const contextValue = useMemo(
    () => ({
      showModal,
      hideModal,
      removeModal,
    }),
    [hideModal, showModal, removeModal],
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {Object.keys(modalsConfig).map((modalKey) => {
        const { component: Component, show, data, onHide } = modalsConfig[modalKey];

        return <Component onHide={onHide} key={modalKey} show={show} {...data} />;
      })}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
