import { FC } from 'react';

import DynamicImage from '../DynamicImage';

import styles from './styles.scss';

type Props = {
  icon: string;
  className?: string;
};
const SymbolIcon: FC<Props> = ({ icon, className }) => (
  <DynamicImage imgName={icon} folder="symbols" className={className || styles.SymbolIcon} alt={icon} />
);

export default SymbolIcon;
