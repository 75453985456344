import React from 'react';
import * as Sentry from '@sentry/react';
import { Scope, Severity } from '@sentry/react';
import { ErrorBoundaryProps, FallbackRender } from '@sentry/react/dist/errorboundary';

// import useUser from 'hooks/useUser';
// import useLocale from 'hooks/useLocale';
import ModuleLoadError from 'common/Errors/ModuleLoadError';
import ApplicationError from 'common/Errors/ApplicationError';

const ErrorBoundary: React.FC<Pick<ErrorBoundaryProps, 'beforeCapture'>> = ({ children, beforeCapture }) => {
  // const user = useUser();
  // const lang = useLocale();
  // const { formatMessage } = useIntl();

  const handleBeforeCapture = (scope: Scope, error: Error | null, componentStack: string | null): void => {
    if (error) scope.setLevel(error.name === 'ChunkLoadError' ? Severity.Info : Severity.Error);
    if (beforeCapture) beforeCapture(scope, error, componentStack);
  };

  const handleFallback: FallbackRender = ({ error }) => {
    if (error.name === 'ChunkLoadError') return <ModuleLoadError />;
    return <ApplicationError />;
  };

  return (
    <Sentry.ErrorBoundary
      beforeCapture={handleBeforeCapture}
      fallback={handleFallback}
      // TODO: enable later, dependency on redux and intl provider
      // showDialog
      // dialogOptions={{
      //   lang,
      //   title: formatMessage(messages.soWrong),
      //   subtitle: formatMessage(messages.assurance),
      //   subtitle2: '',
      //   user: user
      //     ? {
      //         name: `${user.firstName} ${user.lastName}`,
      //         email: user.email,
      //       }
      //     : undefined,
      // }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
