import { defineMessages } from 'react-intl';

export default defineMessages({
  home: {
    id: 'app.components.TopHeader.home',
    defaultMessage: 'Home',
  },
  tasks: {
    id: 'app.components.TopHeader.tasks',
    defaultMessage: 'Tasks',
  },
  customers: {
    id: 'app.components.TopHeader.customers',
    defaultMessage: 'Customers',
  },
  settings: {
    id: 'app.components.TopHeader.settings',
    defaultMessage: 'Settings',
  },
  booking: {
    id: 'app.components.TopHeader.bookings',
    defaultMessage: 'Bookings',
  },
  auction: {
    id: 'app.components.TopHeader.auction',
    defaultMessage: 'Task auction',
  },
  wallet: {
    id: 'app.components.TopHeader.wallet',
    defaultMessage: 'Wallet',
  },
  calendar: {
    id: 'app.components.TopHeader.calendar',
    defaultMessage: 'Calendar',
  },
  messages: {
    id: 'app.components.TopHeader.messages',
    defaultMessage: 'Messages',
  },
  extranetmessages: {
    id: 'app.components.TopHeader.extranet',
    defaultMessage: 'Extranet',
  },
  logout: {
    id: 'app.components.TopHeader.logout',
    defaultMessage: 'Logout',
  },
  raiStats: {
    id: 'app.components.TopHeader.raiStats',
    defaultMessage: 'Statistics',
  },
  messagecenter: {
    id: 'app.components.TopHeader.messagecenter',
    defaultMessage: 'Messages Center',
  },
  duties: {
    id: 'app.components.TopHeader.duties',
    defaultMessage: 'Duties',
  },
  workPlanning: {
    id: 'app.components.TopHeader.workPlanning',
    defaultMessage: 'Work planning',
  },
  webView: {
    id: 'app.components.TopHeader.webView',
    defaultMessage: 'Webview', // not visible in real webview
  },
  frontPage: {
    id: 'app.components.TopHeader.frontPage',
    defaultMessage: 'Front Page',
  },
  users: {
    id: 'app.components.TopHeader.users',
    defaultMessage: 'Users',
  },
  corrections: {
    id: 'app.components.TopHeader.corrections',
    defaultMessage: 'Corrections',
  },
});
