import { featureEnabled } from 'utils/features';
import { CrudMappings } from 'types/PrivilegeMappings';
import { havePrivilege } from 'utils/privileges';
import { NavItem } from 'sharedComponents/Privileges/types';
import { Privileges } from 'types/privileges';

export const contentVisible = (value: Omit<NavItem, 'icon'>, privileges: Privileges): boolean => {
  if (value.enabled === 'global') return true;
  if (typeof value.enabled === 'undefined') {
    return value.children?.some((c) => contentVisible(c, privileges)) || false;
  }
  if (!featureEnabled(value.enabled)) return false;
  if (!value.privilege) return true;
  // yeah value.privilege is just string[] as `havePrivilege` is overloaded function
  return havePrivilege(value.privilege as never, [CrudMappings.read], privileges);
};

export default contentVisible;
