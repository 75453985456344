/* eslint-disable no-underscore-dangle */
import { ActionMeta } from 'react-select';

import { GetSelectOptionValue, OptionTypeBase, SelectSelectedValue, reactSelectActions } from 'common/form/types';

/**
 * @description It will remove options that were created (they have `__isNew__` property)
 * @param options list from Creatable React Select
 */
export const removeNewOptionsSelect = <Option extends OptionTypeBase = any>(
  options: Option[] & { __isNew__?: true },
): Option[] => options.filter((o) => !o.__isNew__);

/**
 * @description It will retrieve new options but without `__isNew__` property
 * @param options list from Creatable React Select
 */
export const retrieveNewOptionsSelect = <Option extends OptionTypeBase = any>(
  options: SelectSelectedValue<true, false, Option & { __isNew__?: true }>,
): Option[] =>
  options
    .filter((o) => o.__isNew__)
    .map((o) => {
      const option = { ...o };
      delete option.__isNew__;
      return option;
    });

export const retrieveValuesSelect = <Option extends Record<string, any> = any, K extends keyof Option = 'value'>(
  options: Option[],
  key?: K,
): Option[K][] => options.map((o) => (key ? o[key] : o.value));

/**
 * @private
 * @internal only internal method for ReactSelect
 */
export const handleSelectChange = <IsMulti extends boolean, Option extends OptionTypeBase = any>(
  option: IsMulti extends true ? Option[] : Option,
  isMulti: IsMulti,
  getOptionValue?: GetSelectOptionValue<Option>,
): string | string[] => {
  if (isMulti) {
    return (option as Option[]).map((item) => (getOptionValue ? getOptionValue(item) : item.value));
  }
  return getOptionValue ? getOptionValue(option as Option) : (option as Option).value;
};

/**
 * @private
 * @internal only internal method for ReactSelect
 */
export const getSelectValue = <Option extends OptionTypeBase, IsMulti extends boolean = false>(
  options: Option[] = [],
  value: SelectSelectedValue<IsMulti>,
  isMulti?: IsMulti,
  getOptionValue?: GetSelectOptionValue<Option>,
): Option[] | Option | null => {
  if (value === undefined || value === null || !options || options.length === 0) {
    return isMulti ? [] : null;
  }

  return isMulti
    ? options.filter((option) => value.indexOf(getOptionValue ? getOptionValue(option) : option.value) >= 0)
    : options.find((option) => {
        const valueToCompare = getOptionValue ? getOptionValue(option) : option.value;
        return valueToCompare === value;
      }) || null;
};

export const actionHandler = <Option extends OptionTypeBase>(
  options: Option[],
  meta: ActionMeta<Option>,
  isAllSelectable: boolean | undefined,
  allOptions: Option[],
  showAll: boolean,
  isCompactMulti: boolean | undefined,
  toggleShowAll: () => void,
  toggleSelectAll: () => void,
  selectAll: boolean,
): Option[] => {
  switch (meta.action) {
    case reactSelectActions.SELECT_OPTION:
      if (isAllSelectable && !meta?.option?.id) {
        toggleSelectAll();
        return allOptions;
      }
      return options;
    case reactSelectActions.REMOVE_VALUE:
    case reactSelectActions.POP_VALUE:
      if (isAllSelectable && selectAll) {
        toggleSelectAll();
      }
      if (meta.removedValue?.isFixed) {
        return options.concat(meta.removedValue);
      }
      return options;
    case reactSelectActions.CLEAR:
      // This is for when user close the expanded list by pressing the cross and showAll is true
      // Otherwise it shows Hide option on single select
      if (isAllSelectable && selectAll) {
        toggleSelectAll();
      }
      if (showAll && isCompactMulti) {
        toggleShowAll();
      }
      return meta.removedValues.filter((item) => item.isFixed);
    default:
      return options;
  }
};
