import React, { InputHTMLAttributes, useCallback, useEffect, useState } from 'react';
import TimeKeeper, { TimeOutput } from 'react-timekeeper';
import cn from 'classnames';

import Input from 'common/form/plain/Input';
import ClockIcon from 'common/icons/ClockIcon';
import Modal, { ModalSize } from 'common/Modal';
import { formatDisplayTimeToDBTime, fromTimeToMoment } from 'common/form/pickers/utils';
import CloseIcon from 'common/icons/CloseIcon';

import { formatTimeToDisplayFormat, isValidTime } from 'utils/dateUtil';

import pickerStyles from '../Pickers.scss';

import styles from './TimePicker.scss';

type Props = {
  name?: string;
  value: string | null;
  placeholder?: string;
  className?: string;
  clearable?: boolean;
  disabled?: boolean;
  error?: string;
  onChange: (time: string | null) => void;
  onBlur?: () => void;
  testId?: string;
  inputId?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement> & { 'data-testid'?: string };
};

const DEFAULT_TIME = '12:00';

const TimePicker = ({
  name,
  value,
  className,
  placeholder,
  clearable,
  disabled,
  error,
  testId,
  onChange,
  onBlur,
  inputId,
  inputProps,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [focused, setFocused] = useState(false);
  const [localError, setLocalError] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | null>(null);

  const handleChange = useCallback((time: string | null) => {
    if (!time) {
      setInputValue('');
      setLocalError(false);
      return true;
    }
    setInputValue(time);
    const isValid = isValidTime(time);
    setLocalError(!isValid);
    return isValid;
  }, []);

  useEffect(() => {
    if (focused) return;
    if (!value) {
      handleChange(null);
      return;
    }
    const mInst = fromTimeToMoment(value);
    handleChange(formatTimeToDisplayFormat(mInst));
  }, [focused, handleChange, value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { value } = event.target;
    const isValid = handleChange(value);
    if (isValid) {
      if (clearable) {
        onChange(value ? formatDisplayTimeToDBTime(value) : null);
      } else if (value) {
        onChange(formatDisplayTimeToDBTime(value));
      }
    }
  };

  const handleClear = () => {
    handleChange(null);
    onChange(null);
    if (onBlur) onBlur();
  };

  const handleTimeKeeperChange = (data: TimeOutput) => {
    const newTime = data.hour < 10 ? `0${data.formatted24}` : data.formatted24;
    handleChange(newTime);
    onChange(formatDisplayTimeToDBTime(newTime));
  };

  const handleTimeIconClick = () => {
    if (!disabled) setVisible(true);
  };

  const handleModalClose = () => {
    setVisible(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
    if (onBlur) onBlur();
  };

  return (
    <>
      <div className={cn(className, pickerStyles.root, { [pickerStyles.disabled]: disabled })}>
        <Input
          {...inputProps}
          name={name}
          data-testid={testId}
          id={inputId}
          value={inputValue || ''}
          onChange={handleInputChange}
          placeholder={placeholder}
          error={localError || error}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          required={!clearable}
          aria-invalid={localError || !!error}
        />
        {inputValue && clearable && !disabled && (
          <div
            role="button"
            className={pickerStyles.clear}
            onClick={handleClear}
            data-testid={testId && `${testId}-clear-icon`}
          >
            <CloseIcon />
          </div>
        )}
        <div
          role="button"
          className={pickerStyles.picker}
          onClick={handleTimeIconClick}
          data-testid={testId && `${testId}-clock-icon`}
        >
          <ClockIcon />
        </div>
      </div>
      <Modal
        size={ModalSize.AUTO}
        show={visible}
        onHide={handleModalClose}
        title={inputValue || DEFAULT_TIME}
        className={styles.modal}
      >
        <TimeKeeper
          time={localError || error || !inputValue ? null : inputValue}
          hour24Mode
          onChange={handleTimeKeeperChange}
          switchToMinuteOnHourSelect
          closeOnMinuteSelect
          coarseMinutes={5}
          forceCoarseMinutes
          onDoneClick={handleModalClose}
          doneButton={() => null as never}
        />
      </Modal>
    </>
  );
};

export default TimePicker;
