/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import cn from 'classnames';
import styles from 'common/form/plain/Textarea.scss';

type Props = {
  inputId?: string;
  error?: React.ReactNode;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

/**
 * Just a plain Textarea with DomaCare colors
 */
const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(({ className, inputId, error, ...props }, ref) => (
  <textarea
    className={cn(styles.root, className, {
      [styles.error]: !!error,
    })}
    id={inputId}
    {...props}
    ref={ref}
  />
));

Textarea.displayName = 'PlainTextarea';

export default Textarea;
