/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import cn from 'classnames';
import styles from './ModalFooter.scss';

/**
 * @deprecated use Modal.Footer and Modal.Body
 * @param className
 * @param children
 * @param rest
 * @constructor
 */
const ModalFooter: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, children, ...rest }) => (
  <div className={cn(styles.root, className)} {...rest}>
    {children}
  </div>
);

export default ModalFooter;
