import { FC, memo, useMemo, useState } from 'react';
import moment from 'moment/moment';
import cn from 'classnames';

import {
  datesAreInRange,
  fromDateToMoment,
  getDaysInMonth,
  isInRange,
  dateRangeSelected,
} from 'common/form/pickers/utils';
import styles from 'common/form/pickers/DatePicker/DatePickerDays.scss';
import { DateRangeType } from 'common/form/pickers/DateRangePicker';

import { isDateBetween, isDateSameAs } from 'utils/dateUtil';

import { Mode } from './DatePicker';

const WEEKDAYS = [1, 2, 3, 4, 5, 6, 0];

type Props = {
  onDayClick: (date: Date) => void;
  month: number;
  year: number;
  mode?: Mode;
  numberOfDaysAllowed?: number;
  minDate?: string;
  maxDate?: string;
  inputValue?: string | DateRangeType | null;
  highlightedDates?: Set<string>; // new prop
};

type DayComponentProps = {
  sunday?: boolean;
  today?: boolean;
  selected?: boolean;
  out?: boolean;
  outOfRange?: boolean;
  dayInRange?: boolean;
  dayIsEndDay?: boolean;
  onClick?: () => void;
  onMouseOver?: () => void;
};

const DayComponent: FC<DayComponentProps> = ({
  sunday,
  today,
  selected,
  out,
  outOfRange,
  dayInRange,
  dayIsEndDay,
  onClick,
  children,
  onMouseOver,
}) => (
  <div
    onMouseOver={onMouseOver}
    className={cn(styles.day, {
      [styles.sunday]: sunday,
      [styles.today]: today,
      [styles.selected]: selected,
      [styles.out]: out,
      [styles.outOfRange]: outOfRange,
      [styles.dayInRange]: dayInRange,
      [styles.dayIsEndDay]: dayIsEndDay,
    })}
    onClick={onClick}
  >
    {children}
  </div>
);

const DatePickerDays = ({
  onDayClick,
  month,
  year,
  inputValue,
  minDate,
  maxDate,
  mode,
  numberOfDaysAllowed,
  highlightedDates,
}: Props) => {
  const [mouseOverDay, setMouseoverDay] = useState<Date>(
    fromDateToMoment(typeof inputValue !== 'string' ? inputValue?.endDate : inputValue).toDate(),
  );

  const selectMouseHoverDay = (day: Date, dayOutOfRange: boolean) => {
    if (mode === Mode.DATE_RANGE && !dayOutOfRange && !dateRangeSelected(inputValue as DateRangeType)) {
      setMouseoverDay(day);
    }
  };

  const days = useMemo(() => {
    const selectedDate = typeof inputValue === 'string' ? inputValue : inputValue?.startDate;

    const date = inputValue
      ? fromDateToMoment(selectedDate).toDate()
      : fromDateToMoment(minDate || new Date()).toDate();

    return getDaysInMonth(month, year, date);
  }, [inputValue, minDate, month, year]);

  return (
    <div className={styles.root}>
      {WEEKDAYS.map((day) => (
        <div key={day} className={styles.weekdays}>
          {moment.weekdaysShort(day)}
        </div>
      ))}

      {days.map((day) => {
        const outOfRange = !isInRange(day.date, minDate, maxDate, 'date'); // If the mode is datepicker

        let outOfDateRange = false;
        let checkIfDateIsInBetween = false;
        let dateIsEndDate = false;

        if (typeof inputValue !== 'string') {
          outOfDateRange = !!(
            numberOfDaysAllowed &&
            inputValue?.startDate &&
            !datesAreInRange(inputValue?.startDate as string, day.date, numberOfDaysAllowed)
          );

          checkIfDateIsInBetween = isDateBetween(
            day.date,
            fromDateToMoment(inputValue?.startDate).toDate(),
            mouseOverDay,
          );

          dateIsEndDate = isDateSameAs(day.date, fromDateToMoment(inputValue?.endDate).toDate());
        }
        // highlight is used to highlight the days from start to end date. Other stuff is disabled
        const isHighlighted = mode === Mode.HIGHLIGHT && highlightedDates?.has(day.date.toISOString());

        if (mode === Mode.HIGHLIGHT) {
          return (
            <DayComponent
              key={day.date.toISOString()}
              onMouseOver={() => selectMouseHoverDay(day.date, outOfDateRange)}
              sunday={day.weekday === 0}
              today={day.today}
              out={day.out}
              selected={isHighlighted}
              onClick={() => (!outOfRange || !outOfDateRange) && onDayClick(day.date)}
            >
              {day.day}
            </DayComponent>
          );
        }

        return (
          <DayComponent
            key={day.date.toISOString()}
            onMouseOver={() => selectMouseHoverDay(day.date, outOfDateRange)}
            sunday={day.weekday === 0}
            today={day.today}
            selected={day.selected}
            out={day.out}
            outOfRange={outOfRange || outOfDateRange}
            dayInRange={mode === Mode.DATE_RANGE && checkIfDateIsInBetween}
            dayIsEndDay={mode === Mode.DATE_RANGE && dateIsEndDate}
            onClick={() => (!outOfRange || !outOfDateRange) && onDayClick(day.date)}
          >
            {day.day}
          </DayComponent>
        );
      })}
    </div>
  );
};

export default memo(DatePickerDays);
