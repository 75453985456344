import React from 'react';
import cn from 'classnames';
import PlainRadio from 'common/form/plain/Radio';
import InlineField from 'common/form/InlineField';
import Error from 'common/form/Error';
import styles from 'common/form/plain/RadioGroup.scss';
import Label from 'common/form/Label';

type ValueType = string | number;

export type RadioValue = {
  label: React.ReactNode;
  disabled?: boolean;
  value: ValueType;
};

type Props = {
  inline?: boolean;
  disabled?: boolean;
  touched?: boolean;
  error?: string;
  label?: React.ReactText;
  required?: boolean;
  value: ValueType;
  options: RadioValue[];
  onChange: (value: ValueType) => void;
  onBlur?: (event: React.FocusEvent) => void;
};

const RadioGroup = ({ options, inline, disabled, value, onChange, touched, error, label, required, onBlur }: Props) => {
  const handleChange = (changed: RadioValue) => {
    if (!disabled && !changed.disabled) onChange(changed.value);
  };

  // must be touched to show error
  const showError = touched && error;

  return (
    <>
      {label ? <Label required={required}>{label}</Label> : null}
      <div className={cn(styles.root, { [styles.inline]: inline })} onBlur={onBlur}>
        {options.map((option) => (
          <InlineField
            className={cn({ [styles.disabled]: disabled || option.disabled })}
            key={option.value}
            label={option.label}
            onClick={() => handleChange(option)}
            disabled={disabled || option.disabled}
          >
            <PlainRadio onChange={() => handleChange(option)} checked={option.value === value} />
          </InlineField>
        ))}
      </div>
      {showError ? <Error>{error}</Error> : null}
    </>
  );
};

export default RadioGroup;
