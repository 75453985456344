/* eslint-disable react/prop-types */
import React from 'react';
import cn from 'classnames';
import styles from 'common/form/Limit.scss';

type Props = {
  length?: number;
  limit: number;
  className?: string;
};

const Limit: React.FC<Props> = ({ length, limit, className }) => (
  <div className={cn(styles.root, className)}>
    {length || 0}/{limit}
  </div>
);

export default Limit;
