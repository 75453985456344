import React from 'react';
import { IconProps } from 'common/icons/IconProps';
import { ICON_SIZE } from 'common/icons/constants';

const BusinessCenterIcon: React.FC<IconProps> = ({ size = ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24" width={size}>
    <path d="M 10 16 v -1 H 3.01 L 3 19 c 0 1.11 0.89 2 2 2 h 14 c 1.11 0 2 -0.89 2 -2 v -4 h -7 v 1 h -4 Z m 10 -9 h -4.01 V 5 l -2 -2 h -4 l -2 2 v 2 H 4 c -1.1 0 -2 0.9 -2 2 v 3 c 0 1.11 0.89 2 2 2 h 6 v -2 h 4 v 2 h 6 c 1.1 0 2 -0.9 2 -2 V 9 c 0 -1.1 -0.9 -2 -2 -2 Z m -6 0 h -4 V 5 h 4 v 2 Z" />
  </svg>
);

export default BusinessCenterIcon;
