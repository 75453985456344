import { defineMessages } from 'react-intl';

export default defineMessages({
  loading: {
    id: 'Booking.CustomDragLayer.DraggableBox.loading',
    defaultMessage: 'Loading...',
  },
  taskTime: {
    id: 'Booking.CustomDragLayer.DraggableBox.taskTime',
    defaultMessage: 'Task Time: ',
  },
  taskId: {
    id: 'Booking.CustomDragLayer.DraggableBox.taskId',
    defaultMessage: 'Task ID: ',
  },
  plannedDateAndTime: {
    id: 'Booking.plannedDateAndTime',
    defaultMessage: 'Planned date and time',
  },
  realisedDateAndTime: {
    id: 'Booking.realisedDateAndTime',
    defaultMessage: 'Realised date and time',
  },
  taskType: {
    id: 'Booking.taskType',
    defaultMessage: 'Task type',
  },
  calendarIsHidden: {
    id: 'Booking.calendarIsHidden',
    defaultMessage: 'Calendar is hidden due to small screen size',
  },
  taskInfo: {
    id: 'Booking.taskInfo',
    defaultMessage: 'Task info',
  },
  groups: {
    id: 'Booking.groups',
    defaultMessage: 'Groups',
  },
  timeWindow: {
    id: 'Booking.timeWindow',
    defaultMessage: 'Time window',
  },
  requirements: {
    id: 'Booking.requirements',
    defaultMessage: 'Requirements',
  },
  notStarted: {
    id: 'Booking.notStarted',
    defaultMessage: 'Not started',
  },
  started: {
    id: 'Booking.started',
    defaultMessage: 'Started',
  },
  finished: {
    id: 'Booking.finished',
    defaultMessage: 'Finished',
  },
  otherConsideration: {
    id: 'Booking.otherConsideration',
    defaultMessage: 'Other consideration',
  },
  additionalInfo: {
    id: 'Booking.additionalInfo',
    defaultMessage: 'Additional info',
  },
  products: {
    id: 'Booking.products',
    defaultMessage: 'Products',
  },
  repetition: {
    id: 'Booking.repetition',
    defaultMessage: 'Repetition',
  },
  repetitionDays: {
    id: 'Booking.repetitionDays',
    defaultMessage: 'Repetition days',
  },
  doChangesStarting: {
    id: 'Booking.dochangesStarting',
    defaultMessage: 'Do changes starting at the date',
  },
  addEndDateToRepetition: {
    id: 'Booking.addEndDateToRepetition',
    defaultMessage: 'Add end date to the repetition',
  },
  planned: {
    id: 'Booking.planned',
    defaultMessage: 'Planned',
  },
  realised: {
    id: 'Booking.realised',
    defaultMessage: 'Realised',
  },
  auctioned: {
    id: 'Booking.auctioned',
    defaultMessage: 'Auctioned',
  },
  markingTypes: {
    id: 'Booking.markingTypes',
    defaultMessage: 'Marking Types',
  },
  TaskId: {
    id: 'Booking.TaskId',
    defaultMessage: 'Task ID',
  },
  RepetitionID: {
    id: 'Booking.RepetitionID',
    defaultMessage: 'Repetition ID',
  },
  addNewTask: {
    id: 'Booking.addNewTask',
    defaultMessage: 'Add new task',
  },
  addNewWorkShift: {
    id: 'Booking.addNewWorkShift',
    defaultMessage: 'Add new work shift',
  },
  validateRoute: {
    id: 'Booking.validateRoute',
    defaultMessage: 'Validate route',
  },
  currentLocation: {
    id: 'Booking.currentLocation',
    defaultMessage: 'Show current location',
  },
  showRoute: {
    id: 'Booking.showRoute',
    defaultMessage: 'Show route',
  },
  breakPlannedTimeNotWithinShiftErrorCode: {
    id: 'Booking.breakPlannedTimeNotWithinShiftErrorCode',
    defaultMessage: "Break's planned start time must come after the shift's planned start time",
  },
  publishingDisabledErrorCode: {
    id: 'Booking.publishingDisabledErrorCode',
    defaultMessage: 'Publishing is disabled',
  },
  planningPeriodValidationErrorCode: {
    id: 'Booking.planningPeriodValidationErrorCode',
    defaultMessage: 'Planning period validation error',
  },
  planningPeriodNotAllowedErrorCode: {
    id: 'Booking.planningPeriodNotAllowedErrorCode',
    defaultMessage: 'Planning period is not allowed',
  },
  auctionSourceNullErrorCode: {
    id: 'Booking.auctionSourceNullErrorCode',
    defaultMessage: 'Auction source was not available',
  },
  auctionDuplicateKeyErrorCode: {
    id: 'Booking.auctionDuplicateKeyErrorCode',
    defaultMessage: 'Auction duplicate key was found',
  },
  auctionEmptyItemsErrorCode: {
    id: 'Booking.auctionEmptyItemsErrorCode',
    defaultMessage: 'Auction items were empty',
  },
  bookingItemsMissingErrorCode: {
    id: 'Booking.bookingItemsMissingErrorCode',
    defaultMessage: 'Booking items were missing',
  },
  itemIdIsMissingErrorCode: {
    id: 'Booking.itemIdIsMissingErrorCode',
    defaultMessage: 'Item id is missing',
  },
  auctionUserMissingErrorCode: {
    id: 'Booking.auctionUserMissingErrorCode',
    defaultMessage: 'Auction user is missing',
  },
  itemNeedsMissingErrorCode: {
    id: 'Booking.itemNeedsMissingErrorCode',
    defaultMessage: 'Item needs are missing from database',
  },
  itemCustomerIdIsRequiredErrorCode: {
    id: 'Booking.itemCustomerIdIsRequiredErrorCode',
    defaultMessage: 'Item customer id is missing',
  },
  auctionIsClosedErrorCode: {
    id: 'Booking.auctionIsClosedErrorCode',
    defaultMessage: 'Auction is closed',
  },
  auctionCallNotFoundErrorCode: {
    id: 'Booking.auctionCallNotFoundErrorCode',
    defaultMessage: 'Auction call is not found',
  },
  itemEmployeeIdNullErrorCode: {
    id: 'Booking.itemEmployeeIdNullErrorCode',
    defaultMessage: 'Item employee id is not assigned',
  },
  itemCustomerNotifyEntityIsMissingErrorCode: {
    id: 'Booking.itemCustomerNotifyEntityIsMissingErrorCode',
    defaultMessage: 'Item customer notify entity is missing',
  },
  itemUserNotifyEntityIsMissingErrorCode: {
    id: 'Booking.itemUserNotifyEntityIsMissingErrorCode',
    defaultMessage: 'Item user notify entity is missing',
  },
  itemHasAssignedEmployeeErrorCode: {
    id: 'Booking.itemHasAssignedEmployeeErrorCode',
    defaultMessage: 'Item has assigned employee',
  },
  itemAlreadyGradedErrorCode: {
    id: 'Booking.itemAlreadyGradedErrorCode',
    defaultMessage: 'Item is already graded by user',
  },
  itemNotFinishedErrorCode: {
    id: 'Booking.itemNotFinishedErrorCode',
    defaultMessage: 'Item is not finished',
  },
  undefinedDatabaseErrorCode: {
    id: 'Booking.undefinedDatabaseErrorCode',
    defaultMessage: 'Database is not defined',
  },
  undefinedUserIdErrorCode: {
    id: 'Booking.undefinedUserIdErrorCode',
    defaultMessage: 'User id is not defined',
  },
  customerIdsEmptyErrorCode: {
    id: 'Booking.customerIdsEmptyErrorCode',
    defaultMessage: 'Customer ids were empty',
  },
  auctionNoSmsCallErrorCode: {
    id: 'Booking.auctionNoSmsCallErrorCode',
    defaultMessage: 'Auction has no sms call',
  },
  startRangeIsAfterEndRangeErrorCode: {
    id: 'Booking.startRangeIsAfterEndRangeErrorCode',
    defaultMessage: 'Start range is after end range',
  },
  dateLimitIsReachedErrorCode: {
    id: 'Booking.dateLimitIsReachedErrorCode',
    defaultMessage: 'Date range limit is reached',
  },
  noAccessForTabErrorCode: {
    id: 'Booking.noAccessForTabErrorCode',
    defaultMessage: 'User has no access for given tab',
  },
  multiBookingTemplateErrorCode: {
    id: 'Booking.multiBookingTemplateErrorCode',
    defaultMessage: 'Customer has multi booking template',
  },
  noTemplateConnectedToRepetitionErrorCode: {
    id: 'Booking.noTemplateConnectedToRepetitionErrorCode',
    defaultMessage: 'Customer has no template connected to repetition',
  },
  itemMissingRepetitionErrorCode: {
    id: 'Booking.itemMissingRepetitionErrorCode',
    defaultMessage: 'Item is missing repetition',
  },
  multiCustomerFoundErrorCode: {
    id: 'Booking.multiCustomerFoundErrorCode',
    defaultMessage: 'Multi customer was found',
  },
  plannedTimeNotProvidedErrorCode: {
    id: 'Booking.plannedTimeNotProvidedErrorCode',
    defaultMessage: 'Item planned time is not provided',
  },
  startTimeGreaterEndTimeErrorCode: {
    id: 'Booking.startTimeGreaterEndTimeErrorCode',
    defaultMessage: 'Item start time is greater than end time',
  },
  itemRealizedWereProvidedErrorCode: {
    id: 'Booking.itemRealizedWereProvidedErrorCode',
    defaultMessage: 'Item realized time was provided',
  },
  itemStateNotUnconfirmedErrorCode: {
    id: 'Booking.itemStateNotUnconfirmedErrorCode',
    defaultMessage: 'Item state was not unconfirmed',
  },
  itemCreateWithCancelledNotAllowedErrorCode: {
    id: 'Booking.itemCreateWithCancelledNotAllowedErrorCode',
    defaultMessage: 'Item creation with cancelled is not allowed',
  },
  updateCancelItemErrorCode: {
    id: 'Booking.updateCancelItemErrorCode',
    defaultMessage: 'Updating cancel item is not allowed',
  },
  updateFinishedItemErrorCode: {
    id: 'Booking.updateFinishedItemErrorCode',
    defaultMessage: 'Updating finished item is not allowed',
  },
  fromConfirmToUnConfirmErrorCode: {
    id: 'Booking.fromConfirmToUnConfirmErrorCode',
    defaultMessage: 'Updating from confirm to un-confirm is not allowed',
  },
  undefinedStatusErrorCode: {
    id: 'Booking.undefinedStatusErrorCode',
    defaultMessage: 'Item status is undefined',
  },
  realizedEndTimeProvidedErrorCode: {
    id: 'Booking.realizedEndTimeProvidedErrorCode',
    defaultMessage: 'Item realized end time was provided',
  },
  realizedStartTimeAfterEndTimeErrorCode: {
    id: 'Booking.realizedStartTimeAfterEndTimeErrorCode',
    defaultMessage: 'realized start time is after end time',
  },
  realizedEndTimeRequiredErrorCode: {
    id: 'Booking.realizedEndTimeRequiredErrorCode',
    defaultMessage: 'Realized end time is required',
  },
  realizedStartTimeModifiedErrorCode: {
    id: 'Booking.realizedStartTimeModifiedErrorCode',
    defaultMessage: 'Provided realized start time was modified',
  },
  realizedStartTimeRequiredErrorCode: {
    id: 'Booking.realizedStartTimeRequiredErrorCode',
    defaultMessage: 'Realized start time was required',
  },
  timeTabRequiredErrorCode: {
    id: 'Booking.timeTabRequiredErrorCode',
    defaultMessage: 'Time tab id is required',
  },
  itemStartedErrorCode: {
    id: 'Booking.itemStartedErrorCode',
    defaultMessage: 'Item is started',
  },
  invalidPhoneNumberFormatErrorCode: {
    id: 'Booking.invalidPhoneNumberFormatErrorCode',
    defaultMessage: 'Phone number format is invalid',
  },
  repetitionNDayMissingErrorCode: {
    id: 'Booking.repetitionNDayMissingErrorCode',
    defaultMessage: 'Repetition NDay is missing',
  },
  overlappingNotAllowed: {
    id: 'Booking.overlappingNotAllowed',
    defaultMessage:
      'One or more of the provided shifts that have been assigned to an employee overlap with existing one',
  },
  defaultErrorCode: {
    id: 'Booking.defaultErrorCode',
    defaultMessage: 'Something went wrong',
  },
  taskTable: {
    id: 'Booking.taskTable',
    defaultMessage: 'Task table',
  },
  listView: {
    id: 'Booking.listView',
    defaultMessage: 'List view',
  },
  preferredEmployee: {
    id: 'Booking.preferredEmployee',
    defaultMessage: 'Preferred employee',
  },
  selectedEmployee: {
    id: 'Booking.selectedEmployee',
    defaultMessage: 'Selected employee',
  },
  selectedEmployeeIsNotPreferredEmployee: {
    id: 'Booking.selectedEmployeeIsNotPreferredEmployee',
    defaultMessage: "Selected employee is not the customer's preferred employee.",
  },
  customersPreferredEmployees: {
    id: 'Booking.customersPreferredEmployees',
    defaultMessage: "Customer's preferred employees",
  },
  noPreferredEmployees: {
    id: 'Booking.noPreferredEmployees',
    defaultMessage: 'No preferred employee',
  },
  taskStatus: {
    id: 'Booking.taskStatus',
    defaultMessage: 'Task status',
  },
  noTimeWindowSet: {
    id: 'Booking.noTimeWindowSet',
    defaultMessage: 'No time window set',
  },
  publishingView: {
    id: 'Booking.publishingView',
    defaultMessage: 'Publishing view',
  },
  legends: {
    id: 'Booking.legends',
    defaultMessage: 'Legends',
  },
  generateWorkShifts: {
    id: 'Booking.generateWorkShifts',
    defaultMessage: 'Generate work shifts from tasks',
  },
  generateWorkShiftsInfo: {
    id: 'Booking.generateWorkShiftsInfo',
    defaultMessage:
      'Generate work shifts automatically from tasks that have task type that is linked to work shift codes.',
  },
  publishedWorkShifts: {
    id: 'Booking.publishedWorkShifts',
    defaultMessage: 'Published work shifts',
  },
  taskNeeds: {
    id: 'Booking.taskNeeds',
    defaultMessage: 'Task needs',
  },
  customerNeeds: {
    id: 'Booking.customerNeeds',
    defaultMessage: 'Customer needs',
  },
  customerNeedsInfo: {
    id: 'Booking.customerNeedsInfo',
    defaultMessage: "Changing customer needs will affect all this customer's other tasks as well!",
  },
  taskNeedsInfo: {
    id: 'Booking.taskNeedsInfo',
    defaultMessage: 'Changing task needs will only effect this task!',
  },
  usedVehicle: {
    id: 'Booking.timelineCalendar.usedVehicle',
    defaultMessage: 'Used vehicle',
  },
  auctionList: {
    id: 'Booking.taskAuction.auctionList',
    defaultMessage: 'Auction list',
  },
  taskAuction: {
    id: 'Booking.taskAuction.taskAuction',
    defaultMessage: 'Task auction',
  },
  selectTasksToAuction: {
    id: 'Booking.taskAuction.selectTasksToAuction',
    defaultMessage: 'Select tasks to auction',
  },
  workShifts: {
    id: 'Booking.filtering.workShifts',
    defaultMessage: 'Work shifts',
  },
  tasks: {
    id: 'Booking.filtering.tasks',
    defaultMessage: 'Tasks',
  },
  availabilities: {
    id: 'Booking.filtering.availabilities',
    defaultMessage: 'Availabilities',
  },
  showInTimeline: {
    id: 'Booking.filtering.showInTimeline',
    defaultMessage: 'Show in timeline',
  },
  showTasks: {
    id: 'Booking.showTasks',
    defaultMessage: 'Show tasks',
  },
  startValidatingAll: {
    id: 'Booking.startValidatingAll',
    defaultMessage: 'Start validating all',
  },
  realShiftMoveError: {
    id: 'Booking.realShiftMoveError',
    defaultMessage: 'Assigned work shifts can not be moved to group calendar',
  },
  workShiftMoved: {
    id: 'Booking.workShiftMoved',
    defaultMessage: 'Work shifts moved',
  },
  tasksMoved: {
    id: 'Booking.tasksMoved',
    defaultMessage: 'Task(s) moved',
  },
  assignedWorkShift: {
    id: 'Booking.assignedWorkShift',
    defaultMessage: 'Assigned work shift',
  },
  draftWorkShift: {
    id: 'Booking.draftWorkShift',
    defaultMessage: 'Draft work shift',
  },
  employeeAvailable: {
    id: 'Booking.employeeAvailable',
    defaultMessage: 'Employee available',
  },
  employeeUnavailable: {
    id: 'Booking.employeeUnavailable',
    defaultMessage: 'Employee unavailable',
  },
  timeline: {
    id: 'Booking.timeline',
    defaultMessage: 'Timeline',
  },
  fourHours: {
    id: 'Booking.fourHours',
    defaultMessage: '4h',
  },
  eightHours: {
    id: 'Booking.eightHours',
    defaultMessage: '8h',
  },
  tenHours: {
    id: 'Booking.tenHours',
    defaultMessage: '10h',
  },
  twelveHours: {
    id: 'Booking.twelveHours',
    defaultMessage: '12h',
  },
  fourteenHours: {
    id: 'Booking.fourteenHours',
    defaultMessage: '14h',
  },
  sixteenHours: {
    id: 'Booking.sixteenHours',
    defaultMessage: '16h',
  },
  eighteenHours: {
    id: 'Booking.eighteenHours',
    defaultMessage: '18h',
  },
  oneDay: {
    id: 'Booking.oneDay',
    defaultMessage: '1d',
  },
  twoDays: {
    id: 'Booking.twoDays',
    defaultMessage: '2d',
  },
  threeDays: {
    id: 'Booking.threeDays',
    defaultMessage: '3d',
  },
  fourDays: {
    id: 'Booking.fourDays',
    defaultMessage: '4d',
  },
  fiveDays: {
    id: 'Booking.fiveDays',
    defaultMessage: '5d',
  },
  sixDays: {
    id: 'Booking.sixDays',
    defaultMessage: '6d',
  },
  oneWeek: {
    id: 'Booking.oneWeek',
    defaultMessage: '1w',
  },
  moveTaskErrorInCustomerView: {
    id: 'Booking.moveTaskErrorInCustomerView',
    defaultMessage: 'Task cannot be moved to another customer',
  },
  customerView: {
    id: 'Booking.customerView',
    defaultMessage: 'Customer view',
  },
  tasksView: {
    id: 'Booking.tasksView',
    defaultMessage: 'Tasks view',
  },
  employeeHasNoWorkShifts: {
    id: 'Booking.errors.employeeHasNoWorkShifts',
    defaultMessage: 'Employee has no work shifts',
  },
  agreementEndDateMissing: {
    id: 'Booking.errors.agreementEndDateMissing',
    defaultMessage: 'The end date is missing',
  },
  noAgreementFound: {
    id: 'Booking.errors.noAgreementFound',
    defaultMessage: 'No agreement found',
  },
  agreementMissingEqualizationPeriods: {
    id: 'Booking.errors.agreementMissingEqualizationPeriods',
    defaultMessage: 'Equalization periods are missing',
  },
  agreementValidationPayloadError: {
    id: 'Booking.errors.agreementValidationPayloadError',
    defaultMessage: 'Invalid payload',
  },
  workShiftNotBelongToAnyPlanningPeriods: {
    id: 'Booking.errors.workShiftNotBelongToAnyPlanningPeriods',
    defaultMessage: 'Work shift does not belong to any planning periods',
  },
  noWorkTimeStatistic: {
    id: 'Booking.errors.noWorkTimeStatistic',
    defaultMessage: 'No work time statistic found',
  },
  noPlanningPeriods: {
    id: 'Booking.errors.noPlanningPeriods',
    defaultMessage: 'No planning periods found',
  },
  agreementCycleIdMissing: {
    id: 'Booking.errors.agreementCycleIdMissing',
    defaultMessage: 'Cycle ids are missing',
  },
  agreementMultipleCycleIds: {
    id: 'Booking.errors.agreementMultipleCycleIds',
    defaultMessage: 'Multiple cycle ids exist',
  },
  previewGeneratedWorkShifts: {
    id: 'Booking.errors.previewGeneratedWorkShifts',
    defaultMessage: 'Preview generated work shifts',
  },
  needsAndOffersManagement: {
    id: 'Booking.components.needsAndOffersManagement',
    defaultMessage: 'Needs and offers management',
  },
  noEqualizationPeriod: {
    id: 'Booking.errors.noEqualizationPeriod',
    defaultMessage: 'Planning periods do not have equalization periods',
  },
  noIntersectingPlanningPeriod: {
    id: 'Booking.errors.noIntersectingPlanningPeriod',
    defaultMessage: 'Work hour forecast: No planning periods were intersecting with given date.',
  },
  draftListIsPublishedErrorCode: {
    id: 'Booking.errors.draftListIsPublishedErrorCode',
    defaultMessage: 'List has been published, work shift can no longer be modified.',
  },
  noCreatePrivilege: {
    id: 'Booking.errors.noCreatePrivilege',
    defaultMessage: 'User has no create privilege for work shifts.',
  },
  noUpdatePrivilege: {
    id: 'Booking.errors.noUpdatePrivilege',
    defaultMessage: 'User has no update privilege for work shifts.',
  },
  noDeletePrivilege: {
    id: 'Booking.errors.noDeletePrivilege',
    defaultMessage: 'User has no delete privilege for work shifts.',
  },
  notValidForUpdate: {
    id: 'Booking.errors.notValidForUpdate',
    defaultMessage: 'Work shift is not valid for update.',
  },
  plannedBegAfterPlannedBreakEnd: {
    id: 'Booking.errors.plannedBegAfterPlannedBreakEnd',
    defaultMessage: 'Work shift break planned start time is after planned end time.',
  },
  plannedBegAfterPlannedEnd: {
    id: 'Booking.errors.plannedBegAfterPlannedEnd',
    defaultMessage: 'Work shift planned start time is after end time.',
  },
  plannedStartMissing: {
    id: 'Booking.errors.plannedStartMissing',
    defaultMessage: 'Work shift planned start time is missing.',
  },
  plannedEndMissing: {
    id: 'Booking.errors.plannedEndMissing',
    defaultMessage: 'Work shift planned end time is missing.',
  },
  employeeIdMissing: {
    id: 'Booking.errors.employeeIdMissing',
    defaultMessage: 'Work shift is missing employee id.',
  },
  employeeIdNegOrZero: {
    id: 'Booking.errors.employeeIdNegOrZero',
    defaultMessage: ' Work shift employee id is negative or zero.',
  },
  commentMissing: {
    id: 'Booking.errors.commentMissing',
    defaultMessage: ' Work shift is missing comment.',
  },
  realizedBegAfterRealizedEnd: {
    id: 'Booking.errors.realizedBegAfterRealizedEnd',
    defaultMessage: ' Work shift realized start time is after realized end time.',
  },
  realizedEndMissing: {
    id: 'Booking.errors.realizedEndMissing',
    defaultMessage: ' Work shift realized end time is missing.',
  },
  noPlanningPeriodLists: {
    id: 'Booking.errors.noPlanningPeriodLists',
    defaultMessage: ' No planning period lists were found.',
  },
  employeeHasNoAgreement: {
    id: 'Booking.errors.employeeHasNoAgreement',
    defaultMessage: ' Employee has no agreement.',
  },
  fteMissing: {
    id: 'Booking.errors.fteMissing',
    defaultMessage: ' FTE is missing.',
  },
  breaksOverlapping: {
    id: 'Booking.errors.breaksOverlapping',
    defaultMessage: 'Breaks are not allowed to overlap with each other',
  },
  noPlanningLists: {
    id: 'Booking.errors.noPlanningLists',
    defaultMessage: 'There is no planning lists available.',
  },
  noSuitablePlanningListsForReps: {
    id: 'Booking.errors.noSuitablePlanningListsForReps',
    defaultMessage: 'Could not find suitable planning lists for all repetitions.',
  },
  moveSingleTask: {
    id: 'Booking.moveSingleTask',
    defaultMessage: 'Move the single task',
  },
  moveRepeatedTask: {
    id: 'Booking.moveRepeatedTask',
    defaultMessage: 'Move all tasks in repetition',
  },
  favorite: {
    id: 'Booking.favorite',
    defaultMessage: 'Favorite',
  },
  selectAsFavorite: {
    id: 'Booking.selectAsFavorite',
    defaultMessage: 'Select as favorite',
  },
  forecastError: {
    id: 'Booking.forecastError',
    defaultMessage: 'Forecast error',
  },
  publishedPlanningLists: {
    id: 'Booking.publishedPlanningLists',
    defaultMessage: 'Published planning lists',
  },
  unpublishedPlanningLists: {
    id: 'Booking.unpublishedPlanningLists',
    defaultMessage: 'Unpublished planning lists',
  },
  addAvailabilities: {
    id: 'Booking.addAvailabilities',
    defaultMessage: 'Add availabilities',
  },
  noUnPublishedPlanningLists: {
    id: 'Booking.errors.noUnPublishedPlanningLists',
    defaultMessage: 'No unpublished planning lists were found.',
  },
  taskBelongsToRepetition: {
    id: 'Booking.taskConfirmModal.taskBelongsToRepetition',
    defaultMessage: 'Task belongs to a repetition',
  },
  moveOnlyCurrentTaskInRepetition: {
    id: 'Booking.taskConfirmModal.moveOnlyCurrentTaskInRepetition',
    defaultMessage: 'Move only current task in repetition',
  },
});
