import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.common.errors.moduleLoadError.title',
    defaultMessage: 'Component load failed',
  },
  text: {
    id: 'app.common.errors.moduleLoadError.text',
    defaultMessage:
      'Application failed to load the part you tried to access. This can happen, if a new version was released, or your network is offline. Please try to reload the application',
  },
});
