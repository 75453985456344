import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './ButtonCircle.scss';

type Props = {
  className?: string;
  secondary?: boolean;
  success?: boolean;
  danger?: boolean;
  disabled?: boolean;
  large?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  testId?: string;
};

const ButtonCircle = React.forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  ({ className, onClick, children, disabled, success, large, secondary, danger, testId }, ref) => (
    <button
      type="button"
      className={cn(
        styles.root,
        { [styles.secondary]: secondary, [styles.success]: success, [styles.danger]: danger, [styles.large]: large },
        className,
      )}
      onClick={onClick}
      disabled={disabled}
      ref={ref}
      data-testid={testId}
    >
      {children}
    </button>
  ),
);

ButtonCircle.displayName = 'ButtonCircle';

export default ButtonCircle;
