import { useIntl } from 'react-intl';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { ICON_SIZE_XXL } from 'common/icons/constants';
import ChatIcon from 'common/icons/ChatIcon';

import useAppContext from 'hooks/useAppContext';
import useWindowThreshold from 'hooks/useWindowThreshold';
import useUser from 'hooks/useUser';

import { Features } from 'types/Features';

import { featureEnabled } from 'utils/features';

import SideNavHeader from 'sharedComponents/TopHeader/SideNavHeader';
import LanguageSwitcher from 'sharedComponents/LanguageSwitcher/LanguageSwitcher';
import DutyCategoryMetaInfo from 'sharedComponents/TopHeader/DutyCategoryMetaInfo';
import DarkModeSwitch from 'sharedComponents/TopHeader/DarkModeSwitch';
import { SIDE_NAV_STATE } from 'providers/AppProvider';
import styles from 'sharedComponents/TopHeader/TopHeader.scss';
import DomaCareLogo from 'assets/images/brand/DomaCare_color_RGB_white_text.svg';
import EArkistoWidget from 'nonRouteFeatures/EArkisto/EArkistoWidget';

const TopHeader = () => {
  const { formatMessage } = useIntl();
  const { featureTitle, openSideNav, onOpenSideNav, isSmallScreen } = useAppContext();
  const user = useUser();
  const showSeparateUsernameRow = useWindowThreshold(650);

  let userDetail = '';

  if (user) {
    userDetail = `${user.firstName} ${user.lastName} / ${user.id}`;
  }

  return (
    <>
      <div className={cn(styles.root, { [styles.small]: isSmallScreen })}>
        <div className={styles.sideNav}>
          <SideNavHeader smallScreen={isSmallScreen} onClick={() => onOpenSideNav()} openSideNav={openSideNav} />
        </div>

        {isSmallScreen && openSideNav === SIDE_NAV_STATE.OPENED ? (
          <div className={styles.logoWrapperSmallScreen}>
            <img src={DomaCareLogo} alt="DomaCareLogo" className={styles.logoSmallScreen} />
          </div>
        ) : (
          <>
            {featureTitle && <div className={cn({ 'text-left': isSmallScreen })}>{formatMessage(featureTitle)}</div>}
            <div className={styles.right}>
              {!showSeparateUsernameRow && <span className={styles.loggedInUser}>{userDetail}</span>}
              {featureEnabled(Features.eArkisto) && <EArkistoWidget />}
              {featureEnabled(Features.DarkMode) && <DarkModeSwitch />}
              {featureEnabled(Features.MessageCenter) && (
                <Link className={styles.messages} to="/messagecenter">
                  <ChatIcon size={ICON_SIZE_XXL} />
                </Link>
              )}
              {featureEnabled(Features.Duties) && <DutyCategoryMetaInfo />}
              <LanguageSwitcher />
            </div>
          </>
        )}
      </div>
      {showSeparateUsernameRow && <div className={styles.userNameRow}>{userDetail}</div>}
    </>
  );
};

export default TopHeader;
