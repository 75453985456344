import { useEffect, useRef, useState } from 'react';

import WINDOW_SIZE from 'types/resolution';

const windowSize = (height?: boolean) => (height ? window.innerHeight : window.innerWidth);

/**
 * window size under a threshold will return true otherwise false
 *
 * @param {number} threshold
 * @param {height?} windowHeight
 * @return boolean
 */
export default function useWindowThreshold(threshold: WINDOW_SIZE, windowHeight?: boolean): boolean {
  const size = windowSize(windowHeight);
  const [value, setValue] = useState(size < threshold);
  const isMounted = useRef(true); // Create a ref to track whether the component is mounted

  useEffect(() => {
    isMounted.current = true; // Set the ref to true when the component mounts

    const updateSize = () => {
      if (isMounted.current) {
        const val = windowSize(windowHeight) < threshold;
        setValue(val);
      }
    };

    window.addEventListener('resize', updateSize);

    // Initial check
    updateSize();

    return () => {
      isMounted.current = false;
      window.removeEventListener('resize', updateSize);
    };
  }, [windowHeight, threshold]);

  return value;
}
