import { SelectOptionTranslate } from 'common/form/types';
import commonMessages from 'translations/CommonTranslations/messages';

export enum RepetitionType {
  EVERY_DAY = 'EVERY_DAY',
  EVERY_WEEK = 'EVERY_WEEK',
  EVERY_MONTH = 'EVERY_MONTH',
  EVERY_NDAY = 'EVERY_NDAY',
  EVERY_NWEEK = 'EVERY_NWEEK',
  EVERY_YEAR = 'EVERY_YEAR',
  NTH_WEEKDAY_OF_MONTH = 'NTH_WEEKDAY_OF_MONTH',
  UNSUPPORTED = 'UNSUPPORTED',
}

export const repetitionOptions: SelectOptionTranslate<RepetitionType>[] = [
  { label: commonMessages.everyDay, value: RepetitionType.EVERY_DAY },
  { label: commonMessages.everyWeek, value: RepetitionType.EVERY_WEEK },
  { label: commonMessages.everyMonth, value: RepetitionType.EVERY_MONTH },
  { label: commonMessages.everyNDay, value: RepetitionType.EVERY_NDAY },
  { label: commonMessages.everyNWeek, value: RepetitionType.EVERY_NWEEK },
  { label: commonMessages.everyYear, value: RepetitionType.EVERY_YEAR },
  { label: commonMessages.everyNWeekDay, value: RepetitionType.NTH_WEEKDAY_OF_MONTH },
];
