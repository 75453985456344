export enum AtostekHashAlgorithm {
  SHA1 = 'SHA1',
  SHA256 = 'SHA256',
  SHA512 = 'SHA512',
}

export enum AtostekLanguage {
  FI = 'fi',
  EN = 'en',
}

export enum AtostekReaderMode {
  NORMAL = 'Normal',
  NORMAL_OR_NFC = 'NormalOrNFC',
  NFC = 'NFC',
}

export enum CardReaderSystemInfoLevel {
  NONE = 0,
  GET_SYSTEM_INFO = 1,
}

export type AtostekCardLoginPayload = {
  Language: AtostekLanguage;
  DataToSign: string;
  HashAlgorithm: AtostekHashAlgorithm;
  ReaderMode: AtostekReaderMode;
  MinVersion: string;
};

export type AtostekPingSuccess = {
  DriverVersion: string;
  ErrorCode: number;
};

export type AtostekPingFailure = {
  DriverVersion: string;
  ErrorCode: number;
};

export type AtostekCardLoginResponse = {
  CertificateData: string;
  DriverVersion: string;
  ErrorCode: number;
  OSVersion: string;
  OSVersionCode: string;
  OSType: string;
  Signature: string;
  ComputerName: string;
  UserName: string;
  RemoteClientName: string;
  CardReaderModel: string;
  SoftwareCertificateBeginTime: string;
  SoftwareCertificateEndTime: string;
  ReaderMode: string;
  SignedInfo: string;
};

export type SmartCardStep2Payload = {
  certificate: string;
  challengeSignature: string;
};
