import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { stringify } from 'qs';
import { Severity } from '@sentry/types';
import * as Sentry from '@sentry/react';

import toast from 'utils/toast';
import { extractMessage } from 'utils/api/utils';
import { getCookie } from 'utils/getCookie';

import errorCodes from 'translations/errorCodes';

export const { apiUrl } = process.env.CONFIG;

const STATUS_CODES: Record<string, unknown> = {};

const wait = (delay: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

export type InfiniteQuery = {
  limit?: number;
  after?: number;
};

type InterceptorOptions = {
  xsrfCookieName: string;
  xsrfHeaderName: string;
};

interface AxiosInstanceExtended extends AxiosInstance {
  pdf: (url: string, payload?: any) => Promise<void>;
  getPdf: (url: string, config?: AxiosRequestConfig, headers?: Record<string, unknown>) => Promise<void>;
  download: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<Blob>>;
}

const MORE_RESULTS_HEADER = 'x-invian-domarest-more-results';
const JOB_DONE_HEADER = 'x-invian-domarest-job-done';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasMoreResults = (headers: any) => headers[MORE_RESULTS_HEADER] === 'true';

const instance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
}) as AxiosInstanceExtended;

// Set default header names and values so they are easy to fetch and change in interceptors if needed
instance.defaults.xsrfCookieName = 'XSRF-TOKEN';
instance.defaults.xsrfHeaderName = 'X-Xsrf-Token';
instance.defaults.headers.common.Accept = 'application/json';

// eslint-disable-next-line func-names
instance.pdf = async function (url: string, payload, config?: AxiosRequestConfig) {
  const response = await this.post(url, payload, {
    headers: { accept: 'application/pdf,application/json' }, // because a job can return an application/json, so we check both
    responseType: 'blob',
    ...config,
  });
  const objectURL = window.URL.createObjectURL(response.data);
  window.open(objectURL, '_blank');
};

// eslint-disable-next-line func-names
instance.getPdf = async function (url: string, config?: AxiosRequestConfig) {
  const response = await this.get(url, {
    headers: { accept: 'application/pdf,application/json' }, // because a job can return an application/json, so we check both
    responseType: 'blob',
    ...config,
  });
  const objectURL = window.URL.createObjectURL(response.data);
  window.open(objectURL, '_blank');
};

// eslint-disable-next-line func-names
instance.download = async function (url: string, config?: AxiosRequestConfig) {
  const response = await this.get(url, {
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
    ...config,
  });
  const fileName = response.headers['content-disposition'].split('filename=')[1];

  const blob = new Blob([response.data], { type: response.headers['content-type'] });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(link.href);

  return response;
};

// Request interceptor. Add XSRF token to the request headers as a special header (new Axios requires this to be explicit)
instance.interceptors.request.use((config) => {
  const { xsrfHeaderName, xsrfCookieName } = config as InternalAxiosRequestConfig & InterceptorOptions;
  // eslint-disable-next-line no-param-reassign
  config.headers[xsrfHeaderName] = getCookie(xsrfCookieName);
  return config;
});

// Response interceptor. Handle error catching to send to Sentry and show toast messages, as well as handle job status polling
instance.interceptors.response.use(
  async (response) => {
    if (response.status === 201 && response.headers.location) {
      return instance.get(response.headers.location);
    }

    if (response.request.responseURL.includes('/domarest/jobs/')) {
      if (response.headers[JOB_DONE_HEADER]) {
        return response;
      }
      await wait(1000);
      return instance.get(response.request.responseURL, { responseType: response.request.responseType });
    }
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      // canceled request via axios CancelToken
      return Promise.reject(error);
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { status, data } = error.response;

      // something f*cked up
      if (status >= 400 && status !== 401) {
        Sentry.captureException(error, {
          level: Severity.Warning,
        });
      }

      if (status === 403) {
        toast.error(errorCodes.FORBIDDEN);
        return Promise.reject(error);
      }

      const errorCode = error.response?.data?.messages?.[0].errorCode as string | undefined;

      if (errorCode) {
        if (errorCodes[errorCode as keyof typeof errorCodes]) {
          toast.error(errorCodes[errorCode as keyof typeof errorCodes]);
        } else {
          toast.error(errorCodes.ERROR_CODE, { errorCode });
        }
      } else {
        const message = extractMessage(error.response);
        (window as any).NetworkActions.notifyError(
          message,
          false,
          status || 'SOMETHING WENT WRONG',
          errorCode,
          STATUS_CODES[status || 'SOMETHING WENT WRONG'],
          data && data.messages?.[0] ? data.messages[0].developerMessage : '',
        );
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // e.g. cors issue / network offline
      toast.error(errorCodes.DEFAULT);
    } else {
      toast.error(errorCodes.DEFAULT);
    }

    return Promise.reject(error);
  },
);

export default instance;
