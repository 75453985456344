/*
 * This is for the top bar
 * */

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import ArchiveIcon from 'common/icons/ArchiveIcon';
import { ICON_SIZE_XXL } from 'common/icons/constants';

import usePrivileges from 'hooks/usePrivileges';
import useModal from 'hooks/useModal';

import { getTransferrableReportThunk, getTransferredReportThunk } from 'nonRouteFeatures/EArkisto/slice';
import EArkistoSelector from 'nonRouteFeatures/EArkisto/selectors';
import EArkistoModal from 'nonRouteFeatures/EArkisto/EArkistoModal';
import ReportDisplayEventEmitter from 'sharedComponents/ReportDisplay/ReportDisplayEventEmitter';

const EArkistoWidget = () => {
  const privileges = usePrivileges();
  const dispatch = useDispatch();

  const state = useSelector(EArkistoSelector);

  const [showEarkistoModal] = useModal(EArkistoModal);

  useEffect(() => {
    if (privileges?.ATOSTEK_ERA?.r) {
      dispatch(getTransferrableReportThunk());
      dispatch(getTransferredReportThunk());
    }
  }, [dispatch, privileges?.ATOSTEK_ERA?.r]);

  useEffect(() => {
    const fetch = () => {
      dispatch(getTransferrableReportThunk());
      dispatch(getTransferredReportThunk());
    };
    ReportDisplayEventEmitter.on('refresh', fetch);
    return () => {
      ReportDisplayEventEmitter.off('refresh', fetch);
    };
  }, [dispatch]);

  return (
    <div role="button" onClick={showEarkistoModal}>
      {state?.transferrableReports?.length > 0 && (
        <div className="count-indicator-red position-absolute ml-2">{state.transferrableReports.length}</div>
      )}
      <ArchiveIcon size={ICON_SIZE_XXL} />
    </div>
  );
};

export default EArkistoWidget;
