import { Player } from '@lottiefiles/react-lottie-player';

import { useEffect, useRef } from 'react';
import spinnerData from './preloader.json';

type Props = {
  width?: number;
  height?: number;
  isPaused?: boolean;
  small?: boolean;
};

const Spinner = ({ width = 150, height = 150, small, isPaused }: Props) => {
  const ref = useRef<Player>(null);

  useEffect(() => {
    if (isPaused !== undefined) ref.current?.[isPaused ? 'pause' : 'play']();
  }, [isPaused]);
  return <Player ref={ref} src={spinnerData} loop autoplay style={{ width, height: small ? 100 : height }} />;
};

export default Spinner;
