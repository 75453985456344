import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import { State } from 'types/State';
import { User } from 'types/User';

import { getUsersForDutiesFeature } from 'services/duties/users';

export const EMPLOYEES_REDUCER_NAME = 'employees';

export const loadEmployees = createAsyncThunk(`${EMPLOYEES_REDUCER_NAME}/getEmployees`, async () => {
  const response = await getUsersForDutiesFeature();
  return response.data;
});

export type EmployeesState = {
  employeesList: User[];
  state: State;
  error: null | SerializedError;
};

const initialState = {
  employeesList: [],
  state: State.NOT_STARTED,
  error: null,
} as EmployeesState;

const slice = createSlice({
  name: EMPLOYEES_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadEmployees.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(loadEmployees.fulfilled, (draft, action) => {
      draft.employeesList = action.payload;
      draft.state = State.SUCCESS;
    });
    builder.addCase(loadEmployees.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
    });
  },
});

export default slice.reducer;
