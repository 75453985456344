/* eslint-disable react/prop-types */
import React from 'react';
import cn from 'classnames';
import { MessageDescriptor, useIntl } from 'react-intl';
import styles from 'common/form/Field.scss';
import Error from './Error';
import Label from './Label';
import Limit from './Limit';

export type FieldProps = {
  label?: React.ReactNode;
  componentClassName?: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string | MessageDescriptor;
  touched?: boolean;
  value?: any;
  limit?: number;
  style?: React.CSSProperties;
  children: React.ReactElement;
  hideError?: boolean;
  name?: string;
  testId?: string;
};

export type DomaFieldProps<T> = Omit<FieldProps, 'children'> & Omit<T, 'error'>;

/**
 * Internal wrapper for Form Fields, don't use it directly!
 * @private
 */
const Field: React.FC<FieldProps> = React.forwardRef<HTMLInputElement, FieldProps>(
  (
    {
      children,
      hideError,
      label,
      value,
      disabled,
      touched,
      error,
      required,
      limit,
      style,
      className,
      componentClassName,
      name,
      testId,
    },
    ref,
  ) => {
    const { formatMessage } = useIntl();
    const dataTestid = testId || name;
    const htmlFor = name || undefined;
    const htmlForFeedback = htmlFor ? `${htmlFor}-feedback` : undefined;
    // must be touched to show errors
    const showError = touched && error;
    return (
      <div className={cn(styles.root, className)} style={style} data-testid={dataTestid && `${dataTestid}-field`}>
        {label && (
          <Label htmlFor={htmlFor} required={required}>
            {label}
          </Label>
        )}
        {React.cloneElement(children, {
          className: componentClassName,
          inputId: htmlFor,
          error: showError,
          disabled,
          required,
          ref,
          'aria-describedby': showError ? htmlForFeedback : undefined,
        })}
        {!hideError && showError && (
          <Error htmlFor={htmlForFeedback} testId={dataTestid && `${dataTestid}-error`}>
            {typeof error === 'string' ? error : formatMessage(error)}
          </Error>
        )}
        {limit && <Limit limit={limit} length={value?.length} />}
      </div>
    );
  },
);

Field.displayName = 'DomaField';

export default Field;
