export enum Features {
  DarkMode = 'darkMode',
  Home = 'home',
  FrontPage = 'frontPage',
  Root = 'root',
  Login = 'login',
  CarePlan = 'carePlan',
  Calendar = 'calendar',
  Customers = 'customers',
  CustomersUnitInfo = 'customersUnitInfo',
  CustomersRooms = 'customersRooms',
  CustomersSearch = 'customersSearch',
  CustomerSettings = 'customerSettings',
  CustomerSettingsBasicInfo = 'customerSettingsBasicInfo',
  CustomerSettingsBasicInfoCustomerGroups = 'customerSettingsBasicInfoCustomerGroups',
  CustomerSettingsBasicInfoStaffingRatio = 'customerSettingsBasicInfoStaffingRatio',
  CustomerSettingsBasicInfoAbsences = 'customerSettingsBasicInfoAbsences',
  CustomerSettingsHistory = 'customerSettingsHistory',
  CustomerSettingsHistoryUnitHistory = 'customerSettingsHistoryUnitHistory',
  CustomerSettingsHistoryContractHistory = 'customerSettingsHistoryContractHistory',
  CustomerSettingsBillingInfo = 'customerSettingsBillingInfo',
  CustomerSettingsSocialArchiveInfo = 'customerSettingsSocialArchiveInfo',
  CustomerSettingsFinalReports = 'customerSettingsFinalReports',
  CustomerNotices = 'customerNotices',
  CustomerAttachments = 'customerAttachments',
  CustomerDetail = 'customerDetail',
  Tasks = 'tasks',
  TasksDetail = 'tasksDetail',
  Settings = 'settings',
  RaiStats = 'RaiStats',
  TestCommonMsg = 'testCommonMessages',
  Activation = 'activation',
  LoginRegistered = 'loginRegistered',
  LoginRegister = 'loginRegister',
  Analyses = 'Analyses',
  AnalysisTableTab = 'AnalysisTableTab',
  Extranet = 'Extranet',
  ExtranetAttachments = 'ExtranetAttachments',
  Rai = 'rai',
  Rai_v2 = 'rai_v2',
  Documents = 'documents',
  CustomersFeatureChild = 'featureChild',
  CreateUser = 'createUser',
  // Auction = 'auction',
  Billing = 'billing',
  BillingContracts = 'billingContracts',
  TaskAuction = 'taskAuction',
  Booking = 'booking',
  BookingWorkShift = 'bookingWorkShift',
  BookingTimeTab = 'bookingTimeTab',
  PrintOut = 'printOut',
  Wallet = 'wallet',
  Reports = 'reports',
  Eresepti = 'eresepti',
  ContractHistory = 'contractHistory',
  Absences = 'absences',
  UnitHistory = 'unitHistory',
  Duties = 'duties',
  RouteOptimization = 'routeOptimization',
  Diagnoses = 'diagnoses',
  MessageCenter = 'messageCenter',
  WorkPlanning = 'workPlanning',
  PreferredEmployees = 'preferredEmployees',
  BookingPublishingView = 'bookingPublishingView',
  CustomerMedicines = 'customerMedicines',
  CustomerMedicinesPrintOut = 'customerMedicinesPrintout',
  CustomerBilling = 'customerBilling',
  ServicePackets = 'servicePackets',
  WebView = 'webView',
  SessionTimeout = 'sessionTimeout',
  Payroll = 'payroll',
  ExportArchive = 'exportArchive',
  Tour = 'tour',
  Users = 'users',
  CustomerDashboard = 'customerDashboard',
  RestrictionEvents = 'restrictionEvents',
  Corrections = 'corrections',
  TasksWidget = 'tasksWidget',
  QuickPrintWidget = 'quickPrintWidget',
  CalendarWidget = 'calendarWidget',
  CarePlanWidget = 'carePlanWidget',
  CustomerNoticesWidget = 'customerNoticesWidget',
  ReportsWidget = 'reportsWidget',
  DutiesWidget = 'dutiesWidget',
  SocialArchiveCustomerShips = 'socialArchiveCustomerShips',
  SocialArchiveTransfers = 'socialArchiveTransfers',
  SSO = 'SSO',
  CustomerCalendar = 'customerCalendar',
  CustomerMeasurements = 'customerMeasurements',
  RouteOptimizationUndo = 'routeOptimizationUndo',
  EditCustomer = 'editCustomer',
  SocialArchiveSearch = 'socialArchiveSearch',
  CustomerGoals = 'customerGoals',
  SosMetaForms = 'sosMetaForms',
  AgreementHistory = 'agreementHistory',
  ReportSplit = 'reportSplit',
  eArkisto = 'eArkisto',
  SocialArchiveDocumentHistory = 'socialArchiveDocumentHistory',
  SocialArchiveInteractionLog = 'socialArchiveInteractionLog',
  RestrictionEventsPrint = 'restrictionEventsPrint',
  DVVSmartCardLogin = 'dvvSmartCardLogin',
}

export enum AlwaysOnFeatures {
  Logout = 'Logout',
  NotFound = 'notfound',
}
