import { SelectOptions } from 'common/form/types';

import {
  AddDutyCategoryFailed,
  DoesCategoryHaveMoreDuties,
  DutyFetchMoreFailed,
  DutyFetchMoreSuccess,
  EditDutyCategorySuccess,
  EditDutyFailed,
  EditDutySuccess,
  GetCategoriesPrivilegesFailed,
  GetCategoriesPrivilegesSuccess,
  GetDutyList,
  GetDutyListFailed,
  GetDutyListSuccess,
  GetMoreDutiesInCategoryFailed,
  GetMoreDutiesInCategorySuccess,
  HasDutyNextPage,
  LoadDutiesInCategory,
  LoadDutiesInCategoryFailed,
  LoadDutiesInCategorySuccess,
  LoadDutyCategoriesFailed,
  LoadDutyCategoriesSuccess,
  LoadDutyDetails,
  LoadDutyDetailsFailed,
  LoadDutyDetailsSuccess,
  ResetStates,
} from 'Duties/types/ActionsType';

import {
  ADD_CATEGORY_FAILED,
  CLOSE_DUTY_DETAIL,
  FETCH_MORE_CATEGORY_DUTIES_FAILED,
  FETCH_MORE_FAILED,
  GET_DUTY_DETAIL_FAILED,
  HAS_NEXT_PAGE,
  LOAD_CATEGORIES_FAILED,
  LOAD_CATEGORIES_PRIVILEGES_SUCCESS,
  LOAD_CATEGORY_DUTIES_FAILED,
  LOAD_DUTY_LIST_FAILED,
  LOAD_DUTY_LIST_SUCCESS,
  MORE_DUTIES_EXISTED_IN_CATEGORY,
  RESET_STATES,
  UPDATE_CATEGORY_FAILED,
  UPDATE_DUTY_FAILED,
  UPDATE_DUTY_SUCCESS,
} from './constants';
import { DutyDetails } from './types';

export function loadDutyListSuccess(dutyList: DutyDetails[]): GetDutyListSuccess {
  return {
    type: LOAD_DUTY_LIST_SUCCESS,
    dutyList,
  };
}

export function loadDutyListFailed(error: Error): GetDutyListFailed {
  return {
    type: LOAD_DUTY_LIST_FAILED,
    error,
  };
}

export function loadCategoriesFailed(error: Error): LoadDutyCategoriesFailed {
  return {
    type: LOAD_CATEGORIES_FAILED,
    error,
  };
}

export type CloseDutyDetail = {
  type: typeof CLOSE_DUTY_DETAIL;
};
export function closeDutyDetail(): CloseDutyDetail {
  return { type: CLOSE_DUTY_DETAIL };
}

export function getDutyDetailFailed(error: Error): LoadDutyDetailsFailed {
  return {
    type: GET_DUTY_DETAIL_FAILED,
    error,
  };
}

export function fetchMoreFailed(error: Error): DutyFetchMoreFailed {
  return {
    type: FETCH_MORE_FAILED,
    error,
  };
}

export function updateDutySuccess(): EditDutySuccess {
  return {
    type: UPDATE_DUTY_SUCCESS,
  };
}

export function updateDutyFailed(error: Error): EditDutyFailed {
  return {
    type: UPDATE_DUTY_FAILED,
    error,
  };
}

export function addCategoryFailed(error: Error): AddDutyCategoryFailed {
  return {
    type: ADD_CATEGORY_FAILED,
    error,
  };
}

export function updateCategoryFailed(error: Error) {
  return {
    type: UPDATE_CATEGORY_FAILED,
    error,
  };
}

export function hasNextPage(isNextPage: boolean): HasDutyNextPage {
  return {
    type: HAS_NEXT_PAGE,
    isNextPage,
  };
}

export function isMoreDutiesExistedInCategory(hasMoreDutiesInCategory: boolean): DoesCategoryHaveMoreDuties {
  return {
    type: MORE_DUTIES_EXISTED_IN_CATEGORY,
    hasMoreDutiesInCategory,
  };
}

export function loadDutiesBelongsToCategoryFailed(error: Error): LoadDutiesInCategoryFailed {
  return {
    type: LOAD_CATEGORY_DUTIES_FAILED,
    error,
  };
}

export function fetchMoreDutiesInCategoryFailed(error: Error): GetMoreDutiesInCategoryFailed {
  return {
    type: FETCH_MORE_CATEGORY_DUTIES_FAILED,
    error,
  };
}

export function loadCategoryPrivilegesSuccess(categoriesPrivileges: SelectOptions): GetCategoriesPrivilegesSuccess {
  return {
    type: LOAD_CATEGORIES_PRIVILEGES_SUCCESS,
    categoriesPrivileges,
  };
}

export function resetStates(): ResetStates {
  return {
    type: RESET_STATES,
  };
}

export type Actions =
  | GetDutyList
  | GetDutyListSuccess
  | GetDutyListFailed
  | LoadDutyCategoriesSuccess
  | LoadDutyCategoriesFailed
  | LoadDutyDetails
  | LoadDutyDetailsSuccess
  | LoadDutyDetailsFailed
  | CloseDutyDetail
  | DutyFetchMoreSuccess
  | DutyFetchMoreFailed
  | HasDutyNextPage
  | DoesCategoryHaveMoreDuties
  | LoadDutiesInCategory
  | LoadDutiesInCategorySuccess
  | LoadDutiesInCategoryFailed
  | EditDutyCategorySuccess
  | GetCategoriesPrivilegesSuccess
  | GetCategoriesPrivilegesFailed
  | GetMoreDutiesInCategorySuccess
  | GetMoreDutiesInCategoryFailed
  | ResetStates;
