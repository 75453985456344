/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment,react/jsx-handler-names */
import { ChangeEvent, ComponentProps, FC } from 'react';
import { ErrorMessage as FormikErrorMessage, ErrorMessageProps, useField } from 'formik';
import { OptionTypeBase } from 'react-select';
import { MessageDescriptor, useIntl } from 'react-intl';

import DomaInlineField, { DomaInlineFieldProps } from 'common/form/InlineField';
import DomaCheckbox from 'common/Checkbox';
import DomaField, { DomaFieldProps } from 'common/form/Field';
import DomaTextarea from 'common/form/plain/Textarea';
import DomaInput from 'common/form/plain/Input';
import DomaTimePicker from 'common/form/pickers/TimePicker';
import DomaDatePicker from 'common/form/pickers/DatePicker';
import DomaDateRangePicker from 'common/form/pickers/DateRangePicker';
import DomaSelect, { Props as DomaSelectProps } from 'common/form/plain/Select';
import DomaRadioGroup from 'common/form/plain/RadioGroup';
import { SelectSelectedValue } from 'common/form/types';
import Error from 'common/form/Error';

type FormikRequiredProps = {
  name: string;
};

type DomaFieldFormikProps<T> = Omit<DomaFieldProps<T>, 'value' | 'error' | 'touched'>;
type DomaInlineFieldFormikProps<T> = Omit<DomaInlineFieldProps<T>, 'value' | 'error' | 'touched'>;

export type FormikDomaErrors<Values> = {
  [K in keyof Values]?: Values[K] extends any[]
    ? Values[K][number] extends Record<string, unknown>
      ? FormikDomaErrors<Values[K][number]>[] | MessageDescriptor | string | string[]
      : MessageDescriptor | string | string[]
    : Values[K] extends Record<string, unknown>
    ? FormikDomaErrors<Values[K]>
    : MessageDescriptor | string;
};

export const ErrorMessage = ({ name, className }: Omit<ErrorMessageProps, 'component' | 'children' | 'render'>) => {
  const { formatMessage } = useIntl();
  return (
    <FormikErrorMessage name={name} className={className}>
      {(error: any) => <Error>{typeof error === 'string' ? error : formatMessage(error)}</Error>}
    </FormikErrorMessage>
  );
};

export const Input: FC<FormikRequiredProps & DomaFieldFormikProps<ComponentProps<typeof DomaInput>>> = ({
  name,
  label,
  className,
  componentClassName,
  required,
  limit,
  disabled,
  style,
  hideError,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    if (rest.type === 'number') {
      helpers.setValue(!Number.isNaN(+input) && input.length === String(+input).length ? +input : input);
    } else {
      helpers.setValue(input);
    }
  };
  return (
    <DomaField
      name={name}
      label={label}
      className={className}
      componentClassName={componentClassName}
      required={required}
      limit={limit}
      disabled={disabled}
      style={style}
      value={field.value}
      error={meta.error}
      touched={meta.touched}
      hideError={hideError}
    >
      <DomaInput {...field} {...rest} name={name} value={field.value ?? ''} onChange={handleChange} />
    </DomaField>
  );
};

export const Textarea: FC<FormikRequiredProps & DomaFieldFormikProps<ComponentProps<typeof DomaTextarea>>> = ({
  name,
  label,
  className,
  componentClassName,
  required,
  limit,
  disabled,
  style,
  hideError,
  ...rest
}) => {
  const [field, meta] = useField(name);
  return (
    <DomaField
      name={name}
      label={label}
      className={className}
      componentClassName={componentClassName}
      required={required}
      limit={limit}
      disabled={disabled}
      style={style}
      value={field.value}
      error={meta.error}
      touched={meta.touched}
      hideError={hideError}
    >
      <DomaTextarea {...field} {...rest} name={name} value={field.value || ''} />
    </DomaField>
  );
};

export const Select = <IsMulti extends boolean, OnlyValue extends boolean, Option extends OptionTypeBase>({
  isMulti,
  onlyValue,
  options,
  ...props
}: FormikRequiredProps &
  Omit<DomaFieldFormikProps<DomaSelectProps<IsMulti, OnlyValue, Option>>, 'onChange' | 'value'>) => {
  const [field, meta, helpers] = useField<SelectSelectedValue<IsMulti, OnlyValue, Option>>(props.name);
  const handleChange = (option: SelectSelectedValue<IsMulti, OnlyValue, Option>) => {
    helpers.setValue(option);
    setTimeout(() => helpers.setTouched(true));
  };

  return (
    <DomaField {...props} error={meta.error} touched={meta.touched}>
      <DomaSelect
        {...props}
        onBlur={() => helpers.setTouched(true)}
        isMulti={isMulti}
        options={options}
        value={field.value}
        onlyValue={onlyValue}
        onChange={handleChange}
      />
    </DomaField>
  );
};

export const Checkbox: FC<
  FormikRequiredProps & DomaInlineFieldFormikProps<Omit<ComponentProps<typeof DomaCheckbox>, 'checked' | 'onChange'>>
> = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const handleChange = () => {
    helpers.setValue(!field.value);
  };
  const handleBlur = () => {
    helpers.setTouched(true);
  };
  return (
    <DomaInlineField
      {...props}
      {...field}
      onClick={handleChange}
      error={meta.error}
      touched={meta.touched}
      onBlur={handleBlur}
    >
      <DomaCheckbox checked={!!field.value} onChange={handleChange} />
    </DomaInlineField>
  );
};

export const RadioGroup: FC<
  FormikRequiredProps & DomaInlineFieldFormikProps<Omit<ComponentProps<typeof DomaRadioGroup>, 'value' | 'onChange'>>
> = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const handleBlur = () => {
    helpers.setTouched(true);
  };
  return (
    <DomaRadioGroup
      {...field}
      {...props}
      error={meta.error}
      touched={meta.touched}
      onChange={helpers.setValue}
      onBlur={handleBlur}
    />
  );
};

export const DatePicker: FC<
  FormikRequiredProps & Omit<DomaFieldFormikProps<ComponentProps<typeof DomaDatePicker>>, 'value' | 'onChange'>
> = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const handleBlur = () => {
    helpers.setTouched(true);
  };
  return (
    <DomaField {...field} {...props} error={meta.error} touched={meta.touched}>
      <DomaDatePicker value={field.value} {...props} onChange={helpers.setValue} onBlur={handleBlur} />
    </DomaField>
  );
};

export const DateRangePicker: FC<
  FormikRequiredProps & Omit<DomaFieldFormikProps<ComponentProps<typeof DomaDatePicker>>, 'value' | 'onChange'>
> = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const handleBlur = () => {
    helpers.setTouched(true);
  };
  return (
    <DomaField {...field} {...props} error={meta.error} touched={meta.touched}>
      <DomaDateRangePicker value={field.value} onChange={helpers.setValue} onBlur={handleBlur} {...props} />
    </DomaField>
  );
};

export const TimePicker: FC<
  FormikRequiredProps & Omit<DomaFieldFormikProps<ComponentProps<typeof DomaTimePicker>>, 'value' | 'onChange'>
> = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const handleBlur = () => {
    helpers.setTouched(true);
  };
  return (
    <DomaField {...field} {...props} error={meta.error} touched={meta.touched}>
      <DomaTimePicker value={field.value} {...props} onChange={helpers.setValue} onBlur={handleBlur} />
    </DomaField>
  );
};
