import get from 'lodash/get';
import { CrudMappings } from 'types/PrivilegeMappings';
import { Crud, Privileges } from 'types/privileges';

type NoCrud<T> = Omit<T, 'c' | 'r' | 'u' | 'd'>;

function privilegePathCheck<
  K1 extends keyof NoCrud<Privileges>,
  K2 extends keyof NoCrud<Privileges[K1]>,
  K3 extends keyof NoCrud<Privileges[K1][K2]>,
  K4 extends keyof NoCrud<Privileges[K1][K2][K3]>,
  K5 extends keyof NoCrud<Privileges[K1][K2][K3][K4]>,
>(path: [K1, K2, K3, K4, K5]): [K1, K2, K3, K4, K5];

function privilegePathCheck<
  K1 extends keyof NoCrud<Privileges>,
  K2 extends keyof NoCrud<Privileges[K1]>,
  K3 extends keyof NoCrud<Privileges[K1][K2]>,
  K4 extends keyof NoCrud<Privileges[K1][K2][K3]>,
>(path: [K1, K2, K3, K4]): [K1, K2, K3, K4];
function privilegePathCheck<
  K1 extends keyof NoCrud<Privileges>,
  K2 extends keyof NoCrud<Privileges[K1]>,
  K3 extends keyof NoCrud<Privileges[K1][K2]>,
>(path: [K1, K2, K3]): [K1, K2, K3];
function privilegePathCheck<K1 extends keyof NoCrud<Privileges>, K2 extends keyof NoCrud<Privileges[K1]>>(
  path: [K1, K2],
): [K1, K2];
function privilegePathCheck<K1 extends keyof NoCrud<Privileges>>(path: [K1]): [K1];
/**
 * just typesafe fuse ;)
 * NOTE: If you use more than 2 keys, you need to add overload for this function
 * example: privilegePathCheck<'K1', 'K2', 'K3'>(['K1', 'K2', 'K3'])
 * @param path
 */
function privilegePathCheck(path: string | string[]) {
  return path;
}

function havePrivilege<
  K1 extends keyof NoCrud<Privileges>,
  K2 extends keyof NoCrud<Privileges[K1]>,
  K3 extends keyof NoCrud<Privileges[K1][K2]>,
  K4 extends keyof NoCrud<Privileges[K1][K2][K3]>,
>(path: [K1, K2, K3, K4], cruds: CrudMappings[], access: Privileges): boolean;
function havePrivilege<
  K1 extends keyof NoCrud<Privileges>,
  K2 extends keyof NoCrud<Privileges[K1]>,
  K3 extends keyof NoCrud<Privileges[K1][K2]>,
>(path: [K1, K2, K3], cruds: CrudMappings[], access: Privileges): boolean;
function havePrivilege<K1 extends keyof NoCrud<Privileges>, K2 extends keyof NoCrud<Privileges[K1]>>(
  path: [K1, K2],
  cruds: CrudMappings[],
  access: Privileges,
): boolean;
function havePrivilege<K1 extends keyof NoCrud<Privileges>>(
  path: [K1],
  cruds: CrudMappings[],
  access: Privileges,
): boolean;

/*
 * Takes a privilege path and checks against cruds that access is allowed
 * TODO: take privileges directly from redux store to remove one parameter, and write test
 * */
function havePrivilege(path: string[], cruds: CrudMappings[], access: Privileges): boolean {
  const found: Crud | undefined = get(access, path);

  if (!found) return false;
  return cruds.every((crud) => found[crud]);
}

export { havePrivilege, privilegePathCheck };
