import moment, { Moment, MomentInput } from 'moment/moment';

import { DateRangeType } from 'common/form/pickers/DateRangePicker';

import {
  DATABASE_DATE_FORMAT,
  DATABASE_TIME_FORMAT,
  DAY,
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  getDurationBetween,
  isAfter,
  isBefore,
  reformatDateTime,
} from 'utils/dateUtil';

export const fromTimeToMoment = (time: MomentInput, strict?: boolean): Moment =>
  moment(time, [DISPLAY_TIME_FORMAT, DATABASE_TIME_FORMAT], strict);

export const fromDateToMoment = (date: MomentInput, strict?: boolean): Moment =>
  moment(date, [DISPLAY_DATE_FORMAT, DATABASE_DATE_FORMAT], strict);

export const formatDisplayTimeToDBTime = (time: string): string =>
  reformatDateTime(time, DISPLAY_TIME_FORMAT, DATABASE_TIME_FORMAT);

export const formatDisplayDateToDBDate = (time: string): string =>
  reformatDateTime(time, DISPLAY_DATE_FORMAT, DATABASE_DATE_FORMAT);

type Day = {
  day: number;
  weekday: number;
  today: boolean;
  date: Date;
  selected: boolean;
  out?: true;
};

export const isInRange = (
  date: Date | string,
  minDate: string | undefined,
  maxDate: string | undefined,
  granularity: Parameters<typeof isBefore>[2],
) => {
  if (minDate) {
    const isBeforeDate = isBefore(date, minDate, granularity);
    if (isBeforeDate) {
      return false;
    }
  }
  if (maxDate) {
    const isAfterDate = isAfter(date, maxDate, granularity);
    if (isAfterDate) {
      return false;
    }
  }

  return true;
};

export const datesAreInRange = (startDate: Date | string, endDate: Date | string, allowedDaysInRange: number) =>
  getDurationBetween(fromDateToMoment(startDate), fromDateToMoment(endDate), 'days') <= allowedDaysInRange;

export const createDay = (year: number, month: number, date: number, today: Date, selected: Date, out?: true): Day => {
  const wd = new Date(year, month, date);
  return {
    day: wd.getDate(),
    weekday: wd.getDay(),
    today: wd.toDateString() === today.toDateString(),
    selected: wd.toDateString() === selected.toDateString(),
    out,
    date: wd,
  };
};

export const getDaysInMonth = (month: number, year: number, selected: Date) => {
  const days: Day[] = [];
  const today = new Date();

  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);
  const daysCount = endDate.getDate();

  // Adjust first day to a Monday-started week (0 = Monday, 6 = Sunday)
  let firstDayInMonth = startDate.getDay();
  firstDayInMonth = firstDayInMonth === 0 ? 6 : firstDayInMonth - 1;

  // Number of leading days from the previous month to start on Monday
  const leadingDaysCount = firstDayInMonth;

  // Total number of days generated so far (including leading and current month)
  const totalDaysGenerated = leadingDaysCount + daysCount;

  // Number of trailing days from the next month to make exactly 35 days in total
  const trailingDaysCount = 35 - totalDaysGenerated;

  // Generate leading days from the previous month
  for (let d = -leadingDaysCount; d < 0; d += 1) {
    const previousMonthDate = new Date(year, month, d + 1);
    days.push(
      createDay(
        previousMonthDate.getFullYear(),
        previousMonthDate.getMonth(),
        previousMonthDate.getDate(),
        today,
        selected,
        true,
      ),
    );
  }

  // Generate days in the current month
  for (let d = 1; d <= daysCount; d += 1) {
    days.push(createDay(year, month, d, today, selected));
  }

  // Generate trailing days from the next month to complete exactly 35 days
  for (let d = 1; d <= trailingDaysCount; d += 1) {
    const nextMonthDate = new Date(year, month + 1, d);
    days.push(
      createDay(nextMonthDate.getFullYear(), nextMonthDate.getMonth(), nextMonthDate.getDate(), today, selected, true),
    );
  }

  // Ensure exactly 35 days are returned
  return days.slice(0, 35);
};

export const dateRangeSelected = (dateRange: DateRangeType) => !!(dateRange.startDate && dateRange.endDate);

export const checkIfDateIsBefore = (dateToCheck: string, dateToCompare: string) =>
  isBefore(fromDateToMoment(dateToCheck).toDate(), fromDateToMoment(dateToCompare).toDate(), DAY);
