const CLIENT_SESSION_TOKEN = 'clientSessionToken';

export const setClientSessionToken = (token: string) => {
  localStorage.setItem(CLIENT_SESSION_TOKEN, token);
};

export const getClientSessionToken = () => localStorage.getItem(CLIENT_SESSION_TOKEN);

export const removeClientSessionToken = () => localStorage.removeItem(CLIENT_SESSION_TOKEN);

export const isClientSessionTokenStale = (token: string | null) => token !== null && token !== getClientSessionToken();
