/**
 * This file is used to create a BroadcastChannel instance and export it.
 * It can communicate between browser tabs, windows, and iframes.
 */
import { getWebViewDomaChannel } from 'utils/webViewUtil';
import { handleCatch } from 'utils/errors';

export enum CHANNEL_ACTION {
  LOGIN = 'login',
  LOGOUT = 'logout',
}

/*
 * This is here for the DC1 webview. Since the JavaFX webview cannot use and HAS no use for broadcast channel, we will
 * mock the broadcast channel for the webview. This is to prevent the webview from throwing an error when it tries to
 * use the broadcast channel, crashing the DC1 app
 *
 * Mobile webview could use broadcast channel, but it's no use there so we will mock it for mobile as well
 * */
class MockBroadcastChannel {
  name: string;

  constructor(name: string) {
    this.name = name;
  }

  postMessage(_message: never) {
    // Mock implementation: do nothing
  }

  close() {
    // Mock implementation: do nothing
  }

  addEventListener(_type: string, _listener: EventListenerOrEventListenerObject) {
    // Mock implementation: do nothing
  }

  removeEventListener(_type: string, _listener: EventListenerOrEventListenerObject) {
    // Mock implementation: do nothing
  }

  dispatchEvent(_event: Event): boolean {
    // Mock implementation: always return true
    return true;
  }
}

const createBroadcastChannel = () => {
  const webViewDomaChannel = getWebViewDomaChannel();
  if (webViewDomaChannel) {
    return new MockBroadcastChannel('login-session');
  }
  try {
    return new BroadcastChannel('login-session');
  } catch (e) {
    // DEBUG FOR RC
    console.error('BroadcastChannel is not supported in this browser', webViewDomaChannel);
    handleCatch(e);
    return new MockBroadcastChannel('login-session');
  }
};

const loginSessionChannel = createBroadcastChannel() as BroadcastChannel;

export const loginChannelAction = () => {
  loginSessionChannel.postMessage({ type: CHANNEL_ACTION.LOGIN });
};

export const logoutChannelAction = () => {
  loginSessionChannel.postMessage({ type: CHANNEL_ACTION.LOGOUT });
};

export default loginSessionChannel;
