import { createAction, createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import { getUserAPI } from 'services/user';
import { pingAtostekCardLogin } from 'services/atostek';
import { logout } from 'services/authentication/logout';

import { CurrentUserEnum, LoginAuth } from 'types/AuthRequired';
import { State } from 'types/State';

import { generateIdentifierHash } from 'utils/hash';
import { setClientSessionToken, getClientSessionToken, removeClientSessionToken } from 'utils/localStorage';
import { loginChannelAction, logoutChannelAction } from 'utils/channels/LoginSessionChannel';

import { RESET_STORE } from 'store/constants';

export type ClientSessionTokenParams = {
  userId: string;
  uniqueId: string;
};

export const AUTH_REDUCER_NAME = 'auth';

export const pingAtostekApiThunk = createAsyncThunk(`${AUTH_REDUCER_NAME}/pingAtostekApi`, async (_a, { dispatch }) => {
  const response = await pingAtostekCardLogin();
  // check if the response is a success or failure
  if (response.data?.ErrorCode !== 0) {
    dispatch({ type: RESET_STORE });
    await logout();
    throw new Error('Ping failed, logging out');
  }
  return response.data;
});

export const checkLoginStatusThunk = createAsyncThunk(`${AUTH_REDUCER_NAME}/checkLoginStatus`, async () => {
  const response = await getUserAPI();

  return response.data;
});

const GENERATE_CLIENT_SESSION_TOKEN = `${AUTH_REDUCER_NAME}/generateClientSessionToken`;
const REMOVE_CLIENT_SESSION_TOKEN = `${AUTH_REDUCER_NAME}/removeClientSessionToken`;

export const generateClientSessionTokenThunk = createAsyncThunk(
  GENERATE_CLIENT_SESSION_TOKEN,
  async ({ userId, uniqueId }: ClientSessionTokenParams) => generateIdentifierHash(userId, uniqueId),
);

export const removeClientSessionTokenThunk = createAction(REMOVE_CLIENT_SESSION_TOKEN);
export const showLoginModal = createAction(`${AUTH_REDUCER_NAME}/showLoginModal`);
export const hideLoginModal = createAction(`${AUTH_REDUCER_NAME}/hideLoginModal`);

export type AuthState = {
  userInfo: LoginAuth;
  state: State;
  error: null | SerializedError;
  loggedIn: boolean;
  previousState: null | string;
  userType: CurrentUserEnum;
  currentDB: null | string;
  clientSessionToken: null | string;
  showLoginModal: boolean;
};

const initialState = {
  userInfo: {},
  state: State.NOT_STARTED,
  loggedIn: false,
  previousState: null,
  currentDB: null,
  clientSessionToken: null,
  showLoginModal: false,
} as AuthState;

const slice = createSlice({
  name: AUTH_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkLoginStatusThunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(checkLoginStatusThunk.fulfilled, (draft, action) => {
      draft.state = State.SUCCESS;
      draft.error = null;
      draft.userInfo = action.payload;
      draft.loggedIn = action.payload.loginState.loggedIn;
      draft.userType = action.payload.loginState.userType as CurrentUserEnum;
      draft.currentDB = action.payload.currentDb;
      // if page is refreshed, but there is no clientSessionToken in the state, but there is one in the localStorage, it means that the session is not stale
      const token = getClientSessionToken();
      if (draft.clientSessionToken === null && token) {
        draft.clientSessionToken = token;
      }
    });
    builder.addCase(checkLoginStatusThunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.loggedIn = false;
      draft.error = action.error;
      console.log('cannot get login state');
      console.log(action.error);
    });
    builder.addCase(generateClientSessionTokenThunk.fulfilled, (draft, action) => {
      const { payload } = action;
      draft.clientSessionToken = payload;
      setClientSessionToken(payload);
      loginChannelAction();
      draft.showLoginModal = false;
    });
    builder.addCase(removeClientSessionTokenThunk, (draft) => {
      draft.clientSessionToken = null;
      removeClientSessionToken();
      logoutChannelAction();
    });
    builder.addCase(showLoginModal, (draft) => {
      draft.showLoginModal = true;
    });
    builder.addCase(hideLoginModal, (draft) => {
      draft.showLoginModal = false;
    });
    // case for pingAtostekApiThunk
    builder.addCase(pingAtostekApiThunk.pending, (draft) => {
      draft.error = null;
    });
    builder.addCase(pingAtostekApiThunk.fulfilled, (draft) => {
      draft.state = State.SUCCESS;
      draft.error = null;
    });
    builder.addCase(pingAtostekApiThunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
    });
  },
});

// export const {} = slice.actions;

export default slice.reducer;
