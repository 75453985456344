import React from 'react';
import cn from 'classnames';
import styles from './HeaderLabel.scss';

type Props = {
  label: React.ReactNode;
  className?: string;
};

const HeaderLabel: React.FC<Props> = ({ label, className, children }) => (
  <h3 className={cn(styles.root, className)}>
    {children ? (
      <>
        <div>{label}</div>
        {children}
      </>
    ) : (
      label
    )}
  </h3>
);

export default HeaderLabel;
