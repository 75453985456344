import { FC, ReactText } from 'react';

import styles from 'common/Errors/BaseError.scss';

type Props = {
  errorImg: string;
  title: ReactText;
};

/**
 * Base Wrapper for Errors
 * @param errorImg img from `assets/images/error-pages`
 * @param title
 * @param children
 */
const BaseError: FC<Props> = ({ errorImg, title, children }) => (
  <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <div className={styles.content}>
      <img src={errorImg} className="mw-100" width={150} />
      <div className={styles.title}>{title}</div>
      <div>{children}</div>
    </div>
  </div>
);

export default BaseError;
