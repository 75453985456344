import { ReactText, useEffect, useRef } from 'react';
import cn from 'classnames';
import Spinner from 'common/Spinner/Spinner';
import styles from './OverlaySpinner.scss';

type Props = {
  width?: number;
  height?: number;
  visible?: boolean;
  text?: ReactText;
  className?: string;
};

const OverlaySpinner = ({ width, height, visible = true, text, className }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.style.opacity = visible ? '1' : '0';
    }
  }, [visible]);
  return (
    <div className={cn(styles.root, className, { [styles.visible]: visible })} ref={ref}>
      <Spinner width={width} height={height} isPaused={!visible} />
      {text && <div className={styles.text}>{text}</div>}
    </div>
  );
};

export default OverlaySpinner;
