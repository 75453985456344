/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import cn from 'classnames';

import styles from 'common/form/plain/Input.scss';

type Props = {
  inputId?: string;
  error?: React.ReactNode;
} & React.InputHTMLAttributes<HTMLInputElement>;

/**
 * Just a plain Input with DomaCare colors
 */
const Input = React.forwardRef<HTMLInputElement, Props>(
  ({ className, inputId, error, type = 'text', ...props }, ref) => (
    <input
      className={cn(styles.root, className, {
        [styles.error]: !!error,
      })}
      id={inputId}
      type={type}
      {...props}
      ref={ref}
    />
  ),
);

Input.displayName = 'PlainInput';

export default Input;
