/* eslint-disable react/prop-types */
import React from 'react';
import cn from 'classnames';
import { ICON_SIZE_XS, ICON_SIZE_XXS } from 'common/icons/constants';
import CloseIcon from 'common/icons/CloseIcon';

import styles from './Badge.scss';

type Props = {
  onClick?: () => void;
  onClear?: () => void;
  className?: string;
  backgroundColor?: string;
  color?: string;
  active?: boolean;
  small?: boolean;
};

const Badge: React.FC<Props> = ({ children, active, onClick, onClear, className, backgroundColor, color, small }) => {
  let style: Partial<React.CSSProperties> | undefined;
  if (backgroundColor || color) {
    style = {};
    if (backgroundColor) style.backgroundColor = backgroundColor;
    if (color) style.color = color;
  }

  return (
    <span
      onClick={onClick}
      className={cn(
        styles.root,
        className,
        { [styles.active]: active || onClear },
        { [styles.clickable]: onClick },
        { [styles.small]: small },
      )}
      style={style}
    >
      <span>{children}</span>
      {onClear ? (
        <span className={styles.clear} onClick={onClear}>
          <CloseIcon size={small ? ICON_SIZE_XXS : ICON_SIZE_XS} />
        </span>
      ) : null}
    </span>
  );
};

export default Badge;
