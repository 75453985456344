import { CSSProperties, useCallback, useEffect, useState } from 'react';

type Props = {
  folder: string;
  imgName: string;
  className?: string;
  alt?: string;
  style?: CSSProperties;
  type?: 'svg' | 'png';
};

const DynamicImage = ({ folder, imgName, className, alt, style, type = 'svg' }: Props) => {
  const [image, setImage] = useState(undefined);
  const loadImage = useCallback(
    () =>
      import(`assets/images/${folder}/${imgName}.${type}`)
        .then((img) => {
          setImage(img.default);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {}),
    [folder, imgName],
  );

  useEffect(() => {
    loadImage();
  }, [imgName, loadImage]);

  return <img src={image} style={style} alt={alt} className={className} />;
};

export default DynamicImage;
