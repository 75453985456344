import api from 'utils/api';
import { LoginAuth } from 'types/AuthRequired';
import { UpdateUserParams } from 'types/params/UpdateUserParams';
import { User } from 'types/User';
import { UpdatedUser } from 'types/UpdatedUser';

export type UserParams = {
  includePrimaryUnit: boolean;
  includeSecondaryUnits: boolean;
  includePayrollStatuses: boolean;
  includeTimeTabs: boolean;
  includeOffers: boolean;
};

export const getUserAPI = () => api.get<LoginAuth>(`user`);

export const getSingleUser = (userId: number, params?: Partial<UserParams>) =>
  api.get<User>(`users/${userId}`, { params });

export const updateUser = (id: number, params: Partial<UpdateUserParams>) =>
  api.patch<UpdatedUser>(`users/${id}/`, params);
