import React from 'react';
import { ICON_SIZE } from 'common/icons/constants';
import { IconProps } from 'common/icons/IconProps';

const ArrowLeftIcon: React.FC<IconProps> = ({ size = ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24" width={size}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
  </svg>
);

export default ArrowLeftIcon;
