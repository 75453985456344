import { useIdleTimer } from 'react-idle-timer';
import { useRef } from 'react';
import { IIdleTimer } from 'react-idle-timer/dist/types/IIdleTimer';
import useModal from 'hooks/useModal';
import SessionTimeoutModal from 'App/modals/SessionTimeoutModal';
// import useUser from 'hooks/useUser';
// import { getUserAutolockTimeout } from 'services/users';
// import useApi from 'hooks/useApi';
// import { SESSION_DEFAULT_TIMEOUT, SESSION_PROMPT_TIMEOUT } from 'App/constants';

// timeout with auto lock setting will be implemented later

const useSessionTimeout = () => {
  const idleRef = useRef<IIdleTimer | null>(null);
  // const user = useUser();
  const [showSessionTimeoutModal] = useModal(SessionTimeoutModal);
  // const { data, fetch } = useApi(getUserAutolockTimeout);
  // const timeout = useMemo(
  //   () => (data?.settingEntities?.[0]?.value || SESSION_DEFAULT_TIMEOUT) - SESSION_PROMPT_TIMEOUT,
  //   [data],
  // );
  const timeout = 3000000;

  const onIdle = () => {
    showSessionTimeoutModal(idleRef.current as IIdleTimer);
  };

  idleRef.current = useIdleTimer({
    timeout,
    onIdle,
  });

  // useEffect(() => {
  //   fetch(user.id);
  // }, []);

  // useEffect(() => {
  //   if (data) {
  //     instance.start();
  //   } else {
  //     instance.pause();
  //   }
  // }, [data]);
};

export default useSessionTimeout;
