import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce, useInterval } from 'usehooks-ts';
import { useState } from 'react';

import { logout } from 'services/authentication/logout';

import { CurrentUserEnum } from 'types/AuthRequired';
import { Features } from 'types/Features';

import { featureEnabled } from 'utils/features';
import { logoutChannelAction } from 'utils/channels/LoginSessionChannel';

import { selectAuthRequiredDomain } from 'store/auth/selectors';
import { AppDispatch } from 'store';
import { pingAtostekApiThunk, showLoginModal } from 'store/auth/slice';

const PING = 5000;
const IDLE_INTERVAL = 500;

const useDVVCardPingTimer = () => {
  const { userInfo } = useSelector(selectAuthRequiredDomain());
  const dispatch = useDispatch<AppDispatch>();
  const [timeIdle, setTimeIdle] = useState(0);

  // 10 min
  let autolockTimeout = 600000;

  if (userInfo?.currentDomacareUser?.autolockTimeout) {
    // the autolockTimeout is in seconds, so we convert it to milliseconds
    autolockTimeout = userInfo.currentDomacareUser.autolockTimeout * 1000;
  }

  useInterval(
    async () => {
      if (timeIdle > autolockTimeout) {
        await logout();
        logoutChannelAction();
        dispatch(showLoginModal());
      } else {
        setTimeIdle(timeIdle + IDLE_INTERVAL);
      }
    },
    userInfo.loginState?.userType === CurrentUserEnum.DVV_CARD_USER_PRINCIPAL &&
      featureEnabled(Features.DVVSmartCardLogin)
      ? IDLE_INTERVAL
      : null,
  );

  useEffectOnce(() => {
    const handleKeyDown = () => setTimeIdle(0);
    const handleKeyUp = () => setTimeIdle(0);
    const setIdle = () => setTimeIdle(0);

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    // even listener for mouse movement
    document.addEventListener('mousemove', setIdle);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('mousemove', setIdle);
    };
  });

  useInterval(
    () => {
      dispatch(pingAtostekApiThunk());
    },
    userInfo.loginState?.userType === CurrentUserEnum.DVV_CARD_USER_PRINCIPAL &&
      featureEnabled(Features.DVVSmartCardLogin)
      ? PING
      : null,
  );
};

export default useDVVCardPingTimer;
