import React from 'react';
import styles from 'Login/components/LoginWrapper/LoginWrapperStyles.scss';
import SplashScreen from 'shared/SplashScreen';

const LoginWrapper: React.FC = (props) => {
  const { children } = props;

  return (
    <SplashScreen>
      <div className={styles.login}>
        <div className={styles.login__logo} />
        {children}
      </div>
    </SplashScreen>
  );
};

export default LoginWrapper;
