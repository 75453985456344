import { Privileges } from 'types/privileges';
import { SRPResponse } from 'types/Authentication';

import { taskRedirect } from 'utils/routeUtil';
import SRPClient from 'utils/thinbus-srp/thinbus-srp6client-sha256';

import { LoginCreds } from 'Login/components/Login/Login';

function homePageSwitcher(features: Privileges) {
  const { TASK_LIST, CUSTOMERS } = features;
  const configFeatures = process.env.CONFIG.features;

  if (TASK_LIST.r && configFeatures.tasks) {
    return taskRedirect();
  }
  if (CUSTOMERS.r && configFeatures.customers) {
    return 'customers/';
  }
  return 'home/';
}

export default homePageSwitcher;

export const getSRPCreds = (srpParams: SRPResponse, formValues: LoginCreds): { A: string; M1: string } => {
  const { bstr, kstr, gstr, sstr, nstr } = srpParams;

  const srpcli = new SRPClient({
    N_base10: nstr,
    g_base10: gstr,
    k_base16: kstr,
  });
  (srpcli as any).step1(formValues.userName, formValues.password);
  return (srpcli as any).step2(sstr, bstr);
};
