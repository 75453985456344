import api from 'utils/api';

import { AllowedCategoryPrivileges, DutiesCategory } from 'Duties/types';

export const postCategoryAPI = (payload: Partial<DutiesCategory>) =>
  api.post<DutiesCategory>(`dutycategories`, payload);

export const getCategoriesAPI = () => api.get<DutiesCategory[]>(`dutycategories`);

export const editCategoryAPI = (categoryId: number, payload: Partial<DutiesCategory>) =>
  api.patch<DutiesCategory>(`dutycategories/${categoryId}`, payload);

export const updateCategoryLinkAPI = (dutyId: number, categoryId: number, payload: { categoryId: number }) =>
  api.patch<DutiesCategory>(`duties/${dutyId}/categories/${categoryId}`, payload);

export const getCategoriesPrivilegesAPI = () =>
  api.get<AllowedCategoryPrivileges>(`dutycategories/allowed_privilege_classes`);
