import { AlternativeRootNode, AlternativeSymbolNode, ReportArchiveType, ReportPayload } from 'types/CustomerReport';
import { Customer } from 'types/Customer';

import api from 'utils/api';

import { ReportType } from 'sharedComponents/ReportDisplay/types';

export type ReportsParams = {
  limit: number;
  from?: string;
  to?: string;
  search?: string;
  after?: number;
  symbol_ids?: string;
  reportArchiveTypes?: ReportArchiveType[];
};

export type ReportsPrintParams = Pick<ReportsParams, 'from' | 'to' | 'search' | 'symbol_ids'>;

export type ReportFilters = {
  searchKeys: string;
  startDate: string | null;
  endDate: string | null;
  symbolsId: number[];
  include?: ReportArchiveType[];
  sources?: Set<ReportArchiveType>; // is turned to reportArchiveType in the utils
};

/* Basics */
export const getReports = (customerId: number, params: ReportsParams) =>
  api.get<ReportType[]>(`customers/${customerId}/v2/reports`, {
    params,
  });

export const postReport = (customerId: number, payload: ReportPayload) =>
  api.post(`/customers/${customerId}/v2/reports/`, payload);

export const editReport = (customerId: number, reportId: number, payload: ReportPayload) =>
  api.post(`/customers/${customerId}/v2/reports/${reportId}`, payload);

export const postReportVersion = (customerId: number, reportId: number, payload: ReportPayload) =>
  api.post(`/customers/${customerId}/reports/${reportId}/versions`, payload);

export const draftReport = (reportId: number, payload: ReportPayload) =>
  api.post(`/reports/${reportId}/versions/`, payload);

export const deleteReport = (customerId: number, reportID: number, deleteReason: string) =>
  api.delete(`/customers/${customerId}/v2/reports/${reportID}`, { params: { deleteReason } });

/* A little more special ones */

export const deleteReportReason = () => api.get(`/customers/v2/reports/delete_reasons`);

export const getAllowedCustomerList = () => api.get<Customer[]>(`/reports/customers`);

// no return type
export const postReportToMultipleCustomer = (customerIds: number[], payload: ReportPayload) =>
  api.post(`/customers/v2/reports/batch`, payload, {
    params: {
      customerIds,
    },
  });

type SymbolApiParams = {
  customerId?: number;
  rootId?: number;
  reportArchiveType?: ReportArchiveType | null;
};

export const printReports = (customerId: number, params: ReportsPrintParams) =>
  api.getPdf(`customers/${customerId}/v2/reports/print`, {
    params: { ...params, limit: 1000 },
  });

/* Symbol related APIs */
export const getSymbols = ({ customerId, rootId, reportArchiveType }: SymbolApiParams = {}) =>
  api.get<AlternativeRootNode>(`/symbolhierarchy/alternative`, {
    params: { customerId, rootId, fetchAll: false, reportArchiveType },
  });

export const getSymbolChildren = ({ customerId, rootId, reportArchiveType }: SymbolApiParams = {}) =>
  api.get<AlternativeSymbolNode>(`/symbolhierarchy/alternative`, {
    params: { customerId, rootId, fetchAll: true, reportArchiveType },
  });

export const getArchives = (customerId: number) => api.get<ReportArchiveType[]>(`/customers/${customerId}/archives`);
