import { defineMessages } from 'react-intl';

export default defineMessages({
  showReminder: {
    id: 'app.Calendar.showReminder',
    defaultMessage: 'Show event reminder on the front page',
  },
  showEvents: {
    id: 'app.Calendar.showEvents',
    defaultMessage: 'Show events',
  },
  addEvent: {
    id: 'app.Calendar.addEvent',
    defaultMessage: 'Add event',
  },
  editEvent: {
    id: 'app.Calendar.editEvent',
    defaultMessage: 'Edit event',
  },
  linkedTo: {
    id: 'app.Calendar.linkedTo',
    defaultMessage: 'Linked to',
  },
  assignTo: {
    id: 'app.Calendar.assignTo',
    defaultMessage: 'Assign to',
  },
  delegatedToUser: {
    id: 'app.Calendar.delegatedToUser',
    defaultMessage: 'Delegated to user',
  },
  pickOneOfUnitTeam: {
    id: 'app.Calendar.pickOneOfUnitTeam',
    defaultMessage: 'Pick one of unit or team',
  },
  personal: {
    id: 'app.Calendar.personal',
    defaultMessage: 'Is personal to the user',
  },
  pickOneOfUnitTeamPersonal: {
    id: 'app.Calendar.pickOneOfUnitTeamPersonal',
    defaultMessage: 'Pick one of unit, team or is personal',
  },
  mandatoryCalendarVisibility: {
    id: 'app.Calendar.errors.mandatoryCalendarVisibility',
    defaultMessage: 'Calendar visibility is mandatory',
  },
  oneFilterRequired: {
    id: 'app.Calendar.errors.oneFilterRequired',
    defaultMessage: 'At least one filter should be selected',
  },
  pastEventInfo: {
    id: 'app.Calendar.errors.pastEventInfo',
    defaultMessage: 'This option is not available for the past events',
  },
  eventDone: {
    id: 'app.Calendar.eventDone',
    defaultMessage: 'Event done.',
  },
  allDay: {
    id: 'app.Calendar.allDay',
    defaultMessage: 'All day',
  },
  selectSymbol: {
    id: 'app.Calendar.calendarEvent.selectSymbol',
    defaultMessage: 'Select Symbol',
  },
  selectedSymbol: {
    id: 'app.Calendar.calendarEvent.selectedSymbol',
    defaultMessage: 'Selected Symbol',
  },
});
