import { ErrorCode } from 'Booking/bookingErrorCodes';
import messages from 'nonRouteFeatures/RAIv2/messages';

export const raiErrorCodes: ErrorCode = {
  RAI_INSTRUMENT_NOT_FOUND: messages.instrumentNotFound,
  RAI_DEFINITION_NOT_FOUND: messages.raiDefinitionNotFound,
  RAI_LANGUAGE_MISSING: messages.raiLanguageMissing,
  RAI_INSTRUMENT_NULL: messages.raiInstrumentNull,
  RAI_UNSUPPORTED_RAI_INSTRUMENT: messages.raiUnsupportedInstrument,
  RAI_VERSION_ID_MISSING: messages.raiVersionIdMissing,
  RAI_VERSION_ID_NEGATIVE: messages.raiVersionIdNegative,
  RAI_VERSION_ID_ZERO: messages.raiVersionIdZero,
  RAI_ASSESSMENT_ID_NULL: messages.raiAssessmentIdNull,
  RAI_ASSESSMENT_ID_ZERO: messages.raiAssessmentIdZero,
  RAI_ASSESSMENT_ID_NEGATIVE: messages.raiAssessmentIdNegative,
  RAI_ASSESSMENT_NOT_FOUND: messages.raiAssessmentNotFound,
  RAI_ASSESSMENT_IS_LOCKED: messages.raiAssessmentIsLocked,
  RAI_ASSESSMENT_IS_ARCHIVED: messages.raiAssessmentIsArchived,
  RAI_ASSESSMENT_IS_ALREADY_LOCKED: messages.raiAssessmentIsAlreadyLocked,
  RAI_ASSESSMENT_IS_ALREADY_UNLOCKED: messages.raiAssessmentIsAlreadyUnlocked,
  RAI_ASSESSMENT_IS_ALREADY_ARCHIVED: messages.raiAssessmentIsAlreadyArchived,
  RAI_ASSESSMENT_UNLOCK_PERIOD_HAS_PASSED: messages.raiAssessmentUnlockPeriodHasPassed,
  RAI_ASSESSMENT_IS_NOT_LOCKED: messages.raiAssessmentIsNotLocked,
  RAI_ASSESSMENT_IS_NOT_ARCHIVED: messages.raiAssessmentIsNotArchived,
  RAI_ASSESSMENT_ONGOING_ASSESSMENT: messages.raiAssessmentOngoingAssessment,
  RAI_ASSESSMENT_UNLOCKED_ARCHIVED_ASSESSMENT: messages.raiAssessmentUnlockedArchivedAssessment,
  RAI_CUSTOMER_ID_MISSING: messages.raiCustomerIdMissing,
  RAI_CUSTOMER_ID_ZERO: messages.raiCustomerIdZero,
  RAI_CUSTOMER_ID_NEGATIVE: messages.raiCustomerIdNegative,
  RAI_CUSTOMER_NOT_FOUND: messages.raiCustomerNotFound,
  RAI_ANSWER_NULL: messages.raiAnswerNull,
  RAI_ANSWER_QUESTION_ID_NULL: messages.raiAnswerQuestionIdNull,
  RAI_ANSWER_CONTAINS_BOTH_VALUE_AND_ROWS: messages.raiAnswerContainsBothValueAndRows,
  RAI_QUESTION_NOT_IN_DEFINITION: messages.raiQuestionNotInDefinition,
  RAI_NO_ANSWER_CHOICES_FOUND: messages.raiNoAnswerChoicesFound,
  RAI_UNSUPPORTED_QUESTION: messages.raiUnsupportedQuestion,
  RAI_ANSWER_INVALID_FOR_QUESTION: messages.raiAnswerInvalidForQuestion,
  RAI_DEFINITION_VERSION_NOT_FOUND: messages.raiDefinitionVersionNotFound,
  RAI_DEFINITION_VERSION_ID_MISSING: messages.raiDefinitionVersionIdMissing,
  RAI_ANSWER_TABLE_MISSING_COLUMN_DEFINITIONS: messages.raiAnswerTableMissingColumnDefinitions,
  RAI_ANSWER_INVALID_I_CODE: messages.raiAnswerInvalidICode,
  RAI_ASSESSMENT_NOT_FULLY_FILLED: messages.raiAssessmentNotFullyFilled,
  RAI_TABLE_INVALID_ROW_ID: messages.raiTableInvalidRowId,
  RAI_FINAL_OWN_GOALS_CANNOT_DELETE_INITIAL_GOAL: messages.raiFinalOwnGoalsCannotDeleteInitialGoal,
  RAI_FINAL_OWN_GOALS_CHOSEN_VALUE_INVALID: messages.raiFinalOwnGoalsChosenValueInvalid,
  RAI_OWN_GOALS_EVALUATION_NO_PREVIOUS_ARCHIVED_ASSESSMENT: messages.raiOwnGoalsEvaluationNoPreviousArchivedAssessment,
  RAI_OWN_GOALS_EVALUATION_MISSING_ROW_IDS: messages.raiOwnGoalsEvaluationMissingRowIds,
  RAI_OWN_GOALS_EVALUATION_ROW_IDS_DO_NOT_MATCH: messages.raiOwnGoalsEvaluationRowIdsDoNotMatch,
  RAI_MEDICATION_TABLE_MISSING_ROW_ID_IN_REQUEST: messages.raiMedicationTableMissingRowIdInRequest,
  RAI_MEDICATION_TABLE_MEDICATION_NOT_FOUND: messages.raiMedicationTableMedicationNotFound,
  RAI_MEDICATION_TABLE_COLUMNS_MISSING_I_CODES: messages.raiMedicationTableColumnsMissingICodes,
  RAI_MEDICATION_TABLE_UNSUPPORTED_I_CODE: messages.raiMedicationTableUnsupportedICode,
  RAI_MEDICATION_TABLE_DOSAGE_UNIT_INVALID_VALUE: messages.raiMedicationTableDosageUnitInvalidValue,
  RAI_MEDICATION_TABLE_DOSAGE_AMOUNT_INVALID_VALUE: messages.raiMedicationTableDosageAmountInvalidValue,
  RAI_MEDICATION_TABLE_DOSAGE_AMOUNT_NOT_DOUBLE: messages.raiMedicationTableDosageAmountNotDouble,
  RAI_MEDICATION_TABLE_TAKEN_INVALID_VALUE: messages.raiMedicationTableTakenInvalidValue,
  RAI_MEDICATION_TABLE_ROUTE_INVALID_VALUE: messages.raiMedicationTableRouteInvalidValue,
  RAI_MEDICATION_TABLE_TAKE_INTERVAL_INVALID_VALUE: messages.raiMedicationTableTakeIntervalInvalidValue,
  RAI_MEDICATION_TABLE_WHEN_NEEDED_INVALID_VALUE: messages.raiMedicationTableWhenNeededInvalidValue,
  RAI_PARTICIPANTS_TABLE_COLUMNS_MISSING_I_CODES: messages.raiParticipantsTableColumnsMissingICodes,
  RAI_PARTICIPANTS_TABLE_UNSUPPORTED_I_CODE: messages.raiParticipantsTableUnsupportedICode,
  RAI_PARTICIPANTS_TABLE_RELATION_TO_ORGANIZATION_INVALID_VALUE:
    messages.raiParticipantsTableRelationToOrganizationInvalidValue,
  RAI_PARTICIPANTS_TABLE_PROFESSIONAL_TITLE_INVALID_VALUE: messages.raiParticipantsTableProfessionalTitleInvalidValue,
  RAI_AUTOMATION_CONFIGURATION_MISSING: messages.raiAutomationConfigurationMissing,
  RAI_AUTOMATION_CONFIGURATION_TABLE_AUTOMATIONS_MISSING: messages.raiAutomationConfigurationTableAutomationsMissing,
  RAI_AUTOMATION_CONFIGURATION_TABLE_AUTOMATION_DOES_NOT_EXIST_FOR_GIVEN_QUESTION_ID:
    messages.raiAutomationConfigurationTableAutomationDoesNotExistForGivenQuestionId,
  RAI_AUTOMATION_CONFIGURATION_TABLE_AUTOMATION_UNSUPPORTED_UPDATE:
    messages.raiAutomationConfigurationTableAutomationUnsupportedUpdate,
  RAI_COMMON_AUTOMATION_NOT_FOUND_IN_DEFINITION: messages.raiCommonAutomationNotFoundInDefinition,
  RAI_SKIP_AUTOMATION_MISSING_CONDITION: messages.raiSkipAutomationMissingCondition,
  RAI_SKIP_AUTOMATION_MISSING_AFFECTED_QUESTIONS: messages.raiSkipAutomationMissingAffectedQuestions,
  RAI_SKIP_AUTOMATION_MISSING_NEGATION: messages.raiSkipAutomationMissingNegation,
  RAI_SKIP_AUTOMATION_MISSING_CONDITION_TYPE: messages.raiSkipAutomationMissingConditionType,
  RAI_SKIP_AUTOMATION_UNSUPPORTED_CONDITION_TYPE: messages.raiSkipAutomationUnsupportedConditionType,
  RAI_SKIP_AUTOMATION_CONDITION_MISSING_QUESTION_ID: messages.raiSkipAutomationConditionMissingQuestionId,
  RAI_SKIP_AUTOMATION_AFFECTED_QUESTION_MISSING_QUESTION_ID:
    messages.raiSkipAutomationAffectedQuestionMissingQuestionId,
  RAI_EVALUATION_REASON_AUTOMATION_MISSING_CONDITION: messages.raiEvaluationReasonAutomationMissingCondition,
  RAI_EVALUATION_REASON_AUTOMATION_CONDITION_MISSING_QUESTION_ID:
    messages.raiEvaluationReasonAutomationConditionMissingQuestionId,
  RAI_EVALUATION_REASON_AUTOMATION_CONDITION_MISSING_TRIGGER_VALUES:
    messages.raiEvaluationReasonAutomationConditionMissingTriggerValues,

  // RAI measurements
  RAI_MEASUREMENT_SUITE_IDENTIFIER_MISSING: messages.raiMeasurementSuiteIdentifierMissing,
  RAI_MEASUREMENT_MULTIPLE_SUITE_IDENTIFIER: messages.raiMeasurementMultipleSuiteIdentifier,
  RAI_MEASUREMENT_SUITE_IDENTIFIER_EMPTY: messages.raiMeasurementSuiteIdentifierEmpty,
  RAI_MEASUREMENT_METADATA_PARSING_FAILED: messages.raiMeasurementMetadataParsingFailed,
  RAI_MEASUREMENT_IDENTIFIER_NOT_FOUND: messages.raiMeasurementIdentifierNotFound,
  RAI_MEASUREMENT_FAILED: messages.raiMeasurementFailed,
  RAI_MEASUREMENT_IDENTIFIERS_MISSING_FROM_INSTRUMENT: messages.raiMeasurementIdentifiersMissingFromInstrument,
  RAI_MEASUREMENT_ALL_CHOICES_MISSING: messages.raiMeasurementAllChoicesMissing,
  RAI_MEASUREMENT_ANSWER_SET_NOT_FOUND: messages.raiMeasurementAnswerSetNotFound,
  RAI_MEASUREMENT_CALCULATION_NOT_FOUND: messages.raiMeasurementCalculationNotFound,
  RAI_NOTES_PAYLOAD_NULL: messages.raiNotesPayloadNull,
  RAI_NOTES_NOTE_WAS_BLANK: messages.raiNotesNoteWasBlank,
  RAI_NOTES_NOTE_TOO_LONG: messages.raiNotesNoteTooLong,
  RAI_NOTE_NOT_FOUND_WITH_ID: messages.raiNoteNotFoundWithId,
  RAI_NOTES_PAYLOAD_MISSING_RAI_VERSION: messages.raiNotesPayloadMissingRaiVersion,
  RAI_NOTES_PAYLOAD_MISSING_QUESTION_CODE: messages.raiNotesPayloadMissingQuestionCode,
};
