import React from 'react';
import cn from 'classnames';
import styles from 'common/form/Error.scss';

type Props = {
  className?: string;
  htmlFor?: string;
  testId?: string;
};

const Error: React.FC<Props> = ({ children, htmlFor, className, testId }) => (
  <div className={cn(styles.error, className)} data-testid={testId} id={htmlFor}>
    {children}
  </div>
);

export default Error;
