export enum TravelMode {
  PUBLIC,
  CAR,
  BICYCLE,
}

export enum TravelModeString {
  PUBLIC = 'PUBLIC',
  CAR = 'CAR',
  BICYCLE = 'BICYCLE',
}
