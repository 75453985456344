import React from 'react';
import { ICON_SIZE } from 'common/icons/constants';
import { IconProps } from 'common/icons/IconProps';

const TaskIcon: React.FC<IconProps> = ({ size = ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60" height={size} width={size}>
    <g>
      <path
        d="M16.5,30.6c0-0.8,0.7-1.5,1.5-1.5h4.4v-7.9c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5v9.3
c0,0.8-0.4,1.6-1.2,1.6H18C17.2,32.1,16.5,31.5,16.5,30.6z"
      />
      <g>
        <path
          d="M21.6,45.9c0.3-0.6,0.6-1.2,1-1.7C14.2,43.5,7.7,36.5,7.7,28c0-9,7.3-16.2,16.2-16.2c5.6,0,10.6,2.9,13.5,7.3
c0,0,0.1,0,0.1,0c1.7-0.2,3.1-0.2,4.5,0.1C38.8,12.5,32,7.9,24.1,7.9C13,7.9,4,16.9,4,28c0,10,7.3,18.3,16.9,19.8
C21,47.1,21.3,46.5,21.6,45.9z"
        />
        <path d="M26.2,48C26.1,48,26.1,48,26.2,48C26.1,48,26.1,48,26.2,48z" />
      </g>
      <path
        d="M56,52.1c-11,0-21.9,0-32.9,0c0-1,0-1.1,0-1.6c0.1-1.3,0.4-2.7,0.9-4c0.6-1.3,1.5-2.3,2.7-3
c1.9-1.1,3.9-1.7,5.9-2.4c0.8-0.3,1.5-0.6,2.3-0.9c0.7-0.3,1.2-1.3,1.1-2c0-0.1-0.1-0.2-0.1-0.3c-1.6-2.3-2.5-4.8-2.9-7.6
c-0.2-1.5-0.3-3,0-4.5c0.5-2.6,2.1-4.2,4.7-4.5c1.2-0.2,2.4-0.2,3.7,0.1c2.6,0.5,4.1,2.1,4.5,4.8c0.4,3-0.2,5.8-1.2,8.6
c-0.4,1-1,1.9-1.5,2.9c-0.1,0.2-0.1,0.3-0.1,0.5c0,1.1,0.5,2,1.6,2.4c1.6,0.5,3.2,1.1,4.8,1.7c1,0.4,2,0.9,2.9,1.4
c1.7,0.8,2.6,2.3,3.1,4c0.4,1.3,0.5,2.7,0.5,4C56,51.7,56,52.1,56,52.1z"
      />
    </g>
  </svg>
);

export default TaskIcon;
