import moment, { Moment, MomentInput, unitOfTime } from 'moment';

export { moment };

// ---------------------------------
// OLD UTIL FUNCTIONS

/**
 * @deprecated
 */
export const onlyDateFormat = 'D';
const databaseTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
const clockTime = 'HH:mm'; // The clock format for moment object
const hourFormat = 'HH';
const minFormat = 'mm';
const dayTime = 'YYYY-MM-DD'; // The day tome format for moment object
const displayDateFormat = 'DD.MM.YYYY';
const displayTimeDate = 'DD.MM.YYYY HH:mm';
const days = 'ddd';

/**
 * @deprecated
 */
export const timeDateWithDay = (date: MomentInput) => moment(date).format(`${days} ${displayTimeDate}`);

/**
 * @deprecated
 */
export const getDate = (date?: MomentInput) => moment(date);

/**
 * @deprecated
 */
export const startTime = () => moment().format(clockTime);

/**
 * @deprecated
 */
export const startDate = () => moment().format(dayTime);

/**
 * @deprecated
 */
export const getFormatedDate = (date: MomentInput) => moment(date).format(dayTime);

/**
 * @deprecated
 */
export const getFormatedTime = (date: MomentInput) => moment(date).format(clockTime);

/**
 * @deprecated
 */
export const getFormattedHours = (date: MomentInput) => moment(date).format(hourFormat);

/**
 * @deprecated
 */
export const getFormattedMinutes = (date: MomentInput) => moment(date).format(minFormat);

/**
 * @deprecated
 */
export const getDateOnly = (date?: MomentInput) => moment(date).format(onlyDateFormat);

/**
 * @description returns a future date string. Defaults to tomorrow. Provide 2nd param to specify range
 * @deprecated
 */
export const getFutureDate = (date: MomentInput, shift = 1) => getDateOnly(addDays(getDate(date), shift));

/**
 * @deprecated
 */
export const getTimeForDatabase = () => moment().format(databaseTimeFormat);

/**
 * @deprecated
 */
export const checkIfDateIsAfter = (date: MomentInput) => moment(date).isAfter(startDate());

/**
 * @deprecated
 */
export const getLocalizedDate = (date: MomentInput, local: string) =>
  local === 'fi' ? moment(date).format(displayDateFormat) : date;

/**
 * @deprecated
 */
export const checkIfDateIsBetween = (date: MomentInput) => {
  const compareDate = moment(date);
  const localStartDate = moment();
  const endDate = moment(localStartDate).subtract(15, 'days');
  return compareDate.isBetween(endDate, localStartDate);
};

/**
 * @deprecated
 */
export const convertTimeForDatabase = (date: MomentInput) => moment(date).format(databaseTimeFormat);

/**
 * @deprecated
 */
export const urlDate = (date: MomentInput) => moment(date).format(dayTime);

/**
 * @deprecated
 */
export const addDays = (date: MomentInput, amount: number) => moment(date, dayTime).add(amount, 'days');

/**
 * @deprecated
 */
export const subtractDays = (date: MomentInput, amount: number) => moment(date, dayTime).subtract(amount, 'days');

/**
 * @deprecated
 */
export const displayDate = (date: MomentInput) => {
  const momentDate = moment(date);
  return momentDate.isValid() ? moment(date).format(displayDateFormat) : null;
};

/**
 * @deprecated
 */
export const currentDisplayDate = () => moment().format(displayDateFormat);

/**
 * @deprecated
 */
export const getDisplayTimeDate = (date: MomentInput) => moment(date).format(displayTimeDate);

/**
 * @deprecated
 */
export const getTimeDuration = (plannedStartTimeArg: MomentInput, plannedEndTimeArg?: MomentInput) => {
  const plannedStartTime = moment(plannedStartTimeArg);
  const plannedEndTime = moment(plannedEndTimeArg);
  return plannedEndTime.diff(plannedStartTime, 'minutes');
};

/**
 * @deprecated
 */
export const getformatedTimeDuration = (plannedStartTime: MomentInput, plannedEndTime: MomentInput, locale: string) => {
  const duration = getTimeDuration(plannedStartTime, plannedEndTime);
  const diffDuration = moment.duration(duration, 'minutes');
  const localDays = diffDuration.days();
  const hours = diffDuration.hours();
  const minutes = diffDuration.minutes();
  const dayLocale = locale === 'fi' ? 'päivä' : 'day';
  const daysLocale = locale === 'fi' ? 'päivää' : 'days';
  const hourLocale = locale === 'fi' ? 'tunti' : 'hour';
  const hoursLocale = locale === 'fi' ? 'tuntia' : 'hours';
  const minutesLocale = locale === 'fi' ? 'minuuttia' : 'minutes';
  const totalDays = localDays === 0 ? '' : localDays > 1 ? `${localDays} ${daysLocale}` : `${localDays} ${dayLocale}`;
  const totalHours = hours === 0 ? '' : hours > 1 ? `${hours} ${hoursLocale}` : `${hours} ${hourLocale}`;
  const totalMinutes =
    minutes === 0 && hours === 0 ? `0  ${minutesLocale}` : minutes > 1 ? `${minutes}  ${minutesLocale}` : ``;
  return `${totalDays} ${totalHours} ${totalMinutes}`;
};

/**
 * @deprecated
 */
export const priceFormat = (price: number) => {
  if (price === 0) {
    return '0.00';
  }
  if (price) {
    return price.toFixed(2);
  }
  return '-';
};

/**
 * @deprecated
 */
export const subtractFromDate = (date: MomentInput, amount: number, unit: unitOfTime.DurationConstructor) =>
  moment(date).subtract(amount, unit);

/**
 * @deprecated
 */
export const addToDate = (date: MomentInput, amount: number, unit: unitOfTime.DurationConstructor) =>
  moment(date).add(amount, unit);

/**
 * @deprecated
 */
export const addToDateGerenal = (
  amount: number,
  unit: unitOfTime.DurationConstructor,
  format: string,
  date: MomentInput,
) => {
  let selectedFormat;
  switch (format) {
    case 'hoursFormat':
      selectedFormat = clockTime;
      break;
    case 'dayTime':
      selectedFormat = dayTime;
      break;
    case 'displayDate':
      selectedFormat = displayDateFormat;
      break;

    default:
      selectedFormat = format || databaseTimeFormat;
  }

  if (date) {
    return moment(date).add(amount, unit).format(selectedFormat);
  }

  return moment().add(amount, unit).format(selectedFormat);
};

/**
 * @deprecated
 */
export const convertTwelveToTwentyFourFormate = (time: MomentInput) => moment(time, 'h:mm').format('HH:mm');

/**
 * @deprecated
 */
// for changing the english days to finnish
export const dateDayFinnishFormatter = (date: MomentInput, locale: string) => {
  const formattedDate = timeDateWithDay(date);
  if (locale === 'fi') {
    const split: string[] = formattedDate.split(' ');
    const finnishDays: Record<string, string> = {
      Mon: 'Ma',
      Tue: 'Ti',
      Wed: 'Ke',
      Thu: 'To',
      Fri: 'Pe',
      Sat: 'La',
      Sun: 'Su',
    };
    const newDay = finnishDays[split[0]];
    return `${newDay} ${split[1]} ${split[2]}`;
  }
  return formattedDate;
};

/**
 * used in booking repetition.
 */
export const weekOfYear = (dateMoment: Moment) => {
  if (dateMoment.month() === 11 && dateMoment.week() === 1) {
    return 53;
  }
  return dateMoment.week();
};

// -------------------------
// NEW UTIL FUNCTIONS

export const DATABASE_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATABASE_DATE_FORMAT = 'YYYY-MM-DD';
export const DATABASE_TIME_FORMAT = 'HH:mm:ss';
export const REVERSE_DATABASE_DATE_FORMAT = 'DD-MM-YYYY';

export const DISPLAY_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DISPLAY_DATE_FORMAT = 'DD.MM.YYYY';
export const DISPLAY_TIME_FORMAT = 'HH:mm';

// April 2021
export const DISPLAY_MONTH_YEAR = 'MMMM YYYY';
export const DISPLAY_MONTH = 'MMMM';

export const ONLY_DAY_FORMAT = 'D';
export const ONLY_HOUR_FORMAT = 'HH';
export const ONLY_HOUR_FULL_FORMAT = 'HH:00:00';
export const ONLY_MINUTE_FORMAT = 'mm';
export const ONLY_YEAR_FORMAT = 'YYYY';
export const ONLY_MONTH_FORMAT = 'MM';

export const MILLI_SECONDS = 'milliseconds';
export const SECONDS = 'seconds';
export const MINUTES = 'minutes';
export const HOURS = 'hours';
export const DAYS = 'days';
export const DAY = 'day';
export const MONTH = 'month';
export const YEAR = 'year';

export const DAY_START_TIME = '00:00:00';
export const DAY_END_TIME = '23:59:59';

export const EXTRANET_DATE_FORMAT = 'ddd DD.MM.YYYY HH:mm';
export const EXTRANET_SINGLE_CONVERSATION_DATE_FORMAT = 'DD.MM HH:mm';

export const DAY_DATE_FORMAT = 'dddd DD.MM.YYYY';
export const SHORT_DAY_DATE_FORMAT = 'dd DD.MM';
export const SHORT_DATE_FORMAT = 'DD.MM';
// ---------------------------------
// STRING GETTERS FOR NOW AND TODAY
export const getCurrentTime = (): string => moment().format(DISPLAY_TIME_FORMAT);
export const getCurrentDate = (): string => moment().format(DISPLAY_DATE_FORMAT);
export const getCurrentDateTime = (): string => moment().format(DISPLAY_DATE_TIME_FORMAT);

export const getCurrentTimeInDbFormat = (): string => moment().format(DATABASE_TIME_FORMAT);
export const getCurrentDateInDbFormat = (): string => moment().format(DATABASE_DATE_FORMAT);
export const getCurrentDateInReverseDbFormat = (): string => moment().format(REVERSE_DATABASE_DATE_FORMAT);
export const getCurrentDateTimeInDbFormat = (): string => moment().format(DATABASE_DATE_TIME_FORMAT);
export const getCurrentHour = (num: number) => moment().hours(num);
export const getStartOfWeek = () => createMoment().startOf('isoWeek');

// ---------------------------------
// FORMATTERS
export const formatDateToDisplayMonthYear = (date: Date | string) => moment(date).format(DISPLAY_MONTH_YEAR);
export const formatDbDateToShortDateFormat = (date: string): string =>
  moment(date, DATABASE_DATE_FORMAT).format(SHORT_DATE_FORMAT);

export const formatDateTimeToISO = (date: string, time: string) => `${date}T${time}`;
export const formatDateToFormat = (date: MomentInput, format: string): string => moment(date).format(format);
export const formatDateToDbFormat = (date: MomentInput): string => moment(date).format(DATABASE_DATE_FORMAT);
export const formatDateToDisplayFormat = (date: MomentInput): string => moment(date).format(DISPLAY_DATE_FORMAT);

export const formatTimeToDbFormat = (time: MomentInput): string => moment(time).format(DATABASE_TIME_FORMAT);
export const formatTimeToDisplayFormat = (time: MomentInput): string => moment(time).format(DISPLAY_TIME_FORMAT);

export const formatDateToDbDateTimeFormat = (date: MomentInput): string =>
  moment(date).format(DATABASE_DATE_TIME_FORMAT);
export const formatDateToDisplayDateTimeFormat = (date: MomentInput): string =>
  moment(date).format(DISPLAY_DATE_TIME_FORMAT);

export const formatDateToOnlyHours = (date: MomentInput): string => moment(date).format(ONLY_HOUR_FORMAT);
export const formatDateToOnlyHoursFull = (date: MomentInput): string => moment(date).format(ONLY_HOUR_FULL_FORMAT);
export const formatDateToOnlyMinutes = (date: MomentInput): string => moment(date).format(ONLY_MINUTE_FORMAT);
export const formatDateToOnlyDate = (date: MomentInput): string => moment(date).format(ONLY_DAY_FORMAT);

// ---------------------------------
// VALIDATORS
export const isValidTime = (time: string, strict = true) => moment(time, DISPLAY_TIME_FORMAT, strict).isValid();
export const isValidDate = (date: string, strict = true) => moment(date, DISPLAY_DATE_FORMAT, strict).isValid();
export const isValidDateTime = (date: string) => moment(date, DISPLAY_DATE_TIME_FORMAT, true).isValid();
export const isValidDateFormat = (date: string, format: string, strict = true) =>
  moment(date, format, strict).isValid();
// ---------------------------------
// CONSTRUCTORS
export const createMoment = (date?: MomentInput, format?: string): Moment => moment(date, format);

// IN DB FORMAT
export const createMomentInDbDateTimeFormat = (date: MomentInput): Moment => moment(date, DATABASE_DATE_TIME_FORMAT);
export const createMomentInDbDateFormat = (date: MomentInput): Moment => moment(date, DATABASE_DATE_FORMAT);
export const createMomentInDbTimeFormat = (date: MomentInput): Moment => moment(date, DATABASE_TIME_FORMAT);

// IN DISPLAY FORMAT
export const createMomentInDisplayDateTimeFormat = (date: MomentInput): Moment =>
  moment(date, DISPLAY_DATE_TIME_FORMAT);
export const createMomentInDisplayDateFormat = (date: MomentInput): Moment => moment(date, DISPLAY_DATE_FORMAT);
export const createMomentInDisplayTimeFormat = (date: MomentInput): Moment => moment(date, DISPLAY_TIME_FORMAT);

// ---------------------------------
// RELATIONS
export const isAfter = (
  date: MomentInput,
  afterDate: MomentInput,
  granularity?: Parameters<Moment['isAfter']>[1], // get isAfter parameters
) => moment(date).isAfter(afterDate, granularity);
export const isBefore = (
  date: MomentInput,
  beforeDate: MomentInput,
  granularity?: Parameters<Moment['isBefore']>[1], // get isBefore parameters
) => moment(date).isBefore(beforeDate, granularity);
export const isAfterToday = (date: MomentInput): boolean => moment(date).isAfter(getCurrentDateInDbFormat());
export const isBeforeToday = (date: MomentInput): boolean => moment(date).isBefore(getCurrentDateInDbFormat());
export const isDateSameAs = (
  a: MomentInput,
  b: MomentInput,
  granularity?: Parameters<Moment['isSame']>[1], // get isSame parameters
): boolean => createMoment(a).isSame(createMoment(b), granularity);
export const isDateBetween = (date: MomentInput, start: MomentInput, end: MomentInput): boolean =>
  createMoment(date).isBetween(createMoment(start), createMoment(end));

export const isDateSameOrBefore = (
  a: MomentInput,
  b: MomentInput,
  granularity?: Parameters<Moment['isSameOrBefore']>[1], // get isSameOrBefore parameters
): boolean => createMoment(a).isSameOrBefore(createMoment(b), granularity);

export const isDateSameOrAfter = (
  a: MomentInput,
  b: MomentInput,
  granularity?: Parameters<Moment['isSameOrAfter']>[1], // get isSameOrAfter parameters
): boolean => createMoment(a).isSameOrAfter(createMoment(b), granularity);

// ---------------------------------
// DURATION
export const getDurationBetween = (
  start: MomentInput,
  end: MomentInput,
  unit: unitOfTime.DurationConstructor = MINUTES,
): number => createMoment(end).diff(createMoment(start), unit);

export const getDurationMomentObject = (date: number, unit: unitOfTime.DurationConstructor) =>
  moment.duration(date, unit);

// ---------------------------------
// CALCULUS
export const addDaysToDate = (date: MomentInput, amount: number): Moment =>
  moment(date, DATABASE_DATE_FORMAT).add(amount, DAYS);
export const subtractDaysFromDate = (date: MomentInput, amount: number): Moment =>
  moment(date, DATABASE_DATE_FORMAT).subtract(amount, DAYS);

export const addTo = (date: MomentInput, amount: number, unit: unitOfTime.DurationConstructor): Moment =>
  moment(date).add(amount, unit);
export const subtractFrom = (date: MomentInput, amount: number, unit: unitOfTime.DurationConstructor): Moment =>
  moment(date).subtract(amount, unit);

export const getNextPossibleDay = (date: string): string => {
  const latestStartDate = createMomentInDbDateFormat(date).startOf(DAY);
  const today = createMoment(new Date()).startOf(DAY);
  return formatDateToDbFormat(latestStartDate < today ? today : addDaysToDate(latestStartDate, 1));
};

// ---------------------------------
// MISC
export const convertTwelveToTwentyFourFormat = (time: MomentInput): string =>
  moment(time, 'h:mm').format(DISPLAY_TIME_FORMAT);
export const reformatDateTime = (dateTime: MomentInput, fromFormat: string, toFormat: string): string =>
  moment(dateTime, fromFormat).format(toFormat);
export const getWeekDays = (localeDependent = true) => moment.weekdays(localeDependent);
export const getWeekDaysShort = (localeDependent = true) => moment.weekdaysShort(localeDependent);

// UNIX
export const getDiffInMS = (start: MomentInput, end: moment.MomentInput): number =>
  moment(end).diff(moment(start), MILLI_SECONDS);

export const getStartDateTimeOfDay = (date?: MomentInput) =>
  moment(date).startOf(DAY).format(DATABASE_DATE_TIME_FORMAT);
export const getEndDateTimeOfDay = (date?: MomentInput) => moment(date).endOf(DAY).format(DATABASE_DATE_TIME_FORMAT);

export const getFirstDateOfMonth = (date: MomentInput) => moment(date).startOf(MONTH).format(DATABASE_DATE_FORMAT);
export const getFirstDateOfCurrentMonth = () => moment().startOf(MONTH).format(DATABASE_DATE_FORMAT);
export const getEndDateOfMonth = (date: MomentInput) => moment(date).endOf(MONTH).format(DATABASE_DATE_FORMAT);
export const getEndDateOfCurrentMonth = () => moment().endOf(MONTH).format(DATABASE_DATE_FORMAT);
export const getEndDateOfYear = (date: MomentInput) => moment(date).endOf(YEAR).format(DATABASE_DATE_FORMAT);

// Display month name
export const getMonthName = (date: MomentInput): string => moment(date).format(DISPLAY_MONTH);

// HELPERS (common combinations)
export const addToInDbFormat = (amount: number, unit: unitOfTime.DurationConstructor = 'year') =>
  formatDateToDbFormat(addTo(createMoment(), amount, unit));

export const subtractFromInDbFormat = (amount: number, unit: unitOfTime.DurationConstructor = 'year') =>
  formatDateToDbFormat(subtractFrom(createMoment(), amount, unit));

export const getDatesInRangeArray = (begDate: string, endDate: string) => {
  const dates: string[] = [];
  let currentDate = moment(begDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currentDate.isSameOrBefore(lastDate)) {
    dates.push(formatDateToDbFormat(currentDate));
    currentDate = currentDate.add(1, 'days');
  }

  return dates;
};

export const areDatesOverlapping = (dateTime: Moment, startDateTime: Moment, endDateTime: Moment) =>
  isDateSameAs(startDateTime, dateTime, 'date') ||
  isDateBetween(dateTime, startDateTime, endDateTime) ||
  isDateSameAs(endDateTime, dateTime, 'date');
