import React from 'react';
import useAppContext from 'hooks/useAppContext';
import { SIDE_NAV_STATE } from 'providers/AppProvider';
import SideNavContent from '../SideNavContent/SideNavContent';

type Props = {
  currentLocation: string;
};

const Sidenav: React.FC<Props> = ({ currentLocation }) => {
  const { isSmallScreen, openSideNav, onOpenSideNav } = useAppContext();

  if (isSmallScreen && openSideNav === SIDE_NAV_STATE.CLOSED) return null;
  return (
    <SideNavContent isSmallScreen={isSmallScreen} currentLocation={currentLocation} onOpenSideNav={onOpenSideNav} />
  );
};

export default Sidenav;
