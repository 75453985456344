import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CaptureContext } from '@sentry/types';
import { isAxiosError } from 'utils/api/utils';

/**
 * Handle Catch
 * @description we want to capture error silently from the user, but not from axios, for that error we have interceptor
 * @param err
 * @param context
 */
// eslint-disable-next-line import/prefer-default-export
export const handleCatch = (err: Error, context?: CaptureContext) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') console.error(err);
  if (isAxiosError(err)) return;
  Sentry.captureException(err, context);
};
