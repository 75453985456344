import React from 'react';
import { ICON_SIZE } from 'common/icons/constants';
import { IconProps } from 'common/icons/IconProps';

const BadgeIcon: React.FC<IconProps> = ({ size = ICON_SIZE }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height={size}
    viewBox="0 0 24 24"
    width={size}
  >
    <g>
      <rect fill="none" height="24" width="24" y="0" />
    </g>
    <g>
      <g>
        <rect height="1.5" width="4" x="14" y="12" />
        <rect height="1.5" width="4" x="14" y="15" />
        <path d="M20,7h-5V4c0-1.1-0.9-2-2-2h-2C9.9,2,9,2.9,9,4v3H4C2.9,7,2,7.9,2,9v11c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V9 C22,7.9,21.1,7,20,7z M11,7V4h2v3v2h-2V7z M20,20H4V9h5c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2h5V20z" />
        <circle cx="9" cy="13.5" r="1.5" />
        <path d="M11.08,16.18C10.44,15.9,9.74,15.75,9,15.75s-1.44,0.15-2.08,0.43C6.36,16.42,6,16.96,6,17.57V18h6v-0.43 C12,16.96,11.64,16.42,11.08,16.18z" />
      </g>
    </g>
  </svg>
);

export default BadgeIcon;
