import { MessageDescriptor } from 'react-intl';
import _compose from 'lodash/fp/compose';
import _omit from 'lodash/fp/omit';

import { FormikDomaErrors } from 'common/form/Formik';

import { Customer } from 'types/Customer';

import {
  createMoment,
  DATABASE_DATE_FORMAT,
  formatDateToDisplayDateTimeFormat,
  isBefore,
  isDateSameAs,
} from 'utils/dateUtil';
import { pickNonUndefinedValues } from 'utils/helperFunctions';

import TaskStatuses from 'Booking/enums/TaskStatuses';
import { AddEditTaskModalFields } from 'Booking/shared/AddEditTask/types/AddEditTaskModalFields';
import { BookingItem } from 'Booking/types/BookingItem';
import { EmployeeGroup } from 'Booking/types/Timeline/Groups/EmployeeGroup';
import { TASK_STATUS } from 'Booking/constants';
import messages from 'Booking/shared/AddEditTask/messages';
import ActionMode from 'Booking/enums/ActionMode';

export const getSelectedCustomer = (customerId: number | undefined, customers: Customer[]) =>
  customerId ? customers.find(({ id }) => id === customerId) : undefined;

export const buildTaskHeader = (task: BookingItem, formatMessage: (value: MessageDescriptor) => string) => {
  const headerInfo: string[] = [];

  if (task.customer && task.customer.lastName && task.customer.firstName) {
    headerInfo.push(`${task.customer.lastName}, ${task.customer.firstName}`);
  }

  if (task.plannedStartTime && task.plannedEndTime) {
    headerInfo.push(
      `${formatDateToDisplayDateTimeFormat(task.plannedStartTime)} - ${formatDateToDisplayDateTimeFormat(
        task.plannedEndTime,
      )}`,
    );
  }

  if (task.taskType?.name) headerInfo.push(`${task.taskType.name}`);

  if (task.repetitionId) headerInfo.push(`${formatMessage(messages.repetitionId)}: ${task.repetitionId}`);

  return `#${task.id} ${headerInfo.join(' | ')}`;
};

export const validate = (values: AddEditTaskModalFields) => {
  const errors: FormikDomaErrors<AddEditTaskModalFields> = {};
  if (isBefore(createMoment(values.plannedEndTime), createMoment(values.plannedStartTime))) {
    errors.plannedStartTime = messages.plannedStartTimeIsAfterPlannedEndTime;
    errors.plannedEndTime = messages.plannedStartTimeIsAfterPlannedEndTime;
  }
  if (!values.customer) errors.customer = messages.selectCustomer;
  // if (!values.taskType) errors.taskType = messages.selectTaskType;
  // if (
  //   values.earliestStartTime &&
  //   values.latestStartTime &&
  //   isDateSameAs(createMoment(values.earliestStartTime), createMoment(values.latestStartTime))
  // ) {
  //   errors.earliestStartTime = messages.sameTime;
  //   errors.latestStartTime = messages.sameTime;
  // }
  // if (
  //   values.earliestStartTime &&
  //   values.latestStartTime &&
  //   isAfter(createMoment(values.earliestStartTime), createMoment(values.latestStartTime))
  // ) {
  //   errors.earliestStartTime = messages.earliestStartTimeAfterLatestStart;
  //   errors.latestStartTime = messages.earliestStartTimeAfterLatestStart;
  // }
  // if (
  //   (values.earliestStartTime &&
  //     values.latestStartTime &&
  //     values.plannedStartTime &&
  //     isBefore(createMoment(values.latestStartTime), createMoment(values.plannedStartTime))) ||
  //   isAfter(createMoment(values.earliestStartTime), createMoment(values.plannedStartTime))
  // ) {
  //   errors.earliestStartTime = messages.plannedStartTimeNotBetweenEarliestAndLatest;
  //   errors.latestStartTime = messages.plannedStartTimeNotBetweenEarliestAndLatest;
  // }
  if (values.status !== TaskStatuses.UNCONFIRMED && !values.realizedStartTime)
    errors.realizedStartTime = messages.selectRealizedStartTime;
  if (values.status === TaskStatuses.FINISHED && !values.realizedEndTime)
    errors.realizedEndTime = messages.selectRealizedEndTime;
  return errors;
};

export const getCurrentWeekDayForSelectedWeekdays = () => {
  const date = new Date();
  const currentDate = date.getDay() || 7;
  return `dayOfWeek${currentDate}`;
};

export function formatDurationInDigitFormat(durationInMinutes: number): string {
  const hours = Math.floor(durationInMinutes / 60);
  const leftOver = durationInMinutes - hours * 60;

  if (hours === 0) {
    return durationInMinutes < 10 ? `00:0${durationInMinutes}` : `00:${durationInMinutes}`;
  }

  if (hours > 0 && hours < 10) {
    return leftOver < 10 ? `0${hours}:0${leftOver}` : `0${hours}:${leftOver}`;
  }

  return leftOver < 10 ? `${hours}:0${leftOver}` : `${hours}:${leftOver}`;
}

export const taskStatusColor = (status: string) => {
  if (status === TaskStatuses.FINISHED) return TASK_STATUS[TaskStatuses.FINISHED].color;
  if (status === TaskStatuses.UNCONFIRMED) return TASK_STATUS[TaskStatuses.UNCONFIRMED].color;
  if (status === TaskStatuses.CONFIRMED) return TASK_STATUS[TaskStatuses.CONFIRMED].color;
  return '#6E6E6E';
};

// export const processWorkData = async (taskDetail: AddEditTaskModalFields, workData: any) => {
//   const { plannedStartTime: taskStartTime, plannedEndTime: taskEndTime } = taskDetail;
//   const previousCustomer = null;
//   const nextCustomer = null;
//
//   // FIXME: This does not seem to affect anything?
/* if (workData.previousTask?.customerId) {
    previousCustomer = (await getCustomerById(workData.previousTask.customerId)).data;
  }

  if (workData.nextTask?.customerId) {
    nextCustomer = (await getCustomerById(workData.nextTask.customerId)).data;
  } */

//   return composeWorkData({
//     workData: {
//       ...workData,
//       nextTask: workData.nextTask ? { ...workData.nextTask, customer: nextCustomer } : null,
//       previousTask: workData.previousTask ? { ...workData.previousTask, customer: previousCustomer } : null,
//     },
//     taskStartTime,
//     taskEndTime,
//   });
// };

export const checkIfTaskIsInPayroll = (
  plannedStartTime: string,
  employeeId: number | null,
  employees: EmployeeGroup[],
) => {
  if (!employeeId) return false;
  const employee = employees.find((emp) => emp.id === employeeId);
  if (employee?.employeesPayrollStatus?.approvedUntil) {
    if (
      isBefore(
        plannedStartTime,
        createMoment(employee.employeesPayrollStatus.approvedUntil, DATABASE_DATE_FORMAT),
        'day',
      ) ||
      isDateSameAs(
        plannedStartTime,
        createMoment(employee.employeesPayrollStatus.approvedUntil, DATABASE_DATE_FORMAT),
        'day',
      )
    )
      return true;
  }
  return false;
};

export const pickOnlyEditableValuesInWorkShift = _compose(pickNonUndefinedValues, _omit(['repetition']));

export const handleTaskInPayrollMessage = (actionMode: ActionMode) => {
  if (ActionMode.CANCEL === actionMode) return messages.taskInPayrollCancelMessage;

  if (ActionMode.CREATE === actionMode) return messages.taskInPayrollCreateMessage;

  if (ActionMode.UPDATE === actionMode) return messages.taskInPayrollEditMessage;

  return messages.taskInPayrollDeleteMessage;
};
