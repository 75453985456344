import { RootState } from 'store';
import { PreferencesState, PREFERENCES_REDUCER_NAME } from './slice';

// selectors for preferences
export const selectPreferencesStateDomain =
  () =>
  (state: RootState): PreferencesState =>
    state[PREFERENCES_REDUCER_NAME];

export default selectPreferencesStateDomain;
