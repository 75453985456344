/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Options } from '@popperjs/core';
import cn from 'classnames';
import { usePopper } from 'react-popper';

import Portal from 'common/Portal';

import styles from './Tooltip.scss';

type Props = {
  tooltip: React.ReactNode;
  referenceElement?: React.RefObject<HTMLElement>;
  children?: React.ReactElement;
  className?: string;
  visible?: boolean;
  portal?: boolean;
  delay?: number;
  onClose?: () => void;
} & Partial<Options>;

const Tooltip: React.FC<Props> = ({
  visible: pVisible,
  referenceElement,
  className,
  tooltip,
  children,
  onClose,
  portal,
  delay = 0,
  ...options
}) => {
  const mouseoverRef = useRef(false);
  const refElement = useRef<Element | null>(null);
  const timeoutRef = useRef({
    mouseover: 0,
    timeout: 0,
  });
  const [isVisible, setIsVisible] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  const visible = pVisible !== undefined ? pVisible : isVisible;

  useEffect(() => {
    if (pVisible !== undefined) setIsVisible(pVisible);
  }, [pVisible]);

  useEffect(() => {
    if (referenceElement) refElement.current = referenceElement.current;
  }, [referenceElement]);

  const { styles: popperStyles, attributes } = usePopper(refElement.current, popperElement, {
    placement: 'top',
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'arrow', options: { element: arrowElement, padding: 10 } },
      ...(options.modifiers || []),
    ],
    ...options,
  });

  const changeOpacity = useCallback(
    (visibility: boolean) => {
      if (popperElement) popperElement.style.opacity = visibility ? '1' : '0';
    },
    [popperElement],
  );

  const showTooltip = useCallback(() => {
    if (mouseoverRef.current) return;
    mouseoverRef.current = true;
    if (timeoutRef.current.timeout) {
      changeOpacity(true);
      window.clearTimeout(timeoutRef.current.timeout);
      timeoutRef.current.timeout = 0;
    }
    timeoutRef.current.timeout = window.setTimeout(() => {
      setIsVisible(true);
    }, delay);
  }, [changeOpacity, delay]);

  const hideTooltip = useCallback(() => {
    mouseoverRef.current = false;
    changeOpacity(false);
    if (timeoutRef.current.timeout) {
      window.clearTimeout(timeoutRef.current.timeout);
      timeoutRef.current.timeout = 0;
    }
    timeoutRef.current.timeout = window.setTimeout(() => {
      setIsVisible(false);
      if (onClose) onClose();
    }, 250);
  }, [changeOpacity, onClose]);

  // Trigger: hover on tooltip, keep it open if hovered
  useEffect(() => {
    if (pVisible) return;
    if (refElement.current === null) return;

    refElement.current.addEventListener('mouseenter', showTooltip);
    refElement.current.addEventListener('mouseleave', hideTooltip);

    // eslint-disable-next-line consistent-return
    return () => {
      if (refElement.current === null) return;
      refElement.current.removeEventListener('mouseenter', showTooltip);
      refElement.current.removeEventListener('mouseleave', hideTooltip);
    };
  }, [showTooltip, hideTooltip, pVisible]);

  useEffect(() => {
    if (visible) {
      changeOpacity(true);
    }
  }, [changeOpacity, popperElement, visible]);

  const Element = portal ? Portal : Fragment;

  return (
    <>
      {visible ? (
        <Element>
          <div
            ref={setPopperElement}
            className={cn(styles.tooltip, className)}
            style={popperStyles.popper}
            role="tooltip"
            {...attributes.popper}
          >
            <div className={styles.arrow} ref={setArrowElement} style={popperStyles.arrow} {...attributes.arrow} />
            {tooltip}
          </div>
        </Element>
      ) : null}
      {children ? React.cloneElement(children, { ref: refElement }) : null}
    </>
  );
};

export default Tooltip;
