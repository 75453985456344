/* eslint-disable react/jsx-props-no-spreading */

import { OptionTypeBase, StylesConfig, Theme } from 'react-select';

import colors from 'assets/styles/variablesExported.scss';

export const domaTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: colors.grey2,
    primary: colors.primaryBlue,
    text: colors.white,
    neutral50: '#1A1A1A',
  },
});

export const customStyles = (transparentBg?: boolean): StylesConfig<OptionTypeBase, boolean> => ({
  container: (base) => ({ ...base, width: '100%' }),
  control: (base, state) => ({
    ...base,
    background: transparentBg ? 'transparent' : 'var(--dc-input-bg)',
    color: 'var(--dc-input-text)',
    borderRadius: '0',
    minHeight: 30,
    fontSize: '14px',
    boxShadow: 'none',
    cursor: 'pointer',
    outline: '2px solid transparent',
    transition: 'outline-color 250ms ease-out',
    borderColor: 'transparent',
    // eslint-disable-next-line no-nested-ternary
    outlineColor: `${state.selectProps.error ? colors.danger : state.isFocused ? colors.primaryBlue : 'transparent'}`,
    borderWidth: 0,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled ? colors.grey4 : 'var(--dc-input-text)',
    opacity: '1',
    padding: '0',
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    marginRight: '8px',
    ':hover': {
      color: colors.grey7,
    },
  }),
  valueContainer(base) {
    return {
      ...base,
      minHeight: 30,
      maxHeight: 300,
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    };
  },
  input(base) {
    return { ...base, padding: 0, margin: 0 };
  },
  clearIndicator(base) {
    return {
      ...base,
      padding: 0,
      color: colors.grey4,
      ':hover': {
        color: colors.grey7,
      },
    };
  },
  singleValue: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? 0.5 : 1,
    margin: 0,
    transition: 'opacity 300ms',
    color: 'var(--dc-input-text)',
  }),
  menu(base) {
    return {
      ...base,
      backgroundColor: 'var(--dc-input-bg)',
      color: 'var(--dc-input-text)',
      borderRadius: 0,
      margin: '4px 0',
    };
  },
  menuList(base) {
    return { ...base, padding: 0 };
  },
  multiValue: (base) => ({
    ...base,
    borderRadius: '20px',
    backgroundColor: colors.primaryBlue,
  }),
  multiValueLabel: (base) => ({ ...base, borderRadius: '20px', color: 'white', padding: '3px 10px' }),
  multiValueRemove: (base, state) =>
    state.data.isFixed
      ? { ...base, display: 'none' }
      : {
          ...base,
          backgroundColor: colors.primaryBlue,
          color: 'white',
          borderRadius: '20px',
          transition: 'background-color 250ms ease-in-out',
          ':hover': { backgroundColor: colors.primaryBlueHover, color: 'white' },
        },

  indicatorSeparator: () => ({ display: 'none' }),
  placeholder: (base, state) => ({
    ...base,
    padding: '0px',
    color: state.isDisabled ? colors.grey4 : 'var(--dc-input-placeholder)',
  }),
  option: (base, state) => {
    let bg = 'initial';

    if (state.isSelected) bg = colors.primaryBlue;
    if (state.isFocused) bg = colors.primaryBlueHover;
    if (state.isSelected && state.isFocused) bg = colors.primaryBlueHover;

    return {
      ...base,
      fontSize: '14px',
      padding: '4px 8px',
      backgroundColor: bg,
      color: state.isFocused || state.isSelected ? colors.white : colors.primaryTextColor,
      cursor: 'pointer',
      transition: 'color 250ms ease-in-out, background-color 250ms ease-in-out',
    };
  },
  menuPortal: (base) => ({ ...base, zIndex: 1050 }),
});
