import CustomerIcon from 'common/icons/CustomerIcon';
import RaiStatsIcon from 'common/icons/RaiStatsIcon';
import SettingsIcon from 'common/icons/SettingsIcon';
import LogoutIcon from 'common/icons/LogoutIcon';
import { ICON_SIZE_XXL } from 'common/icons/constants';
import BillingIcon from 'common/icons/BillingIcon';
import TaskIcon from 'common/icons/TaskIcon';
import AssignmentIcon from 'common/icons/AssignmentIcon';
import BusinessCenterIcon from 'common/icons/BusinessCenterIcon';
import ChatIcon from 'common/icons/ChatIcon';
import HomeIcon from 'common/icons/HomeIcon';
import EuroIcon from 'common/icons/EuroIcon';
import BadgeIcon from 'common/icons/BadgeIcon';
import CalendarIcon from 'common/icons/CalendarIcon';

import { Features } from 'types/Features';

import { privilegePathCheck } from 'utils/privileges';
import { getCurrentDateInDbFormat } from 'utils/dateUtil';

import messages from 'sharedComponents/Sidenav/messages';

import { NavItems } from '../Privileges/types';

const list: NavItems = [
  {
    key: 'frontPage',
    state: messages.frontPage,
    url: `/frontPage`,
    enabled: Features.FrontPage,
    icon: <HomeIcon size={ICON_SIZE_XXL} />,
    privilege: privilegePathCheck(['FRONT_PAGE']),
  },

  {
    key: 'tasks',
    state: messages.tasks,
    url: `/tasks/day/${getCurrentDateInDbFormat()}`,
    enabled: Features.Tasks,
    icon: <AssignmentIcon size={ICON_SIZE_XXL} />,
    privilege: privilegePathCheck(['TASK_LIST']),
  },

  {
    key: 'raiStats',
    state: messages.raiStats,
    url: '/raiStats',
    enabled: Features.RaiStats,
    icon: <RaiStatsIcon size={ICON_SIZE_XXL} />,
    privilege: privilegePathCheck(['STATISTICS']),
  },

  {
    key: 'booking',
    state: messages.booking,
    url: '/booking',
    enabled: Features.Booking,
    icon: <TaskIcon size={ICON_SIZE_XXL} />,
    privilege: privilegePathCheck(['BOOKING']),
    children: [
      {
        key: 'auction',
        state: messages.auction,
        url: '/booking/auction',
        enabled: Features.Booking,
        privilege: privilegePathCheck(['AUCTION']),
      },
    ],
  },

  {
    key: 'customers',
    state: messages.customers,
    url: '/customers',
    enabled: Features.Customers,
    icon: <CustomerIcon size={ICON_SIZE_XXL} />,
    privilege: privilegePathCheck(['CUSTOMERS']),
  },
  {
    key: 'users',
    state: messages.users,
    url: '/users',
    enabled: Features.Users,
    icon: <BadgeIcon size={ICON_SIZE_XXL} />,
    privilege: privilegePathCheck(['USERS']),
  },
  {
    key: 'calendar',
    state: messages.calendar,
    url: '/calendar',
    enabled: Features.Calendar,
    icon: <CalendarIcon size={ICON_SIZE_XXL} />,
    privilege: privilegePathCheck(['CALENDAR']),
  },

  {
    key: 'messages',
    state: messages.messages,
    url: '/messagecenter',
    enabled: Features.MessageCenter,
    icon: <ChatIcon size={ICON_SIZE_XXL} />,
    privilege: privilegePathCheck(['MESSAGES']),
  },
  {
    key: 'workPlanning',
    state: messages.workPlanning,
    url: '',
    icon: <BusinessCenterIcon size={ICON_SIZE_XXL} />,
    children: [
      {
        key: 'availabilities',
        state: messages.availabilities,
        url: '/work-planning/availability',
        enabled: Features.WorkPlanning,
        privilege: privilegePathCheck(['WORK_SHIFTS', 'WORK_SHIFT_PLANNING']),
      },
      {
        key: 'auction',
        state: messages.auction,
        url: '/work-planning/auction',
        enabled: Features.TaskAuction,
        privilege: privilegePathCheck(['AUCTION']),
      },
    ],
  },

  {
    key: 'billing',
    state: messages.billing,
    url: '',
    enabled: Features.Billing,
    icon: <BillingIcon size={ICON_SIZE_XXL} />,
    privilege: privilegePathCheck(['BILLING']),
    children: [
      {
        key: 'contracts',
        state: messages.contracts,
        url: '/billing/contracts',
        enabled: Features.BillingContracts,
        privilege: privilegePathCheck(['BILLING', 'BILLING_CONTRACTS']),
      },
    ],
  },
  {
    key: 'payroll',
    state: messages.payroll,
    url: '/payroll',
    enabled: Features.Payroll,
    privilege: privilegePathCheck(['PAYROLL']),
    icon: <EuroIcon size={ICON_SIZE_XXL} />,
    children: [
      {
        key: 'exportArchive',
        state: messages.exportArchive,
        url: '/exportArchive',
        enabled: Features.Payroll,
      },
      {
        key: 'payroll',
        state: messages.payroll,
        url: '/payroll',
        enabled: Features.Payroll,
      },
      {
        key: 'corrections',
        state: messages.corrections,
        url: '/corrections',
        enabled: Features.Corrections,
        privilege: privilegePathCheck(['PAYROLL', 'PAYROLL_CORRECTIONS']),
      },
    ],
  },

  {
    key: 'settings',
    state: messages.settings,
    url: '/settings',
    enabled: Features.Settings,
    icon: <SettingsIcon size={ICON_SIZE_XXL} />,
    privilege: privilegePathCheck(['SETTINGS']),
  },

  {
    key: 'logout',
    state: messages.logout,
    url: '/logout',
    enabled: 'global',
    icon: <LogoutIcon size={ICON_SIZE_XXL} />,
  },
];

export default list;
