import { Unit } from 'types/Unit';

export type AuthRequiredType = {
  userInfo: {
    currentDomacareUser: CurrentDomacareUser;
  };
};

export type CurrentDomacareUser = {
  id: number;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  assignment: string;
  phoneNumber: string;
  unit: Unit;
  autolockTimeout: number; // seconds
};

export enum CurrentUserEnum {
  DOMACARE_WITH_SMARTCARD = 'domacare_with_smartcard',
  PRIVILEGED_EARKISTO_USER_PRINCIPAL = 'PrivilegedEarkistoUserPrincipal',
  DVV_CARD_USER_PRINCIPAL = 'DvvCardUserPrincipal',
}

export type LoginAuth = {
  currentUserType: CurrentUserEnum | string; // TODO: add other types to enum
  registeredDbs: string[];
  loginState: {
    loggedIn: boolean;
    clientRegistered: boolean;
    clientRegisteringCookiePresent: boolean;
    clientRegisteringCookieValid: boolean;
    registeredDbs: string[];
    sessionCookiePresent: boolean;
    sessionCookieValid: boolean;
    userType: string;
    xsrfpresent: boolean;
    xsrffoundFromSessionStorage: boolean;
  };
  currentDomacareUser: CurrentDomacareUser;
  currentDb: string;
};
