import api from 'utils/api';
import { Unit } from 'types/Unit';

export type UnitsQuery = {
  limit: number;
  hasVisibleCustomers: boolean; // If true, load only units which contain customers the current user can view.
};

export const getUnitsAPI = (params?: Partial<UnitsQuery>) => api.get<Unit[]>(`units`, { params });
export const patchUnitAPI = (unitId: number, payload: Partial<Unit>) =>
  api.patch<Partial<Unit>>(`units/${unitId}`, payload);
