/* eslint-disable react/jsx-props-no-spreading */
import React, { InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';

import Input from 'common/form/plain/Input';
import CalendarEventIcon from 'common/icons/CalendarEventIcon';
import { checkIfDateIsBefore, formatDisplayDateToDBDate, fromDateToMoment, isInRange } from 'common/form/pickers/utils';
import Modal, { ModalSize } from 'common/Modal';
import ArrowLeftIcon from 'common/icons/ArrowLeftIcon';
import ArrowRightIcon from 'common/icons/ArrowRightIcon';
import CloseIcon from 'common/icons/CloseIcon';
import ArrowDropDownIcon from 'common/icons/ArrowDropDownIcon';
import DatePickerDays from 'common/form/pickers/DatePicker/DatePickerDays';
import DatePickerYears from 'common/form/pickers/DatePicker/DatePickerYears';
import { DateRangeType } from 'common/form/pickers/DateRangePicker';

import { formatDateToDbFormat, formatDateToDisplayFormat, isValidDate } from 'utils/dateUtil';

import pickerStyles from '../Pickers.scss';

import styles from './DatePicker.scss';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  'data-testid'?: string;
};

export type InputPropsCustom = {
  value?: string | ReadonlyArray<string> | number | undefined | DateRangeType;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> & {
    'data-testid'?: string;
  };

export enum Mode {
  DATE_RANGE = 'dateRange',
  DATE_PICKER = 'datePicker',
  HIGHLIGHT = 'highlight',
}

export type CustomInputType = {
  inputProps: InputPropsCustom;
  Icon: React.ReactElement;
  onIconClick: () => void;
};

type Props = {
  name?: string;
  value: string | null | DateRangeType;
  className?: string;
  placeholder?: string;
  testId?: string;
  inputId?: string;
  error?: string;
  clearable?: boolean;
  disabled?: boolean;
  onChange: (date: string | null) => void;
  minDate?: string;
  maxDate?: string;
  mode?: Mode;
  onBlur?: () => void;
  numberOfDaysAllowed?: number;
  inputProps?: any;
  renderInput?: ({ inputProps, Icon, onIconClick }: CustomInputType) => React.ReactElement;
};

export interface DateRangeProps extends Omit<Props, 'onChange'> {
  onChange: (date: DateRangeType) => void;
}

type DatePickerView = 'days' | 'years';

const DatePicker = ({
  name,
  className,
  placeholder,
  testId,
  inputId,
  value,
  error,
  clearable,
  disabled,
  onChange,
  mode,
  onBlur,
  minDate,
  maxDate,
  inputProps,
  renderInput,
  numberOfDaysAllowed,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [focused, setFocused] = useState(false);
  const [view, setView] = useState<DatePickerView>('days');
  const [localError, setLocalError] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<DateRangeType>({ startDate: null, endDate: null });
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(2020);

  const handleChange = useCallback(
    (date: string | null) => {
      if (!date) {
        setInputValue('');
        setLocalError(false);
        return true;
      }
      setInputValue(date);
      const isValid = isValidDate(date);
      setLocalError(!isValid);
      if (isValid && (minDate || maxDate)) {
        const isValidRange = isInRange(fromDateToMoment(date).toDate(), minDate, maxDate, 'days');
        setLocalError(!isValidRange);
        return isValidRange;
      }
      return isValid;
    },
    [minDate, maxDate],
  );

  useEffect(() => {
    if (focused) {
      return;
    }

    // If the mode is date dateRange and the initial value is passed, set that initial value as the dateRange
    if (mode === Mode.DATE_RANGE && value && typeof value !== 'string') {
      const mInst = fromDateToMoment(value.startDate);

      setDateRange(value as DateRangeType);
      setMonth(mInst.month());
      setYear(mInst.year());
      return;
    }

    const mInst = fromDateToMoment(value as string);
    // If the user hasn't past any starting value, set it with the current date
    if (!value || !mInst.isValid()) {
      const date = new Date();
      handleChange(null);
      setMonth(date.getMonth());
      setYear(date.getFullYear());
      return;
    }
    handleChange(formatDateToDisplayFormat(mInst));
    setMonth(mInst.month());
    setYear(mInst.year());
  }, [focused, handleChange, value, mode]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { value } = event.target;
    const isValid = handleChange(value);
    if (isValid) {
      if (clearable) {
        onChange(value ? formatDisplayDateToDBDate(value) : null);
      } else if (value) {
        onChange(formatDisplayDateToDBDate(value));
      }
    }
  };

  const handleModalClose = () => {
    setVisible(false);
    setView('days');
  };

  const handleDateRangeChange = (date: string | null) => {
    if (!date) {
      setDateRange({ startDate: null, endDate: null });
      setLocalError(false);
      return;
    }

    const formattedDate = formatDisplayDateToDBDate(date);
    /* If the start date is not selected yet or the selected date is before the start date
     or both dates are selected, start the date range selection
     Else just set the end date with the new date */
    if (
      dateRange.startDate === null ||
      checkIfDateIsBefore(formattedDate, dateRange.startDate) ||
      (dateRange.startDate && dateRange.endDate)
    ) {
      setDateRange({ startDate: formattedDate, endDate: null });
    } else {
      setDateRange({ startDate: dateRange.startDate, endDate: formattedDate });
      setVisible(false);

      onChange({
        startDate: dateRange.startDate,
        endDate: formattedDate,
      } as never);
    }
  };

  const handleDatRangeClick = (date: Date) => {
    const mInst = fromDateToMoment(date);
    handleDateRangeChange(formatDateToDisplayFormat(mInst));
    setMonth(mInst.month());
    setYear(mInst.year());
  };

  const handleDayClick = (date: Date) => {
    const mInst = fromDateToMoment(date);
    handleChange(formatDateToDisplayFormat(mInst));
    setMonth(mInst.month());
    setYear(mInst.year());
    onChange(formatDateToDbFormat(mInst));
    setVisible(false);
  };

  const handleYearChange = (selectedYear: number) => {
    setYear(selectedYear);
    setView('days');
  };

  const handleClear = () => {
    handleChange(null);
    onChange(null);
    if (onBlur) {
      onBlur();
    }
  };

  const monthName = useMemo(() => moment().month(month).format('MMMM'), [month]);

  const title = useMemo(() => fromDateToMoment(inputValue || new Date()).format('ddd, MMM D'), [inputValue]);

  const handleDateIconClick = () => {
    if (!disabled) {
      setVisible(true);
    }
  };

  const handleViewChange = (event: React.MouseEvent) => {
    event.stopPropagation();
    setView((prevView) => (prevView === 'days' ? 'years' : 'days'));
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
    if (onBlur) {
      onBlur();
    }
  };

  const handleMonthChange = (m: number) => {
    if (m < 0) {
      setMonth(11);
      setYear(year - 1);
    } else if (m > 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(m);
    }
  };

  const localInputProps: InputProps = {
    id: inputId,
    value: mode === Mode.DATE_RANGE ? dateRange : inputValue || '',
    name,
    onChange: handleInputChange,
    placeholder,
    onFocus: handleFocus,
    onBlur: handleBlur,
    disabled,
    required: !clearable,
    'aria-invalid': localError || !!error,
    'data-testid': testId,
    ...inputProps,
  };

  return (
    <>
      {renderInput ? (
        renderInput({
          inputProps: localInputProps,
          onIconClick: handleDateIconClick,
          Icon: <CalendarEventIcon />,
        })
      ) : (
        <div className={cn(className, pickerStyles.root, { [pickerStyles.disabled]: disabled })}>
          <Input {...localInputProps} error={error || localError} />
          {inputValue && clearable && !disabled && (
            <div
              role="button"
              className={pickerStyles.clear}
              onClick={handleClear}
              data-testid={testId && `${testId}-clear-icon`}
            >
              <CloseIcon />
            </div>
          )}
          <div
            role="button"
            className={pickerStyles.picker}
            onClick={handleDateIconClick}
            data-testid={testId && `${testId}-date-icon`}
          >
            <CalendarEventIcon />
          </div>
        </div>
      )}
      <Modal show={visible} onHide={handleModalClose} title={title} size={ModalSize.AUTO}>
        <Modal.Body className={styles.body}>
          <div className={styles.header}>
            <div className={styles.monthYearTitle} onClick={handleViewChange}>
              <span className={styles.month}>{monthName}</span>
              <span className={styles.year}>{year}</span>
              <button
                type="button"
                onClick={handleViewChange}
                className={cn(styles.action, { [styles.rotate]: view === 'years' })}
              >
                <ArrowDropDownIcon />
              </button>
            </div>
            {view === 'days' && (
              <div className={styles.actions}>
                <button
                  type="button"
                  onClick={() => handleMonthChange(month - 1)}
                  className={styles.action}
                  aria-label="previous month"
                >
                  <ArrowLeftIcon />
                </button>
                <button
                  type="button"
                  onClick={() => handleMonthChange(month + 1)}
                  className={styles.action}
                  aria-label="next month"
                >
                  <ArrowRightIcon />
                </button>
              </div>
            )}
          </div>
          {view === 'days' ? (
            <DatePickerDays
              month={month}
              year={year}
              minDate={minDate}
              maxDate={maxDate}
              mode={mode}
              onDayClick={mode === Mode.DATE_RANGE ? handleDatRangeClick : handleDayClick}
              inputValue={mode === Mode.DATE_RANGE ? dateRange : inputValue}
              numberOfDaysAllowed={numberOfDaysAllowed}
            />
          ) : (
            <DatePickerYears year={year} onChange={handleYearChange} minDate={minDate} maxDate={maxDate} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DatePicker;
