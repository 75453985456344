import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmLogin: {
    id: 'app.EArkisto.confirmLogin',
    defaultMessage: 'Click confirm after you have logged in to EArkisto succesfully to intitiate the transfer',
  },
  transferCancelled: {
    id: 'app.EArkisto.transferCancelled',
    defaultMessage: 'Transfer cancelled',
  },
  transferFailed: {
    id: 'app.EArkisto.transferFailed',
    defaultMessage: 'Transfer failed',
  },
  transferrableReports: {
    id: 'app.EArkisto.transferrableReports',
    defaultMessage: 'Transferrable reports',
  },
  transferredReports: {
    id: 'app.EArkisto.transferredReports',
    defaultMessage: 'Transferred reports',
  },
  inLogin: {
    id: `app.EArkisto.loginStatus.in_login`,
    defaultMessage: 'Logging in',
  },
  transferring: {
    id: `app.EArkisto.loginStatus.IN_PROGRESS`,
    defaultMessage: 'Transfer in progress',
  },
});
