export const GET_CURRENT_CARE_PLAN_SCHEMA_SUCCESS = 'app/CarePlan/GET_CURRENT_CARE_PLAN_SCHEMA_SUCCESS';
export const GET_PREVIOUS_CARE_PLAN_SCHEMA_SUCCESS = 'app/CarePlan/GET_PREVIOUS_CARE_PLAN_SCHEMA_SUCCESS';

export const GET_CURRENT_CARE_PLAN = 'app/CarePlan/GET_CURRENT_CARE_PLAN';
export const GET_CURRENT_CARE_PLAN_SUCCESS = 'app/CarePlan/GET_CURRENT_CARE_PLAN_SUCCESS';

export const GET_CARE_PLAN_VERSIONS_SUCCESS = 'app/CarePlan/GET_CARE_PLAN_VERSIONS_SUCCESS';

export const GET_PREVIOUS_CARE_PLAN_VERSION = 'app/CarePlan/GET_PREVIOUS_CARE_PLAN_VERSION';
export const GET_PREVIOUS_CARE_PLAN_VERSION_SUCCESS = 'app/CarePlan/GET_PREVIOUS_CARE_PLAN_VERSION_SUCCESS';

export const POST_NEW_CARE_PLAN = 'app/CarePlan/POST_NEW_CARE_PLAN';

export const GET_CATEGORY_EVALUATIONS_SUCCESS = 'app/CarePlan/GET_CATEGORY_EVALUATIONS_SUCCESS';

export const POST_CATEGORY_EVALUATIONS = 'app/CarePlan/POST_CATEGORY_EVALUATIONS';

export const GET_CATEGORY_EVALUATIONS_QUESTIONS = 'app/CarePlan/GET_CATEGORY_EVALUATIONS_QUESTIONS';
export const GET_CATEGORY_EVALUATIONS_QUESTIONS_SUCCESS = 'app/CarePlan/GET_CATEGORY_EVALUATIONS_QUESTIONS_SUCCESS';

export const GET_SIMPLE_EVALUATIONS_QUESTIONS = 'app/CarePlan/GET_SIMPLE_EVALUATIONS_QUESTIONS';
export const GET_SIMPLE_EVALUATIONS_QUESTIONS_SUCCESS = 'app/CarePlan/GET_SIMPLE_EVALUATIONS_QUESTIONS_SUCCESS';

export const GET_GOAL_EVALUATIONS = 'app/CarePlan/GET_GOAL_EVALUATIONS';
export const GET_GOAL_EVALUATIONS_SUCCESS = 'app/CarePlan/GET_GOAL_EVALUATIONS_SUCCESS';

export const POST_GOAL_EVALUATIONS = 'app/CarePlan/POST_GOAL_EVALUATIONS';

export const GET_GOAL_EVALUATIONS_QUESTIONS = 'app/CarePlan/GET_GOAL_EVALUATIONS_QUESTIONS';
export const GET_GOAL_EVALUATIONS_QUESTIONS_SUCCESS = 'app/CarePlan/GET_GOAL_EVALUATIONS_QUESTIONS_SUCCESS';

export const GET_PENDING_EVALUATIONS = 'app/CarePlan/GET_PENDING_EVALUATIONS';
export const GET_PENDING_EVALUATIONS_SUCCESS = 'app/CarePlan/GET_PENDING_EVALUATIONS_SUCCESS';

export const GET_RAI_NOTES = 'app/CarePlan/GET_RAI_NOTES';
export const GET_RAI_NOTES_SUCCESS = 'app/CarePlan/GET_RAI_NOTES_SUCCESS';

export const RESET_CARE_PLAN_STORE = 'app/CarePlan/RESET_CARE_PLAN_STORE';
