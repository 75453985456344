import { MessageDescriptor } from 'react-intl';
import { toast as toastify, TypeOptions } from 'react-toastify';
import EventEmitter from 'utils/EventEmitter';

export const ToastEmitter = new EventEmitter<{
  message: {
    type: TypeOptions;
    message: MessageDescriptor;
    values?: Record<string, unknown>;
  };
}>();

class Toast {
  success(message: MessageDescriptor, values?: Record<string, unknown>) {
    ToastEmitter.emit('message', { type: 'success', message, values });
  }

  info(message: MessageDescriptor, values?: Record<string, unknown>) {
    ToastEmitter.emit('message', { type: 'info', message, values });
  }

  warning(message: MessageDescriptor, values?: Record<string, unknown>) {
    ToastEmitter.emit('message', { type: 'warning', message, values });
  }

  error(message: MessageDescriptor, values?: Record<string, unknown>) {
    ToastEmitter.emit('message', { type: 'error', message, values });
  }

  dismiss = toastify.dismiss;
}

const toast = new Toast();

export default toast;
