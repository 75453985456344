import { useIntl } from 'react-intl';

import BaseError from 'common/Errors/BaseError';

import errorImg from 'assets/images/error-pages/server_error.svg';
import messages from 'common/Errors/ApplicationError/messages';

type Props = {
  title?: string;
  text?: string;
};

/**
 * Internal Application Error or when backend response with error 500
 * @constructor
 */
const ApplicationError = ({ text, title }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <BaseError errorImg={errorImg} title={title || formatMessage(messages.title)}>
      {text || formatMessage(messages.text)}
    </BaseError>
  );
};

export default ApplicationError;
