const enum TaskStatuses {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  FINISHED = 'FINISHED',
  AUCTIONED = 'AUCTIONED',
  WORK_SHIFT = 'WORK_SHIFT',
  WORK_SHIFT_AVAILABLE = 'WORK_SHIFT_AVAILABLE',
  WORK_SHIFT_NOT_AVAILABLE = 'WORK_SHIFT_NOT_AVAILABLE',
  TASKS = 'TASKS',
  DELETED = 'DELETED',
}

export default TaskStatuses;
