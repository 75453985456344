import React from 'react';
import cn from 'classnames';
import RadioButtonCheckedIcon from 'common/icons/RadioButtonCheckedIcon';
import RadioButtonUncheckedIcon from 'common/icons/RadioButtonUncheckedIcon';
import styles from 'common/form/plain/Radio.scss';

type Props = {
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
};

const Radio: React.FC<Props> = ({ checked, onChange, disabled }) => {
  const handleChange = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!disabled) onChange(!checked);
  };

  return (
    <div onClick={handleChange} className={cn(styles.root, { [styles.checked]: checked, [styles.disabled]: disabled })}>
      {checked ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
    </div>
  );
};

export default Radio;
