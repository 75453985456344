import { defineMessages } from 'react-intl';

export default defineMessages({
  sessionTimeout: {
    id: 'app.app.sessionTimeout',
    defaultMessage: 'Session timeout',
  },
  continueSession: {
    id: 'app.app.continueSession',
    defaultMessage: 'Continue session',
  },
  sessionExpire: {
    id: 'app.app.sessionExpire',
    defaultMessage: 'Your session will expire in',
  },
  continueSessionInfo: {
    id: 'app.app.continueSessionInfo',
    defaultMessage: 'Please click “Continue session” to keep working or “Logout” to end your session now.',
  },
  authenticationError: {
    id: 'app.app.authenticationError',
    defaultMessage: 'Authentication error',
  },
});
