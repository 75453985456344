/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import cn from 'classnames';
import styles from './Modal.scss';

const ModalFooter: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, children, ...rest }) => (
  <div className={cn(styles.footer, className)} {...rest}>
    {children}
  </div>
);

export default ModalFooter;
