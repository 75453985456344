import { createAction } from '@reduxjs/toolkit';

import { Customer } from 'types/Customer';

import {
  GET_CUSTOMER_ALL_ASSESSMENTS_SUCCESS,
  GET_CUSTOMER_DETAILS_SUCCESS,
  RESET_ASSESSMENTS,
  START_NEW_ASSESSMENT_SUCCESS,
  GET_INSTRUMENTS_LIST_SUCCESS,
  GET_SINGLE_ASSESSMENT_SUCCESS,
  RESET_RAI,
  GET_INSTRUMENT_QUESTIONS_SUCCESS,
  POST_ANSWER,
  POST_ANSWER_SUCCESS,
  POST_ANSWER_FAILED,
  GET_ASSESSMENT_ANSWERS_SUCCESS,
  GET_ASSESSMENT_ANSWERS_FAILED,
  GET_INSTRUMENT_QUESTIONS_FAILED,
  SET_RAI_IS_LOADING,
  GET_MODIFIED_CATEGORIES,
  RESET_INQUIRY_MODE,
  GET_MODIFIED_CATEGORIES_SUCCESS,
  GET_ASSESSMENT_MEASUREMENTS,
  GET_ASSESSMENT_MEASUREMENTS_SUCCESS,
  SET_FOCUSED,
  GET_RAI_NOTES_SUCCESS,
} from 'nonRouteFeatures/RAIv2/constants';
import {
  InstrumentsList,
  Assessment,
  Instruments,
  InstrumentQuestions,
  AnswerResponse,
  AssessmentMeasurements,
  Note,
} from 'nonRouteFeatures/RAIv2/types';

export const getInstrumentsListSuccess = createAction<{
  instrumentsList: InstrumentsList;
}>(GET_INSTRUMENTS_LIST_SUCCESS);

export const startNewAssessmentSuccess = createAction<{ newAssessment: Assessment<Instruments> }>(
  START_NEW_ASSESSMENT_SUCCESS,
);

export const getCustomerAllAssessmentsSuccess = createAction<{ customerAllAssessments: Assessment<Instruments>[] }>(
  GET_CUSTOMER_ALL_ASSESSMENTS_SUCCESS,
);

export const getCustomerDetailsSuccess = createAction<{ customerDetails: Customer }>(GET_CUSTOMER_DETAILS_SUCCESS);

export const resetAssessments = createAction(RESET_ASSESSMENTS);

export const getSingleAssessmentSuccess = createAction<{ assessment: Assessment<Instruments> }>(
  GET_SINGLE_ASSESSMENT_SUCCESS,
);

export const resetRai = createAction(RESET_RAI);

export const getInstrumentQuestionsSuccess = createAction<{
  instrumentQuestions: InstrumentQuestions;
}>(GET_INSTRUMENT_QUESTIONS_SUCCESS);

export const getInstrumentQuestionsFailed = createAction(GET_INSTRUMENT_QUESTIONS_FAILED);
export const postAnswer = createAction(POST_ANSWER);

export const postAnswerFailed = createAction(POST_ANSWER_FAILED);
export const postAnswerSuccess = createAction<AnswerResponse>(POST_ANSWER_SUCCESS);

export const getAssessmentAnswersSuccess = createAction<AnswerResponse>(GET_ASSESSMENT_ANSWERS_SUCCESS);

export const getAssessmentAnswersFailed = createAction(GET_ASSESSMENT_ANSWERS_FAILED);

export const setRaiIsLoading = createAction<boolean>(SET_RAI_IS_LOADING);

export const getModifiedCategories = createAction(GET_MODIFIED_CATEGORIES);

export const getModifiedCategoriesSuccess = createAction<{
  answers: AnswerResponse;
  questions: InstrumentQuestions;
}>(GET_MODIFIED_CATEGORIES_SUCCESS);

export const resetInquiryMode = createAction(RESET_INQUIRY_MODE);

export const getAssessmentMeasurements = createAction(GET_ASSESSMENT_MEASUREMENTS);

export const getAssessmentMeasurementsSuccess = createAction<{ measurements: AssessmentMeasurements }>(
  GET_ASSESSMENT_MEASUREMENTS_SUCCESS,
);

export const setFocused = createAction<boolean>(SET_FOCUSED);

export const getRaiNotesSuccess = createAction<{ raiNotes: Note[] }>(GET_RAI_NOTES_SUCCESS);
