import { defineMessages } from 'react-intl';

export default defineMessages({
  startDateAfterEndDate: {
    id: 'app.users.startDateAfterEndDate',
    defaultMessage: 'Start date cannot be after end date',
  },
  startDateMissing: {
    id: 'app.users.startDateMissing',
    defaultMessage: 'Start date is missing',
  },
  idMissing: {
    id: 'app.users.idMissing',
    defaultMessage: 'Id is missing',
  },
  idZeroOrNegative: {
    id: 'app.users.idZeroOrNegative',
    defaultMessage: 'Id cannot be zero or negative',
  },
  payloadMissing: {
    id: 'app.users.payloadMissing',
    defaultMessage: 'Payload is missing',
  },
  userIdMissing: {
    id: 'app.users.userIdMissing',
    defaultMessage: 'User id is missing from payload',
  },
  agreementIdMissing: {
    id: 'app.users.agreementIdMissing',
    defaultMessage: 'Agreement id is missing from payload',
  },
  activeUserDoesNotExist: {
    id: 'app.users.activeUserDoesNotExist',
    defaultMessage: 'Active user does not exist',
  },
  agreementDoesNotExist: {
    id: 'app.users.agreementDoesNotExist',
    defaultMessage: 'Agreement does not exist',
  },
  agreementDoesNotBelongToUser: {
    id: 'app.users.agreementDoesNotBelongToUser',
    defaultMessage: 'Agreement does not belong to user',
  },
  agreementHistoryDoesNotExist: {
    id: 'app.users.agreementHistoryDoesNotExist',
    defaultMessage: 'Agreement history does not exist',
  },
  agreementIsNotLatest: {
    id: 'app.users.agreementIsNotLatest',
    defaultMessage: 'Agreement is not the latest',
  },
  userIdsMissing: {
    id: 'app.users.userIdsMissing',
    defaultMessage: 'User ids are missing from payload',
  },
  multipleUniqueUserIds: {
    id: 'app.users.multipleUniqueUserIds',
    defaultMessage: 'Payload contained multiple unique user ids',
  },
  agreementsForOtherUser: {
    id: 'app.users.agreementsForOtherUser',
    defaultMessage: 'Payload contained agreements for other user',
  },
  invalidOpenEndedAgreement: {
    id: 'app.users.invalidOpenEndedAgreement',
    defaultMessage: 'Payload contained invalid open ended agreement',
  },
  gapsBetweenAgreements: {
    id: 'app.users.gapsBetweenAgreements',
    defaultMessage: 'Payload contained gaps between agreements',
  },
  overlappingAgreements: {
    id: 'app.users.overlappingAgreements',
    defaultMessage: 'Payload contained overlapping agreements',
  },
  agreementHistoryIsUpdated: {
    id: 'app.users.agreementHistoryIsUpdated',
    defaultMessage: 'Agreement history is updated',
  },
  newAgreementHistoryIsAdded: {
    id: 'app.users.newAgreementHistoryIsAdded',
    defaultMessage: 'New agreement history is added',
  },
});
