import { useContext, useEffect } from 'react';
import { AppContext } from 'providers/AppProvider';
import { Features } from 'types/Features';

const useAppContext = (feature?: Features) => {
  const context = useContext(AppContext);

  useEffect(() => {
    if (feature) context.setFeature(feature);
  }, [context, feature]);

  return context;
};

export default useAppContext;
