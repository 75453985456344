const enum ZoomLevels {
  FOUR_HOURS = 4,
  EIGHT_HOURS = 8,
  TEN_HOURS = 10,
  TWELVE_HOURS = 12,
  FOURTEEN_HOURS = 14,
  SIXTEEN_HOURS = 16,
  EIGHTEEN_HOURS = 18,
  ONE_DAY = 24,
  TWO_DAYS = 48,
  THREE_DAYS = 72,
  FOUR_DAYS = 96,
  FIVE_DAYS = 120,
  SIX_DAYS = 144,
  ONE_WEEK = 168,
}

export default ZoomLevels;
