import { Features } from 'types/Features';

const env = process.env.NODE_ENV;
const config = process.env.CONFIG;

// CONFIG is string in Jest, so it needs to be parsed.
export const featureEnabled = (feature: Features) => {
  if (env === 'test') return JSON.parse(config).features[feature];
  return config.features[feature];
};
