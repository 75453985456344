import { ReactNode } from 'react';
import cn from 'classnames';

import styles from './Modal.scss';

type Props = {
  children: ReactNode;
  className?: string;
  flex?: boolean;
};

const ModalBody = ({ children, className, flex }: Props) => (
  <div className={cn(styles.body, className, { [styles.bodyFlex]: flex })}>{children}</div>
);

export default ModalBody;
