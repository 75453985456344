import React from 'react';
import cn from 'classnames';
import CheckBoxIcon from 'common/icons/CheckBoxIcon';
import CheckBoxOutlineIcon from 'common/icons/CheckBoxOutlineIcon';
import IndeterminateCheckBoxIcon from 'common/icons/IndeterminateCheckBoxIcon';
import styles from './Checkbox.scss';

type Props = {
  checked: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  className?: string;
  onChange: (checked: boolean) => void;
};

const Checkbox: React.FC<Props> = ({ checked, indeterminate, className, disabled, onChange }) => {
  const handleChange = (event: React.MouseEvent) => {
    event.stopPropagation();
    onChange(!checked);
  };

  const icon = () => {
    if (indeterminate) return <IndeterminateCheckBoxIcon />;
    if (checked) return <CheckBoxIcon />;
    return <CheckBoxOutlineIcon />;
  };

  return (
    <div
      onClick={handleChange}
      role="checkbox"
      className={cn(styles.root, className, {
        [styles.checked]: !indeterminate && checked,
        [styles.disabled]: disabled,
      })}
    >
      {icon()}
    </div>
  );
};

export default Checkbox;
