import { FC, RefObject } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { NavItemChild } from 'sharedComponents/Privileges/types';
import Popper from 'common/Popper';
import useAppContext from 'hooks/useAppContext';
import { SIDE_NAV_STATE } from 'providers/AppProvider';

import usePrivileges from 'hooks/usePrivileges';
import { contentVisible } from 'sharedComponents/Sidenav/utils';
import styles from './SideNavSubMenu.scss';

type Props = {
  sideNavChildren: NavItemChild[];
  itemRef: RefObject<HTMLDivElement>;
  floating: boolean;
  currentLocation: string;
  handleClick: () => void;
};

const SideNavSubMenu: FC<Props> = ({ sideNavChildren, itemRef, floating, currentLocation, handleClick }) => {
  const { formatMessage } = useIntl();
  const { isSmallScreen, onOpenSideNav } = useAppContext();
  const privileges = usePrivileges();

  const subMenuContent = sideNavChildren.map((child) => {
    const visible = contentVisible(child, privileges);
    if (!visible) return null;
    const isSelected = currentLocation.includes(child.url);

    return (
      <Link
        to={child.url}
        className={cn(styles.link, {
          [styles.linkSmallSize]: isSmallScreen,
          [styles.selected]: isSelected,
          [styles.floating]: floating,
        })}
        key={child.key}
        onClick={handleClick}
      >
        {!floating && <span className={cn(styles.dot, { [styles.dotSelected]: isSelected })} />}
        <div className={styles.label}>{formatMessage(child.state)}</div>
      </Link>
    );
  });

  if (floating) {
    return (
      <Popper
        referenceElement={itemRef}
        className={styles.floatingSubMenu}
        onClose={() => onOpenSideNav(SIDE_NAV_STATE.CLOSED)}
        placement="right-start"
      >
        {subMenuContent}
      </Popper>
    );
  }
  return <>{subMenuContent}</>;
};

export default SideNavSubMenu;
