import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getPreferences, updatePreferences } from 'services/user/preferences';
import PreferencesStore from 'types/Preferences';

export const PREFERENCES_REDUCER_NAME = 'preferences';

export type PreferencesState = {
  data: PreferencesStore;
  loading: boolean;
  error?: string | null;
};

const initialState: PreferencesState = {
  data: {},
  loading: false,
  error: null,
};

export const fetchPreferences = createAsyncThunk('preferences/fetchPreferences', async () => {
  const response = await getPreferences();
  return response.data;
});

// thunk for updating
export const updatePreferencesThunk = createAsyncThunk(
  'preferences/updatePreferences',
  async (preferences: Record<string, unknown>) => {
    const response = await updatePreferences(preferences);
    return response.data;
  },
);

const preferencesSlice = createSlice({
  name: PREFERENCES_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPreferences.pending, (draft) => {
        draft.loading = true;
      })
      .addCase(fetchPreferences.fulfilled, (draft, action: PayloadAction<Record<string, unknown>>) => {
        draft.loading = false;
        draft.data = action.payload;
      })
      .addCase(fetchPreferences.rejected, (draft, action) => {
        draft.loading = false;
        draft.error = action.error.message;
      })
      .addCase(updatePreferencesThunk.pending, (draft) => {
        draft.loading = true;
      })
      .addCase(updatePreferencesThunk.fulfilled, (draft, action: PayloadAction<Record<string, unknown>>) => {
        draft.loading = false;
        draft.data = { ...draft.data, ...action.payload };
      })
      .addCase(updatePreferencesThunk.rejected, (draft, action) => {
        draft.loading = false;
        draft.error = action.error.message;
      });
  },
});

export default preferencesSlice.reducer;
