import { defineMessages } from 'react-intl';

export default defineMessages({
  intensivePeriodsTitle: {
    id: 'app.components.intensivePeriods.intensivePeriodsTitle',
    defaultMessage: 'Intensive periods',
  },
  tableError: {
    id: 'app.rai.errorValidation.tableError',
    defaultMessage: 'Table must be filled',
  },
  mandatoryFieldError: {
    id: 'app.rai.errorValidation.mandatoryFieldError',
    defaultMessage: 'Medication table contains empty mandatory value',
  },
  previousAssessmentOwnGoalError: {
    id: 'app.rai.errorValidation.previousAssessmentOwnGoalError',
    defaultMessage: 'Previous assessment ownGoal evaluation missing',
  },
  previousAssessmentEvaluationError: {
    id: 'app.rai.errorValidation.previousAssessmentEvaluationError',
    defaultMessage: 'Previous assessment ownGoal contains wrong value',
  },
  diagnosesError: {
    id: 'app.rai.errorValidation.diagnosesError',
    defaultMessage: 'Diagnosis somatic contains no answer',
  },
  diagnosesNoStatus: {
    id: 'app.rai.errorValidation.diagnosesNoStatus',
    defaultMessage: 'Status not selected for all diagnoses',
  },
  printAllQuestions: {
    id: 'app.rai.featureHeader.printAllQuestions',
    defaultMessage: 'All questions',
  },
  printMeasurements: {
    id: 'app.rai.featureHeader.printMeasurements',
    defaultMessage: 'Measurements',
  },
  printForm: {
    id: 'app.rai.featureHeader.printForm',
    defaultMessage: 'Form',
  },
  printHandBook: {
    id: 'app.rai.featureHeader.printHandBook',
    defaultMessage: 'Handbook',
  },
  printMeasurementsHandBook: {
    id: 'app.rai.featureHeader.printMeasurementsHandBook',
    defaultMessage: 'Measurements handbook',
  },
  archivedAssessments: {
    id: 'app.rai.archivedAssessments.ArchivedAssessments',
    defaultMessage: 'Archived RAI-Assessments',
  },
  archivedAssessmentsBriefExplanation: {
    id: 'app.rai.archivedAssessments.archivedAssessmentsBriefExplanation',
    defaultMessage: 'All archived RAI-assessments will be sent to National Health Institute for...',
  },
  archivedAssessmentsExplanation: {
    id: 'app.rai.archivedAssessments.archivedAssessmentsExplanation',
    defaultMessage: `All archived RAI-assessments will be sent to National Health Institute for Health and Welfare (THL)
                        automatically twice in year (only admin can unlock archived RAI-assessments).`,
  },
  archivedAssessmentsLocking: {
    id: 'app.rai.archivedAssessments.archivedAssessmentsLocking',
    defaultMessage: 'Finish and lock',
  },
  archivedAssessmentsUnlocking: {
    id: 'app.rai.archivedAssessments.archivedAssessmentsUnlocking',
    defaultMessage: 'Unlock',
  },
  archivedAssessmentsCancel: {
    id: 'app.rai.archivedAssessments.archivedAssessmentsCancel',
    defaultMessage: 'Cancel',
  },
  archivedAssessmentsUnlockConfirmation: {
    id: 'app.rai.archivedAssessments.archivedAssessmentsUnlockConfirmation',
    defaultMessage: 'Are you sure you want to unlock the assessment?',
  },
  noArchivedAssessments: {
    id: 'app.rai.archivedAssessments.noArchivedAssessments',
    defaultMessage: 'No archived assessments',
  },
  archivedAssessmentsStartDateAndTime: {
    id: 'app.rai.archivedAssessments.StartDateAndTime',
    defaultMessage: 'Creation date and time',
  },
  archivedAssessmentsEndDateAndTime: {
    id: 'app.rai.archivedAssessments.endDateAndTime',
    defaultMessage: 'Archive date and time',
  },
  lastModifier: {
    id: 'app.rai.archivedAssessments.lastModifier',
    defaultMessage: 'Last modifier',
  },
  confirmationTitle: {
    id: 'app.rai.archivedAssessments.confirmationTitle',
    defaultMessage: 'Confirmation',
  },
  archivedAssessmentsCustomer: {
    id: 'app.rai.archivedAssessments.archivedAssessmentsCustomer',
    defaultMessage: 'Customer',
  },
  archivedAssessmentsOpen: {
    id: 'app.rai.archivedAssessments.archivedAssessmentsOpen',
    defaultMessage: 'Open',
  },
  archivedAssessmentRaiInstrument: {
    id: 'app.rai.archivedAssessments.archivedAssessmentRaiInstrument',
    defaultMessage: 'RAI-Instrument',
  },
  notes: {
    id: 'app.rai.raiNotes.notes',
    defaultMessage: 'Notes',
  },
  addNote: {
    id: 'app.rai.raiNotes.addNote',
    defaultMessage: 'Add note',
  },
  save: {
    id: 'app.rai.raiNotes.save',
    defaultMessage: 'Save',
  },
  modify: {
    id: 'app.rai.raiNotes.modify',
    defaultMessage: 'Modify',
  },
  delete: {
    id: 'app.rai.raiNotes.delete',
    defaultMessage: 'Delete',
  },
  deleteConfirmation: {
    id: 'app.rai.raiNotes.deleteConfirmation',
    defaultMessage: 'Are you sure you want to delete it?',
  },
  confirmation: {
    id: 'app.rai.raiNotes.confirmation',
    defaultMessage: 'Confirmation',
  },
  ongoingAssessments: {
    id: 'app.rai.ongoingAssessments.ongoingAssessments',
    defaultMessage: 'Ongoing RAI-Assessments',
  },
  noOngoingAssessments: {
    id: 'app.rai.ongoingAssessments.noOngoingAssessments',
    defaultMessage: 'No ongoing RAI-Assessments',
  },
  ongoingAssessmentsBriefExplanation: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsBriefExplanation',
    defaultMessage: 'Start RAI-assessment when you have done two (2) weeks intensive period. When you ...',
  },
  ongoingAssessmentsExplanation: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsExplanation',
    defaultMessage:
      'Start RAI-assessment when you have done two (2) weeks intensive period. When you have answered all the RAI-questions (all questions are green) remember to finish and archive RAI-assessement. Without finishing and archiving the RAI-assessment DomaCare doesnt send it to National Institute for Health and Welfare (THL). Sending every RAI-assessments to THL two times in a year is mandatory for everyone who is using RAI-instruments.',
  },
  ongoingAssessmentsNewAssessment: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsNewAssessment',
    defaultMessage: 'Create new assessment',
  },
  ongoingAssessmentsChoosingInstrument: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsChoosingInstrument',
    defaultMessage: 'Please choose the instrument you would want to create an assessment:',
  },
  ongoingAssessmentsCreate: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsCreate',
    defaultMessage: 'Create',
  },
  ongoingAssessmentsCancel: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsCancel',
    defaultMessage: 'Cancel',
  },
  openAssessment: {
    id: 'app.rai.ongoingAssessments.openAssessment',
    defaultMessage: 'Open assessment',
  },
  openOngoingAssessmentsHover: {
    id: 'app.rai.ongoingAssessments.openOngoingAssessmentsHover',
    defaultMessage: 'Open',
  },
  ongoingAssessmentsStartDateAndTime: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsStartDateAndTime',
    defaultMessage: 'Creation date and time',
  },
  ongoingAssessmentsEndDateAndTime: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsEndDateAndTime',
    defaultMessage: 'Archive date and time',
  },
  ongoingAssessmentsInstrument: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsInstrument',
    defaultMessage: 'Rai-Instrument',
  },
  ongoingAssessmentsCustomer: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsCustomer',
    defaultMessage: 'Customer',
  },
  ongoingAssessmentsLastModifier: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsLastModifier',
    defaultMessage: 'Last modifier',
  },
  ongoingAssessmentsArchive: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsArchive',
    defaultMessage: 'Finish and archive',
  },
  ongoingAssessmentsDeleteAssessment: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsDeleteAssessment',
    defaultMessage: 'Delete the assessment',
  },
  ongoingAssessmentConfirmation: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentConfirmation',
    defaultMessage: 'Confirmation',
  },
  ongoingAssessmentsDeleteText: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsDeleteText',
    defaultMessage: 'Are you sure you want to delete the selected assessment?',
  },
  ongoingAssessmentsDelete: {
    id: 'app.rai.ongoingAssessments.ongoingAssessmentsDelete',
    defaultMessage: 'Delete',
  },
  pastWarning: {
    id: 'app.rai.questions.timeInThePastWarning',
    defaultMessage: 'The date is more than 90 years in the past',
  },
  futureWarning: {
    id: 'app.rai.questions.timeInTheFutureWarning',
    defaultMessage: 'The date is in the future',
  },
  oneYearInPastWarning: {
    id: 'app.rai.questions.oneYearInPastWarning',
    defaultMessage: 'The date is one year in the past',
  },
  sameTextWarning: {
    id: 'app.rai.questions.sameTextWarning',
    defaultMessage: 'The answer is the same as before',
  },
  errorValidation: {
    id: 'app.rai.questions.errorValidation',
    defaultMessage: 'Empty value is not allowed',
  },
  fromPreviousWarning: {
    id: 'app.rai.questions.fromPreviousWarning',
    defaultMessage: 'This value is copied from previous assessment. Either change or approve it.',
  },
  skip: {
    id: 'app.rai.questions.skip',
    defaultMessage: 'Skip the question.',
  },
  addGoal: {
    id: 'app.rai.questions.tableQuestions.addGoal',
    defaultMessage: 'Add goal',
  },
  print: {
    id: 'app.rai.scores.print',
    defaultMessage: 'Print',
  },
  showRaiQuestions: {
    id: 'app.rai.scores.showRaiQuestions',
    defaultMessage: 'Show RAI questions',
  },
  indexValue: {
    id: 'app.rai.scores.indexValue',
    defaultMessage: "Customer's index value",
  },
  noResults: {
    id: 'app.rai.scores.noResults',
    defaultMessage: 'No results',
  },
  title: {
    id: 'app.rai.scores.raiQuestionsModal.title',
    defaultMessage: 'RAI-questions',
  },
  confirm: {
    id: 'app.rai.scores.raiQuestionsModal.confirm',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'app.rai.scores.raiQuestionsModal.cancel',
    defaultMessage: 'Cancel',
  },
  closeAllQuestions: {
    id: 'app.rai.scores.raiQuestionsModal.raiQuestions.closeAllQuestions',
    defaultMessage: 'Close all questions',
  },
  openAllQuestions: {
    id: 'app.rai.scores.raiQuestionsModal.raiQuestions.openAllQuestions',
    defaultMessage: 'Open all questions',
  },
  scales: {
    id: 'app.rai.scores.scales',
    defaultMessage: 'Scales',
  },
  createAssessment: {
    id: 'app.rai.sagas.createAssessment',
    defaultMessage: 'Assessment is created',
  },
  patchedAnswer: {
    id: 'app.rai.sagas.patchedAnswer',
    defaultMessage: 'Question is answered',
  },
  deletedAssessment: {
    id: 'app.rai.sagas.deletedAssessment',
    defaultMessage: 'Assessment is deleted',
  },
  noteModified: {
    id: 'app.rai.sagas.noteModified',
    defaultMessage: 'Note is modified',
  },
  noteDeleted: {
    id: 'app.rai.sagas.noteDeleted',
    defaultMessage: 'Note is deleted',
  },
  assessmentLockedArchived: {
    id: 'app.rai.sagas.assessmentLockedArchived',
    defaultMessage: 'Assessment is locked and archived',
  },
  assessmentArchivedError: {
    id: 'app.rai.sagas.assessmentArchivedError',
    defaultMessage: 'All questions are not answered',
  },
  patchedAnswerEmpty: {
    id: 'app.rai.sagas.patchedAnswerEmpty',
    defaultMessage: 'Answer is the same as before',
  },
  raiQuestions: {
    id: 'app.rai.questions.raiQuestions',
    defaultMessage: 'RAI-questions',
  },
  capsAndRaps: {
    id: 'app.rai.apValues.capsAndRaps',
    defaultMessage: 'Assessment protocols',
  },
  ongoingIntensivePeriod: {
    id: 'app.rai.intensivePeriod.ongoingIntensivePeriod',
    defaultMessage: 'Ongoing RAI-intensive period',
  },
  noOngoingIntensivePeriod: {
    id: 'app.rai.intensivePeriod.noOngoingIntensivePeriod',
    defaultMessage: 'No ongoing Intensive period',
  },
  startIntensivePeriod: {
    id: 'app.rai.intensivePeriod.startIntensivePeriod',
    defaultMessage: 'Start intensive period',
  },
  intensivePeriodBriefExplanation: {
    id: 'app.rai.intensivePeriod.intensivePeriodBriefExplanation',
    defaultMessage: 'You should start RAI intensive period two (2) weeks before ...',
  },
  intensivePeriodExplanation: {
    id: 'app.rai.intensivePeriod.intensivePeriodExplanation',
    defaultMessage:
      'You should start RAI intensive period two (2) weeks before starting filling customer’s RAI-assessment. Many of RAI-questions ask how customer have been feeling in the past two weeks. Intensive period helps you to answer these questions.Intensive period asks some of the RAI-questions of all members of multi professional team working with the customer. Intensive period lasts two (2) weeks.',
  },
  defaultError: {
    id: 'app.rai.errors.defaultError',
    defaultMessage: 'An error was encountered',
  },
  questionNotAvailable: {
    id: 'app.rai.inquiryMode.questionNotAvailable',
    defaultMessage: 'Question either does not exist or it is hidden',
  },
  clickHere: {
    id: 'app.rai.inquiryMode.clickHere',
    defaultMessage: 'Click here',
  },
  inquiryModeErrorValidation: {
    id: 'app.rai.inquiryModeWrapper.errorValidation',
    defaultMessage: 'Empty value is not allowed',
  },
  next: {
    id: 'app.rai.inquiryModeFooter.next',
    defaultMessage: 'Next',
  },
  emptyValueError: {
    id: 'app.rai.errorValidation.emptyValueError',
    defaultMessage: 'Empty value is not allowed',
  },
  illegalValueError: {
    id: 'app.rai.errorValidation.illegalValueError',
    defaultMessage: 'Illegal value dependant on other fields',
  },
  commonError: {
    id: 'app.rai.errorValidation.commonError',
    defaultMessage: 'Wrong answer',
  },
  unskippableError: {
    id: 'app.rai.errorValidation.unskippableError',
    defaultMessage: 'This question cannot be skipped',
  },
  wrongDateFormatError: {
    id: 'app.rai.errorValidation.wrongDateFormatError',
    defaultMessage: 'Wrong date format',
  },
  notANumberError: {
    id: 'app.rai.errorValidation.notANumberError',
    defaultMessage: 'This question has to be answered with a number',
  },
  invalidNumberError: {
    id: 'app.rai.errorValidation.invalidNumberError',
    defaultMessage: 'Incorrect number as an answer',
  },
  participantMissingValueError: {
    id: 'app.rai.errorValidation.participantMissingValueError',
    defaultMessage: 'The table is missing values',
  },
  participantWrongProfessionTitleError: {
    id: 'app.rai.errorValidation.participantWrongProfessionTitleError',
    defaultMessage: 'Wrong answer to profession title',
  },
  participantWrongOrganizationError: {
    id: 'app.rai.errorValidation.participantWrongOrganizationError',
    defaultMessage: 'Wrong answer to organization relation question',
  },
  wrongInputError: {
    id: 'app.rai.errorValidation.wrongInputError',
    defaultMessage: 'Input data is wrong',
  },
  noChoicesError: {
    id: 'app.rai.errorValidation.noChoicesError',
    defaultMessage: 'Input is not one of the choices',
  },
  noHourFieldError: {
    id: 'app.rai.errorValidation.noHourFieldError',
    defaultMessage: 'Day field cannot contains hours',
  },
  noMinuteFieldError: {
    id: 'app.rai.errorValidation.noMinuteFieldError',
    defaultMessage: 'There are more minutes in this question than there are days in another question',
  },
  noQuestions: {
    id: 'app.rai.noQuestions',
    defaultMessage: 'No questions',
  },
  resultDescription: {
    id: 'app.rai.resultDescription',
    defaultMessage: 'Result description',
  },
  instrumentNotFound: {
    id: 'app.rai.instrumentNotFound',
    defaultMessage: 'Instrument not found',
  },
  raiDefinitionNotFound: {
    id: 'app.rai.raiDefinitionNotFound',
    defaultMessage: 'RAI definition not found',
  },
  raiLanguageMissing: {
    id: 'app.rai.raiLanguageMissing',
    defaultMessage: 'Language missing',
  },
  raiInstrumentNull: {
    id: 'app.rai.raiInstrumentNull',
    defaultMessage: 'Instrument is null',
  },
  raiUnsupportedInstrument: {
    id: 'app.rai.raiUnsupportedInstrument',
    defaultMessage: 'Unsupported instrument',
  },
  raiVersionIdMissing: {
    id: 'app.rai.raiVersionIdMissing',
    defaultMessage: 'Version id missing',
  },
  raiVersionIdNegative: {
    id: 'app.rai.raiVersionIdNegative',
    defaultMessage: 'Version id negative',
  },
  raiVersionIdZero: {
    id: 'app.rai.raiVersionIdZero',
    defaultMessage: 'Version id is zero',
  },
  raiAssessmentIdNull: {
    id: 'app.rai.raiAssessmentIdNull',
    defaultMessage: 'Assessment id is null',
  },
  raiAssessmentIdZero: {
    id: 'app.rai.raiAssessmentIdZero',
    defaultMessage: 'Assessment id is zero',
  },
  raiAssessmentIdNegative: {
    id: 'app.rai.raiAssessmentIdNegative',
    defaultMessage: 'Assessment id is negative',
  },
  raiAssessmentNotFound: {
    id: 'app.rai.raiAssessmentNotFound',
    defaultMessage: 'Assessment not found',
  },
  raiAssessmentIsLocked: {
    id: 'app.rai.raiAssessmentIsLocked',
    defaultMessage: 'Assessment is locked',
  },
  raiAssessmentIsArchived: {
    id: 'app.rai.raiAssessmentIsArchived',
    defaultMessage: 'Assessment is archived',
  },
  raiAssessmentIsAlreadyLocked: {
    id: 'app.rai.raiAssessmentIsAlreadyLocked',
    defaultMessage: 'Assessment is already locked',
  },
  raiAssessmentIsAlreadyUnlocked: {
    id: 'app.rai.raiAssessmentIsAlreadyUnlocked',
    defaultMessage: 'Assessment is already unlocked',
  },
  raiAssessmentIsAlreadyArchived: {
    id: 'app.rai.raiAssessmentIsAlreadyArchived',
    defaultMessage: 'Assessment is already archived',
  },
  raiAssessmentUnlockPeriodHasPassed: {
    id: 'app.rai.raiAssessmentUnlockPeriodHasPassed',
    defaultMessage: 'Unlock period has passed',
  },
  raiAssessmentIsNotLocked: {
    id: 'app.rai.raiAssessmentIsNotLocked',
    defaultMessage: 'Assessment is not locked',
  },
  raiAssessmentIsNotArchived: {
    id: 'app.rai.raiAssessmentIsNotArchived',
    defaultMessage: 'Assessment is not archived',
  },
  raiAssessmentOngoingAssessment: {
    id: 'app.rai.raiAssessmentOngoingAssessment',
    defaultMessage: 'Ongoing assessment',
  },
  raiAssessmentUnlockedArchivedAssessment: {
    id: 'app.rai.raiAssessmentUnlockedArchivedAssessment',
    defaultMessage: 'Unlocked archived assessment',
  },
  raiCustomerIdMissing: {
    id: 'app.rai.raiCustomerIdMissing',
    defaultMessage: 'Customer id missing',
  },
  raiCustomerIdZero: {
    id: 'app.rai.raiCustomerIdZero',
    defaultMessage: 'Customer id is zero',
  },
  raiCustomerIdNegative: {
    id: 'app.rai.raiCustomerIdNegative',
    defaultMessage: 'Customer id is negative',
  },
  raiCustomerNotFound: {
    id: 'app.rai.raiCustomerNotFound',
    defaultMessage: 'Customer not found',
  },
  raiAnswerNull: {
    id: 'app.rai.raiAnswerNull',
    defaultMessage: 'Answer is null',
  },
  raiAnswerQuestionIdNull: {
    id: 'app.rai.raiAnswerQuestionIdNull',
    defaultMessage: 'Question id is null',
  },
  raiAnswerContainsBothValueAndRows: {
    id: 'app.rai.raiAnswerContainsBothValueAndRows',
    defaultMessage: 'Answer contains both value and rows',
  },
  raiQuestionNotInDefinition: {
    id: 'app.rai.raiQuestionNotInDefinition',
    defaultMessage: 'Question not in definition',
  },
  raiNoAnswerChoicesFound: {
    id: 'app.rai.raiNoAnswerChoicesFound',
    defaultMessage: 'No answer choices found',
  },
  raiUnsupportedQuestion: {
    id: 'app.rai.raiUnsupportedQuestion',
    defaultMessage: 'Unsupported question',
  },
  raiAnswerInvalidForQuestion: {
    id: 'app.rai.raiAnswerInvalidForQuestion',
    defaultMessage: 'Answer invalid for question',
  },
  raiDefinitionVersionNotFound: {
    id: 'app.rai.raiDefinitionVersionNotFound',
    defaultMessage: 'Definition version not found',
  },
  raiDefinitionVersionIdMissing: {
    id: 'app.rai.raiDefinitionVersionIdMissing',
    defaultMessage: 'Definition version id missing',
  },
  raiAnswerTableMissingColumnDefinitions: {
    id: 'app.rai.raiAnswerTableMissingColumnDefinitions',
    defaultMessage: 'Answer table missing column definitions',
  },
  raiAnswerInvalidICode: {
    id: 'app.rai.raiAnswerInvalidICode',
    defaultMessage: 'Answer invalid i-code',
  },
  raiAssessmentNotFullyFilled: {
    id: 'app.rai.raiAssessmentNotFullyFilled',
    defaultMessage: 'Assessment not fully filled',
  },
  raiTableInvalidRowId: {
    id: 'app.rai.raiTableInvalidRowId',
    defaultMessage: 'Table invalid row id',
  },
  raiFinalOwnGoalsCannotDeleteInitialGoal: {
    id: 'app.rai.raiFinalOwnGoalsCannotDeleteInitialGoal',
    defaultMessage: 'Final own goals cannot delete initial goal',
  },
  raiFinalOwnGoalsChosenValueInvalid: {
    id: 'app.rai.raiFinalOwnGoalsChosenValueInvalid',
    defaultMessage: 'Final own goals chosen value invalid',
  },
  raiOwnGoalsEvaluationNoPreviousArchivedAssessment: {
    id: 'app.rai.raiOwnGoalsEvaluationNoPreviousArchivedAssessment',
    defaultMessage: 'Own goals evaluation no previous archived assessment',
  },
  raiOwnGoalsEvaluationMissingRowIds: {
    id: 'app.rai.raiOwnGoalsEvaluationMissingRowIds',
    defaultMessage: 'Own goals evaluation missing row ids',
  },
  raiOwnGoalsEvaluationRowIdsDoNotMatch: {
    id: 'app.rai.raiOwnGoalsEvaluationRowIdsDoNotMatch',
    defaultMessage: 'Own goals evaluation row ids do not match',
  },
  raiMedicationTableMissingRowIdInRequest: {
    id: 'app.rai.raiMedicationTableMissingRowIdInRequest',
    defaultMessage: 'Medication table missing row id in request',
  },
  raiMedicationTableMedicationNotFound: {
    id: 'app.rai.raiMedicationTableMedicationNotFound',
    defaultMessage: 'Medication table medication not found',
  },
  raiMedicationTableColumnsMissingICodes: {
    id: 'app.rai.raiMedicationTableColumnsMissingICodes',
    defaultMessage: 'Medication table columns missing i-codes',
  },
  raiMedicationTableUnsupportedICode: {
    id: 'app.rai.raiMedicationTableUnsupportedICode',
    defaultMessage: 'Medication table unsupported i-code',
  },
  raiMedicationTableDosageUnitInvalidValue: {
    id: 'app.rai.raiMedicationTableDosageUnitInvalidValue',
    defaultMessage: 'Medication table dosage unit invalid value',
  },
  raiMedicationTableDosageAmountInvalidValue: {
    id: 'app.rai.raiMedicationTableDosageAmountInvalidValue',
    defaultMessage: 'Medication table dosage amount invalid value',
  },
  raiMedicationTableDosageAmountNotDouble: {
    id: 'app.rai.raiMedicationTableDosageAmountNotDouble',
    defaultMessage: 'Medication table dosage amount not double',
  },
  raiMedicationTableTakenInvalidValue: {
    id: 'app.rai.raiMedicationTableTakenInvalidValue',
    defaultMessage: 'Medication table taken invalid value',
  },
  raiMedicationTableRouteInvalidValue: {
    id: 'app.rai.raiMedicationTableRouteInvalidValue',
    defaultMessage: 'Medication table route invalid value',
  },
  raiMedicationTableTakeIntervalInvalidValue: {
    id: 'app.rai.raiMedicationTableTakeIntervalInvalidValue',
    defaultMessage: 'Medication table take interval invalid value',
  },
  raiMedicationTableWhenNeededInvalidValue: {
    id: 'app.rai.raiMedicationTableWhenNeededInvalidValue',
    defaultMessage: 'Medication table when needed invalid value',
  },
  raiParticipantsTableColumnsMissingICodes: {
    id: 'app.rai.raiParticipantsTableColumnsMissingICodes',
    defaultMessage: 'Participants table columns missing i-codes',
  },
  raiParticipantsTableUnsupportedICode: {
    id: 'app.rai.raiParticipantsTableUnsupportedICode',
    defaultMessage: 'Participants table unsupported i-code',
  },
  raiParticipantsTableRelationToOrganizationInvalidValue: {
    id: 'app.rai.raiParticipantsTableRelationToOrganizationInvalidValue',
    defaultMessage: 'Participants table relation to organization invalid value',
  },
  raiParticipantsTableProfessionalTitleInvalidValue: {
    id: 'app.rai.raiParticipantsTableProfessionalTitleInvalidValue',
    defaultMessage: 'Participants table professional title invalid value',
  },
  raiAutomationConfigurationMissing: {
    id: 'app.rai.raiAutomationConfigurationMissing',
    defaultMessage: 'Automation configuration missing',
  },
  raiAutomationConfigurationTableAutomationsMissing: {
    id: 'app.rai.raiAutomationConfigurationTableAutomationsMissing',
    defaultMessage: 'Automation configuration table automations missing',
  },
  raiAutomationConfigurationTableAutomationDoesNotExistForGivenQuestionId: {
    id: 'app.rai.raiAutomationConfigurationTableAutomationDoesNotExistForGivenQuestionId',
    defaultMessage: 'Automation configuration table automation does not exist for given question id',
  },
  raiAutomationConfigurationTableAutomationUnsupportedUpdate: {
    id: 'app.rai.raiAutomationConfigurationTableAutomationUnsupportedUpdate',
    defaultMessage: 'Automation configuration table automation unsupported update',
  },
  raiCommonAutomationNotFoundInDefinition: {
    id: 'app.rai.raiCommonAutomationNotFoundInDefinition',
    defaultMessage: 'Common automation not found in definition',
  },
  raiSkipAutomationMissingCondition: {
    id: 'app.rai.raiSkipAutomationMissingCondition',
    defaultMessage: 'Skip automation is missing condition',
  },
  raiSkipAutomationMissingAffectedQuestions: {
    id: 'app.rai.raiSkipAutomationMissingAffectedQuestions',
    defaultMessage: 'Skip automation missing affected questions',
  },
  raiSkipAutomationMissingNegation: {
    id: 'app.rai.raiSkipAutomationMissingNegation',
    defaultMessage: 'Skip automation missing negation',
  },
  raiSkipAutomationMissingConditionType: {
    id: 'app.rai.raiSkipAutomationMissingConditionType',
    defaultMessage: 'Skip automation missing condition type',
  },
  raiSkipAutomationUnsupportedConditionType: {
    id: 'app.rai.raiSkipAutomationUnsupportedConditionType',
    defaultMessage: 'Skip automation unsupported condition type',
  },
  raiSkipAutomationConditionMissingQuestionId: {
    id: 'app.rai.raiSkipAutomationConditionMissingQuestionId',
    defaultMessage: 'Skip automation condition missing question id',
  },
  raiSkipAutomationAffectedQuestionMissingQuestionId: {
    id: 'app.rai.raiSkipAutomationAffectedQuestionMissingQuestionId',
    defaultMessage: 'Skip automation affected question missing question id',
  },
  raiEvaluationReasonAutomationMissingCondition: {
    id: 'app.rai.raiEvaluationReasonAutomationMissingCondition',
    defaultMessage: 'Evaluation reason automation missing condition',
  },
  raiEvaluationReasonAutomationConditionMissingQuestionId: {
    id: 'app.rai.raiEvaluationReasonAutomationConditionMissingQuestionId',
    defaultMessage: 'Evaluation reason automation condition missing question id',
  },
  raiEvaluationReasonAutomationConditionMissingTriggerValues: {
    id: 'app.rai.raiEvaluationReasonAutomationConditionMissingTriggerValues',
    defaultMessage: 'Evaluation reason automation condition missing trigger values',
  },
  raiMeasurementSuiteIdentifierMissing: {
    id: 'app.rai.raiMeasurementSuiteIdentifierMissing',
    defaultMessage: 'Measurement suite identifier missing',
  },
  raiMeasurementMultipleSuiteIdentifier: {
    id: 'app.rai.raiMeasurementMultipleSuiteIdentifier',
    defaultMessage: 'Multiple measurement suite identifiers',
  },
  raiMeasurementSuiteIdentifierEmpty: {
    id: 'app.rai.raiMeasurementSuiteIdentifierEmpty',
    defaultMessage: 'Measurement suite identifier empty',
  },
  raiMeasurementMetadataParsingFailed: {
    id: 'app.rai.raiMeasurementMetadataParsingFailed',
    defaultMessage: 'Measurement metadata parsing failed',
  },
  raiMeasurementIdentifierNotFound: {
    id: 'app.rai.raiMeasurementIdentifierNotFound',
    defaultMessage: 'Measurement identifier not found',
  },
  raiMeasurementFailed: {
    id: 'app.rai.raiMeasurementFailed',
    defaultMessage: 'Measurement failed',
  },
  raiMeasurementIdentifiersMissingFromInstrument: {
    id: 'app.rai.raiMeasurementIdentifiersMissingFromInstrument',
    defaultMessage: 'Measurement identifiers missing from instrument',
  },
  raiMeasurementAllChoicesMissing: {
    id: 'app.rai.raiMeasurementAllChoicesMissing',
    defaultMessage: 'Measurement all choices missing',
  },
  raiMeasurementAnswerSetNotFound: {
    id: 'app.rai.raiMeasurementAnswerSetNotFound',
    defaultMessage: 'Measurement answer set not found',
  },
  raiMeasurementCalculationNotFound: {
    id: 'app.rai.raiMeasurementCalculationNotFound',
    defaultMessage: 'Measurement calculation not found',
  },
  raiNotesPayloadNull: {
    id: 'app.rai.raiNotesPayloadNull',
    defaultMessage: 'Notes payload is null',
  },
  raiNotesNoteWasBlank: {
    id: 'app.rai.raiNotesNoteWasBlank',
    defaultMessage: 'Note was blank',
  },
  raiNotesNoteTooLong: {
    id: 'app.rai.raiNotesNoteTooLong',
    defaultMessage: 'Note too long',
  },
  raiNoteNotFoundWithId: {
    id: 'app.rai.raiNoteNotFoundWithId',
    defaultMessage: 'Note not found with id',
  },
  raiNotesPayloadMissingRaiVersion: {
    id: 'app.rai.raiNotesPayloadMissingRaiVersion',
    defaultMessage: 'Notes payload missing rai version',
  },
  raiNotesPayloadMissingQuestionCode: {
    id: 'app.rai.raiNotesPayloadMissingQuestionCode',
    defaultMessage: 'Notes payload missing question code',
  },
  valueNotValid: {
    id: 'app.rai.errorValidation.valueNotValid',
    defaultMessage: 'Value is not valid',
  },
});
