import { FC, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const Portal: FC = ({ children }) => {
  const portalRef = useRef(document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(portalRef.current);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      document.body.removeChild(portalRef.current);
    };
  }, []);

  return ReactDOM.createPortal(children, portalRef.current);
};

export default Portal;
