// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventMap = Record<string, any>;

type EventKey<T extends EventMap> = string & keyof T;
type EventReceiver<T> = (params: T) => void;

class EventEmitter<T extends EventMap> {
  private listeners: {
    [K in keyof EventMap]?: ((p: EventMap[K]) => void)[];
  } = {};

  on<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>) {
    this.listeners[eventName] = (this.listeners[eventName] || []).concat(fn);
  }

  off<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>) {
    this.listeners[eventName] = (this.listeners[eventName] || []).filter((f) => f !== fn);
  }

  emit<K extends EventKey<T>>(eventName: K, params?: T[K]) {
    (this.listeners[eventName] || []).forEach((fn) => {
      fn(params);
    });
  }
}

export default EventEmitter;
