import { ChangeEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useLocale from 'hooks/useLocale';
import usePreferences from 'hooks/usePreferences';

import styles from 'sharedComponents/LanguageSwitcher/LanguageSwitcher.scss';
import { changeLocale } from 'store/language/slice';
import { Locales } from 'i18n';

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const { updatePreference, preferences } = usePreferences();
  const locale = useLocale();

  useEffect(() => {
    if (preferences.locale) {
      dispatch(changeLocale(preferences.locale));
    }
  }, [dispatch, preferences.locale]);

  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    dispatch(changeLocale(value as Locales));
    updatePreference('locale', value);
  };

  return (
    <select className={styles.select} value={locale} onChange={handleLanguageChange} data-testid="language_switcher">
      <option value={Locales.fi}>Suomeksi</option>
      <option value={Locales.en}>English</option>
      <option value={Locales.se}>Svenska</option>
    </select>
  );
};

export default LanguageSwitcher;
