import React from 'react';
import { ICON_SIZE } from 'common/icons/constants';
import { IconProps } from 'common/icons/IconProps';

const BillingIcon: React.FC<IconProps> = ({ size = ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height={size} width={size}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M6.5 10h-2v7h2v-7zm6 0h-2v7h2v-7zm8.5 9H2v2h19v-2zm-2.5-9h-2v7h2v-7zm-7-6.74L16.71 6H6.29l5.21-2.74m0-2.26L2 6v2h19V6l-9.5-5z" />
  </svg>
);

export default BillingIcon;
