/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/ban-types */
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { nanoid } from '@reduxjs/toolkit';

import { ModalContext } from 'providers/ModalProvider';

type ModalData<T extends (...args: any[]) => any> = Omit<
  Parameters<T>[0],
  'show' | 'onHide' | 'onAnimationEnd' | 'children'
>;

/**
 * example:
 * ```
 * MyModalProps = { field: string, optional?: boolean };
 * const [show] = useModal(MyModalProps, { field });
 * show(); // show will not ask for `field`, but you can override it
 *
 * const [show] = useModal(MyModalProps);
 * show(); show will complain about missing field
 * ```
 */
export default function useModal<T extends (...args: any[]) => any, R extends ModalData<T>, D extends Partial<R> = {}>(
  component: T,
  data?: D,
  onHide?: () => void,
) {
  const key = useMemo<string>(nanoid, []);
  const context = useContext(ModalContext);
  const showModal = useCallback(
    // TODO: it's not working when calling without modal data as it's optional...
    (modalData?: Omit<R, keyof D> & Partial<D>) => {
      context.showModal(key, component, { ...data, ...modalData }, onHide);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, context.showModal],
  );
  const hideModal = useCallback(
    () => context.hideModal(key, onHide),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context.hideModal, onHide, key],
  );

  useEffect(
    () => () => {
      context.removeModal(key);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [showModal, hideModal];
}
