import { useIntl } from 'react-intl';
// It's giving error on MultiValueGenericProps that it doesn't exist, but it exported in react-select
// @ts-ignore
import { components, IndicatorProps, OptionTypeBase, MultiValueGenericProps, MenuListProps } from 'react-select';
import { FixedSizeList as List } from 'react-window';

import Badge, { BadgeList } from 'common/Badge';
import CloseIcon from 'common/icons/CloseIcon';
import ArrowDropDownIcon from 'common/icons/ArrowDropDownIcon';

import commonMessages from 'translations/CommonTranslations/messages';

import colors from 'assets/styles/variablesExported.scss';

export const DropdownIndicator = <Option extends OptionTypeBase, IsMulti extends boolean>(
  props: IndicatorProps<Option, IsMulti>,
) => (
  <components.DropdownIndicator {...props}>
    <ArrowDropDownIcon />
  </components.DropdownIndicator>
);

export const ClearIndicator = <Option extends OptionTypeBase, IsMulti extends boolean>(
  props: IndicatorProps<Option, IsMulti>,
) => (
  <components.ClearIndicator {...props}>
    <CloseIcon />
  </components.ClearIndicator>
);

export const MenuList = <Option extends OptionTypeBase, IsMulti extends boolean>(
  props: MenuListProps<Option, IsMulti>,
) => {
  const itemHeight = 35;
  const { options, children, maxHeight, getValue } = props;

  const [value] = getValue();
  const initialOffset = options.indexOf(value) * itemHeight;

  return (
    <List
      height={children.length > 0 ? maxHeight : 0} // When all the options are selected, don't show the empty list
      itemCount={children.length}
      itemSize={itemHeight}
      width="100%"
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

export const MultiValue = <Option extends MultiValueGenericProps, IsMulti extends boolean>({
  ...props
}: MultiValueGenericProps<Option, IsMulti>) => {
  const { formatMessage } = useIntl();
  const {
    index,
    getValue,
    selectProps: { toggleShowAll, showAll },
  } = props;
  const selectedOptions = getValue();

  const maxToShow = 9;
  const totalOverTheMaxSelectedOptions = selectedOptions.slice(maxToShow).length;

  const showOption = () => <components.MultiValue {...props}>{props.children}</components.MultiValue>;

  if (index + 1 === selectedOptions.length && showAll) {
    return (
      <>
        {showOption()}
        <BadgeList>
          <Badge active onClick={toggleShowAll} backgroundColor={colors.grey4}>
            {formatMessage(commonMessages.hide)}
          </Badge>
        </BadgeList>
      </>
    );
  }

  if (showAll || index < maxToShow) {
    return showOption();
  }

  if (!showAll && index === maxToShow) {
    return (
      <BadgeList>
        <Badge active onClick={toggleShowAll}>
          + {totalOverTheMaxSelectedOptions} {formatMessage(commonMessages.selected)}
          <ArrowDropDownIcon />
        </Badge>
      </BadgeList>
    );
  }

  return null;
};
