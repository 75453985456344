import { RouteComponent } from 'types/Routes';
import { AlwaysOnFeatures } from 'types/Features';
import { getCurrentDateInDbFormat } from './dateUtil';

export const taskRedirect = () => {
  const homeState = process?.env?.CONFIG?.homeState;
  return `${homeState}/day/${getCurrentDateInDbFormat()}`;
};

const alwaysOn = Object.values(AlwaysOnFeatures);

const filterRoutes = (routes: RouteComponent[], features: { [key: string]: boolean }): RouteComponent[] =>
  routes
    .filter(({ name }: RouteComponent) => features[name] || alwaysOn.find((f) => f === name))
    .map((route) => {
      if (route.childRoutes && route.childRoutes.length) {
        return { ...route, childRoutes: filterRoutes(route.childRoutes, features) };
      }
      return route;
    });

export const routeFilterer = (
  routeCreator: () => RouteComponent[],
  features: { [key: string]: boolean } = process.env.CONFIG.features,
) => {
  const routes = routeCreator();
  return filterRoutes(routes, features);
};
