import React from 'react';
import { ICON_SIZE } from 'common/icons/constants';
import { IconProps } from 'common/icons/IconProps';

const CalendarIcon: React.FC<IconProps> = ({ size = ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 60 60" height={size} width={size}>
    <g>
      <path
        d="M50.7,7.8H9.3c-0.5,0-1,0.4-1,1v42.4c0,0.5,0.4,1,1,1h41.4c0.5,0,1-0.4,1-1V8.8C51.7,8.3,51.2,7.8,50.7,7.8z
		 M48.2,47.3c0,0.5-0.4,1-1,1H12.7c-0.5,0-1-0.4-1-1V20.6h36.5V47.3z"
      />
      <rect x="15.6" y="39.8" width="4.9" height="4.9" />
      <rect x="15.6" y="24" width="4.9" height="4.9" />
      <rect x="15.6" y="31.9" width="4.9" height="4.9" />
      <rect x="39.3" y="39.8" width="4.9" height="4.9" />
      <rect x="39.3" y="24" width="4.9" height="4.9" />
      <rect x="39.3" y="31.9" width="4.9" height="4.9" />
      <rect x="23.5" y="39.8" width="4.9" height="4.9" />
      <rect x="23.5" y="24" width="4.9" height="4.9" />
      <rect x="23.5" y="31.9" width="4.9" height="4.9" />
      <rect x="31.4" y="39.8" width="4.9" height="4.9" />
      <rect x="31.4" y="24" width="4.9" height="4.9" />
      <rect x="31.4" y="31.9" width="4.9" height="4.9" />
    </g>
  </svg>
);

export default CalendarIcon;
