import React from 'react';
import { ICON_SIZE } from 'common/icons/constants';
import { IconProps } from 'common/icons/IconProps';

const RaiStatsIcon: React.FC<IconProps> = ({ size = ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" height={size} width={size}>
    <g>
      <polygon points="14.5,20.5 10.5,20.5 10.5,75.5 10.5,79.5 14.5,79.5 73.5,79.5 73.5,75.5 14.5,75.5 	" />
      <rect x="21.5" y="39.5" width="9" height="32" />
      <rect x="39.5" y="26.5" width="9" height="45" />
      <rect x="57.5" y="53.5" width="9" height="18" />
      <path
        d="M79.67,43.71h-1.56l-0.55-0.53c1.93-2.25,3.09-5.16,3.09-8.33c0-7.07-5.73-12.81-12.81-12.81
		c-7.07,0-12.81,5.73-12.81,12.81s5.73,12.81,12.81,12.81c3.17,0,6.09-1.16,8.33-3.09l0.53,0.55v1.56l9.85,9.83l2.94-2.94
		L79.67,43.71z M67.85,43.71c-4.91,0-8.87-3.96-8.87-8.87c0-4.91,3.96-8.87,8.87-8.87c4.91,0,8.87,3.96,8.87,8.87
		C76.71,39.75,72.75,43.71,67.85,43.71z"
      />
    </g>
  </svg>
);

export default RaiStatsIcon;
