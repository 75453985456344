import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { AxiosError } from 'axios';

import { extractErrorMsg } from 'utils/api/utils';

interface Options<T = never> {
  keepData?: boolean;
  filterData?: (data: T) => T;
}

/**
 * Simple useApi hook
 * usage:
 * ```typescript
 * const {data, response, loading, fetch, error} = useApi(getCustomer);
 *
 * useEffect(() => {
 *   fetch(customerId);
 * }, [fetch, customerId]);
 * ```
 * @param callback
 * @param defaultValue
 * @param options
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useApi = <T extends (...args: any[]) => any, R = any, D extends Awaited<ReturnType<T>>['data'] = null>(
  callback: T,
  defaultValue?: D | null,
  options?: Options<D>,
): {
  response: null | Awaited<ReturnType<T>>;
  data: D | Awaited<ReturnType<T>>['data'];
  setData: (
    data:
      | ((prevState: Awaited<ReturnType<T>>['data'] | D) => Awaited<ReturnType<T>>['data'] | D)
      | Awaited<ReturnType<T>>['data']
      | D,
  ) => void;
  loading: boolean;
  fetch: (...args: Parameters<T>) => Promise<void>;
  error: null | AxiosError<R>;
  errorMsg?: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
} => {
  const [response, setResponse] = useState<null | Awaited<ReturnType<T>>>(null);
  const [data, setData] = useState<Awaited<ReturnType<T>>['data']>(defaultValue || null);
  const [error, setError] = useState<null | AxiosError<R>>(null);
  const [loading, setLoading] = useState(false);

  const errorMsg = useMemo(() => {
    if (!error) {
      return null;
    }
    try {
      return extractErrorMsg(error);
    } catch {
      return error.toString();
    }
  }, [error]);

  const fetch = useCallback(
    async (...args: Parameters<T>) => {
      setLoading(true);
      if (!options?.keepData) {
        setData(defaultValue || null);
        setResponse(null);
      }
      setError(null);
      try {
        const res = await callback(...args);
        if (options?.filterData) {
          const filteredData = options.filterData(res.data);
          setResponse(res);
          setData(filteredData);
        } else {
          setResponse(res);
          setData(res.data);
        }
      } catch (e) {
        setResponse(null);
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    // don't update when defaultValue is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback],
  );

  return { data, setData, response, loading, fetch, error, errorMsg, setLoading };
};

export default useApi;
