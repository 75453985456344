import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getTransferrableReports, getTransferredReports, postReportTransfer } from 'services/Kanta';

import { CustomerReport } from 'types/CustomerReport';

export type EArkistoState = {
  transferrableReports: CustomerReport[];
  transferredReports: CustomerReport[];
};

export const initialState: EArkistoState = {
  transferrableReports: [],
  transferredReports: [],
};

// thunk for getting transferred documents
export const getTransferredReportThunk = createAsyncThunk('eArkisto/getTransferredDocuments', async () => {
  const response = await getTransferredReports();
  return response.data;
});

// thunk for getting transferrable documents
export const getTransferrableReportThunk = createAsyncThunk('eArkisto/getTransferrableDocuments', async () => {
  const response = await getTransferrableReports();
  return response.data;
});

// thunk for posting transfer
export const postTransferDocument = createAsyncThunk('eArkisto/postTransferDocument', async (data: any) => {
  const response = await postReportTransfer(data);
  return response.data;
});

export const eArkistoSlice = createSlice({
  name: 'eArkisto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransferredReportThunk.fulfilled, (draft, action) => {
        draft.transferredReports = action.payload;
      })
      .addCase(getTransferrableReportThunk.fulfilled, (draft, action) => {
        draft.transferrableReports = action.payload;
      });
  },
});

// export const {} = eArkistoSlice.actions;

export default eArkistoSlice.reducer;
