/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';

import DP from 'common/form/pickers/DatePicker';
import { DateRangeProps, Mode } from 'common/form/pickers/DatePicker/DatePicker';

import CustomInput from './CustomInput';
import { DateRangeType } from './types';

type Props = {
  value: DateRangeType;
  name: string;
  numberOfDaysAllowed?: number;
  onChange: (dateRange: DateRangeType) => void;
  testId?: string;
  inputId?: string;
  error?: string;
  disabled?: boolean;
  onBlur?: () => void;
};

const DatePicker: FC<DateRangeProps> = (props) => <DP {...(props as any)} />;

const DateRangePicker = ({ onChange, ...props }: Props) => (
  <div className="d-flex w-100">
    <DatePicker
      onChange={(dateRange: DateRangeType) => onChange(dateRange)}
      mode={Mode.DATE_RANGE}
      renderInput={({ inputProps, onIconClick, Icon }) => (
        <CustomInput onIconClick={onIconClick} Icon={Icon} inputProps={inputProps as { value: DateRangeType }} />
      )}
      {...props}
    />
  </div>
);

export default DateRangePicker;
