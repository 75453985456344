import moment from 'moment';

import { DutyStates } from 'Duties/types/DutyStates';

export const DEFAULT_LAZY_LOAD_OFFSET = 0;

export const LOAD_DUTY_LIST = 'app/duties/LOAD_DUTY_LIST';
export const LOAD_DUTY_LIST_SUCCESS = 'app/duties/LOAD_DUTY_LIST_SUCCESS';
export const LOAD_DUTY_LIST_FAILED = 'app/duties/LOAD_DUTY_LIST_FAILED';

export const LOAD_CATEGORIES = 'app/duties/LOAD_CATEGORIES';
export const LOAD_CATEGORIES_SUCCESS = 'app/duties/LOAD_CATEGORIES_SUCCESS';
export const LOAD_CATEGORIES_FAILED = 'app/duties/LOAD_CATEGORIES_FAILED';

export const GET_DUTY_DETAIL = 'app/duties/GET_DUTY_DETAIL';
export const GET_DUTY_DETAIL_SUCCESS = 'app/duties/GET_DUTY_DETAIL_SUCCESS';
export const GET_DUTY_DETAIL_FAILED = 'app/duties/GET_DUTY_DETAIL_FAILED';

export const CLOSE_DUTY_DETAIL = 'app/duties/CLOSE_DUTY_DETAIL';

export const FETCH_MORE = 'app/duties/FETCH_MORE';
export const FETCH_MORE_SUCCESS = 'app/duties/FETCH_MORE_SUCCESS';
export const FETCH_MORE_FAILED = 'app/duties/FETCH_MORE_FAILED';

export const UPDATE_DUTY = 'app/duties/UPDATE_DUTY';
export const UPDATE_DUTY_SUCCESS = 'app/duties/UPDATE_DUTY_SUCCESS';
export const UPDATE_DUTY_FAILED = 'app/duties/UPDATE_DUTY_FAILED';

export const ADD_CATEGORY_SUCCESS = 'app/duties/ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILED = 'app/duties/ADD_CATEGORY_FAILED';

export const UPDATE_CATEGORY_SUCCESS = 'app/duties/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILED = 'app/duties/UPDATE_CATEGORY_FAILED';

export const HAS_NEXT_PAGE = 'app/duties/HAS_NEXT_PAGE';

export const LOAD_CATEGORY_DUTIES = 'app/duties/LOAD_CATEGORY_DUTIES';
export const LOAD_CATEGORY_DUTIES_SUCCESS = 'app/duties/LOAD_CATEGORY_DUTIES_SUCCESS';
export const LOAD_CATEGORY_DUTIES_FAILED = 'app/duties/LOAD_CATEGORY_DUTIES_FAILED';

export const FETCH_MORE_CATEGORY_DUTIES_SUCCESS = 'app/duties/FETCH_MORE_CATEGORY_DUTIES_SUCCESS';
export const FETCH_MORE_CATEGORY_DUTIES_FAILED = 'app/duties/FETCH_MORE_CATEGORY_DUTIES_FAILED';

export const MORE_DUTIES_EXISTED_IN_CATEGORY = 'app/duties/MORE_DUTIES_EXISTED_IN_CATEGORY';

export const CATEGORY_UPDATED_SUCCESS = 'app/duties/CATEGORY_UPDATED_SUCCESS';
export const CATEGORY_UPDATED_FAILED = 'app/duties/CATEGORY_UPDATED_FAILED';

export const LOAD_CATEGORIES_PRIVILEGES_SUCCESS = 'app/duties/LOAD_CATEGORIES_PRIVILEGES_SUCCESS';
export const LOAD_CATEGORIES_PRIVILEGES_FAILED = 'app/duties/LOAD_CATEGORIES_PRIVILEGES_FAILED';

// default duty states
export const DUTY_STATUS_LIST = [DutyStates.NOT_STARTED, DutyStates.IN_PROGRESS, DutyStates.DONE];

// default assignees values
export const INITIAL_ASSIGNEES_VALUES = {
  dateAssigned: moment().format('YYYY-MM-DDTHH:mm:ss'),
  state: DutyStates.NOT_STARTED,
  isCancelled: false,
};

export const RESET_STATES = 'app/duties/RESET_STATES';
