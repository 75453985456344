import { useIntl } from 'react-intl';

import BaseError from 'common/Errors/BaseError';

import errorImg from 'assets/images/error-pages/server_down.svg';
import messages from 'common/Errors/ModuleLoadError/messages';

type Props = {
  title?: string;
  text?: string;
};

/**
 * Module Load Error aka `ChunkLoadError`
 */
const ModuleLoadError = ({ text, title }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <BaseError errorImg={errorImg} title={title || formatMessage(messages.title)}>
      {text || formatMessage(messages.text)}
    </BaseError>
  );
};

export default ModuleLoadError;
