import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Locales, storedLocale } from 'i18n';

export const LANGUAGE_REDUCER_NAME = 'language';

export type LanguageState = {
  locale: Locales;
};

const initialState = {
  locale: storedLocale,
} as LanguageState;

const slice = createSlice({
  name: LANGUAGE_REDUCER_NAME,
  initialState,
  reducers: {
    changeLocale(draft, action: PayloadAction<Locales>) {
      draft.locale = action.payload;
    },
  },
});

export const { changeLocale } = slice.actions;

export default slice.reducer;
