import React from 'react';
import { ICON_SIZE } from 'common/icons/constants';
import { IconProps } from 'common/icons/IconProps';

const SettingsIcon: React.FC<IconProps> = ({ size = ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 60 60" height={size} width={size}>
    <path
      d="M54.9,35.1L50.2,32c0.1-0.7,0.1-1.3,0.1-2c0-0.6,0-1.2-0.1-1.8l4.7-3.1c0.4-0.3,0.7-0.6,0.6-1.1l-1.3-4
	c-0.2-0.5-0.5-0.6-1.1-0.6l-5.6,0.3c-0.6-1.1-1.4-2.1-2.2-3l2-5.3c0.2-0.5,0.2-0.9-0.2-1.2l-3.4-2.5c-0.4-0.3-0.7-0.2-1.2,0.2
	L38,11.4c-1.1-0.5-2.3-0.9-3.5-1.2L33,4.8c-0.1-0.6-0.3-0.9-0.9-0.9h-4.2c-0.5,0-0.8,0.4-0.9,0.9l-1.5,5.4c-1.2,0.3-2.4,0.7-3.5,1.2
	l-4.4-3.5c-0.5-0.4-0.8-0.5-1.2-0.2L13,10.2c-0.4,0.3-0.4,0.7-0.2,1.2l2,5.3c-0.8,1-1.6,2-2.3,3.1L7,19.5c-0.6-0.1-0.9,0-1.1,0.6
	l-1.3,4c-0.2,0.5,0.1,0.8,0.6,1.1l4.7,3.1c0,0.6-0.1,1.1-0.1,1.7c0,0.6,0,1.3,0.1,1.9L5.1,35c-0.5,0.3-0.7,0.6-0.6,1.1l1.3,4
	c0.2,0.5,0.6,0.6,1.1,0.6l5.6-0.2c0.6,1.1,1.4,2.1,2.2,3l-2,5.3c-0.2,0.6-0.2,0.9,0.2,1.2l3.4,2.5c0.4,0.3,0.8,0.1,1.2-0.2l4.4-3.5
	c1.1,0.5,2.3,0.9,3.5,1.1l1.5,5.4c0.1,0.5,0.3,0.9,0.9,0.9h4.2c0.5,0,0.7-0.3,0.9-0.9l1.5-5.4c1.2-0.3,2.4-0.7,3.5-1.1l4.4,3.5
	c0.4,0.3,0.8,0.5,1.2,0.2l3.4-2.5c0.4-0.3,0.4-0.6,0.2-1.2l-2-5.3c0.8-1,1.6-2,2.2-3.1l-0.1,0.2l5.7,0.2c0.5,0,0.9,0,1.1-0.6l1.3-4
	C55.6,35.7,55.4,35.4,54.9,35.1z M30,40.4c-5.7,0-10.4-4.7-10.4-10.4S24.3,19.6,30,19.6c5.7,0,10.4,4.7,10.4,10.4S35.7,40.4,30,40.4
	z"
    />
  </svg>
);

export default SettingsIcon;
