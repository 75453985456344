import { AnyAction } from '@reduxjs/toolkit';
import {
  NETWORK_REQUEST_ERROR_NOTIFY,
  NETWORK_REQUEST_MESSAGE_DISMISS,
  NETWORK_REQUEST_SUCCESS_NOTIFY,
} from './constants';

export type NetworkResponseState = {
  message: string;
  personalizedErrorMessage: boolean;
  statusCode: string;
  internalErrorCode: string;
  explanation: string;
  developerMessage: string;
  notificationType: string;
};

const initialState: NetworkResponseState = {
  message: '',
  personalizedErrorMessage: false,
  statusCode: '',
  internalErrorCode: '',
  explanation: '',
  developerMessage: '',
  notificationType: '',
};

// eslint-disable-next-line default-param-last
function notificationReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case NETWORK_REQUEST_ERROR_NOTIFY:
      return {
        ...state,
        message: action.message,
        personalizedErrorMessage: action.personalizedErrorMessage,
        statusCode: action.statusCode,
        internalErrorCode: action.internalErrorCode,
        explanation: action.explanation,
        developerMessage: action.developerMessage,
        notificationType: 'error',
      };
    case NETWORK_REQUEST_SUCCESS_NOTIFY:
      return {
        ...state,
        message: action.message,
        personalizedErrorMessage: action.personalizedErrorMessage,
        notificationType: 'success',
      };
    case NETWORK_REQUEST_MESSAGE_DISMISS:
      return { ...state, message: '', personalizedErrorMessage: '', notificationType: '' };
    default:
      return state;
  }
}

export default notificationReducer;
