import React, { useEffect, useState } from 'react';
import { Options } from '@popperjs/core';
import cn from 'classnames';
import { usePopper } from 'react-popper';
import useOutsideClick from 'hooks/useOutsideClick';
import styles from './Popper.scss';

type Props = {
  referenceElement: React.RefObject<HTMLElement>;
  className?: string;
  onClose: () => void;
} & Partial<Options>;

const Popper: React.FC<Props> = ({ referenceElement, className, children, onClose, ...options }) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement.current, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    ...options,
  });

  useOutsideClick(referenceElement, () => {
    onClose();
  });

  useEffect(() => {
    const el = popperElement as HTMLDivElement;
    if (el) {
      el.style.opacity = '1';
    }
  }, [popperElement]);

  return (
    <div
      ref={setPopperElement}
      className={cn(styles.root, className)}
      style={popperStyles.popper}
      {...attributes.popper}
    >
      {children}
    </div>
  );
};

export default Popper;
