import { MedicineType, PrescriptionPayload } from 'EResepti/types';

export enum MODE {
  CREATE = 'CREATE',
  MODIFY = 'MODIFY',
  RENEW = 'RENEW',
}

export const MEDICATION_STORAGE_KEY = 'med';
export const ATC_STORAGE_KEY = 'atc';
export const initialCocktail = {
  substance: null,
  uncodedName: '',
  amountText: '',
};
export const defaultForm = {
  careTypeCodeSickness: true,
  careTypeCodeWorkAccident: null,
  careTypeCodeOther: null,
  recipeCategory: 1,
  packageSize: null,
  productCategory: null,
  substances: null,
  // vnrItem: null,
  vnrId: null,
  vnrName: null,
  medicineATC: null,
  strengthAndUnit: null,
  pharmaceuticalForm: null,
  uncodedProductName: null,
  dosageType: '1',
  packageAsText: null,
  packageCount: null,
  quantityValue: null,
  quantityUnit: null,
  effectiveTimeDuration: null,
  effectiveTimeDurationUnit: null,
  dosageText: null,
  purposeAsText: null,
  messageToPharmacy: null,
  renewalProhibition: null,
  renewalProhibitionReason: null,
  iterationCount: null,
  iterationFrequency: null,
  iterationFrequencyUnit: null,
  iterationAsText: null,
  medicationChangeProhibition: null,
  permanentMedication: null,
  drugUsageStart: null,
  infectionMedication: null,
  pkvPrescriptionCode: null,
  physicianFeeSpecialized: null,
  reward: null,
  separateCode: null,
  doctorsNote: null,
  dosageDistribution: null,
  dosageDistributionAsText: null,
  ATCSubstance: null,
  packaging: null,
  patient: null,
  atcCode: null,
  narcotic: null,
  // think about these. Think about it
  // NON_VNR_WARNING: null,
  // deadDrop: null,
  // instructions: null,
  modificationReasonCode: null,
  recipeType: '1',
  medicineType: MedicineType.VNR,
} as unknown as Partial<PrescriptionPayload>;

export const printSummaryOptions = [
  { value: 'Potilas itse', label: 'Potilas itse' },
  { value: 1, label: 'Huoltaja' },
  { value: 2, label: 'Valtuutettu' },
  { value: 3, label: 'Määrätty edunvalvoja' },
  { value: 4, label: 'Muu' },
];

export const printSummaryQueryReason = [
  {
    label: 'normaali, suomenkielinen yhteenvetotuloste',
    value: 5,
  },
  {
    label: 'ulkomaan yhteenveto',
    value: 23,
  },
];
