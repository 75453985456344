import React from 'react';
import { useSwipeable } from 'react-swipeable';
import cn from 'classnames';
import useAppContext from 'hooks/useAppContext';
import { SIDE_NAV_STATE } from 'providers/AppProvider';
import usePrivileges from 'hooks/usePrivileges';
import { contentVisible } from 'sharedComponents/Sidenav/utils';
import items from '../../sidenav-items';
import SideNavItem from '../SideNavItem/SideNavItem';

import styles from './SideNavContent.scss';

type Props = {
  isSmallScreen: boolean;
  currentLocation: string;
  onOpenSideNav: (open?: SIDE_NAV_STATE) => void;
};

const SideNavContent: React.FC<Props> = ({ isSmallScreen, currentLocation, onOpenSideNav }) => {
  const { openSideNav } = useAppContext();
  const privileges = usePrivileges();
  const swipeHandler = () => {
    if (isSmallScreen) onOpenSideNav();
  };
  const handlers = useSwipeable({
    onSwipedLeft: swipeHandler,
    onSwipedRight: swipeHandler,
  });

  return (
    <nav className={cn(styles.root, { [styles.contentWidth]: openSideNav === SIDE_NAV_STATE.OPENED })} {...handlers}>
      <div className={styles.nav__menu}>
        {privileges &&
          !!Object.keys(privileges).length &&
          items
            .filter((item) => contentVisible(item, privileges))
            .map((item) => <SideNavItem detail={item} key={item.key} currentLocation={currentLocation} />)}
      </div>
    </nav>
  );
};

export default SideNavContent;
