import React from 'react';
import { ICON_SIZE } from 'common/icons/constants';
import { IconProps } from 'common/icons/IconProps';

const CustomerIcon: React.FC<IconProps> = ({ size = ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 60 60" height={size} width={size}>
    <g>
      <path
        d="M40.6,48.2c-12.6,0-25.2,0-37.8,0c0-1,0-1.1,0-1.6c0.1-1.6,0.4-3.1,1.1-4.5c0.7-1.5,1.7-2.6,3.1-3.4
		c2.2-1.2,4.5-2,6.8-2.8c0.9-0.3,1.8-0.6,2.6-1c0.8-0.3,1.4-1.5,1.3-2.3c0-0.1-0.1-0.2-0.2-0.3c-1.9-2.6-2.8-5.6-3.3-8.7
		c-0.3-1.7-0.3-3.4,0-5.2c0.6-3,2.4-4.8,5.4-5.2c1.4-0.2,2.8-0.2,4.2,0.1c3,0.6,4.8,2.4,5.2,5.5c0.5,3.4-0.2,6.7-1.4,9.8
		c-0.5,1.2-1.1,2.2-1.7,3.3c-0.1,0.2-0.2,0.4-0.2,0.6c0.1,1.3,0.5,2.3,1.9,2.7c1.8,0.6,3.7,1.3,5.5,2c1.1,0.5,2.3,1,3.4,1.6
		c1.9,1,3,2.6,3.6,4.6c0.4,1.4,0.6,2.9,0.6,4.4C40.6,47.9,40.6,48.2,40.6,48.2z"
      />
      <path
        d="M57.2,42.2c-5.6,0-10.6,0-16.2,0c-0.8-6-13.3-8.1-13.3-8.1c1.9-1.1,3.9-1.6,6-2.3c0.8-0.3,1.5-0.5,2.3-0.8
		c0.7-0.3,1.2-1.3,1.1-2c0-0.1-0.1-0.2-0.1-0.3c-1.6-2.3-2.5-4.9-2.9-7.6c-0.2-1.5-0.3-3,0-4.5c0.5-2.6,2.1-4.2,4.8-4.5
		c1.2-0.2,2.5-0.2,3.7,0.1c2.6,0.5,4.2,2.1,4.5,4.8c0.4,3-0.2,5.9-1.3,8.6c-0.4,1-1,2-1.5,2.9c-0.1,0.2-0.2,0.3-0.1,0.5
		c0,1.1,0.5,2,1.6,2.4c1.6,0.5,3.2,1.1,4.8,1.7c1,0.4,2,0.9,2.9,1.4c1.7,0.9,2.6,2.3,3.2,4c0.4,1.3,0.5,2.3,0.5,3.7
		C57.2,42.2,57.2,42.2,57.2,42.2z"
      />
    </g>
  </svg>
);

export default CustomerIcon;
