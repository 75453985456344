/*
 * These are types that are from or used to interact with KANTA services like social archive or eArkisto
 * */

export type EArkistoDataView = {
  code: number;
  name: string;
  description: string;
};

export type CodeType = {
  descriptionFI: string;
  descriptionSV: string;
  identifier: string;
  lastModifiedDate: string;
  nameFI: string;
  systemCodesystem: string;
  validFrom: string;
  validTo: string;
  value: string;
};

// These are used to interact with Kanta koodistopalvelin through codeSystem endpoint (services/customer/socialArchive getCodeType is the method)
export enum CodeSystemValues {
  SpecialContentReason = '1.2.246.537.6.1288.201901',
}

export interface ReportTransferOrder {
  authorIdentifier?: string;
  reportId: number;
  versionNumber: number;
}

export interface EarkistoTransferOrder {
  reports: ReportTransferOrder[];
}

export enum EArkistoTransferOrderStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
}

export type EarkistoTransferOrderResponse = {
  id: number;
  reports: ReportTransferOrder[];
  userId: number;
  state: EArkistoTransferOrderStatus;
  totalReports: number;
};

export type AtostekLoginResponse = {
  uri: string;
};

export enum EArkistoLoginStatus {
  in_login = 'in_login',
  active = 'active',
  locked = 'locked',
  expired = 'expired',
}
