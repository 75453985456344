import { createSelector } from 'reselect';

import { RootState } from 'store/index';
import { AUTH_REDUCER_NAME, AuthState } from 'store/auth/slice';

/**
 * Direct selector to the AuthRequired state domain
 */
export const selectAuthRequiredDomain =
  () =>
  (state: RootState): AuthState =>
    state[AUTH_REDUCER_NAME];

export const selectUserInfo = () => createSelector(selectAuthRequiredDomain(), (authState) => authState.userInfo);
export const selectUnitInfo = () =>
  createSelector(selectAuthRequiredDomain(), (authState) => authState.userInfo.currentDomacareUser.unit);
/**
 * Default selector used by AuthRequired
 */

export const makeSelectAuthRequired = () => createSelector(selectAuthRequiredDomain(), (authState) => authState);

export default makeSelectAuthRequired;
