import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { State } from 'types/State';
import { getCustomerList } from 'services/customers';
import { Customer } from 'types/Customer';

export const CUSTOMERS_REDUCER_NAME = 'customers';

export const loadCustomers = createAsyncThunk(`${CUSTOMERS_REDUCER_NAME}/loadCustomers`, async () => {
  const response = await getCustomerList();
  return response.data;
});

export type CustomersState = {
  customersList: Customer[];
  state: State;
  error: null | SerializedError;
};

const initialState = {
  customersList: [],
  state: State.NOT_STARTED,
  error: null,
} as CustomersState;

const slice = createSlice({
  name: CUSTOMERS_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadCustomers.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(loadCustomers.fulfilled, (draft, action) => {
      draft.customersList = action.payload;
      draft.state = State.SUCCESS;
    });
    builder.addCase(loadCustomers.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
    });
  },
});

export default slice.reducer;
