import React from 'react';
import { IconProps } from 'common/icons/IconProps';
import { ICON_SIZE } from 'common/icons/constants';

const IndeterminateCheckBoxIcon: React.FC<IconProps> = ({ size = ICON_SIZE }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24" width={size}>
    <g>
      <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M17,13H7v-2h10V13z" />
    </g>
  </svg>
);

export default IndeterminateCheckBoxIcon;
