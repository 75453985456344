import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import cn from 'classnames';
import styles from 'common/form/InlineField.scss';
import Error from './Error';
import Label from './Label';

type Props = {
  label?: React.ReactNode;
  componentClassName?: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  name?: string;
  error?: string | MessageDescriptor;
  touched?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  value?: any;
  style?: React.CSSProperties;
  hideError?: boolean;
  onClick?: (event: any) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  children: React.ReactElement;
  testId?: string;
};

export type DomaInlineFieldProps<T> = Omit<Props, 'children'> & T;

/**
 * Internal wrapper for Form Fields, don't use it directly!
 * @private
 */
const InlineField: React.FC<Props> = ({
  children,
  label,
  touched,
  error,
  required,
  onClick,
  onBlur,
  onFocus,
  style,
  className,
  componentClassName,
  disabled,
  hideError,
  testId,
}) => {
  const { formatMessage } = useIntl();
  // must be touched to show error
  const showError = touched && error;
  return (
    <div className={cn(styles.root, className)} style={style}>
      <div
        className={cn(styles.inline, { [styles.disabled]: disabled })}
        onClick={(e) => {
          if (!disabled && onClick) {
            onClick(e);
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={0}
        data-testid={testId}
      >
        {React.cloneElement(children, { className: componentClassName, error: showError, disabled })}
        {label ? <Label required={required}>{label}</Label> : null}
      </div>
      {!hideError && showError ? <Error>{typeof error === 'string' ? error : formatMessage(error)}</Error> : null}
    </div>
  );
};

export default InlineField;
