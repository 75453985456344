import React from 'react';
import cn from 'classnames';
import styles from './BadgeList.scss';

const BadgeList: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children, className, ...rest }) => (
  <div className={cn(styles.root, className)} {...rest}>
    {children}
  </div>
);

export default BadgeList;
