import React from 'react';

import ArrowDownIcon from 'common/icons/ArrowDownIcon';
import ArrowRightIcon from 'common/icons/ArrowRightIcon';
import CloseIcon from 'common/icons/CloseIcon';
import { ICON_SIZE_XXL } from 'common/icons/constants';
import BurgerMenuIcon from 'common/icons/BurgerMenuIcon';

import { SIDE_NAV_STATE } from 'providers/AppProvider';

import DomaCareLogo from 'assets/images/brand/DomaCare_color_RGB_white_text.svg';

import styles from 'sharedComponents/TopHeader/TopHeader.scss';

type Props = {
  smallScreen: boolean;
  openSideNav: SIDE_NAV_STATE;
  onClick: () => void;
};

const SideNavHeader: React.FC<Props> = ({ smallScreen, onClick, openSideNav }) => (
  <div>
    {!smallScreen ? (
      <div className={styles.burgerMenuIconWrapper} onClick={onClick} role="button">
        <img src={DomaCareLogo} alt="DomaCareLogo" className={styles.logo} />
        {openSideNav === SIDE_NAV_STATE.OPENED ? <ArrowDownIcon /> : <ArrowRightIcon />}
      </div>
    ) : (
      <div className={styles.burgerMenuIconWrapperSmallScreen} onClick={onClick} role="button">
        {openSideNav === SIDE_NAV_STATE.OPENED ? (
          <CloseIcon size={ICON_SIZE_XXL} />
        ) : (
          <BurgerMenuIcon size={ICON_SIZE_XXL} />
        )}
      </div>
    )}
  </div>
);

export default SideNavHeader;
